<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="БИТВА")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7
        p Фан-рейтинг клуба — среднее количество голосов (сумма отданных за клуб голосов, делённая на число проголосовавших за него уникальных фанатов).
        p «Битва» — самый главный конкурс, в нем участники бьются за то, чтобы максимально поднять фан-рейтинг своего клуба и сделать его фан-чемпионом.
        p «Битва» проводится каждый месяц. Клуб-победитель попадет в «<a href="/glory-hall">Зал Славы</a>».
        p Самые активные болельщики получат ценные призы, смотри на страницах конкурсов 
          | «<a href="/inside/fight-common">Герой Битвы</a>» и «<a href="/inside/fight-club">Фан-лидер клуба</a>».
        //- p.mb-0 
          //- em Если самым активным игроком чемпионата и победившего клуба окажется
            //- |  один и тот же человек — он получит оба приза!
        div.mt-4.mobile_button
          v-btn.green_button(
            v-if="voices && club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="Vote"
          ) Отдать голос
          v-btn.green_button(
            v-else-if="club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="goTo('/inside/shop')"
          ) Получить ещё голос
      BitvaTimer.mt-5

      .subcontent_wrap
        h3 Турнирная таблица фан-рейтингов клубов РПЛ И ФНЛ в текущей Битве
        table
          tr.table_header
            th.place
              img(src="~@/assets/icons_and_logos/solar_cup-star-bold.svg")
            th.clubname Клуб
            th.rating Фан-Рейтинг *
          tr(v-for="(club, index) in clubs" :class="{'odd': index % 2, 'my_club': club.fc_id == my_club.fc_id}")
            td.place {{ index + 1 }}
            td.clubname
              .flex
                span {{ club.name }}
                v-btn.green_button(
                  v-if="voices && club.fc_id == my_club.fc_id"
                  x-large
                  dark
                  color="#04841F"
                  @click="Vote"
                ) Отдать голос
                v-btn.green_button(
                  v-else-if="club.fc_id == my_club.fc_id"
                  x-large
                  dark
                  color="#04841F"
                  @click="goTo('/inside/shop')"
                ) Получить ещё голос
            td.rating(v-if="club.rating") {{ ratingText(club.rating) }}
            td.rating(v-else) —
        p.note.mt-5 <span class="note_star">*</span> Фан-рейтинг клуба РПЛ показывается в таблице если за него проголосовали 500 и более уникальных болельщиков. Для клубов ФНЛ это количество начинается с 250.
        p.note.mt-2 <span class="note_star">**</span> Прочерк в графе «Фан-рейтинг» означает, 
            | что клуб не преодолел минимальный порог 
            | проголосовавших фанатов, см. 
            | «<a @click="rules = !rules">Правила Конкурса</a>»
        v-dialog(v-model="rules" width="900")
          v-card
            v-card-title.main_card_title.pt-2.pr-10 Правила конкурса «Битва фанатов» <i class="close_icon" @click="rules = !rules"></i>
            v-divider
            v-card-text.share_wrap.bp-0.main_card_txt
              v-row.pt-0.pb-2(align="start")
                v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
                  BitvaRulesText.pt-4

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { ratingText } from "../../../service/service"
import { championshipClubs } from "./../../../api/LandingPage"
import BitvaRulesText from '@/components/InsidePages/Bitva/BitvaRulesText'
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'BitvaCommands',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    BitvaRulesText,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      rules: false,
      clubs: [
        {
          "id": "1",
          "name": "Спартак",
          "rating": "3.2",
          "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
        },
        {
          "id": "2",
          "name": "Спартак",
          "rating": "3.2",
          "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
        }
      ],
      sub_menu: [
        {
          "title": "Фан-рейтинг клубов",
          "link": "/inside/fight"
        },
        {
          "title": "История Фан-рейтингов",
          "link": "/inside/fight-history"
        },
        // {
          // "title": "Рейтинг участников (ваш клуб)",
          // "link": "/inside/fight-club"
        // },
        // {
          // "title": "Рейтинг участников (общий)",
          // "link": "/inside/fight-common"
        // },
        {
          "title": "Правила",
          "link": "/inside/fight-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
    this.championshipClubs()
  },
  methods: {
    async championshipClubs () {
      let context = this
      let result = await championshipClubs(context)
      if (result) {
        console.log(result)
        this.clubs = result
      }
    },
    ratingText (rating) {
      return ratingText(rating)
    }
  }
}

</script>


<style lang="sass" scoped>

p.note
  font-size: 14px

table, th, td
  border: 1px solid white
  border-collapse: collapse
table
  table-layout: auto
  width: 100%
  border: 0
  margin-top: 10px
  .table_header
    width: 100%
    border-top-right-radius: 5px
    border-top-left-radius: 5px
    th
      background-color: #0D1D27
      color: #fff
      font-weight: 400
      font-size: 14px
      height: 43px
      padding: 0 5px
      line-height: 43px
      border-top: 0
      @media (max-width: 550px)
        font-size: 12px
    th.place
      width: 44px
      text-align: center
      padding: 0
      border-left: 0
      border-top-left-radius: 5px
      img
        position: relative
        top: 6px
    th.rating
      width: 85px
      border-right: 0
      line-height: 20px
      border-top-right-radius: 5px
      text-align: right
      @media (max-width: 550px)
        width: 70px
  tr
    td
      text-align: left
      font-weight: 400
      font-size: 16px
      padding: 0 5px
      height: 37px
      line-height: 37px
      @media (max-width: 550px)
        font-size: 14px
    td.place
      text-align: center
      padding: 0
      font-weight: 600
      font-size: 16px
      border-left: 0
      background-color: #fff
    td.clubname
      background-color: #fff
      .flex
        display: flex
        width: 100%
        justify-content: space-between
        align-items: center
        .green_button
          margin: 5px 0 7px
          @media (max-width: 550px)
            display: none
          
    td.rating
      border-right: 0
      background-color: #fff
      text-align: right
  tr.odd
    td.place, td.rating
      background-color: #EDEDED
    td.clubname
      background-color: #EDEDED
  tr.my_club
    td.place, td.rating, td.clubname
      background-color: #EAF2DF


</style>
    