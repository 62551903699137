<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ЛИЧНЫЙ КАБИНЕТ")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
          
      .avatar_data_wrap
        .picture_block
          img(v-if="picture" :src="picture")
          .faked_picture(v-else)
        .data_block
          .hello Привет, {{ username }}!
          ul
            li(v-if="club") 
              span(v-if="club.league == 1 && club_users_voice_given >= 500") Твой клуб ({{ club.name }} ({{ club.city }})) на {{ club_place }} месте (конкурс «<a href="/inside/fight">Битва</a>»)
              span(v-else-if="club.league == 2 && club_users_voice_given >= 250") Твой клуб ({{ club.name }} ({{ club.city }})) на {{ club_place }} месте
              span(v-else) Твой клуб ({{ club.name }} ({{ club.city }})) на {{ club_place }} <span class="question_info" v-tippy content="Твой клуб ещё не преодолел порог голосования. Фан-рейтинг клуба РПЛ учитывается, если за него проголосовали 500 и более уникальных болельщиков. Для клубов ФНЛ это количество начинается с 250.">!</span> месте
            li(v-if="personal_club_rating") 
              span(v-if="club.league == 1 && club_users_voice_given >= 500") Ты занимаешь {{ personal_club_rating }} место в клубном зачёте (конкурс «Фан-лидер клуба»))
              span(v-else-if="club.league == 2 && club_users_voice_given >= 250") Ты занимаешь {{ personal_club_rating }} место в клубном зачёте (конкурс «Фан-лидер клуба»))
              span(v-else) Ты занимаешь {{ personal_club_rating }} <span class="question_info" v-tippy content="Конкурс считается состоявшимся, когда твой клуб преодолеет порог голосования. Для клуба РПЛ этот порог составляет 500 и более голосов уникальных болельщиков. Для клуба ФНЛ — 250.">!</span> место в клубном зачёте (конкурс «Фан-лидер клуба»))
            li(v-if="personal_rating") Ты занимаешь {{ personal_rating }} место в общем зачёте (конкурс «Герой Битвы»)
            li Ты отдал {{ voices_given }} {{ word_num_cases(voices_given, "голос", "голоса", "голосов") }} за эту Битву
            div.mt-4.mobile_button
              v-btn.green_button(
                v-if="voices && club.fc_id == my_club.fc_id"
                x-large
                dark
                color="#04841F"
                @click="Vote"
              ) Отдать голос
              v-btn.green_button(
                v-else-if="club.fc_id == my_club.fc_id"
                x-large
                dark
                color="#04841F"
                @click="goTo('/inside/shop')"
              ) Получить ещё голос
      
      .inventory_wrap.pb-4
        h3 Инвентарь:
        .inventory_blocks
          .coins_block
            strong Мои баллы:
            p {{ coins }} <span>шт.</span>
          .shop_block(@click="goTo('/inside/shop')")
            img(src="../../../assets/icons_and_logos/cart.svg")
            span В магазин
          .voices_block
            strong Мои голоса:
            p {{ voices }} <span>шт.</span>
      
      BitvaTimer
      .hr_small
      .subcontent_wrap
        h3 Как выигрывать призы?
        ul
          li(v-if="club") Отдавай голоса в поддержку своего ФК {{ club.name }} ({{ club.city }})
          li Участвуй в конкурсах и поединках

      .subcontent_wrap
        h3 Как добывать голоса?
        ul
          li Играй в игру «<a href="/inside/match3">Добыча голосов</a>»
          li Покупай за баллы в игровом «<a href="/inside/shop">Магазине</a>»

      .subcontent_wrap
        h3 Как добывать баллы?
        ul
          li Играй в мини-игру «<a href="/inside/ball">Добыча баллов</a>»
          li Занимай призовые места в конкурсах «<a href="/inside/fight-common">Герой Битвы</a>» и «<a href="/inside/fight-club">Фан-лидер клуба</a>»
          li Распространяй код своей карточки футболиста в конкурсе «<a href="/inside/dreamteam">Команда мечты</a>»
          li Побеждай в конкурсе «<a href="/inside/dreamteam">Команда мечты</a>»
          li Распространяй свою реферальную ссылку в конкурсе «<a href="/inside/share-win">Поделись и выиграй</a>»
          li Делай взносы в поединках «<a href="/inside/raznaraz">Раз на Раз</a>»
          li Продавай повторы марок из своей «<a href="/inside/stamps">Коллекции марок</a>»
          li Покупай в игровом «<a href="/inside/shop">Магазине</a>»

      .subcontent_wrap(v-if="notifications.length")
        h3.notifications_h3 Уведомления:
        .notification_wrap(v-for="notify in notifications")
          .notification 
            p {{ notify.description }}
            .notification_prize(v-if="notify.is_prize")
              img(src="../../../assets/icons_and_logos/gold_cup.svg")
          .datetime {{ notify.datetime }}
        //- .notification_wrap
          //- .notification 
            //- p Вы купили 20 баллов
            //- .notification_prize
              //- img(src="../../../assets/icons_and_logos/gold_cup.svg")
          //- .datetime 27.01.23 17:45
        //- .notification_wrap
          //- .notification 
            //- p Вы отдали голос за свой клуб
          //- .datetime 27.01.23 17:45
        //- .notification_wrap
          //- .notification 
            //- p Ваш клуб одержал победу в чемпионате. Вы получили голос
          //- .datetime 27.01.23 17:45
        //- .notification_wrap
          //- .notification 
            //- p Вы купили 20 баллов
          //- .datetime 27.01.23 17:45
        //- .notification_wrap
          //- .notification 
            //- p Вашу карточка попала в победившую сборную. Свяжитесь с нами для получения приза. Свяжитесь с нами для получения приза
          //- .datetime 27.01.23 17:45

        .notification_pagination
          //- img.left(
          //-   :class="{'unactive': page_num == 1}"
          //-   src="../../../assets/icons_and_logos/paginate-arrow-left.svg"
          //-   @click="reducePage"
          //- )
          //- span Страница <strong>{{ page_num }}</strong> из <strong>{{ pages_count }}</strong>
          //- img.right(
          //-   :class="{'unactive': page_num == pages_count}"
          //-   src="../../../assets/icons_and_logos/paginate-arrow-right.svg"
          //-   @click="increasePage"
          //- )
          span.paginate_page(
            v-if="page_num != 1"
            @click="reducePage"
          ) Предыдущая страница
          span.between(v-if="page_num != 1")
          span.paginate_page(
            v-if="page_num != pages_count && pages_count"
            @click="increasePage"
          ) Следующая страница

      

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { Notifications } from "../../../api/Inside"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import vueCustomScrollbar from "vue-custom-scrollbar"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'PersonalCabinet',
  components: {
    vueCustomScrollbar,
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      club: "club",
      club_rating: "club_rating",
      club_place: "club_place",
      voices_given: "voice_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
      picture: "picture",
      username: "username",
      club_users_voice_given: "club_users_voice_given"
    }),
    club_name: function () {
      if (this.club) {
        return this.club.name
      }
      return null
    },
    notifyses: function () {
      if (this.notifications) {
        return this.notifications
      }
      return null
    }
  },
  data () {
    return {
      page_num: 1,
      pages_count: 4,
      notifications: [],
      sub_menu: [
        {
          "title": "Моя страница",
          "link": "/inside/personal"
        },
        {
          "title": "Мои призы",
          "link": "/inside/my-wins"
        },
        {
          "title": "История покупок",
          "link": "/inside/purchases"
        },
        {
          "title": "Персональные данные",
          "link": "/inside/my-data"
        }
      ]
    }
  },
  mounted () {
    this.getNotifications(1)
    // this.$swal({
    //   "icon": "warning",
    //   "text": "Сайт работает в тестовом режиме. Пожалуйста, воздержитесь от покупок в «Магазине»!"
    // })
  },
  methods: {
    reducePage () {
      if (this.page_num != 1) {
        this.page_num -= 1
        this.getNotifications(this.page_num)
      }
    },
    increasePage () {
      if (this.page_num != this.pages_count) {
        this.page_num += 1
        this.getNotifications(this.page_num)
      }
    },
    async getNotifications (page) {
      let context = this
      let notifications = await Notifications(context, page)
      if (notifications) {
        this.page_num = notifications["data"]["page_num"]
        this.pages_count = notifications["data"]["pages_count"]
        this.notifications = notifications["data"]["notifications"]
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.question_info
  display: inline-flex
  margin: 0 3px
  width: 16px
  height: 16px
  background-color: #d23939
  border-radius: 3px
  align-items: center
  justify-content: center
  color: #fff
  font-size: 12px
  font-weight: 600

.avatar_data_wrap
  display: flex
  justify-content: flex-start
  align-items: flex-start
  flex-wrap: wrap
  margin-top: 20px
  border-bottom: 1px dotted #DDDDDD
  padding-bottom: 20px
  .picture_block
    margin-right: 20px
    width: 145px
    height: 145px
    display: flex
    justify-content: center
    align-items: center
    overflow: hidden
    @media (max-width: 550px)
      width: 100%
      margin-right: 0
    img
      width: 100%
      @media (max-width: 550px)
        display: block
        margin: 0 auto
    .faked_picture
      width: 145px
      height: 145px
      border-radius: 145px
      background-color: #DDD
      @media (max-width: 550px)
        margin: 0 auto
  .data_block
    text-align: left
    width: calc( 100% - 165px )
    @media (max-width: 550px)
      width: 100%
    .hello
      margin-bottom: 10px
      font-size: 18px
      font-weight: 600

.mobile_button
  display: none
  @media (max-width: 1050px)
    display: block
  
.subcontent_wrap
  width: 100%
  text-align: left
  border-bottom: 1px dotted #DDDDDD
  padding-bottom: 20px
  ul
    margin-top: 10px
    padding: 0
    li
      display: block
      padding-left: 15px
      position: relative
      margin-bottom: 2px
      &:before
        content: ""
        display: block
        width: 6px
        height: 6px
        border-radius: 10px
        background-color: #2c3e50
        position: absolute
        left: 0
        top: 8px
      a
        &:hover
          text-decoration: none
  .notifications_h3
    margin-bottom: 20px
  .notification_wrap
    width: 100%
    border: 2px solid #EFEFEF
    border-radius: 10px
    min-height: 44px
    display: flex
    justify-content: flex-start
    align-items: stretch
    margin-bottom: 10px
    .notification, .datetime
      min-height: 44px
      font-size: 14px
      font-weight: 300
      padding: 5px 10px
    .notification
      width: calc( 100% - 120px )
      background: linear-gradient(to right, #EFEFEF, #FFF)
      display: flex
      align-items: center
      justify-content: flex-start
      padding-right: 35px !important
      position: relative
      @media (max-width: 550px)
        width: calc( 100% - 100px )
        padding-right: 15px !important
      p
        line-height: 20px
        margin: 0
        padding: 0
        @media (max-width: 550px)
          font-size: 12px
          line-height: 18px
      .notification_prize
        position: absolute
        right: 0
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        width: 35px
    .datetime
      width: 120px
      text-align: center
      background-color: #EFEFEF
      border-top-right-radius: 8px
      border-bottom-right-radius: 8px
      display: flex
      align-items: center
      justify-content: center
      @media (max-width: 550px)
        width: 100px
        font-size: 12px
  .notification_pagination
    height: 25px
    display: flex
    align-items: center
    justify-content: flex-start
    font-size: 15px
    margin-top: 20px
    font-weight: 300
    img
      cursor: pointer
    img.unactive
      cursor: default
      filter: grayscale(1) opacity(0.7)
    img.left
      margin-right: 10px
    img.right
      margin-left: 10px

.inventory_wrap
  width: 100%
  border-bottom: 1px dotted #DDDDDD
  padding-bottom: 25px
  .inventory_blocks
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 10px
    .shop_block
      width: 100px
      height: 100px
      text-align: center
      cursor: pointer
      @media (max-width: 550px)
        width: 60px
      &:hover
        span
          border-bottom: 1px dashed transparent
      img
        margin-top: 8px
        @media (max-width: 550px)
          width: 40px
      span
        display: inline-block
        font-size: 14px
        line-height: 12px
        color: #3579A1
        border-bottom: 1px dashed #3579A1
        @media (max-width: 550px)
          font-size: 10px
        
    .coins_block, .voices_block
      width: calc( 50% - 50px )
      height: 100px
      background-color: #375567
      color: #fff
      border-radius: 10px
      position: relative
      display: flex
      justify-content: center
      align-items: center
      @media (max-width: 550px)
        width: calc( 50% - 30px )
        height: 60px
      strong
        position: absolute
        top: 5px
        left: 12px
        font-weight: 400
        @media (max-width: 550px)
          left: 0
          font-size: 13px
          width: 100%
          text-align: center
      p
        font-size: 26px
        font-weight: 600
        margin: 10px 0 0
        padding: 0
        position: relative
        top: 3px
        span
          font-weight: 300
        @media (max-width: 550px)
          font-size: 21px


</style>
    