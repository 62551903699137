// return value from object by name or default
const _v = (obj, name, defaultValue) => {
  if (obj[name] === undefined) {
    if (defaultValue === undefined) {
      console.error(`No value passed for '${name}'`);
    } else {
      return defaultValue;
    }
  } else {
    return obj[name];
  }
};

class SocketSettings {
  constructor(opts) {
    // socket server address
    this.address = _v(opts, "address");
    this.debug = _v(opts, "debug", false);
    this.autoConnect = _v(opts, "autoConnect", false);
  }
}

class AuthSettings {
  constructor(opts) {
    this.accessExp = _v(opts, "accessExp", "29min");
    this.refreshExp = _v(opts, "refreshExp", "3d");
  }
}

class Settings {
  constructor(opts) {
    this.env = _v(opts, "env", false);
    this.debug = _v(opts, "debug");
    // api
    this.address = _v(opts  , "address");
    // site
    this.addres = _v(opts  , "addres");
    this.ws_addres = _v(opts  , "ws_addres");
    // socket settings
    this.socket = new SocketSettings(opts.socket);
    // site without slash
    this.site = this.addres.substr(0,this.addres.length-1)
    this.auth = new AuthSettings(opts.auth)
  }
  // replaces [host_name] with address of api
  formatWithHostName(str) {
    return str.replace("[host_name]", this.address);
  }

  // replaces [host_name] with address of site
  formatWithSiteAddress(str) {
    return str.replace("[host_name]", this.site);
  }
}


function getSettings() {
  switch (process.env.NODE_ENV) {
    case  "production" : {
      return new Settings({
        env: process.env.NODE_ENV,
        debug: false,
        address: "https://bitvafanatov.ru:8000",
        addres: "https://bitvafanatov.ru:8000/",
        ws_addres: "wss://bitvafanatov.ru:8000/pvp/",
        // socketAddress: "https://inexsy.com:3000",
        socket: {
          address: "https://bitvafanatov.ru:3000",
          autoConnect: false,
          debug: false
        },
        auth : {}
      });
    }
    case "development" : {
      console.warn("DEVELOPMENT_LOCAL");
      return new Settings({
        env: process.env.NODE_ENV,
        debug: true,
        address: "http://localhost:8000",
        addres: "http://localhost:8000/",
        ws_addres: "ws://localhost:8000/pvp/",
        socket: {
          address: "http://localhost:3000",
          debug: true,
          autoConnect: false //автоподключение
        },
        auth : {

        }
      });
    }
    case "development_server" : {
      console.warn("development");
      return new Settings({
        env: process.env.NODE_ENV,
        debug: true,
        address: "http://bitva_back:8000",
        addres: "http://bitva_back:8000/",
        ws_addres: "ws://localhost:8000/pvp/",
        socket: {
          address: "http://bitva_back:3000",
          debug: true,
          autoConnect: false //автоподключение
        },
        auth : {

        }
      });
    }
    default: {
      console.error("no env provided", process.env.NODE_ENV);
    }
  }
}

export default getSettings();