<template lang="pug">
div
  h3.mb-4 Правила конкурса «Битва»
  ol
    li.mb-2 «Битва» — это главный Конкурс на нашем сайте, он проходит постоянно и его итоги подводятся каждый месяц.

    li.mb-2 Цель Конкурса заключается в том, чтобы выяснить болельщики какого футбольного клуба самые активные, коммуникабельные и преданные.

    li.mb-2 Для участия в этом Конкурсе Вам достаточно голосовать за свой клуб, это можно делать в любое время и неограниченное количество раз.

    li.mb-2 Голоса приобретаются только за «баллы» (все о «баллах» см. в «Пользовательском соглашении»). Голоса, израсходованные при голосовании, возврату не подлежат.

    li.mb-2 Под термином «<em>фанатский рейтинг футбольного клуба</em>» (далее — <strong><em>фан-рейтинг</em></strong>) понимается среднее арифметическое значение, равное сумме всех голосов, отданных болельщиками за данный футбольный клуб, деленной на число уникальных болельщиков, проголосовавших за этот клуб за определенный период времени. На сайте фан-рейтинги представляются в виде десятичных дробей, округленных по правилам арифметики до второго знака после запятой.

    li.mb-2 Текущий фан-рейтинг клуба пересчитывается каждые 5 минут с учетом всех отданных за него голосов, поэтому на сайте всегда указываются актуальные текущие фан-рейтинги клубов на момент их последнего пересчета.

    li.mb-2 Фан-рейтинг клуба начинает отображаться на сайте, если количество проголосовавших за него уникальных пользователей превышает определенное минимальное число (порог). Для Премьер-Лиги этот порог равен 500 человек, а для Первой лиги — 250. До преодоления этих порогов в турнирной таблице в графе фан-рейтинг ставится прочерк. Пороги вводятся для избежания статистически недостоверных результатов.

    li.mb-2 Если до конца месяца фанаты клуба не смогут преодолеть установленный порог, то их клуб не участвует в подведении итогов Конкурса за прошедший месяц.

    li.mb-2 В начале каждого месяца подсчет фан-рейтингов начинается заново, с нуля. На сайте можно посмотреть фан-рейтинги всех клубов за прошедшие месяцы.


    h4.mlminush4.mb-2.mt-4 Призы, бонусы, звания

    li.mb-2 В конце каждого месяца подводятся итоги этого Конкурса. По истечении последних суток месяца для всех клубов фиксируются их фан-рейтинги. Клуб с максимальным фан-рейтингом становится победителем этого Конкурса за прошедший месяц, получает почетное звание <em><strong>Фан-чемпион месяца</strong></em> и занимает место в Зале Славы. Если у нескольких клубов фан-рейтинги равны между собой (с машинной точностью), то победителем становится тот клуб, у которого дата и время последнего голосования самые ранние в прошедшем месяце.

    li.mb-2 В конце календарного года определяется <em><strong>Фан-чемпион года</strong></em> — клуб с максимальным фан-рейтингом по итогам прошедшего года.

    li.mb-2 В конце каждого месяца, вместе с итогами конкурса «Битва», подводятся итоги еще двух связанных с ним конкурсов – «Герой Битвы» и «Фан-лидер клуба».

    li.mb-2 В конкурсе «Герой Битвы» определяется, кто из болельщиков всех клубов отдал наибольшее количество голосов в этой «Битве». Победитель получает звание <em><strong>«Герой Битвы»</strong></em>, приз – Сертификат Ozon на 14 000 руб., бонус в 150 баллов и попадает в «Зал Славы». Участники из всех клубов, занявшие места со второго по десятое, получают по 150 баллов.

    li.mb-2 
      p.mb-2 В конкурсе «Фан-лидер клуба» победители определяются в двух номинациях.
      ol
        li.mb-2 Если клуб стал победителем «Битвы», то участник, отдавший наибольшее количество голосов за этот клуб, получает звание <em><strong>«Фан-лидер клуба-победителя»</strong></em>, приз – Сертификат Ozon на 14 000 руб., бонус в 100 баллов и попадает в «Зал Славы». Участники, занявшие места со второго по десятое, получают по 100 баллов. Все остальные участники, проголосовавшие за свой клуб-победитель, получают по 25 баллов.
        li.mb-2 Если клуб НЕ стал победителем «Битвы», то участник, отдавший наибольшее количество голосов за этот клуб, получает звание <em><strong>«Фан-лидер клуба»</strong></em> и бонус в 100 баллов. Участники, занявшие места со второго по десятое, получат по 50 баллов. Таким образом, в каждом клубе ежемесячно определяется свой «фан-лидер клуба», а если клуб стал еще и фан-чемпионом месяца (победителем «Битвы»), то фан-лидер этого клуба получает более почетное звание – «фан-лидер клуба-победителя».

    li.mb-2 Если один и тот же участник «Битвы» завоюет одновременно несколько призов, званий или бонусов в конкурсах «Герой битвы» и «Фан-лидер клуба», то он получит все призы, звания и бонусы за каждый из этих Конкурсов.
    li.mb-2 Победители получают призы в своих Личных Кабинетах. Файл сертификата становится доступен для скачивания не позднее, чем через 7 дней с момента окончания Конкурса.

  p
    strong
      em Призы ждут вас! Желаем удачи!

</template>

<script>
export default {
  name: 'BitvaRulesText',
}
</script>

<style lang="sass" scoped>
.mlminush4
  margin-left: -24px !important
</style>