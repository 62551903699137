<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="КОМАНДА МЕЧТЫ")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      .subcontent_wrap.mt-5
        div(v-if="cards_collected")
          p.mt-2.mb-0 Ты собрал {{ cards_collected }} {{ word_num_cases(cards_collected, "карточку", "карточки", "карточек") }}
    
      .subcontent_wrap
        table.mt-4
          tr.table_header
            th.place
              img(src="~@/assets/icons_and_logos/solar_cup-star-bold.svg")
            th.clubname Псевдоним
            th.rating
              .flex <span>Кол-во карточек</span>
          tr(v-for="(person, index) in persons" :class="{'odd': index % 2}")
            td.place {{ index + 1 }}
            td.clubname {{ person.username }}
            td.rating {{ person.rating }}
  
        .notification_pagination.mt-5
          //- img.left(
          //-   :class="{'unactive': page_num == 1}"
          //-   src="../../../assets/icons_and_logos/paginate-arrow-left.svg"
          //-   @click="reducePage"
          //- )
          //- span Страница <strong>{{ page_num }}</strong> из <strong>{{ pages_count }}</strong>
          //- img.right(
          //-   :class="{'unactive': page_num == pages_count}"
          //-   src="../../../assets/icons_and_logos/paginate-arrow-right.svg"
          //-   @click="increasePage"
          //- )
          span.paginate_page(
            v-if="page_num != 1"
            @click="reducePage"
          ) Предыдущая страница
          span.between(v-if="page_num != 1")
          span.paginate_page(
            v-if="page_num != pages_count"
            @click="increasePage"
          ) Следующая страница

      BitvaTimer.border-bottom.mt-6
</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { GetDreamteamRatings } from "./../../../api/Inside"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'BitvaPersonalClub',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      page_num: 1,
      cards_collected: 2,
      pages_count: 4,
      backoff_interval: null,
      persons: [
        {
          "id": "1",
          "username": "Иван Константинопольский забухайло",
          "votes": "100",
          "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
          "club": {
            "fc_id": 1,
            "name": "string",
            "description": "string",
            "picture": null
          }
        },
        {
          "id": "2",
          "username": "Пётр",
          "votes": "2",
          "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
          "club": {
            "fc_id": 2,
            "name": "string",
            "description": "string",
            "picture": null
          }
        }
      ],
      sub_menu: [
        {
          "title": "Конкурс",
          "link": "/inside/dreamteam"
        },
        {
          "title": "Прогресс участников",
          "link": "/inside/dreamteam-progress"
        },
        {
          "title": "Правила",
          "link": "/inside/dreamteam-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voice_given: "voice_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
    this.GetDreamteamRatings(1)
    let context = this
    let backoff_usr_id = function () {
      if (context.voice_given >= 2) {
        let sub_menu = []
        for (let i=0; i<context.sub_menu.length; i++) {
          if (i == 1) {
            sub_menu.push({
              "title": "Описание конкурса",
              "link": "/inside/dreamteam-info"
            })
          }
          sub_menu.push(context.sub_menu[i])
        }
        context.sub_menu = sub_menu
        clearInterval(context.backoff_interval)
      } else if (context.voice_given === 0 || context.voice_given > 0) {
        clearInterval(context.backoff_interval)
      }
    }
    this.backoff_interval = setInterval(backoff_usr_id, 500)
  },
  methods: {
    async GetDreamteamRatings (page) {
      let context = this
      let result = await GetDreamteamRatings(context, page)
      if (result) {
        this.persons = result["data"]["dreamteam_members"]
        this.page_num = result["data"]["page_num"]
        this.pages_count = result["data"]["pages_count"]
        this.cards_collected = result["data"]["cards_collected"]
      }
    },
    reducePage () {
      if (this.page_num != 1) {
        this.page_num -= 1
        this.GetDreamteamRatings(this.page_num)
      }
    },
    increasePage () {
      if (this.page_num != this.pages_count) {
        this.page_num += 1
        this.GetDreamteamRatings(this.page_num)
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.green_button
  margin-top: 10px

p.note
  font-size: 14px

.border-bottom
  border-bottom: 1px dotted #DDDDDD

table, th, td
  border: 1px solid white
  border-collapse: collapse
table
  table-layout: auto
  width: 100%
  border: 0
  margin-top: 10px
  .table_header
    width: 100%
    border-top-right-radius: 5px
    border-top-left-radius: 5px
    th
      background-color: #0D1D27
      color: #fff
      font-weight: 400
      font-size: 14px
      height: 43px
      padding: 0 5px
      line-height: 43px
      border-top: 0
      @media (max-width: 550px)
        font-size: 12px
    th.place
      width: 44px
      text-align: center
      padding: 0
      border-left: 0
      border-top-left-radius: 5px
      img
        position: relative
        top: 6px
    th.clubname
      border-right: 0
      // border-top-right-radius: 5px
    th.rating
      width: 85px
      border-right: 0
      border-top-right-radius: 5px
      .flex
        width: 100%
        height: 100%
        display: flex
        align-items: center
        span
          display: inline-block
          line-height: 18px
      @media (max-width: 550px)
        width: 62px
  tr
    td
      text-align: left
      font-weight: 400
      font-size: 16px
      padding: 0 5px
      height: 37px
      line-height: 37px
      @media (max-width: 550px)
        font-size: 14px
    td.place
      text-align: center
      padding: 0
      font-weight: 600
      font-size: 16px
      border-left: 0
      background-color: #fff
    td.clubname
      background-color: #EDEDED
      padding-left: 5px
      line-height: 20px
      .flex
        display: flex
        width: 100%
        justify-content: space-between
        align-items: center
        padding: 5px 0 7px
        .green_button
          margin: 0
          @media (max-width: 550px)
            display: none
        .sub_flex
          display: flex
          justify-content: flex-start
          align-items: center
          line-height: 20px
          .img_wrap
            min-width: 45px
            display: flex
            justify-content: center
            align-items: center
            @media (max-width: 550px)
              min-width: 40px
            img
              max-height: 40px
              min-width: 40px
              @media (max-width: 550px)
                max-height: 35px
    td.rating
      border-right: 0
      background-color: #fff
  tr.odd
    td.place, td.rating
      background-color: #EDEDED
    td.clubname
      background-color: #fff

</style>
    