<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="КОМАНДА МЕЧТЫ")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7
        h3.mb-4 Правила конкурса «Команда мечты»
        ol
          li.mb-2 Для участия в этом Конкурсе Вам необходимо отдать за свой клуб 2 голоса. Голоса, отданные за Ваш футбольный клуб, необходимые для участия в этом Конкурсе, не подлежат возврату, независимо от его результата.
          li.mb-2 Конкурс проводится с первого по последнее число каждого месяца. Принять участие в Конкурсе можно в любое время до его окончания. Победители получат призы.
          li.mb-2 Суть Конкурса заключается в том, чтобы его участники совместными действиями быстрее всех собрали набор виртуальных карточек футболистов, представляющих состав сборной СССР/России по футболу за определенный год.
          li.mb-2 В каждом Конкурсе состав сборной СССР/России может состоять из разного количества футболистов, но не более 22 игроков. Каждая виртуальная карточка представляет одного футболиста (например, Лев Яшин, вратарь).
          li.mb-2 В начале Конкурса, в разделе “Команда мечты”, Вам дается таблица с пустыми клетками, подписанными именами футболистов. Одна клетка в этой таблице уже заполнена карточкой, которая имеет уникальный код (например, A1B2C3) и является Вашей “личной” карточкой – ее код “привязан” только к Вам. Разным участникам могут достаться их “личные“ карточки одного и того же футболиста, но код у этих карточек всегда будет разный и уникальный для каждого участника. Каждый участник в своей таблице имеет одну “личную” карточку.
          li.mb-2 Ваша задача – собрать сборную в полном составе, т.е. заполнить все пустые клетки своей таблицы карточками футболистов сборной. Где брать карточки? Недостающие в таблице карточки (их коды) Вы должны взять у других участников Конкурса за пределами нашего сайта (мессенджеры, соц. сети, и т.д.). Подсказка: для получения новых карточек и быстрого заполнения Вашей таблицы, приглашайте как можно больше участников в этот Конкурс, они – хороший источник новых карточек.
          li.mb-2 Для заполнения клетки в таблице необходимо ввести код соответствующей карточки, полученной от другого участника. Например, у Вас не заполнена клетка с именем “Лев Яшин” и Вам сообщил код этой карточки другой участник, тогда Вы можете в своей таблице в клетку с именем “Лев Яшин” вставить полученный код и эта клетка будет заполнена, а Ваша таблица запомнит участника, давшего эту карточку.
          li.mb-2 Вы можете неоднократно в таблице менять карточку одного и того же футболиста, но при этом должны понимать, что при ее замене у новой карточки (того же футболиста) будет код уже другого участника и в случае Вашей победы приз достанется, в том числе, и ему. Подсказка: неплохо, если этим участником будет Ваш друг. “Личную” карточку в своей таблице Вы заменить не можете.
          li.mb-2 Если Вы раньше всех заполните все клетки своей таблицы, то Вы и все другие участники, карточки которых (их коды) присутствуют в этот момент в собранной таблице, станут победителями этого Конкурса. Например, если в Вашей таблице было 22 клетки, то победителями станут 22 участника, включая Вас, и каждый из 22 победителей получит ценный приз – Сертификат Ozon на 5000 руб., бонус в 100 баллов, звание «Игрок «Команды мечты»» и попадет в «Зал Славы».
          li.mb-2 Участники Конкурса, которые до окончания его срока также собрали полностью свои таблицы, но не были при этом первыми, получат бонус – по 100 баллов каждый.
          li.mb-2 Если до окончания срока Конкурса ни один из участников полностью не соберет таблицу, то Конкурс закончится без победителей.
          li.mb-2 Подсказка: помните, чем шире (активнее) Вы будете распространять свою “личную” карточку, тем у большего кол-ва участников она будет содержаться в их таблицах и тем больше вероятность, что Вы окажетесь в таблице-победителе и получите приз.
          li.mb-2 За попадание Вашей личной карточки в определенное число таблиц других участников Вы будете получать баллы.
          li.mb-2 Участники Конкурса могут обмениваться кодами имеющихся у них карточек без каких-либо ограничений, а также кооперироваться любыми способами. Для победы важна коммуникабельность, смекалка каждого участника и командная работа в целом.
          li.mb-2 Победители получают призы в своих Личных Кабинетах. Файл сертификата становится доступен для скачивания не позднее, чем через 7 дней с момента окончания Конкурса.

        p Приглашайте больше друзей и Вы обязательно победите!<br>Призы ждут вас! Желаем удачи!


      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'DreamteamRules',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      sub_menu: [
        {
          "title": "Конкурс",
          "link": "/inside/dreamteam"
        },
        {
          "title": "Прогресс участников",
          "link": "/inside/dreamteam-progress"
        },
        {
          "title": "Правила",
          "link": "/inside/dreamteam-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voice_given: "voice_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
    let context = this
    let backoff_usr_id = function () {
      if (context.voice_given >= 2) {
        let sub_menu = []
        for (let i=0; i<context.sub_menu.length; i++) {
          console.log(context.sub_menu[i])
          if (i == 1) {
            sub_menu.push({
              "title": "Описание конкурса",
              "link": "/inside/dreamteam-info"
            })
          }
          sub_menu.push(context.sub_menu[i])
        }
        context.sub_menu = sub_menu
        clearInterval(context.backoff_interval)
      } else if (context.voice_given === 0 || context.voice_given > 0) {
        clearInterval(context.backoff_interval)
      }
    }
    this.backoff_interval = setInterval(backoff_usr_id, 500)
  },
  methods: {
  }
}

</script>


<style lang="sass" scoped>


</style>
    