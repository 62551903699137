import Vue from "vue"
import Vuex from "vuex"
import user_store from "./user"

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    router_page_counter: 0,
    campionship_ends: null
  },
  getters: {
    campionship_ends (state) {
      return state.campionship_ends;
    },
  },
  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
    incRouterPageCounter(state) {
      state.router_page_counter++
    },
  },
  actions: {
    UPDATE_ACTION({ commit }, key_value) {
      commit("update", key_value);
    },
  },
  modules: {
    user_store,
  }
});

export default store