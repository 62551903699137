<template lang="pug">

.subcontent_wrap.mt-5
  .mob_plashka
    h3.mt-0.mb-10.ta_center(
      v-if="!active_pvp[opponent_role + '_id']"
    ) Поиск соперника
    h3.mt-0.mb-10.ta_center(
      v-else-if="active_pvp[opponent_role + '_id'] && !active_pvp[my_role + '_ready']"
    ) Нажми «Готов», чтобы начать поединок
    h3.mt-0.mb-10.ta_center(
      v-else-if="active_pvp[opponent_role + '_id'] && active_pvp[my_role + '_ready'] && !active_pvp[opponent_role + '_ready']"
    ) Ожидаем готовности оппонента
    h3.mt-0.mb-10.ta_center(
      v-else-if="active_pvp[opponent_role + '_id'] && active_pvp[my_role + '_ready'] && active_pvp[opponent_role + '_ready']"
    ) Игра сейчас начнётся
    .avas_block
      .ava_block
        .player_ready(v-if="active_pvp[my_role + '_ready']")
        .ava(v-if="picture")
          img(:src="picture")
          // img(src="~@/assets/images/stamp2.jpg")
        .ava(v-else)
          span.question ?
        .clud_username
          .club
            img(:src="my_club.picture")
          .username {{ username }} <em>{{ my_club.name }} ({{ my_club.city }})</em>
      span.versus VS
      
      .ava_block(v-if="active_pvp[opponent_role + '_username']")
        .player_ready.opponent(v-if="active_pvp[opponent_role + '_ready']")
        .ava(v-if="active_pvp[opponent_role + '_picture']")
          img(:src="active_pvp[opponent_role + '_picture']")
          // img(src="~@/assets/images/stamp2.jpg")
        .ava(v-else)
          span.question ?
        .clud_username
          .club
            img(:src="active_pvp[opponent_role + '_club_picture']")
          .username {{ active_pvp[opponent_role + '_username'] }} <em>{{ active_pvp[opponent_role + '_club'] }}</em>
      .ava_block(v-else)
        .ava
          span.question ?
        .clud_username
          .club
          .username

  .timer_block {{ wait_writind }}
  .approve_block
    v-btn.green_button(
      v-if="active_pvp[opponent_role + '_username']"
      x-large
      dark
      :disabled="active_pvp[my_role + '_ready']"
      color="#04841F"
      @click="$emit('i_am_ready')"
    ) {{ active_pvp[my_role + '_ready'] && !active_pvp[opponent_role + '_ready'] ? 'Ожидаем готовности оппонента' : 'Готов' }}
  .cancel_block
    v-btn.red_button(
      v-if="!active_pvp[my_role + '_ready']"
      dark
      color="#850404"
      @click="$emit('fight_cancel')"
    ) Отменить поединок


</template>
  
<script>
  
  import { mapGetters } from "vuex"
  
  export default {
    name: 'PvpWaiting',
    props: {
      pvp_id: Number,
      bet: Number,
      connection: null,
      personal_connection: null,
      wait_seconds: Number,
      wait_interval: null,
      wait_writind: String,
      backoff_interval: null,
      my_role: String,
      opponent_role: String,
    },
    data () {
      return {}
    },
    computed: {
      ...mapGetters("user_store", {
        user_id: "user_id",
        username: "username",
        voices: "voices",
        coins: "coins",
        my_club: "club",
        picture: "picture",
        active_pvp: "active_pvp",
      }),
    },
    methods: {}
  }
  
</script>

<style lang="sass" scoped>
.mob_plashka
  @media (max-width: 500px)
    width: calc(100% + 60px)
    position: relative
    left: -30px
    padding: 10px 10px
    background-color: #fff
    box-shadow: 0px 0px 10px 0px #00000021
    border-radius: 5px

.timer_block
  margin-top: 40px
  width: 100%
  height: 55px
  border-radius: 10px
  background-color: #375567
  text-align: center
  line-height: 55px
  font-size: 24px
  color: #fff

.cancel_block, .approve_block
  display: flex
  justify-content: center
  margin-top: 20px

.avas_block
  display: flex
  justify-content: center
  align-items: flex-start
  .ava_block
    width: 140px
    min-height: 200px
    display: flex
    justify-content: center
    align-items: flex-start
    flex-wrap: wrap
    position: relative
    @media (max-width: 500px)
      width: 110px
    .player_ready
      position: absolute
      width: 80px
      height: 80px
      top: -30px
      left: -30px
      z-index: 2
      transform: rotate(-52deg)
      background-color: #4AA900
      border-radius: 40px
      @media (max-width: 500px)
        transform: rotate(-52deg) scale(70%)
      &:before, &:after
        content: ""
        display: block
        position: absolute
      &:before
        width: 40px
        height: 7px
        background-color: #FFF
        top: 46px
        left: 25px
      &:after
        width: 7px
        height: 30px
        background-color: #FFF
        top: 20px
        left: 25px
    .player_ready.opponent
      left: auto
      right: -30px
    .ava 
      width: 140px
      height: 140px
      border-radius: 70px
      background-color: #ccc
      overflow: hidden
      display: flex
      justify-content: center
      align-items: center
      margin-bottom: 5px
      @media (max-width: 500px)
        width: 110px
        height: 110px
      img
        width: 100%
      .question
        font-size: 47px
        font-weight: 600
        color: #fff
    .clud_username
      position: absolute
      top: 150px
      left: 0
      width: 100%
      display: flex
      align-items: flex-start
      .club
        width: 20px
        height: 100%
        margin-right: 5px
        img
          width: 100%
      .username
        width: calc(100% - 25px)
        text-align: left
        font-size: 14px
        line-height: 16px

  .versus
    min-width: 70px
    max-width: 200px
    width: 25%
    height: 140px
    display: flex
    justify-content: center
    align-items: center
    font-size: 50px
    font-weight: 500
    @media (max-width: 500px)
      min-width: 40px
      font-size: 30px
      width: 15%



</style>