/* eslint-disable */

let opts = {
  history: true, // CSS history knocking or not .. can be network intensive
  java: false, // Java applet on/off... may prompt users for permission to run.
  tests: 10,  // 1000 what is it, actually?
  silverlight: true, // you might want to turn it off https://github.com/samyk/evercookie/issues/45,
  lso: true, // local storage
  domain: '.' + window.location.host.replace(/:\d+/, ''), // Get current domain
  baseurl: '', // base url for php, flash and silverlight assets
  asseturi: false, // assets = .fla, .jar, etc
  phpuri: false, // php file path or route
  authPath: false, //'/evercookie_auth.php', // set to false to disable Basic Authentication cache
  swfFileName: false,
  xapFileName: false,
  jnlpFileName: false,
  pngCookieName: 'evercookie_png',
  pngPath: false,
  etagCookieName: 'evercookie_etag',
  etagPath: false,
  cacheCookieName: 'evercookie_cache',
  cachePath: false,
  hsts: false,
  hsts_domains: [],
  db: true, // Database
  idb: true // Indexed DB
};

let idbData = ""
let dbData = ""

let createElem = function (type, name, append) {
  var el;
  if (name !== undefined && document.getElementById(name)) {
    el = document.getElementById(name);
  } else {
    el = document.createElement(type);
  }
  el.style.visibility = "hidden";
  el.style.position = "absolute";

  if (name) {
    el.setAttribute("id", name);
  }

  if (append) {
    document.body.appendChild(el);
  }
  return el;
};

let _baseKeyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
let _link = createElem("a", "_ec_rgb_link")
let  created_style, _cssText, style = "#_ec_rgb_link:visited{display:none;color:#FF0000}";

let getHost = function () {
  return window.location.host.replace(/:\d+/, '');
};

export function evercookie_database_storage (name, value) {
  //try {
    console.log("я тут", window.openDatabase)
    if (window.openDatabase) {
      return new Promise (function(resolve) {
        var database = window.openDatabase("sqlite_evercookie", "", "evercookie", 1024 * 1024);
        if (value !== undefined) {
          database.transaction(function (tx) {
            tx.executeSql("CREATE TABLE IF NOT EXISTS cache(" +
              "id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT, " +
              "name TEXT NOT NULL, " +
              "value TEXT NOT NULL, " +
              "UNIQUE (name)" +
              ")", [], function (tx, rs) {}, function (tx, err) {});
            tx.executeSql("INSERT OR REPLACE INTO cache(name, value) " +
              "VALUES(?, ?)",
              [name, value], function (tx, rs) {}, function (tx, err) {});
          });
        } else {
          database.transaction(function (tx) {
            tx.executeSql("SELECT value FROM cache WHERE name=?", [name],
              function (tx, result1) {
                if (result1.rows.length >= 1) {
                  return resolve(result1.rows.item(0).value);
                } else {
                  return null
                }
              }, function (tx, err) {});
          });
        }
      })
    }
  //} catch (e) { console.log("я тут catch")}
};


export function evercookie_indexdb_storage(name, value) {
  return new Promise (function(resolve) {
    try {
      if (!('indexedDB' in window)) {
          indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
          IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.msIDBTransaction;
          IDBKeyRange = window.IDBKeyRange || window.webkitIDBKeyRange || window.msIDBKeyRange;
      }
      let result = null
      if (indexedDB) {
        var ver = 1;
        //FF incognito mode restricts indexedb access
        var request = indexedDB.open("idb_evercookie", ver);
        request.onerror = function(e) {}
        request.onupgradeneeded = function(event) {
          var db = event.target.result;
          var store = db.createObjectStore("evercookie", {
              keyPath: "name",
              unique: false
          })
        }
        if (value !== undefined) {
            request.onsuccess = function(event) {
                var idb = event.target.result;
                if (idb.objectStoreNames.contains("evercookie")) {
                    var tx = idb.transaction(["evercookie"], "readwrite");
                    var objst = tx.objectStore("evercookie");
                    var qr = objst.put({
                        "name": name,
                        "value": value
                    })
                } idb.close();
            }
        } else {
          request.onsuccess = function(event) {
            var idb = event.target.result;
            if (!idb.objectStoreNames.contains("evercookie")) {
                idbData = undefined;
            } else {
              var tx = idb.transaction(["evercookie"]);
              var objst = tx.objectStore("evercookie");
              var qr = objst.get(name);
              console.log(objst)
              console.log(qr)
              qr.onsuccess = function (event) {
                if (qr.result === undefined) {
                  idbData = undefined
                } else {
                  return resolve(qr.result.value);
                }
              }
            }
            idb.close();
            // console.log("я тут", result)
            // return result
          }
        }
      }
    } catch (e) {}
  })
};


export function evercookie_history(name, value) {
  // - is special
  var baseElems = (_baseKeyStr + "-").split(""),
    // sorry google.
    url = "https://" + getHost() + "/" + name,
    i, base,
    letter = "",
    val = "",
    found = 1;

  if (value !== undefined) {
    // don't reset this if we already have it set once
    // too much data and you can't clear previous values
    if (hasVisited(url)) {
      return true;
    }

    createIframe(url, "if");
    url = url + "/";

    base = encode(value).split("");
    for (i = 0; i < base.length; i++) {
      url = url + base[i];
      createIframe(url, "if" + i);
    }

    // - signifies the end of our data
    url = url + "-";
    createIframe(url, "if_");
  } else {
    // omg you got csspwn3d
    if (hasVisited(url)) {
      url = url + "/";

      while (letter !== "-" && found === 1) {
        found = 0;
        for (i = 0; i < baseElems.length; i++) {
          if (hasVisited(url + baseElems[i])) {
            letter = baseElems[i];
            if (letter !== "-") {
              val = val + letter;
            }
            url = url + letter;
            found = 1;
            break;
          }
        }
      }

      // lolz
      return decode(val);
    }
  }
};

let no_color = undefined
let no_style = undefined
let hasVisited = function (url) {
  if (no_color) {
    no_style = _getRGB("https://samy-was-here-this-should-never-be-visited.com", -1);
    if (no_style === -1) {
      no_color = _getRGB("https://samy-was-here-" + Math.floor(Math.random() * 9999999) + "rand.com");
    }
  }
  // did we give full url?
  if (url.indexOf("https:") === 0 || url.indexOf("http:") === 0) {
    return _testURL(url, no_color);
  }
  // if not, just test a few diff types  if (exact)
  return _testURL("http://" + url, no_color) ||
    _testURL("https://" + url, no_color) ||
    _testURL("http://www." + url, no_color) ||
    _testURL("https://www." + url, no_color);
};

let _getRGB = function (u, test_color) {
  if (test_color && created_style === 0) {
    return -1;
  }

  /* create the new anchor tag with the appropriate URL information */
  _link.href = u;
  _link.innerHTML = u;
  // not sure why, but the next two appendChilds always have to happen vs just once
  // document.body.appendChild("style");
  document.body.appendChild(_link);

  /* add the link to the DOM and save the visible computed color */
  var color;
  if (document.defaultView) {
    if (document.defaultView.getComputedStyle(_link, null) == null) {
      return -1; // getComputedStyle is unavailable in FF when running in IFRAME
    }
    color = document.defaultView.getComputedStyle(_link, null).getPropertyValue("color");
  } else {
    color = _link.currentStyle.color;
  }
  return color;
};

let _testURL = function (url, no_color) {
  var color = _getRGB(url);

  /* check to see if the link has been visited if the computed color is red */
  if (color === "rgb(255, 0, 0)" || color === "#ff0000") {
    return 1;
  } else if (no_color && color !== no_color) {
    /* if our style trick didn't work, just compare default style colors */
    return 1;
  }
  /* not found */
  return 0;
};

let getFromStr = function (name, text) {
  if (typeof text !== "string") {
    return;
  }
  var nameEQ = name + "=",
    ca = text.split(/[;&]/),
    i, c;
  for (i = 0; i < ca.length; i++) {
    c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
};


let encode = function (input) {
  var output = "",
    chr1, chr2, chr3, enc1, enc2, enc3, enc4,
    i = 0;

  input = _utf8_encode(input);

  while (i < input.length) {

    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }

    output = output +
      _baseKeyStr.charAt(enc1) + _baseKeyStr.charAt(enc2) +
      _baseKeyStr.charAt(enc3) + _baseKeyStr.charAt(enc4);

  }

  return output;
};

// public method for decoding
let decode = function (input) {
  var output = "",
    chr1, chr2, chr3,
    enc1, enc2, enc3, enc4,
    i = 0;

  input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

  while (i < input.length) {
    enc1 = _baseKeyStr.indexOf(input.charAt(i++));
    enc2 = _baseKeyStr.indexOf(input.charAt(i++));
    enc3 = _baseKeyStr.indexOf(input.charAt(i++));
    enc4 = _baseKeyStr.indexOf(input.charAt(i++));

    chr1 = (enc1 << 2) | (enc2 >> 4);
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
    chr3 = ((enc3 & 3) << 6) | enc4;

    output = output + String.fromCharCode(chr1);

    if (enc3 !== 64) {
      output = output + String.fromCharCode(chr2);
    }
    if (enc4 !== 64) {
      output = output + String.fromCharCode(chr3);
    }
  }
  output = _utf8_decode(output);
  return output;
};

// private method for UTF-8 encoding
let _utf8_encode = function (str) {
  str = str.replace(/\r\n/g, "\n");
  var utftext = "", i = 0, n = str.length, c;
  for (; i < n; i++) {
    c = str.charCodeAt(i);
    if (c < 128) {
      utftext += String.fromCharCode(c);
    } else if ((c > 127) && (c < 2048)) {
      utftext += String.fromCharCode((c >> 6) | 192);
      utftext += String.fromCharCode((c & 63) | 128);
    } else {
      utftext += String.fromCharCode((c >> 12) | 224);
      utftext += String.fromCharCode(((c >> 6) & 63) | 128);
      utftext += String.fromCharCode((c & 63) | 128);
    }
  }
  return utftext;
};

// private method for UTF-8 decoding
let _utf8_decode = function (utftext) {
  var str = "",
  i = 0, n = utftext.length,
  c = 0, c1 = 0, c2 = 0, c3 = 0;
  while (i < n) {
    c = utftext.charCodeAt(i);
    if (c < 128) {
      str += String.fromCharCode(c);
      i += 1;
    } else if ((c > 191) && (c < 224)) {
      c2 = utftext.charCodeAt(i + 1);
      str += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
      i += 2;
    } else {
      c2 = utftext.charCodeAt(i + 1);
      c3 = utftext.charCodeAt(i + 2);
      str += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
      i += 3;
    }
  }
  return str;
};

let createIframe = function (url, name) {
  var el = createElem("iframe", name, 1);
  el.setAttribute("src", url);
  return el;
};

