<template lang="pug">

.subcontent_wrap.mt-5
  .dreamteam_info
    h4.mb-2 {{ name }}
    p {{ description }}

  .dreamteam_info_prizes(v-if="is_collected")
    img(
      src="../../../assets/icons_and_logos/wincup_gray.svg"
    )
    p(v-if="is_winner") Поздравляем, твоя сборная полностью собрана. Все участники сборной получили приз в 100 баллов.
    p(v-else-if="is_first_winner") Поздравляем, твоя сборная заняла первое место в конкурсе «Команда мечты». Вы найдёте свои призы в личных кабинетах не позднее чем через 7 дней после окончания конкурса.

  .dreamteam_cols_wrap.mt-8
    h3 Твоя личная карточка:
    .dreamteam_col_left
      DreamTeamCard.dreamteam_card.mb-2(:rare="my_card.rare" :name="my_card.name" :role="my_card.name")
    .dreamteam_col_right
      p {{ my_card.text }}
  
  .card_code_wrap <span>Код твоей карточки:</span> <strong>{{ my_card.code }}</strong> 
    img(
      @click="CopyCode('card_code_text')"
      @shown="showTippy('card_code_text')"
      ref="card_code_text"
      src="../../../assets/icons_and_logos/copy.svg"
      v-tippy="{'trigger':'click', 'duration':[150, 150]}"
      content="Скопировано"
    )
    input(type="text" id="card_code_text" :value="card_code")

  p.card_used.mt-4 Твою карточку использовали {{ my_card.used }} раз

  .progress_bar_wrap
    .progress_bar 
      .progress(:style="'width: ' + (my_card.used / 2) + '%;'")
      .delimiter.delimiter_10(:class="{'green': my_card.used >= 10}")
        .delimiter_num 10
        .delimiter_coins 
          img(v-if="my_card.used < 10" src="../../../assets/icons_and_logos/small_coin_gray.svg")
          img(v-else src="../../../assets/icons_and_logos/small_coin_green.svg")
      .delimiter.delimiter_50(:class="{'green': my_card.used >= 50}")
        .delimiter_num 50
        .delimiter_coins 6
          img(v-if="my_card.used < 50" src="../../../assets/icons_and_logos/small_coin_gray.svg")
          img(v-else src="../../../assets/icons_and_logos/small_coin_green.svg")
      .delimiter.delimiter_100(:class="{'green': my_card.used >= 100}")
        .delimiter_num 100
        .delimiter_coins 13
          img(v-if="my_card.used < 100" src="../../../assets/icons_and_logos/small_coin_gray.svg")
          img(v-else src="../../../assets/icons_and_logos/small_coin_green.svg")
      .delimiter.delimiter_200(:class="{'green': my_card.used >= 200}")
        .delimiter_num 200
        .delimiter_coins 30
          img(v-if="my_card.used < 200" src="../../../assets/icons_and_logos/small_coin_gray.svg")
          img(v-else src="../../../assets/icons_and_logos/small_coin_green.svg")

  h3.mt-8 Команда мечты ({{ name }}):

  table.table_dreamteam
    tr.table_header
      th.place №
      th.football_player Футболист <span>(нажми)</span>
      th.username Болельщик <span>(нажми)</span>
      th.code Введи код и нажми Enter
      th.status Статус
    tr(v-for="(card, index) in cards" :class="{'odd': index % 2, 'collected': card.code}")
      td.place {{ index + 1 }}
      td.football_player
        span(@click="showBio(card)") {{ card.name }}
        img(v-tippy="card.name" src="../../../assets/icons_and_logos/ion_person.svg" @click="showBio(card)")
      td.username
        span {{ card.username }}
        img(v-if="card.username" v-tippy="card.username" src="../../../assets/icons_and_logos/ion_person.svg")
      td.code(v-if="card.code && card.code == card_code") {{ card.code }}
      td.code(v-else)
        .code_wrap
          input.code_input(
            type="text"
            minlength="6"
            maxlength="6"
            v-model="card.code_to_change"
            @change="checkCode(card)"
            :disabled="is_collected"
          )
          .changed_hint(:id="'aa' + card.footballer_id") Код должен содержать 6 символов

      td.status(v-if="!card.code") –
      td.status(v-else-if="card.code == card_code" class="my_collected")
        div
          span Твоё
      td.status(v-else class="collected")
        div
          img(src="../../../assets/icons_and_logos/galka_green.svg")

  p.mt-4 Ты собрал {{ cards_collected }} {{ word_num_cases(cards_collected, "карточку", "карточки", "карточек") }}

  v-overlay.my_overlay(
    absolute
    :dark="false"
    :light="true"
    color="#ffffff"
    :value="code_loading"
  )
    v-progress-circular(
      indeterminate
      color="primary"
    )

  v-dialog(v-model="show_bio" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 {{ football_player_dialog }} <i class="close_icon" @click="show_bio = !show_bio"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            p.pt-4 {{ bio_dialog }}

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { showIt, hideIt } from "../../../service/service"
import { GetDreamteam, SetDreamteam } from "./../../../api/Inside"
import DreamTeamCard from "./DreamTeamCard"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"
import { tippy } from "vue-tippy"

export default {
  name: 'HasCard',
  components: {
    DreamTeamCard,
    tippy
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      card_code: "124ABC",
      cards_collected: 0,
      code_loading: false,
      name: null,
      description: null,
      show_bio: false,
      football_player_dialog: null,
      bio_dialog: null,
      my_card: {
        'used': 50,
        "code": "124ABC",
        "text": "Родился, женился, выиграл то, выиграл сё. Вроде ещё живой"
      },
      cards: [
        {
          "footballer_id": 100500,
          "user_id": 100500,
          "username": "Bugsy007",
          "name": "Лев Яшин",
          "code": "123ABC",
          "show_hint": false,
          "code_to_change": "123ABC"
        },
        {
          "footballer_id": 100501,
          "user_id": 100502,
          "username": "Bugsy007",
          "name": "Лев Яшин",
          "code": "124ABC",
          "show_hint": false,
          "code_to_change": "124ABC"
        },
        {
          "footballer_id": 100502,
          "user_id": "",
          "username": "",
          "name": "Константин Константинопольский",
          "code": "",
          "show_hint": false,
          "code_to_change": ""
        },
        {
          "footballer_id": 100502,
          "user_id": 100501,
          "username": "Константин Константинопольский",
          "name": "Константин Константинопольский",
          "code": "153ABC",
          "show_hint": false,
          "code_to_change": "153ABC"
        },
        {
          "footballer_id": 100502,
          "user_id": null,
          "username": "",
          "name": "Лев Яшин",
          "code": "",
          "show_hint": false,
          "code_to_change": ""
        },
      ],
      is_collected: false,
      is_winner: false,
      is_first_winner: false
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices_given: "voices_given",
    })
  },
  mounted () {
    this.GetDreamteam()
  },
  methods: {
    showBio (card) {
      this.bio_dialog = card.text
      this.football_player_dialog = card.name + " (" + card.role + ")"
      this.show_bio = true
    },
    CopyCode (txt) {
      let copyText = document.getElementById(txt)
      copyText.style.display = "inline"
      copyText.select() /* Select the text field */
      copyText.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand("copy")
      copyText.style.display = "none"
    },
    showTippy (ref) {
      var context = this
      setTimeout(function() { context.$refs[ref]._tippy.hide() }, 500)
    },
    async checkCode (card) {
      let context = this
      let data = {
        "footballer_id": card.team_players_id,
        "code": card.code_to_change
      }
      console.log(data)
      let thenn = function () {
        context.code_loading = false
      }
      if (card.code_to_change) {
        if (card.code_to_change.length < 6) {
          showIt('aa' + card.footballer_id)
          let ff = function () {
            hideIt('aa' + card.footballer_id, 300)
          }
          setTimeout(ff, 3000)
        } else {
          if (card.code) {
            this.$swal({
              "icon": "warning",
              "text": "Вы уверены, что хотите заменить код?",
              "buttons": ["Нет", "Да, заменить"],
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: "Да, заменить",
              cancelButtonText: "Нет",
            }).then(async function (res) {
              context.code_loading = true
              if (res.isConfirmed) {
                let result = await SetDreamteam(data, context, thenn)
                if (result) {
                  context.$swal({
                    "icon": "success",
                    "text": "Код успешно изменён",
                  }).then(async function () {
                    await context.GetDreamteam()
                  })
                } else {
                  card.code_to_change = card.code
                }
                context.code_loading = false
              } else {
                context.code_loading = false
                card.code_to_change = card.code
              }
            })
          } else {
            this.code_loading = true
            let result = await SetDreamteam(data, context, thenn)
            console.log(result["data"])
            if (result) {
              this.is_collected = result["data"]["is_collected"]
              this.is_winner = result["data"]["is_winner"]
              this.is_first_winner = result["data"]["is_collectis_first_winnered"]
              if (result["data"]["is_collected"] && result["data"]["is_first_winner"]) {
                context.$swal({
                  "icon": "success",
                  "title": "Поздравляем!",
                  "text": "Ты и все члены твоей команды стали победителями конкурса «Команда мечты». Вы найдёте свои призы в личных кабинетах не позднее чем через 7 дней после окончания конкурса",
                }).then(async function () {
                  await context.GetDreamteam()
                })
              } else if (result["data"]["is_collected"] && result["data"]["is_winner"]) {
                context.$swal({
                  "icon": "success",
                  "title": "Поздравляем!",
                  "text": "Ты собрал всю сборную! Ты и все члены твоей команды получили бонус в 100 баллов!",
                }).then(async function () {
                  await context.GetDreamteam()
                })
              } else {
                context.$swal({
                  "icon": "success",
                  "text": "Твоя сборная стала больше!",
                }).then(async function () {
                  await context.GetDreamteam()
                })
              }
            } else {
              card.code_to_change = card.code
            }
          }
          this.code_loading = false
        }
      }
    },
    async GetDreamteam() {
      let context = this
      let result = await GetDreamteam(context)
      this.cards_collected = 0
      if (result) {
        this.name = result["data"]["name"]
        this.description = result["data"]["description"]
        this.my_card = result["data"]["my_card"]
        this.cards = result["data"]["dreamteam_entries"]
        this.is_collected = result["data"]["is_collected"]
        this.is_winner = result["data"]["is_winner"]
        this.is_first_winner = result["data"]["is_first_winner"]
        this.card_code = result["data"]["my_card"]["code"]
        for (let i = 0; i<this.cards.length; i++) {
          this.cards[i]["code_to_change"] = this.cards[i]["code"]
          this.cards[i]["show_hint"] = false
          if (this.cards[i]["code"]) {
            this.cards_collected += 1
          }
        }
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.dreamteam_info_prizes
  width: 100%
  border-radius: 10px
  background-color: #eaf2df
  padding: 10px
  display: flex
  justify-content: flex-start
  align-items: center
  img
    width: 50px
  p
    padding: 0
    margin: 0 0 0 10px
  
.subcontent_wrap
  position: relative

.table_dreamteam, th, td
  border: 1px solid #CCCCCC
  border-collapse: collapse

.table_dreamteam
  table-layout: auto
  width: 100%
  border: 0
  margin-top: 10px
  .table_header
    width: 100%
    border-top-right-radius: 5px
    border-top-left-radius: 5px
    th
      font-weight: 400
      font-size: 14px
      height: 43px
      padding: 0 5px
      line-height: 43px
      border-top: 0
      line-height: 18px
      span
        display: none
      @media (max-width: 600px)
        font-size: 12px
        line-height: 16px
        span
          display: inline
      @media (max-width: 400px)
        font-size: 10px
        line-height: 14px

    th.place
      width: 34px
      text-align: center
      padding: 0
      border-left: 0
    th.status
      width: 55px
      border-right: 0
      @media (max-width: 550px)
        width: 50px

  tr
    td
      text-align: left
      font-weight: 400
      font-size: 16px
      padding: 0 5px
      height: 37px
      line-height: 37px
      background-color: #ECECEC
      font-size: 14px
      line-height: 15px
      @media (max-width: 550px)
        font-size: 12px
    td.code
      .code_wrap
        position: relative
        display: flex
        align-items: center
        width: 100%
        height: 100%
        .changed_hint
          position: absolute
          top: 0
          left: 0
          border-radius: 5px
          background: rgba(255, 255, 255, 0.9)
          border: 1px solid #c7c7c7
          font-size: 12px
          text-align: center
          padding: 4px 10px 5px
          line-height: 13px
          color: red
          top: -36px
          display: none
          opacity: 0
          transition: opacity 0.3s ease
        .changed_hint.show
          opacity: 1
        .code_input
          margin-top: 0
          padding-top: 0
          width: 100%
          height: 27px
          border-radius: 4px
          border: 1px solid #CCCCCC
          background-color: #FFF
          padding: 0 4px
          @media (max-width: 550px)
            font-size: 12px

    td.place
      text-align: center
      padding: 0
      font-weight: 600
      font-size: 16px
      border-left: 0
    td.status
      border-right: 0
      font-weight: 600
      font-size: 16px
      text-align: center
    td.football_player, td.username
      img
        display: none
      span
        display: inline-block
        border-bottom: 1px dashed #343434
      @media (max-width: 600px)
        img
          display: inline-block
          width: 20px
          position: relative
          top: 2px
        span
          display: none
    td.football_player
      img, span
        cursor: pointer
      span
        text-decoration: none
      &:hover
        span
          text-decoration: none
          border-bottom: 1px dashed transparent
    td.username
      span
        border-bottom: 1px dashed transparent !important

  tr.odd
    td
      background-color: #FFF

  tr.collected
    td
      background-color: #EAF2DF

  tr.collected
    td.collected, td.my_collected
      div
        width: 100%
        height: 100%
        display: flex
        flex-wrap: wrap
        align-items: center
        justify-content: center
    td.my_collected
      div
        span
          display: block
          width: 100%
          line-height: 10px
          height: auto
          text-align: center
          color: #00B05C
          font-weight: 300
          font-size: 12px
          position: relative

.progress_bar_wrap
  width: 100%
  height: 10px
  position: relative
  margin: 35px 0 20px
  border: 1px solid #E4E4E4
  border-radius: 5px
  background: linear-gradient(#d7d7d7, #f2f2f2)
  padding: 0 6px
  &:before
    content: ""
    display: block
    width: 6px
    height: 8px
    position: absolute
    top: 0px
    left: 0px
    background: linear-gradient(#009c52, #00b25d)
    border-top-left-radius: 5px
    border-bottom-left-radius: 5px
  .progress_bar
    width: 100%
    height: 8px
    position: relative
    .progress
      height: 8px
      background: linear-gradient(#009c52, #00b25d)
      position: relative
      &:before, &:after
        content: ""
        display: block
        position: absolute
      &:before
        width: 9px
        height: 9px
        right: -4px
        top: 0
        background-color: #FFF
        border-radius: 5px
        z-index: 2
        box-shadow: 0px 0px 2px 0px #979797
      &:after
        width: 21px
        height: 21px
        right: -10px
        top: -6px
        background: linear-gradient(#FFF, #E8E8E8)
        border-radius: 10px
        z-index: 1
        box-shadow: 0px 0px 3px 0px #979797
        border: 1px solid #F2F2F2

    .delimiter
      position: absolute
      top: 0
      height: 8px
      width: 1px
      &:before, &:after
        content: ""
        display: block
        position: absolute
        width: 1px
        height: 4px
        left: 0
        background-color: #DADADA
      &:before
        top: -4px
      &:after
        bottom: -4px
      .delimiter_num
        width: 32px
        height: 20px
        text-align: center
        line-height: 20px
        position: absolute
        bottom: -27px
        left: -16px
        color: #B8B8B8
      .delimiter_coins
        width: 42px
        height: 21px
        text-align: center
        line-height: 21px
        position: absolute
        top: -27px
        left: -21px
        display: flex
        align-items: center
        justify-content: center
        color: #B8B8B8
    .delimiter.green
      background: transparent !important
      .delimiter_num, .delimiter_coins
        color: #00B05C
      &:before, &:after
        background-color: #00B05C
      
    .delimiter_10
      left: calc(5% - 1px)
    .delimiter_50
      left: calc(25% - 1px)
    .delimiter_100
      left: calc(50% - 1px)
    .delimiter_200
      left: calc(100% - 1px)


.card_code_wrap
  background-color: #375567
  padding: 10px 20px
  min-height: 57px
  line-height: 47px
  border-radius: 10px
  color: #FFF
  display: flex
  align-items: center
  span
    line-height: 20px
  strong
    font-size: 27px
    display: inline-block
    margin: 0 10px
  img
    cursor: pointer

#card_code_text
  display: none

.dreamteam_cols_wrap
  display: flex
  align-items: flex-start
  justify-content: space-between
  flex-wrap: wrap
  width: 100%
  padding: 20px
  background: linear-gradient(#EAEAEA, #FFF)
  border-top-left-radius: 10px
  border-top-right-radius: 10px
  h3
    width: 100%
    margin: 0 0 20px
    line-height: 20px
    @media (max-width: 700px)
      margin-bottom: 0
  .dreamteam_col_right
    width: calc(100% - 210px)
    @media (max-width: 700px)
      width: 100%
      p
        margin-bottom: 0
    h3
      margin-top: 0px

  .dreamteam_col_left
    width: 180px
    @media (max-width: 700px)
      width: 100%
    p
      text-align: center
      font-weight: 300
      line-height: 23px
      margin-bottom: 5px
      strong
        font-size: 30px
        font-weight: 600
        line-height: 42px
    .dreamteam_card
      position: relative
      left: -3px

</style>
    