<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="КОЛЛЕКЦИЯ МАРОК")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")

      .stamps_info_wrap.mt-5.mb-5
        h3.mt-0.mb-3 Собери все 33 уникальные марки!
        .text_wrap
          .pos_r
            img.blue_prize(src="../../../assets/icons_and_logos/prize_blue.svg")
            div.mb-0.p_image_prize 
              p Каждый раз, когда ты отдаёшь голос в поддержку своего клуба, 
                |  ты получаешь одну из 33 марок.
                |  Кждый, кто соберёт все 33 марки получит звание «Коллекционер марок», подарочный сертификат Ozon номиналом <strong>10000 руб.</strong> и попадёт в «Зал Славы»!


          // p.mb-2 Каждый раз, когда ты отдаёшь голос в поддержку своего клуба, 
          //   |  ты получаешь одну из 33 марок.
          //   |  Собери все 33 марки и получи подарочный сертификат Ozon номиналом <strong>10000 руб.</strong>!
          p.mb-2 <em>Конкурс длится до {{ end_date }}</em>
          p <em>При выдаче марок возможны повторы.</em>
        .progress_wrap
          p собрано: <strong>{{ collected }} из {{ stamps_length }}</strong>
      .dreamteam_info
        h4.mb-2 {{ name }}
        p {{ text }}
      .stamps_buttons_wrap.mt-5
        v-btn.green_button(
          v-if="voices"
          x-large
          dark
          color="#04841F"
          @click="Vote"
        ) Отдать голос
        v-btn.green_button(
          v-else
          x-large
          dark
          color="#04841F"
          @click="goTo('/inside/shop')"
        ) Получить ещё голос
        v-btn.light_green_button(
          v-if="repeats"
          x-large
          dark
          color="#04841F"
          @click="SellRepeats"
        ) Продать повторы<br>(получить {{ repeats }} {{ word_num_cases(repeats, "балл", "балла", "баллов") }})
        v-btn.light_green_button(
          v-else
          x-large
          dark
          disabled
          color="#04841F"
          @click="SellRepeats"
        ) Продать повторы
      
      .stamps_wrap
        .stapm(
          v-for="(stamp, index) in stamps"
          :class="{'no_stamp': !stamp.quantity}"
          v-tippy="stamp.public_country + '. ' + stamp.note"
        )
          img(:src="addres + 'media/' + stamp.picture")
          //- img(v-if="!(index % 3)" src="~@/assets/images/stamp3.jpg")
          //- img(v-else-if="!(index % 2)" src="~@/assets/images/stamp2.jpg")
          //- img(v-else src="~@/assets/images/stamp1.jpg")
          span.quant {{ stamp.quantity }} шт

        

      //- .notification_pagination.mt-5.mb-5
      //-   img.left(
      //-     :class="{'unactive': page_num == 1}"
      //-     src="../../../assets/icons_and_logos/paginate-arrow-left.svg"
      //-     @click="reducePage"
      //-   )
      //-   span Страница <strong>{{ page_num }}</strong> из <strong>{{ pages_count }}</strong>
      //-   img.right(
      //-     :class="{'unactive': page_num == pages_count}"
      //-     src="../../../assets/icons_and_logos/paginate-arrow-right.svg"
      //-     @click="increasePage"
      //-   )

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { GetStamps, SellRepeats } from "./../../../api/Inside"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"
import settings from "../../../settings";

export default {
  name: 'StampsMain',
  components: {
    HeaDer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      page_num: 1,
      pages_count: 4,
      repeats: 5,
      stamps_length: 33,
      collected: 0,
      stamps: [],
      addres: settings.addres,
      name: null,
      text: null,
      end_date: null,
      // stamps: [
      //   {
      //     "stamp_code": 123,
      //     "picture": "~@/assets/images/stamp1.jpg",
      //     "public_country": "Argentina",
      //     "public_year": 1987,
      //     "note": "Текст про марку, описание",
      //     "quantity": 1
      //   },
      //   {
      //     "stamp_code": 123,
      //     "picture": "~@/assets/images/stamp2.jpg",
      //     "public_country": "Argentina",
      //     "public_year": 1987,
      //     "note": "Текст про марку, описание",
      //     "quantity": 2
      //   },
      //   {
      //     "stamp_code": 123,
      //     "picture": "~@/assets/images/stamp3.jpg",
      //     "public_country": "Argentina",
      //     "public_year": 1987,
      //     "note": "Текст про марку, описание",
      //     "quantity": 1
      //   },
      //   {
      //     "stamp_code": 123,
      //     "picture": "~@/assets/images/stamp1.jpg",
      //     "public_country": "Argentina",
      //     "public_year": 1987,
      //     "note": "Текст про марку, описание",
      //     "quantity": 3
      //   },
      //   {
      //     "stamp_code": 123,
      //     "picture": "~@/assets/images/stamp2.jpg",
      //     "public_country": "Argentina",
      //     "public_year": 1987,
      //     "note": "Текст про марку, описание",
      //     "quantity": 0
      //   },
      //   {
      //     "stamp_code": 123,
      //     "picture": "~@/assets/images/stamp3.jpg",
      //     "public_country": "Argentina",
      //     "public_year": 1987,
      //     "note": "Текст про марку, описание",
      //     "quantity": 0
      //   },
      //   {
      //     "stamp_code": 123,
      //     "picture": "~@/assets/images/stamp2.jpg",
      //     "public_country": "Argentina",
      //     "public_year": 1987,
      //     "note": "Текст про марку, описание",
      //     "quantity": 0
      //   },
      //   {
      //     "stamp_code": 123,
      //     "picture": "~@/assets/images/stamp1.jpg",
      //     "public_country": "Argentina",
      //     "public_year": 1987,
      //     "note": "Текст про марку, описание",
      //     "quantity": 0
      //   },
      //   {
      //     "stamp_code": 123,
      //     "picture": "~@/assets/images/stamp3.jpg",
      //     "public_country": "Argentina",
      //     "public_year": 1987,
      //     "note": "Текст про марку, описание",
      //     "quantity": 0
      //   },
      // ],
      sub_menu: [
        {
          "title": "Коллекция марок",
          "link": "/inside/stamps"
        },
        {
          "title": "Победители",
          "link": "/inside/stamps-winners"
        },
        {
          "title": "Правила",
          "link": "/inside/stamps-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    }),
    // club_id: function () {
      // if (this.my_club) {
        // return this.my_club["fc_id"]
      // }
      // return ""
    // },
  },
  mounted () {
    this.GetStamps(1)
  },
  methods: {
    async GetStamps (page) {
      let context = this
      let result = await GetStamps(context, page)
      if (result) {
        this.stamps = result["data"]["stamps"]
        this.stamps_length = result["data"]["stamps"].length
        this.repeats = result["data"]["repeats"]
        this.collected = result["data"]["collected"]
        this.page_num = result["data"]["page_num"]
        this.pages_count = result["data"]["pages_count"]
        this.name = result["data"]["name"]
        this.text = result["data"]["text"]
        this.end_date = result["data"]["end_date"]
      }
    },
    async SellRepeats () {
      let context = this
      console.log(this.my_club)
      if (this.my_club['fc_id']) {
        let result = await SellRepeats({}, context)
        if (result) {
          this.ResultToUserState(result.data)
          this.repeats = 0
          for(let i = 0; i < this.stamps.length; i++) {
            if (this.stamps[i]["quantity"] > 1) {
              this.stamps[i]["quantity"] = 1
            }
          }
        }
      }
    },
    async VoteFromStamps() {
      console.log('VoteFromStamps')
      await this.Vote()
      let context = this
      let after = function () {
        context.GetStamps(this, 1)
      }
      setTimeout(after, 500)
    },
    reducePage () {
      if (this.page_num != 1) {
        this.page_num -= 1
        this.GetStamps(this.page_num)
      }
    },
    increasePage () {
      if (this.page_num != this.pages_count) {
        this.page_num += 1
        this.GetStamps(this.page_num)
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.dreamteam_info
  width: 100%
  padding: 20px
  background: linear-gradient(#EAEAEA, #FFF)
  border-top-left-radius: 10px
  text-align: left
  border-top-right-radius: 10px

.stamps_info_wrap
  display: flex
  width: 100%
  align-items: flex-start
  flex-wrap: wrap
  justify-content: space-between
  h3
    width: 100%
  .text_wrap
    width: calc( 65% - 10px )
    p
      text-align: left
  .progress_wrap
    width: calc( 35% - 10px )
    display: flex
    padding: 10px 10px 10px 15px
    align-items: center
    justify-content: flex-start
    background-color: #375567
    border-radius: 10px
    min-height: 100px
    p
      color: #FFF
      margin: 0
      padding: 0
      font-weight: 300
      font-size: 16px
      strong
        font-size: 20px
        font-weight: 500
  @media (max-width: 600px)
    .progress_wrap, .text_wrap
      width: 100%
    .progress_wrap
      min-height: 60px

.stamps_buttons_wrap
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-wrap: wrap
  .green_button
    margin-right: 20px
  @media (max-width: 500px)
    .green_button, .light_green_button
      width: 100%
    .green_button
      margin: 0 0 20px 0



.stamps_wrap
  display: flex
  align-items: flex-start
  justify-content: space-between
  flex-wrap: wrap
  margin-top: 30px
  .stapm
    width: calc(20% - 15px)
    min-width: 100px
    height: 145px
    position: relative
    margin-bottom: 50px
    display: flex
    align-items: center
    justify-content: center
    img
      width: 100%
      max-width: 90%
      max-height: 145px
      transition: opacity 0.3s ease
    span.quant
      position: absolute
      bottom: -30px
      width: 100%
      text-align: center
      font-size: 18px
      font-weight: 500
  .stapm.no_stamp
    img
      opacity: 0.2
      &:hover
        opacity: 1
  
        .pos_r
  position: relative

.blue_prize
  position: absolute
  top: 0
  left: 0
  width: 60px

.p_image_prize
  padding: 0 0 0 80px
  text-align: left

</style>
    