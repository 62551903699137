const icons = {
  head: {
    img: require("@/assets/icons_and_logos/head.svg"),
    txt: "Вы не блокировали голову, и соперник по ней не бил",
    opp_txt: "Соперник не блокировал голову, и вы по ней не били"
  },
  sun: {
    img: require("@/assets/icons_and_logos/sun.svg"),
    txt: "Вы не блокировали солнышко, и соперник в него не бил",
    opp_txt: "Соперник не блокировал солнышко, и вы по нему не били"
  },
  liver: {
    img: require("@/assets/icons_and_logos/liver.svg"),
    txt: "Вы не блокировали печень, и соперник по ней не бил",
    opp_txt: "Соперник не блокировал печень, и вы по ней не били"
  },
  legs: {
    img: require("@/assets/icons_and_logos/legs.svg"),
    txt: "Вы не блокировали ноги, и соперник по ним не бил",
    opp_txt: "Соперник не блокировал ноги, и вы по ним не били"
  },

  head_red: {
    img: require("@/assets/icons_and_logos/head_red.svg"),
    txt: "Соперник пробил вам в голову, вы её не блокировали",
    opp_txt: "Вы пробили сопернику в голову, он её не блокировал"
  },
  sun_red: {
    img: require("@/assets/icons_and_logos/sun_red.svg"),
    txt: "Соперник пробил вам в солнышко, вы его не блокировали",
    opp_txt: "Вы пробили сопернику в солнышко, он его не блокировал"
  },
  liver_red: {
    img: require("@/assets/icons_and_logos/liver_red.svg"),
    txt: "Соперник пробил вам по печени, вы её не блокировали",
    opp_txt: "Вы пробили сопернику по печени, он её не блокировал"
  },
  legs_red: {
    img: require("@/assets/icons_and_logos/legs_red.svg"),
    txt: "Соперник пробил вам по ногам, вы их не блокировали",
    opp_txt: "Вы пробили сопернику по ногам, он их не блокировал"
  },

  head_gray: {
    img: require("@/assets/icons_and_logos/head_gray.svg"),
    txt: "Вы блокировали голову, но соперник по ней не бил",
    opp_txt: "Соперник блокировал голову, но вы по ней не били"
  },
  sun_gray: {
    img: require("@/assets/icons_and_logos/sun_gray.svg"),
    txt: "Вы блокировали солнышко, но соперник по нему не бил",
    opp_txt: "Соперник блокировал солнышко, но вы по нему не били"
  },
  liver_gray: {
    img: require("@/assets/icons_and_logos/liver_gray.svg"),
    txt: "Вы блокировали печень, но соперник по ней не бил",
    opp_txt: "Соперник блокировал печень, но вы по ней не били"
  },
  legs_gray: {
    img: require("@/assets/icons_and_logos/legs_gray.svg"),
    txt: "Вы блокировали ноги, но соперник по ним не бил",
    opp_txt: "Соперник блокировал ноги, но вы по ним не били"
  },

  head_green: {
    img: require("@/assets/icons_and_logos/head_green.svg"),
    txt: "Соперник пытался пробить вам в голову, но вы заблокировали",
    opp_txt: "Вы пытались пробить сопернику в голову, но он заблокировал"
  },
  sun_green: {
    img: require("@/assets/icons_and_logos/sun_green.svg"),
    txt: "Соперник пытался пробить вам в солнышко, но вы заблокировали",
    opp_txt: "Вы пытались пробить сопернику в солнышко, но он заблокировал"
  },
  liver_green: {
    img: require("@/assets/icons_and_logos/liver_green.svg"),
    txt: "Соперник пытался пробить вам в печень, но вы заблокировали",
    opp_txt: "Вы пытались пробить сопернику в печень, но он заблокировал"
  },
  legs_green: {
    img: require("@/assets/icons_and_logos/legs_green.svg"),
    txt: "Соперник пытался пробить вам по ногам, но вы заблокировали",
    opp_txt: "Вы пытались пробить сопернику по ногам, но он заблокировал"
  },
}

export function icon_detection(target, blocks, hits) {
  if ((blocks.indexOf(target) + 1) && (hits.indexOf(target) + 1)) {
    return icons[target + "_green"]
  } else if (blocks.indexOf(target) + 1) {
    return icons[target + "_gray"]
  } else if (hits.indexOf(target) + 1) {
    return icons[target + "_red"]
  }
  return icons[target]
}

export function iconsCondition(my_role, opponent_role, last_steps) {
  console.log(my_role, opponent_role, last_steps)
  let last_step = last_steps[0]
  console.log(last_steps)
  let my_hits = []
  let my_blocks = []
  let opponent_hits = []
  let opponent_blocks = []
  let targets = ["head", "sun", "liver", "legs"]
  let my_icons = {
    head: icons.head,
    sun: icons.sun,
    liver: icons.liver,
    legs: icons.legs
  }
  let opponent_icons = {
    head: icons.head,
    sun: icons.sun,
    liver: icons.liver,
    legs: icons.legs
  }
  if (!last_steps.length) {
    return [my_icons, opponent_icons]
  }
  for (let i=0; i<last_step[my_role + "_moves"].length; i++) {
    if (last_step[my_role + "_moves"][i].type == "block") {
      my_blocks.push(last_step[my_role + "_moves"][i].target)
    } else {
      my_hits.push(last_step[my_role + "_moves"][i].target)
    }
  }
  for (let i=0; i<last_step[opponent_role + "_moves"].length; i++) {
    if (last_step[opponent_role + "_moves"][i].type == "block") {
      opponent_blocks.push(last_step[opponent_role + "_moves"][i].target)
    } else {
      opponent_hits.push(last_step[opponent_role + "_moves"][i].target)
    }
  }
  for (let i=0; i < targets.length; i++) {
    my_icons[targets[i]] = icon_detection(targets[i], my_blocks, opponent_hits)
    opponent_icons[targets[i]] = icon_detection(targets[i], opponent_blocks, my_hits)
  }
  console.log(my_icons, opponent_icons)
  return [my_icons, opponent_icons]
}
