<template lang="pug">

.inside_wrapper
  .top_dark_line.white_bg
  LeftMenu(@slideTo="false" :currentSlide="3" :isGlory="true")
  .login_register.white_bg
    a(@click="goTo('/login')") Войти
    span &nbsp;&nbsp;|&nbsp;&nbsp;
    a(@click="goTo('/registration')") Регистрация
  vue-custom-scrollbar.inside_inwrap
    .content
      logotype
      .enter_form_wrap
        h2.confirm_phone ПОДТВЕРЖДЕНИЕ EMAIL
        v-form.confirm_form(autocomplete="off")
          v-overlay.my_overlay(
            absolute
            :dark="false"
            :light="true"
            color="#ffffff"
            :value="loading"
          )
            v-progress-circular(
              indeterminate
              color="primary"
            )
          h3.pb-4 <center>Подтверждение изменения Email</center>
</template>


<script>

import Logotype from '../../LandingPage/Logotype'
import { commonMixin } from "../../../shared/mixins"
import { CheckEmailCode } from "./../../../api/Inside"
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'EmailConfirm',
  components: {
    Logotype,
    vueCustomScrollbar
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      loading: true,
      code: null
    }
  },
  computed: {
    ...mapGetters("user_store", {
      username: "username",
    }),
  },
  created () {
    this.code = this.$route.params.code
    this.CheckCode(this.code)
  },
  mounted () {
    this.RequestSendingAgain()
  },
  methods: {
    async CheckCode (code) {
      let context = this
      let data = {
        "confirm_code": code
      }
      let then = function() {
        context.$router.push("/")
      }
      let result = await CheckEmailCode(data, context, then)
      if (result) {
        context.$swal({
          "icon": "success",
          "text": "Email успешно изменён",
        }).then(function () {
          context.loading = false
          if (context.username) {
            context.$router.push("/inside/my-data")
          } else {
            context.$router.push("/login")
          }
        })
      }
    }
  }
}

</script>


<style lang="sass" scoped>
.confirm_form
  width: 250px
  margin: 0 auto

.request_again, .wait_for_it
  margin: 20px 0 0
  padding: 0

.wait_for_it
  font-size: 14px

.request_again
  span
    display: inline-block
    border-bottom: 1px dashed #3579A1
    line-height: 17px
    color: #3579A1
    cursor: pointer
    &:hover
      text-decoration: none
      border-bottom: 1px solid transparent

.enter_form_wrap
  h2.confirm_phone
    top: -70px !important
  .v-btn.green_button
    margin-top: 10px

.top_dark_line
  @media (max-width: 1400px)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 60px
    z-index: 998
    background: linear-gradient(#000, #00000000)
  
.top_dark_line.white_bg
  @media (max-width: 1400px)
    background: linear-gradient(#fff, #ffffff00)
    
.login_register
  position: absolute
  top: 20px
  right: 30px
  z-index: 998
  @media (max-width: 700px)
    right: 10px
    top: 10px
  span
    color: #fff !important
    font-size: 16px
  a
    color: #fff !important
    font-size: 16px
    &:hover
      text-decoration: underline
  span, a
    @media (max-width: 700px)
      font-size: 14px

.login_register.white_bg
  span, a
    color: #000 !important

</style>
    