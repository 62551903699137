<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="КОЛЛЕКЦИЯ МАРОК")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .stamps_info_wrap.mt-5
        h3.mt-0.mb-3 Победители конкурса

      .other_winners(v-if="winners.length")
        .winner_wrap(v-for="winner in winners")
          img(v-if="winner.picture" :src="winner.picture")
          img(v-else src="~@/assets/images/ava_example.png")
          strong {{ winner.username }} ({{ winner.club_name }} ({{ winner.club_city }}))
          span.name {{ winner.datetime }}
        //- .winner_wrap
          //- img(src="~@/assets/images/ava_example.png")
          //- strong Иван Иванович (Торпедо)
          //- span.name 22.01.2023 17:42
        //- .winner_wrap
          //- img(src="~@/assets/images/ava_example.png")
          //- strong Иван Иванович (Торпедо)
          //- span.name 22.01.2023 17:42
        //- .winner_wrap
          //- img(src="~@/assets/images/ava_example.png")
          //- strong Иван Иванович (Торпедо)
          //- span.name 22.01.2023 17:42
        //- .winner_wrap
          //- img(src="~@/assets/images/ava_example.png")
          //- strong Иван Иванович (Торпедо)
          //- span.name 22.01.2023 17:42
        //- .winner_wrap
          //- img(src="~@/assets/images/ava_example.png")
          //- strong Иван Иванович (Торпедо)
          //- span.name 22.01.2023 17:42
      .subcontent_wrap(v-else)
        p 
          em Победителей ещё нет
      .notification_pagination.mt-5.mb-5(v-if="winners.length")
        //- img.left(
        //-   :class="{'unactive': page_num == 1}"
        //-   src="../../../assets/icons_and_logos/paginate-arrow-left.svg"
        //-   @click="reducePage"
        //- )
        //- span Страница <strong>{{ page_num }}</strong> из <strong>{{ pages_count }}</strong>
        //- img.right(
        //-   :class="{'unactive': page_num == pages_count}"
        //-   src="../../../assets/icons_and_logos/paginate-arrow-right.svg"
        //-   @click="increasePage"
        //- )
        span.paginate_page(
          v-if="page_num != 1"
          @click="reducePage"
        ) Предыдущая страница
        span.between(v-if="page_num != 1")
        span.paginate_page(
          v-if="page_num != pages_count"
          @click="increasePage"
        ) Следующая страница

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { GetStampsWinners } from "./../../../api/Inside"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'StampsWinners',
  components: {
    HeaDer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      page_num: 1,
      pages_count: 4,
      winners: [],
      // winners: [
      //   {
      //     "user_id": 100500,
      //     "username": "theUser",
      //     "club_picture": "https://images.com/users_avatar12.png",
      //     "club_name": "Торпедо",
      //     "datetime": "27.01.2023 17:42:12"
      //   },
      //   {
      //     "user_id": 100500,
      //     "username": "theUser",
      //     "club_picture": "https://images.com/users_avatar12.png",
      //     "club_name": "Торпедо",
      //     "datetime": "27.01.2023 17:42:12"
      //   },
      //   {
      //     "user_id": 100500,
      //     "username": "theUser",
      //     "club_picture": "https://images.com/users_avatar12.png",
      //     "club_name": "Торпедо",
      //     "datetime": "27.01.2023 17:42:12"
      //   },
      //   {
      //     "user_id": 100500,
      //     "username": "theUser",
      //     "club_picture": "https://images.com/users_avatar12.png",
      //     "club_name": "Торпедо",
      //     "datetime": "27.01.2023 17:42:12"
      //   },
      // ],
      sub_menu: [
        {
          "title": "Коллекция марок",
          "link": "/inside/stamps"
        },
        {
          "title": "Победители",
          "link": "/inside/stamps-winners"
        },
        {
          "title": "Правила",
          "link": "/inside/stamps-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    }),
  },
  mounted () {
    this.GetStampsWinners(1)
  },
  methods: {
    async GetStampsWinners (page) {
      let context = this
      let result = await GetStampsWinners(context, page)
      console.log('GetStampsWinners', result)
      if (result) {
        this.winners = result["data"]["winners"]
        this.page_num = result["data"]["page_num"]
        this.pages_count = result["data"]["pages_count"]
      }
    },
    reducePage () {
      if (this.page_num != 1) {
        this.page_num -= 1
        this.GetStampsWinners(this.page_num)
      }
    },
    increasePage () {
      if (this.page_num != this.pages_count) {
        this.page_num += 1
        this.GetStampsWinners(this.page_num)
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.other_winners
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  justify-content: space-evenly
  padding: 15px 0 0

.winner_wrap
  width: 135px
  padding: 0 5px
  margin-bottom: 30px
  @media (max-width: 500px)
    width: 115px
  img
    display: block
    width: 100%
  strong
    font-size: 16px
    font-weight: 400
    line-height: 20px
    display: block
    text-align: center
    margin-top: 7px
  .name
    font-size: 10px
    font-weight: 400
    line-height: 13px
    margin-top: 2px
    text-align: center
    display: block
</style>
    