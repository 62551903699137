<template lang="pug">
.left_menu_wrap#left_menu_wrap(:class="{'menuwrap_is_open': menu_is_open}")
  // vue-custom-scrollbar#second_screen_bitva(class="scroll-area")
  .left_menu#left_menu(:class="{'menu_is_open': menu_is_open}")
    vue-custom-scrollbar.inside_inwrap(v-if="is_auth")
      // .inside_inwrap(v-if="is_auth")
      LeftMenuPuncts(:is_main="true")
    vue-custom-scrollbar.inside_inwrap(v-else)
      a(@click="MenuItemClick(0, '/0', isGlory)" href="#slide1") <span>Главная</span>
      a(@click="onas = !onas") <span>О проекте</span>
      a(@click="MenuItemClick(1, '/1', isGlory)" href="#slide2") <span>Битва</span>
      a(@click="MenuItemClick(2, '/2', isGlory)" href="#slide3") <span>Конкурсы</span>
      a.conkurs(@click="MenuItemClick(3, '/3', isGlory)" href="#slide4") <span>Команда мечты</span>
      a.conkurs(@click="MenuItemClick(4, '/4', isGlory)" href="#slide5") <span>Коллекция марок</span>
      a.conkurs(@click="MenuItemClick(5, '/5', isGlory)" href="#slide6") <span>Агитируй болельщиков</span>
      a.conkurs(@click="MenuItemClick(6, '/6', isGlory)" href="#slide7") <span>Поединки «Раз на Раз»</span>
      a(@click="MenuItemClick(7, '/7', isGlory)" href="#slide8") Призы
      a(href="/glory-hall") <span>Зал Славы</span>
      .hr
      div(v-if="is_auth")
        a(href="/inside/personal") <span>Личный кабинет</span>
      div(v-else)
        a(href="/login") <span>Войти</span>
        a(href="/registration") <span>Регистрация</span>
      .hr
      // .small_punkt.mt-8
      //   a(href="/contacts") <span>Контакты</span>
      //- .small_punkt
        //- a(@click="onas = !onas") <span>О проекте</span>
      .small_punkt
        a(@click="contacts = !contacts") <span>Контакты</span>
      .small_punkt
        a(@click="agreement = !agreement") <span>Оферта</span>
      //- .small_punkt
      //-   a(@click="pay = !pay") <span>Оплата и возврат</span>
      .small_punkt
        a(@click="confident = !confident") <span>Политика конфиденциальности</span>

      // p.mb-2.mt-4 Платежи принимаются при помощи платёжной системы
      p.mt-4.mb-0
        // img.mt-2(src="~@/assets/icons_and_logos/pays.png" width="270")
        img.mr-2(src="~@/assets/icons_and_logos/mir.jpg" height="30")
        img.mr-2(src="~@/assets/icons_and_logos/visa.jpg" height="30")
        img.mr-2(src="~@/assets/icons_and_logos/mastercard.jpg" height="30")
        img.sbp_logo(src="~@/assets/icons_and_logos/sbp.jpg" height="50")
        // a(href="https://freekassa.ru" target="_blank" rel="noopener noreferrer")
        //   img.mr-2(src="https://cdn.freekassa.ru/banners/small-white-1.png" height="30" title="Прием платежей на сайте для физических лиц и т.д.")
      p.mt-3 2024 © БИТВА ФАНАТОВ®
  .burger_button(@click="openMenu")
    img(v-if="!menu_is_open && !is_black" src="~@/assets/icons_and_logos/majesticons_menu.svg")
    img(v-else-if="!menu_is_open && is_black" src="~@/assets/icons_and_logos/majesticons_menu_black.svg")
    img(v-else src="~@/assets/icons_and_logos/majesticons_menu_close.svg")

  v-dialog(v-model="agreement" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 ОФЕРТА <i class="close_icon" @click="agreement = !agreement"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            AgreementText.pt-4

  v-dialog(v-model="confident" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ <i class="close_icon" @click="confident = !confident"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            ConfidentText.pt-4

  v-dialog(v-model="contacts" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 КОНТАКТЫ <i class="close_icon" @click="contacts = !contacts"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            ContactsText.pt-4

  v-dialog(v-model="onas" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 О ПРОЕКТЕ <i class="close_icon" @click="onas = !onas"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            OnasText.pt-4

  v-dialog(v-model="pay" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 ОПЛАТА <i class="close_icon" @click="pay = !pay"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            PaymentText.pt-4

</template>
  
  
<script>
  
import { commonMixin } from "../../shared/mixins"
// import { wheel_main_slider_calculator, initializeClock } from "../../shared/utils"
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { getAccessTokenSimple } from "../../api/api"
import LeftMenuPuncts from "../Common/LeftMenuPuncts"
import BitvaPersonalClubRulesText from "../InsidePages/BitvaClub/BitvaPersonalClubRulesText"
import AgreementText from '@/components/Common/AgreementText'
import ConfidentText from '@/components/Common/ConfidentText'
import OnasText from '@/components/Common/OnasText'
import PaymentText from '@/components/Common/PaymentText'
import ContactsText from '@/components/InsidePages/ContactsText'

export default {
  name: 'LeftMenu',
  components: {
    BitvaPersonalClubRulesText,
    vueCustomScrollbar,
    AgreementText,
    ConfidentText,
    ContactsText,
    OnasText,
    PaymentText,
    LeftMenuPuncts
  },
  mixins: [
    commonMixin
  ],
  props: [
    'currentSlide',
    'isGlory',
  ],
  data () {
    return {
      menu_is_open: false,
      is_auth: false,
      agreement: false,
      confident: false,
      contacts: false,
      onas: false,
      pay: false,
    }
  },
  computed: {
    is_black: function () {
      if (
        parseInt(this.currentSlide) == 3 ||
        parseInt(this.currentSlide) == 4 ||
        parseInt(this.currentSlide) == 7
      ) {
        return true
      }
      return false
    }
  },
  async created () {
    document.addEventListener("click", this.click_handler)
  },
  async updated () {
    await this.checkIsAuth()
  },
  beforeDestroy () {
    document.removeEventListener("click", this.click_handler)
  },
  methods: {
    async checkIsAuth () {
      let access = await getAccessTokenSimple()
      if (access) {
        this.is_auth = true
      }
    },
    click_handler (event) {
      if (!event.target.closest('#left_menu_wrap')) {
        document.querySelector('#left_menu_wrap').classList.remove("menuwrap_is_open")
        document.querySelector('#left_menu').classList.remove("menu_is_open")
        this.menu_is_open = false
      }
    },
    slideTo (slide_num) {
      this.$emit("slideTo", slide_num)
    },
    openMenu () {
      this.menu_is_open = !this.menu_is_open
    },
    MenuItemClick (slide, link, isGlory) {
      if (!isGlory && (slide || slide === 0)) {
        this.slideTo(slide)
      } else if (link) {
        this.goTo(link)
      }
      this.menu_is_open = !this.menu_is_open
    }
  }
}

</script>


<style lang="sass" scoped>

.sbp_logo
  position: relative
  top: 7px

.small_punkt
  a
    font-size: 18px !important

.burger_button
  position: absolute
  top: -2px
  left: 0px
  z-index: 100
  cursor: pointer
  @media (max-width: 700px)
    left: 0px
  @media (max-width: 500px)
    top: -4px

.left_menu
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 0
  opacity: 0
  z-index: 99
  background: url('~@/assets/images/football_pattern.jpg') top center repeat
  padding: 0
  text-align: left
  overflow: hidden
  transition: width 0.3s ease, opacity 0.3s ease, padding 0.3s ease
  .hr
    width: calc(100% - 15px)
  a
    color: #000 !important
    font-size: 20px
    display: block
    width: 275px
    margin-bottom: 10px
    text-decoration: none
    span
      display: inline-block
      border-bottom: 1px dashed transparent
      line-height: 20px
    &:hover
      // text-decoration: underline
      span
        display: inline-block
        border-bottom: 1px dashed #2c3e50
  a.conkurs
    padding-left: 10px
  .hr
    width: 100%
    height: 1px
    background-color: #000
    margin-bottom: 15px
    margin-top: 15px
    opacity: 0.6

.left_menu_wrap.menuwrap_is_open
  @media (max-width: 500px)
    width: 100%

  .left_menu
    width: 330px
    opacity: 1
    padding: 70px 15px 20px 20px
    box-shadow: 0px 0px 10px 0px #000
    @media (max-width: 700px)
      width: 300px
    @media (max-width: 500px)
      width: 100%
    
</style>
