const state = {
  user_id: null,
  username: null,
  email: null,
  phone: null,
  ref_link: null,
  picture: null,
  active_pvp: {
    pvp_id: null,
    master_ready: false,
    client_ready: false,
    master_step_ready: false,
    client_step_ready: false,
    bet: null,
    master_id: null,
    master_username: "",
    master_picture: "",
    master_club: "",
    master_club_picture: "",
    client_id: null,
    client_username: "",
    client_picture: "",
    client_club: "",
    client_club_picture: "",
    pvp_websocket_link: "",
    created_at: "",
    pvp_status: "",
    pvp_condition: {
      fints_market: [
        {
          id: 1,
          name: "",
          description: "",
          price: 1
        },
      ],
      step_num: null,
      master_condition: {
        energy: 3,
        energy_limit: 4,
        health: 10,
        angry: 1,
        hit_exclude: "head", // - head, sun, liver, legs
        fints: [
          {
            id: 1,
            name: "",
            description: "",
            price: 1
          },
        ]
      },
      client_condition: {
        energy: 3,
        energy_limit: 4,
        health: 10,
        angry: 1,
        hit_exclude: "head", // - head, sun, liver, legs
        fints: [
          {
            id: 1,
            name: "",
            description: "",
            price: 1
          },
        ]
      },
      last_steps: [
        {
          master_moves: [
            {
              type: "block", // - hit, block
              target: "head", // - head, sun, liver, legs
              result: true,
            },
          ],
          client_moves: [
            {
              type: "block", // - hit, block
              target: "head", // - head, sun, liver, legs
              result: true,
            },
          ],
          master_fint: {
            id: 1,
            name: "",
            description: "",
            price: 1
          },
          master_fint_result: null,
          client_fint: {
            id: 1,
            name: "",
            description: "",
            price: 1
          },
          client_fint_result: true
        }
      ]
    },
  },
  coins: 0,
  voices: 0,
  club: {
    "id": 1,
    "name": "Зенит",
  },
  voice_given: null,
  club_users_voice_given: null,
  club_place: 0,
  club_rating: 0,
  zaglushka: false,
  personal_club_rating: 0,
  personal_rating: 0,
}

const getters = {
  user_id (state) {
    return state.user_id;
  },
  username (state) {
    return state.username;
  },
  email (state) {
    return state.email;
  },
  phone (state) {
    return state.phone;
  },
  zaglushka (state) {
    return state.zaglushka;
  },
  ref_link (state) {
    return state.ref_link;
  },
  picture (state) {
    return state.picture;
  },
  coins (state) {
    return state.coins;
  },
  voices (state) {
    return state.voices;
  },
  club (state) {
    return state.club;
  },
  active_pvp (state) {
    return state.active_pvp;
  },
  voice_given (state) {
    return state.voice_given;
  },
  club_place (state) {
    return state.club_place;
  },
  club_rating (state) {
    return state.club_rating;
  },
  personal_club_rating (state) {
    return state.personal_club_rating;
  },
  personal_rating (state) {
    return state.personal_rating;
  }
}
const mutations = {
  update(state, { key, value }) {
    state[key] = value;
  }
}
const actions = {
  ACTION_UPDATE ({ commit }, key_value) {
    commit('update', key_value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}