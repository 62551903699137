<template lang="pug">

keep-alive
  .super_zaglushka(v-if="zaglushka")
    .super_zaglushka_in
      a(href="/0")
        img(src='~@/assets/icons_and_logos/main_logo.svg')
      h1 Идёт подсчёт результатов
      p Битва будет доступна в <strong>01:00</strong> по московскому времени
  router-view(v-else)

</template>

<script>

import { mapGetters } from "vuex"
import { commonMixin } from "../../shared/mixins"
import Logotype from '../LandingPage/Logotype'

export default {
  name: "InsidePages",
  components: {
    Logotype,
  },
  mixins: [
    commonMixin
  ],
  computed: {
    ...mapGetters("user_store", {
      user_id: "user_id",
      zaglushka: "zaglushka"
    })
  },
  created () {
    console.log()
    this.load()
    // if (this.$route.meta.needAuth) {
    //   if (!this.user_id) {
    //     this.load()
    //   } else {
    //     this.checkRedirectIsNeed()
    //   }
    // } else {
    //   this.checkRedirectIsNeed()
    // }
    
  },
  methods: {
    async load() {
      // let context = this
      let then = function () {
        // context.$router.push("/login")
        // context.LogOut()
      }
      let result = await this.loadUserData(then)
      if (result) {
        this.checkRedirectIsNeed()
      }
    },
  }

};
</script>

<style lang="sass" scoped>

.super_zaglushka
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center
  .super_zaglushka_in
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    h1
      width: 100%
    p
      strong
        font-size: 1.2em

</style>
