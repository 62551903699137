<template lang="pug">


.subcontent_wrap.mt-5
  .dreamteam_cols_wrap
    .dreamteam_col_left
      .dreamteam_info
        h4.mb-2 {{ name }}
        p {{ description }}
      h3(v-if="voice_given < 2") Отдай два голоса в поддержку своего клуба и получи свою личную карточку футболиста!
      .button_wrap.mt-4(v-if="voice_given < 2")
        v-btn.green_button(
          v-if="voices"
          x-large
          dark
          color="#04841F"
          @click="Vote"
        ) Отдать голос
        v-btn.green_button(
          v-else
          x-large
          dark
          color="#04841F"
          @click="goTo('/inside/shop')"
        ) Получить ещё голос
        .voices {{ voice_given }} <span>из</span> 2

      h3.mt-6 Правила конкурса <span>(кратко)</span>

      ul.pl-1
        li Ты получаешь одну из {{ count }} карточек с именем футболиста знаменитой сборной. У твоей карточки будет уникальный код.
        li Распространяй этот код среди друзей и других участников, чтобы получать баллы!
        li Ищи других участников, готовых поделиться своим кодом с тобой, чтобы первым собрать всю сборную и выиграть приз!
        li Все {{ count }} {{ word_num_cases(count, "участник", "участника", "участников") }}, коды которых попадут в первую полностью укомплектованную сборную, получат звание «Игрок Команды Мечты» и Сертификаты Ozon номиналом 5000 руб.!
        li Все участники, попавшие в полностью укомплектованную сборную (даже если она окажется не первой), получат по 100 баллов на следующую Битву!
      

      p.mt-5 Форма заполнения сборной (пример):
      img.dreamteam_table(src="../../../assets/images/dreamteam_table.jpg")

      .dark_blue_wrap Ты получаешь одну и только одну карточку за весь конкурс!<br>
        | Твоя задача — распространить её среди наибольшего числа других участников и
        |  попробовать самостоятельно собрать как можно больше кодов от других участников!

      h3.mt-6 Карточки отличаются редкостью:
      
      .stars_wrap.mt-4
        img.card_rare(src="../../../assets/icons_and_logos/gold_star.svg")
        img.card_rare(src="../../../assets/icons_and_logos/purple_star.svg")
        img.card_rare(src="../../../assets/icons_and_logos/green_star.svg")
        img.card_rare(src="../../../assets/icons_and_logos/gray_star.svg")

      p.mt-4 Карточки с серой звездой выпадают чаще всего. С зелёной — чуть реже. С сиреневой — ещё реже. Карточки с золотой звездой выпадают реже всего.

    .dreamteam_col_right
      p Карточка футболиста (пример):
      DreamTeamCard.dreamteam_card.mb-2(rare="4" name="ЛЕВ ЯШИН" role="вратарь")
      p Код карточки (пример): <strong>A1B2C3</strong>

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import DreamTeamCard from "./DreamTeamCard"
import { GetDreamteamBase } from "./../../../api/Inside"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'NoCard',
  components: {
    DreamTeamCard
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      name: null,
      description: null,
      count: 22,
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voice_given: "voice_given",
      voices: "voices",
      club: "club",
    }),
    club_id: function () {
      if (this.club) {
        return this.club.fc_id
      }
      return null
    }
  },
  mounted () {
    this.GetDreamteamBase()
  },
  methods: {
    async GetDreamteamBase() {
      let context = this
      let result = await GetDreamteamBase(context)
      if (result) {
        this.name = result["data"]["name"]
        this.description = result["data"]["description"]
        this.count = result["data"]["count"]
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.dreamteam_cols_wrap
  display: flex
  align-items: flex-start
  justify-content: space-between
  flex-wrap: wrap
  .dreamteam_col_left
    width: calc(100% - 210px)
    @media (max-width: 700px)
      width: 100%
    h3
      margin-top: 0px

  .dreamteam_col_right
    width: 180px
    @media (max-width: 700px)
      width: 100%
    p
      text-align: center
      font-weight: 300
      line-height: 23px
      margin-bottom: 5px
      strong
        font-size: 30px
        font-weight: 600
        line-height: 42px


.button_wrap
  position: relative

  .green_button
    position: relative
    z-index: 3

  .voices
    width: 85px
    height: 53px
    line-height: 53px
    text-align: center
    padding-left: 6px
    background-color: #CEF4D6
    color: #000
    font-weight: 600
    fint-size: 18px
    position: absolute
    top: 0
    left: 155px
    z-index: 1
    border-top-right-radius: 5px
    border-bottom-right-radius: 5px
    span
      font-weight: 300
      fint-size: 16px

.dreamteam_table
  width: 100%
  margin-bottom: 10px

.dark_blue_wrap
  width: 100%
  border-radius: 10px
  background-color: #375567
  padding: 10px 20px 12px
  text-align: center
  color: #fff
  font-size: 14px
  font-weight: 400

.stars_wrap
  width: 100%
  display: flex
  align-items: flex-start
  justify-content: space-between
  img
    width: 20%

.dreamteam_info
  width: 100%
  padding: 20px
  background: linear-gradient(#EAEAEA, #FFF)
  border-top-left-radius: 10px
  border-top-right-radius: 10px

</style>
    