<template lang="pug">

.inside_wrapper
  .left_col_wrap
    LeftMenu

  .right_col_wrap
    HeaDer(header="ПЕРСОНАЛЬНЫЕ ДАННЫЕ")
    .content_wrap.my_data_wrap
      
      HeaderSubMenu(:pages="sub_menu")

      .subcontent_wrap
        h3.notifications_h3 Изменить аватарку:
        v-form(autocomplete="off" ref="form_picture" v-model="valid_picture" @keyup.native.enter="ChangeAvatar")
          .picture_name_wrap
            picture-input(
              ref="pictureInput"
              width="180"
              height="180"
              margin="10"
              radius="2"
              accept="image/jpeg,image/png,image/gif,image/bmp"
              size="4"
              button-class="picture_button"
              removeButtonClass="picture_button_del"
              :custom-strings="custom_strings"
              :removable="false"
              :prefill="prefill"
              @change="onChangePicture"
            )
            v-overlay.my_overlay(
              absolute
              :dark="false"
              :light="true"
              color="#ffffff"
              :value="picture_loading"
            )
              v-progress-circular(
                indeterminate
                color="primary"
              )
            v-btn.green_button(
              dark
              color="#04841F"
              @click="ChangeAvatar"
              :disabled="!new_picture"
            ) {{ pictureButtonText }}
            // .username

      .subcontent_wrap
        h3.notifications_h3 Изменить псевдоним:
        v-form(autocomplete="off" ref="form_username" v-model="valid_username" @keyup.native.enter="ChangeUsername")
          v-text-field(
            label="Ваш псевдоним (или имя):"
            v-model="new_username"
            :label="username"
            solo
            @change="CheckUsername"
            :error-messages="username_hint"
            :rules="[usernameRules]"
          )
          v-overlay.my_overlay(
            absolute
            :dark="false"
            :light="true"
            color="#ffffff"
            :value="username_loading"
          )
            v-progress-circular(
              indeterminate
              color="primary"
            )
          v-btn.green_button(
            x-large
            dark
            color="#04841F"
            @click="ChangeUsername"
            :disabled="new_username == initial_username || !valid_username"
          ) Сохранить новый псевдоним

      .subcontent_wrap
        h3.notifications_h3 Изменить E-mail:
        v-form(autocomplete="off" ref="form_email" v-model="valid_email" @keyup.native.enter="ChangeEmail")
          v-text-field(
            label="Новый E-mail Адрес:"
            v-model="new_email"
            solo
            :rules="[emailRules]"
            :error-messages="email_hint"
            @change="CheckEmail"
          )
          v-overlay.my_overlay(
            absolute
            :dark="false"
            :light="true"
            color="#ffffff"
            :value="email_loading"
          )
            v-progress-circular(
              indeterminate
              color="primary"
            )
          p Твой текущий E-mail адрес: {{ email }}<br>
            |  После того как ты нажмёшь «Сохранить новый E-mail», проверь входящие
            |  в твоём почтовом ящике и кликни по ссылке для активации нового адреса.

          v-btn.green_button(
            x-large
            dark
            color="#04841F"
            @click="ChangeEmail"
            :disabled="new_email == initial_email"
          ) Сохранить новый E-mail

      .subcontent_wrap
        h3.notifications_h3 Изменить пароль:
        v-form(autocomplete="off" ref="form_password" v-model="valid_password" @keyup.native.enter="ChangePassword")
          v-text-field(
            label="Введи свой текущий пароль"
            v-model="old_password"
            solo
            :rules="[required, counter30, min]"
            :type="show ? 'text' : 'password'"
          )
          v-text-field(
            label="Введи новый пароль"
            v-model="new_password"
            solo
            :rules="[required, counter30, min]"
            :type="show ? 'text' : 'password'"
          )
          v-text-field(
            label="Повтори новый пароль"
            v-model="new_password_repeat"
            solo
            :rules="[required, counter30, min]"
            :type="show ? 'text' : 'password'"
          )
          v-overlay.my_overlay(
            absolute
            :dark="false"
            :light="true"
            color="#ffffff"
            :value="password_loading"
          )
            v-progress-circular(
              indeterminate
              color="primary"
            )
          v-btn.green_button(
            x-large
            dark
            color="#04841F"
            @click="ChangePassword"
            :disabled="!new_password && !new_password_repeat && new_password == new_password_repeat"
          ) Сохранить новый пароль

      .subcontent_wrap
        v-btn.red_button.mt-7(
          x-large
          dark
          color="#04841F"
          @click="deleteAccaunt"
        ) Удалить аккаунт

      BitvaTimer

</template>


<script>

import PictureInput from 'vue-picture-input'
import { commonMixin } from "../../../shared/mixins"
import { ChangeAvatar, ChangeEmail, ChangeUsername, ChangePassword, deleteAccaunt } from "../../../api/Inside"
import { checkEmail, CheckUsername } from "../../../api/Registration"
import HeaDer from "../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import vueCustomScrollbar from "vue-custom-scrollbar"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'MyWins',
  components: {
    vueCustomScrollbar,
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    PictureInput,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  computed: {
    ...mapGetters("user_store", {
      picture: "picture",
      username: "username",
      email: "email",
    }),
    prefill: function () {
      if (this.picture) {
        return this.picture
      }
      return null
      // return "https://cdn.pixabay.com/photo/2023/06/27/10/51/man-8091933_1280.jpg"
    },
    pictureButtonText: function () {
      if (this.new_picture) {
        return "Сохранить новое изображение"
      }
      return "Сохранить изменения"
    }
  },
  data () {
    return {
      valid_picture: false,
      valid_username: false,
      valid_email: false,
      valid_password: false,

      picture_loading: false,
      username_loading: false,
      email_loading: false,
      password_loading: false,
      confident: false,

      new_picture: null,
      initial_username: null,
      initial_email: null,
      new_username: null,
      new_email: null,
      old_password: null,
      new_password: null,
      new_password_repeat: null,

      show: false,
      email_hint: [],
      username_hint: [],
      sub_menu: [
        {
          "title": "Моя страница",
          "link": "/inside/personal"
        },
        {
          "title": "Мои призы",
          "link": "/inside/my-wins"
        },
        {
          "title": "История покупок",
          "link": "/inside/purchases"
        },
        {
          "title": "Персональные данные",
          "link": "/inside/my-data"
        }
      ],
      custom_strings: {
        upload: '<p>Твоё устройство не поддерживает загрузку файлов</p>',
        drag: 'Перетащи сюда изображение <br>или кликни, чтобы выбрать файл',
        tap: 'Нажми сюда, чтобы выбрать изображение <br>из твоей галереи',
        change: 'Сменить изображение',
        remove: 'Удалить изображение',
        select: 'Выбрать изображение',
        selected: '<p>Изображение выбрано успешно!</p>',
        fileSize: 'Размер файла превышет допустимый лимит',
        fileType: 'Такой тип фойла не поддерживается.',
        aspect: 'Горизонтальная/Вертикальная'
      }
    }
  },
  mounted () {
    console.log(this.username)
    let context = this
    let check_is_data_loaded = function() {
      context.new_username = context.username
      context.initial_username = context.username
      context.new_email = context.email
      context.initial_email = context.email
      if (context.email) {
        clearInterval(check_loaded)
      }
    }
    let check_loaded = setInterval(check_is_data_loaded, 300)
  },
  methods: {
    async onChangePicture (image) {
      // console.log('Picture loaded.')
      // console.log(image)
      if (image) {
        this.new_picture = image
      } else {
        console.log('FileReader API not supported: use the <form>, Luke!')
      }
    },
    async CheckUsername () {
      console.log('gbctw')
      let context = this
      let data = {
        "username": this.new_username
      }
      let result = await CheckUsername(data, context)
      if (result) {
        if (result["result"] != "ok") {
          this.username_hint.push("Пользователь с таким псевдонимом уже зарегистрирован")
        } else {
          this.username_is_free = true
          this.username_hint = []
        }
      }
    },
    async ChangeAvatar () {
      this.picture_loading = true
      let context = this
      // let formData = new FormData()
      let data = {
        "file_bytes": this.new_picture
      }
      // formData.append("picture", this.new_picture)
      // console.log(this.new_picture)
      // let header = {
      //     'Content-Type': 'image/jpeg'
      // }
      let result = await ChangeAvatar(data, context)
      if (result) {
        this.$swal({
          "icon": "success",
          "text": "Аватарка успешно изменена",
        }).then(function () {
          // this.ResultToUserState(result.data)
        })
      }
      this.picture_loading = false
    },
    async deleteAccaunt () {
      let context = this
      let then = function() {
        context.LogOut()
        context.$router.push("/login")
      }
      this.$swal({
        "icon": "warning",
        "title": "Вы уверены, что хотите удалить аккаунт?",
        // "text": "Остатки баллов и голосов (если они есть) будут безвозвратно аннулированы. Вы не сможете заново создать аккаунт с текущими адресом электронной почты или номером мобильного телефона.",
        "html": `Данные Вашего аккаунта будут удалены согласно «<a href="/confident">Политике конфиденциальности</a>». Вы не сможете регистрировать новый аккаунт с текущими номером телефона и/или псевдонимом.`,
        // "buttons": ["Нет", "Да, удалить"],
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Да, удалить",
        cancelButtonText: "Нет",
      }).then(async function (res) {
        if (res.isConfirmed) {
          // let data = {
          //   "user_id": context.user_id
          // }
          let result = await deleteAccaunt(context, then)
          if (result) {
            context.$swal({
              "icon": "success",
              "text": "Аккаунт успешно удалён",
            }).then(function () {
              context.LogOut()
            })
          }
        }
      })
    },
    async ChangeEmail () {
      this.email_loading = true
      let context = this
      let data = {
        "email": this.new_email
      }
      let result = await ChangeEmail(data, context)
      if (result) {
        this.$swal({
          "icon": "success",
          "text": "Процесс изменения Email запущен. Проверь почту.",
        }).then(function () {
          // this.ResultToUserState(result.data)
        })
      }
      this.email_loading = false
    },
    async ChangeUsername () {
      this.username_loading = true
      let context = this
      let data = {
        "username": this.new_username
      }
      let result = await ChangeUsername(data, context)
      if (result) {
        this.$swal({
          "icon": "success",
          "text": "Псевдоним успешно изменён",
        }).then(function () {
          context.ResultToUserState(result.data)
          context.initial_username = data.username
        })
      }
      this.username_loading = false
    },
    async ChangePassword () {
      if (this.new_password == this.new_password_repeat) {
        this.password_loading = true
        let context = this
        let data = {
          "old_password": this.old_password,
          "new_password": this.new_password,
          "new_password_repeat": this.new_password
        }
        let result = await ChangePassword(data, context)
        if (result) {
          this.$swal({
            "icon": "success",
            "text": "Пароль успешно изменён",
          }).then(function() {
            context.old_password = null
            context.new_password = null
            context.new_password_repeat = null
          })
        }
        this.password_loading = false
      } else {
        this.$swal({
          "icon": "error",
          "text": "Пароли не совпадают",
        })
      }
    },
    async CheckEmail () {
      let context = this
      let data = {
        "email": this.new_email
      }
      let result = await checkEmail(data, context)
      if (result) {
        if (result["result"] != "ok") {
          this.email_hint.push("Пользователь с таким адресом уже зарегистрирован")
        } else {
          this.email_is_free = true
        }
      }
    },
    usernameRules (v) {
      if (v) {
        // let pattern = /^(?!\s)(?!.*\s\s)(?<!\s)$/
        // let pattern = /^(?! )(?!.* $)(?!(?:.* ){2}).*$/
        let pattern = /^(?! )(?!.* $)(?!.*\s\s).*$/
        if (v.length > 30) {
          this.username_hint = []
          return 'Максимум 30 символов'
        }
        if (v.length < 3) {
          this.username_hint = []
          return 'Минимум 3 символа'
        }
        if (!pattern.test(v)) {
          this.email_hint = []
          return 'Некорректный Псевдоним.'
        }
      } else {
        this.username_hint = []
        return 'Обязательное поле.'
      }
      return true
    },
  }
}

</script>


<style lang="sass">

.my_data_wrap

  .subcontent_wrap
    padding-bottom: 30px
    form
      margin-top: 15px
      max-width: 600px

    .green_button, .red_button
      height: 50px !important
      padding: 0px 15px !important
      @media (max-width: 500px)
        padding: 0px 10px !important
      span
        font-size: 16px !important
        @media (max-width: 500px)
          font-size: 15px !important

    .picture_name_wrap
      width: 100%
      position: relative
      display: flex
      justify-content: flex-start
      align-items: flex-start
      flex-wrap: wrap
      @media (max-width: 500px)
        padding-bottom: 160px
      
      .green_button
        position: absolute
        bottom: 0
        left: 200px
        @media (max-width: 500px)
          top: auto
          bottom: 0
          left: 50%
          margin-left: -90px
          width: 180px
          span
            font-size: 15px !important

      .picture-input
        width: 180px !important
        height: auto
        box-sizing: border-box
        margin: 0
        cursor: pointer
        position: relative
        transform: translate3d(0,0,0)
        .preview-container
          width: 180px !important
          max-width: 180px !important
          height: 180px !important
        @media (max-width: 500px)
          width: 180px !important
          margin: 0 auto
          .preview-container
            width: 100% !important
            max-width: 100% !important
      
      .picture_button
        width: 100%
        position: absolute
        bottom: 65px
        left: 200px
        height: 40px
        color: #fff
        background: linear-gradient(#588dad, #375567)
        font-size: 15px
        font-weight: 300
        border-radius: 5px
        margin: 0
        @media (max-width: 500px)
          top: auto
          bottom: -50px
          left: 0
          width: 100%

      .picture_button_del
        width: 100%
        height: 40px
        color: #fff
        background: linear-gradient(#BB5E5E, #830E0E)
        font-size: 15px
        font-weight: 300
        border-radius: 5px
        margin: 0
        position: absolute
        top: 55px
        left: 200px
        @media (max-width: 500px)
          top: auto
          bottom: -100px
          left: 0
          width: 100%

      .username
        width: calc( 100% - 210px )
        flex: none

      .picture-preview
        width: 100% !important
        height: 100% !important
        position: absolute
        top: 0
        left: 0
        z-index: 10001
        box-sizing: border-box
        background-color: rgba(200,200,200,.25)

      .picture-inner
        position: absolute
        z-index: 10002
        pointer-events: none
        box-sizing: border-box
        padding: 0.5em
        border: 0.3em dashed rgba(66,66,66,.15)
        border-radius: 8px
        width: calc(100% - 20px)
        height: calc(100% - 20px)
        display: flex
        justify-content: center
        align-items: center
        top: 10px !important
        left: 10px !important
        font-size: 8px
        border-radius: 2%
        z-index: 10002
        margin: 0 !important

  // .avatar_data_wrap
  //   display: flex
  //   justify-content: flex-start
  //   align-items: center
  //   flex-wrap: wrap
  //   margin-top: 20px
  //   border-bottom: 1px dotted #DDDDDD
  //   padding-bottom: 20px
  //   .picture_block
  //     margin-right: 20px
  //     width: 145px
  //     img
  //       width: 145px
  //     .faked_picture
  //       width: 145px
  //       height: 145px
  //       border-radius: 145px
  //       background-color: #DDD
  //   .data_block
  //     text-align: left
  //     width: calc( 100% - 165px )
  //     h4
  //       margin-bottom: 5px
  //       font-size: 18px
  //       font-weight: 500

</style>
    