<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ПОЕДИНКИ «РАЗ НА РАЗ»")
    .content_wrap#animatable

      HeaderSubMenu(:pages="sub_menu")
      #pvp_main_wrapper(v-if="my_role")
        // h1 {{ active_pvp.pvp_condition.master_condition.energy }}
        .subcontent_wrap.mt-5
          .game_block
            .your_data
              .player_ready(v-if="active_pvp[my_role + '_step_ready'] || i_am_stepped")
              .user_data
                .avatar(v-if="active_pvp[my_role + '_picture']")
                  img(:src="active_pvp[my_role + '_picture']")
                .avatar(v-else)
                  // img(v-if="active_pvp[my_role + '_picture']" :src="active_pvp[my_role + '_picture']")
                  span ?
                .club_picture
                  // img(:src="my_club.picture")
                  img(v-if="active_pvp[my_role + '_club_picture']" :src="active_pvp[my_role + '_club_picture']")
              .username(v-tippy="username + ' - ' + active_pvp[my_role + '_club']")
                .username_username {{ username }}
                .username_club <em>{{ active_pvp[my_role + '_club'] }}</em>
              // .username Констансальский Ержан Забухайлович
              .energy_block.mt-2
                span.stats_text Энергия:
                .energys
                  .energy(v-for="num in energy")
                  .energy.no_energy(
                    v-for="num in (energy_limit - energy)"
                  )
                span.stats_text Здоровье:
                .healthes
                  .health(v-for="num in active_pvp['pvp_condition'][my_role + '_condition']['health']")
                  .health.no_health(v-for="num in (full_health - active_pvp['pvp_condition'][my_role + '_condition']['health'])")
                span.stats_text(v-if="active_pvp['pvp_condition'][my_role + '_condition']['angry']") Ярость:
                .angrys(v-if="active_pvp['pvp_condition'][my_role + '_condition']['angry']")
                  .angry(v-for="num in active_pvp['pvp_condition'][my_role + '_condition']['angry']")
                span.stats_text Состояние:
                .organs
                  .organ_icon.head_icon
                    img(:src="my_icons.head.img" v-tippy="my_icons.head.txt")
                  .organ_icon.sun_icon
                    img(:src="my_icons.sun.img" v-tippy="my_icons.sun.txt")
                  .organ_icon.liver_icon
                    img(:src="my_icons.liver.img" v-tippy="my_icons.liver.txt")
                  .organ_icon.legs_icon
                    img(:src="my_icons.legs.img" v-tippy="my_icons.legs.txt")

            .moves_block
              .moves_block_inwrap
                .moves_subblock_hits
                  img(src="~@/assets/icons_and_logos/fist.svg")
                  .move_punkt.hit_head(
                    @click="hitMoveHead"
                    :class="{ 'active': head_hit, 'exclude': active_pvp['pvp_condition'][my_role + '_condition']['hit_exclude'].indexOf('head') + 1 }"
                  )
                    .bullet_wrap
                    .move_text Пробить в голову
                  .move_punkt.hit_head(
                    @click="hitMoveSun" 
                    :class="{ 'active': sun_hit, 'exclude': active_pvp['pvp_condition'][my_role + '_condition']['hit_exclude'].indexOf('sun') + 1 }"
                  )
                    .bullet_wrap
                    .move_text Пробить в солнышко
                  .move_punkt.hit_head(
                    @click="hitMoveLiver"
                    :class="{ 'active': liver_hit, 'exclude': active_pvp['pvp_condition'][my_role + '_condition']['hit_exclude'].indexOf('liver') + 1 }"
                  )
                    .bullet_wrap
                    .move_text Пробить по печени
                  .move_punkt.hit_head(
                    @click="hitMoveLegs"
                    :class="{ 'active': legs_hit, 'exclude': active_pvp['pvp_condition'][my_role + '_condition']['hit_exclude'].indexOf('legs') + 1 }"
                  )
                    .bullet_wrap
                    .move_text Пробить по ногам

                .moves_subblock_blocks
                  img(src="~@/assets/icons_and_logos/shield.svg")
                  .move_punkt.block_head(@click="blockMoveHead" :class="{ 'active': head_block }")
                    .bullet_wrap
                    .move_text Заблокировать голову
                  .move_punkt.block_head(@click="blockMoveSun" :class="{ 'active': sun_block }")
                    .bullet_wrap
                    .move_text Заблокировать солнышко
                  .move_punkt.block_head(@click="blockMoveLiver" :class="{ 'active': liver_block }")
                    .bullet_wrap
                    .move_text Заблокировать печень
                  .move_punkt.block_head(@click="blockMoveLegs" :class="{ 'active': legs_block }")
                    .bullet_wrap
                    .move_text Заблокировать ноги

                .moves_buttons_block.mt-5
                  v-btn.green_button(
                    v-if="some_moved"
                    large
                    dark
                    :disabled="active_pvp[my_role + '_step_ready'] && !active_pvp[opponent_role + '_step_ready'] || i_am_stepped"
                    color="#04841F"
                    @click="MakeAStep"
                  ) {{ active_pvp[my_role + '_step_ready'] && !active_pvp[opponent_role + '_step_ready'] || i_am_stepped ? 'Ожидаем хода оппонента' : 'Сделать ход' }}
                  v-btn.green_button(
                    v-else
                    large
                    dark
                    :disabled="true"
                    color="#04841F"
                  ) Сделать ход
                  .step_info_wrap.mt-5
                    .step_num Раунд № {{ active_pvp.pvp_condition.step_num }}
                    .step_separator
                    .step_time До конца хода: <span class="step_seconds">{{ step_seconds }}</span> сек.
                  .give_up.mt-5(@click="GiveUp") Сдаться
                  .history_button.mt-5(@click="hist_pop_up = !hist_pop_up") История
              
            .opponent_data
              .player_ready.opponent(v-if="active_pvp[opponent_role + '_step_ready'] || opponent_stepped")
              .user_data
                .avatar(v-if="active_pvp[opponent_role + '_picture']")
                  img(:src="active_pvp[opponent_role + '_picture']")
                .avatar(v-else)
                  // img(v-if="active_pvp[opponent_role + '_picture']" :src="active_pvp[opponent_role + '_picture']")
                  span ?
                .club_picture
                  // img(:src="my_club.picture")
                  img(v-if="active_pvp[opponent_role + '_club_picture']" :src="active_pvp[opponent_role + '_club_picture']")
              .username(v-tippy="active_pvp[opponent_role + '_username'] + ' - ' + active_pvp[opponent_role + '_club']")
                .username_username {{ active_pvp[opponent_role + '_username'] }}
                .username_club <em>{{ active_pvp[opponent_role + '_club'] }}</em>
              .energy_block.mt-2
                span.stats_text Энергия:
                .energys
                  .energy(v-for="num in active_pvp['pvp_condition'][opponent_role + '_condition']['energy']")
                  .energy.no_energy(
                    v-for="num in (active_pvp['pvp_condition'][opponent_role + '_condition']['energy_limit'] - active_pvp['pvp_condition'][opponent_role + '_condition']['energy'])"
                  )
                span.stats_text Здоровье:
                .healthes
                  .health(v-for="num in active_pvp['pvp_condition'][opponent_role + '_condition']['health']")
                  .health.no_health(v-for="num in (full_health - active_pvp['pvp_condition'][opponent_role + '_condition']['health'])")
                span.stats_text(v-if="active_pvp['pvp_condition'][opponent_role + '_condition']['angry']") Ярость:
                .angrys(v-if="active_pvp['pvp_condition'][opponent_role + '_condition']['angry']")
                  .angry(v-for="num in active_pvp['pvp_condition'][opponent_role + '_condition']['angry']")
                span.stats_text Состояние:
                .organs
                  .organ_icon.head_icon
                    img(:src="opponent_icons.head.img" v-tippy="opponent_icons.head.opp_txt")
                  .organ_icon.sun_icon
                    img(:src="opponent_icons.sun.img" v-tippy="opponent_icons.sun.opp_txt")
                  .organ_icon.liver_icon
                    img(:src="opponent_icons.liver.img" v-tippy="opponent_icons.liver.opp_txt")
                  .organ_icon.legs_icon
                    img(:src="opponent_icons.legs.img" v-tippy="opponent_icons.legs.opp_txt")

          
        .subcontent_wrap.mt-5(v-if="active_pvp.pvp_condition[my_role + '_condition'].fints.length")
          .fints_wrap
            .fints_head.mb-4 Мои финты (нажми, чтобы разыграть в текущем ходу):
            .fints_list.my_fints_list
              .fint_wrap(
                v-for="fint in my_fints"
                @click="SetFint(fint)"
                :class="{ 'active': detectActiveFint(fint) }"
              )
                .fint_head {{ fint.name }}
                .fint_text {{ fint.description }}

        .subcontent_wrap.mt-3
          .fints_wrap
            .fints_market_head.mb-4 Рынок финтов:
            .fint_notify(:class="{ 'active': fint_notify }") {{ fint_notify }}
            .fints_list
              .fint_selling_wrap(v-for="fint in active_pvp.pvp_condition.fints_market")
                .fint_wrap
                  .fint_head {{ fint.name }}
                  .fint_text {{ fint.description }}
                .price_wrap
                  span Цена: 
                  span.fint_price {{ fint.price }}
                  img(src="~@/assets/icons_and_logos/angry.svg")
                  v-btn.green_button.fint_buy_btn.mt-1(
                    dark
                    color="#04841F"
                    @click="buyFint(fint)"
                  ) Купить

        .subcontent_wrap.mt-3
          .fints_wrap
            .fints_market_head.mb-4 История раундов:
            vue-custom-scrollbar.history_wrap
              .content
                .step_wrap(v-for="step in reversed_history")
                  h3 Ход {{ step.step_num }}
                  p(v-for="move in step[my_role + '_moves']" v-if="move.type == 'hit'") 
                    | Вы били: <strong>{{ hitTargeter(move.target) }}</strong> <em>{{ move.result ? "(Успешно)" : "(Заблокировано)" }}</em>
                  p(v-for="move in step[my_role + '_moves']" v-if="move.type == 'block'")
                    | Вы блокировали: <strong>{{ blockTargeter(move.target) }}</strong>
                  p(v-if="step[my_role + '_fint']") Вы разыграли финт: 
                    span {{ step[my_role + '_fint'].name }} — {{ step[my_role + '_fint'].description }}
                  p ———
                  p(v-for="move in step[opponent_role + '_moves']" v-if="move.type == 'hit'") 
                    | Соперник бил: <strong>{{ hitTargeter(move.target) }}</strong> <em>{{ move.result ? "(Успешно)" : "(Заблокировано)" }}</em>
                  p(v-for="move in step[opponent_role + '_moves']" v-if="move.type == 'block'")
                    | Соперник блокировал: <strong>{{ blockTargeter(move.target) }}</strong>
                  p(v-if="step[opponent_role + '_fint']") Оппонент разыграл финт: 
                    span {{ step[opponent_role + '_fint'].name }} — {{ step[opponent_role + '_fint'].description }}

        v-dialog(v-model="hist_pop_up" max-width="500px")
          .fints_market_head.mb-2 История раундов:
            .close_icon(@click="hist_pop_up = !hist_pop_up")
          vue-custom-scrollbar.history_wrap
            .content
              .step_wrap(v-for="step in reversed_history")
                h3.pt-2 Ход {{ step.step_num }}
                p(v-for="move in step[my_role + '_moves']" v-if="move.type == 'hit'") 
                  | Вы били: <strong>{{ hitTargeter(move.target) }}</strong> <em>{{ move.result ? "(Успешно)" : "(Заблокировано)" }}</em>
                p(v-for="move in step[my_role + '_moves']" v-if="move.type == 'block'")
                  | Вы блокировали: <strong>{{ blockTargeter(move.target) }}</strong>
                p(v-if="step[my_role + '_fint']") Вы разыграли финт: 
                  span {{ step[my_role + '_fint'].name }} — {{ step[my_role + '_fint'].description }}
                p ———
                p(v-for="move in step[opponent_role + '_moves']" v-if="move.type == 'hit'") 
                  | Соперник бил: <strong>{{ hitTargeter(move.target) }}</strong> <em>{{ move.result ? "(Успешно)" : "(Заблокировано)" }}</em>
                p(v-for="move in step[opponent_role + '_moves']" v-if="move.type == 'block'")
                  | Соперник блокировал: <strong>{{ blockTargeter(move.target) }}</strong>
                p(v-if="step[opponent_role + '_fint']") Оппонент разыграл финт: 
                  span {{ step[opponent_role + '_fint'].name }} — {{ step[opponent_role + '_fint'].description }}

        audio#audio(src="~@/assets/sounds/t-rex-roar.mp3" muted)


</template>
  
<script>
  
  import { commonMixin } from "../../../shared/mixins"
  import { clientOrMaster, clientOrMasterExcept, step_timer } from "../../../service/service"
  import { iconsCondition } from "./service"
  import { GetOneFight, BuyFint, MakeAStep, GiveUp } from "../../../api/Inside"
  import HeaDer from "./../../Common/HeaDer"
  import LeftMenu from "../../Common/LeftMenu"
  import HeaderSubMenu from "../../Common/HeaderSubMenu"
  import "vue-custom-scrollbar/dist/vueScrollbar.css"
  import vueCustomScrollbar from "vue-custom-scrollbar"
  import { mapActions, mapGetters } from "vuex"
  import settings from "../../../settings"
  
  export default {
    name: 'PvP',
    components: {
      HeaDer,
      HeaderSubMenu,
      LeftMenu,
      vueCustomScrollbar
    },
    mixins: [
      commonMixin
    ],
    data () {
      return {
        hist_pop_up: false,
        pvp_id: null,
        bet: null,
        energy: 3,
        energy_limit: 4,
        connection: null,
        fint_notify: null,
        personal_connection: null,
        step_seconds_interval: null,
        check_connection_interval: null,
        step_seconds: 90,
        full_health: 10,
        backoff_interval: null,
        my_role: null,
        opponent_role: null,
        head_hit: false,
        sun_hit: false,
        liver_hit: false,
        legs_hit: false,
        head_block: false,
        sun_block: false,
        liver_block: false,
        legs_block: false,
        i_am_stepped: false,
        opponent_stepped: false,
        some_moved: false,
        current_fint: null,
        reversed_history: [],
        my_fints: [],
        my_hits: [],
        my_blocks: [],
        opponent_hits: [],
        opponent_blocks: [],
        targets_list: [
          "head_hit",
          "sun_hit",
          "liver_hit",
          "legs_hit",
          "head_block",
          "sun_block",
          "liver_block",
          "legs_block",
        ],
        sub_menu: [
          {
            "title": "Игра",
            "link": "/inside/raznaraz"
          },
          {
            "title": "Рейтинг игроков",
            "link": "/inside/raznaraz-ratings"
          },
          {
            "title": "История игр",
            "link": "/inside/raznaraz-story"
          },
          {
            "title": "Правила игры",
            "link": "/inside/raznaraz-game-rules"
          },
          {
            "title": "Правила конкурса",
            "link": "/inside/raznaraz-rules"
          }
        ],
        my_icons: {
          head: {
            img: require("@/assets/icons_and_logos/head.svg"),
            txt: "Вы не блокировали голову и соперник по ней не бил"
          },
          sun: {
            img: require("@/assets/icons_and_logos/sun.svg"),
            txt: "Вы не блокировали солнышко и соперник в него не бил"
          },
          liver: {
            img: require("@/assets/icons_and_logos/liver.svg"),
            txt: "Вы не блокировали печень и соперник по ней не бил"
          },
          legs: {
            img: require("@/assets/icons_and_logos/legs.svg"),
            txt: "Вы не блокировали ноги и соперник по ним не бил"
          }
        },
        opponent_icons: {
          head: {
            img: require("@/assets/icons_and_logos/head.svg"),
            opp_txt: "Соперник не блокировал голову и вы по ней не били"
          },
          sun: {
            img: require("@/assets/icons_and_logos/sun.svg"),
            opp_txt: "Соперник не блокировал солнышко и вы по нему не били"
          },
          liver: {
            img: require("@/assets/icons_and_logos/liver.svg"),
            opp_txt: "Соперник не блокировал печень и вы по ней не били"
          },
          legs: {
            img: require("@/assets/icons_and_logos/legs.svg"),
            opp_txt: "Соперник не блокировал ноги и вы по ним не били"
          }
        }
      }
    },
    computed: {
      ...mapGetters("user_store", {
        user_id: "user_id",
        username: "username",
        voices: "voices",
        coins: "coins",
        my_club: "club",
        picture: "picture",
        active_pvp: "active_pvp",
      }),
    },
    async activated () {
      this.ResetData()
      this.pvp_id = this.$route.params.pvp_id
      let context = this
      let active_pvp = await this.GetOneFight()
      if (active_pvp.pvp_id) {
        this.bet = active_pvp.bet
        let backoff_usr_id = function () {
          if (context.user_id) {
            context.my_role = clientOrMaster(context.user_id, active_pvp)
            context.opponent_role = clientOrMasterExcept(context.my_role)
            if (!context.my_role) {
              context.notForYou()
            }
            if (active_pvp["pvp_status"] == "waiting") {
              context.CloseConection()
              context.$router.push("/inside/raznaraz/" + context.pvp_id)
            } else if (active_pvp["pvp_status"] == "on_going") {
              // context.$router.push("/inside/raznaraz/" + context.pvp_id)
              context.personal_connection = new WebSocket(settings.ws_addres + "pvp-personal/" + context.user_id)
              context.personal_connection.onmessage = function(event) {
                var obj = JSON.parse(event.data)
                context.WsPersonalMessage(obj)
              }
              context.connection = new WebSocket(settings.ws_addres + "pvp-room/" + active_pvp.pvp_id)
              context.connection.onmessage = function(event) {
                var obj = JSON.parse(event.data);
                context.WsMessage(obj)
              }
              context.energy = active_pvp.pvp_condition[context.my_role + "_condition"].energy
              context.energy_limit = active_pvp.pvp_condition[context.my_role + "_condition"].energy_limit
              context.my_fints = active_pvp.pvp_condition[context.my_role + '_condition'].fints
              let icons = iconsCondition(context.my_role, context.opponent_role, active_pvp.pvp_condition.last_steps)
              context.my_icons = icons[0]
              context.opponent_icons = icons[1]

              context.reversed_history = active_pvp.pvp_condition.last_steps.reverse()
              context.StepTimerFuncInterval()
              context.CheckConnectionInterval()
            }
            clearInterval(context.backoff_interval)
          }
        }
        this.backoff_interval = setInterval(backoff_usr_id, 100)
      } else {
        this.notForYou()
      }
    },
    beforeDestroy () {
      this.CloseConection()
      // clearInterval(this.step_seconds_interval)
    },
    methods: {
      ...mapActions("user_store", {
        update: "ACTION_UPDATE",
      }),
      GiveUp () {
        let context = this
        this.$swal({
          "icon": "warning",
          "text": "Ты уверен, что хочешь сдаться?",
          // "buttons": ["Нет", "Да"],
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Да",
          cancelButtonText: "Нет",
        }).then(async function (res) {
          if (res.isConfirmed) {
            await GiveUp(context, context.pvp_id)
          }
        })
      },
      CloseConection () {
        if (this.connection) {
          this.connection.close()
        }
        if (this.personal_connection) {
          this.personal_connection.close()
        }
        if (this.step_seconds_interval) {
          clearInterval(this.step_seconds_interval)
        }
        if (this.check_connection_interval) {
          clearInterval(this.check_connection_interval)
        }
      },
      WsMessage (obj) {
        let context = this
        if (obj.master_id) {
          if (obj.winner_is) {
            let msg = ""
            if (obj.winner_is == "master") {
              if (this.my_role == "master") {
                msg = "Поединок окончен! Вы победили!"
              } else {
                msg = "Поединок окончен! Победил " + this.active_pvp.master_username + " " + "(" + this.active_pvp.master_club + ")"
              }
            }
            if (obj.winner_is == "client") {
              if (this.my_role == "client") {
                msg = "Поединок окончен! Вы победили!"
              } else {
                msg = "Поединок окончен! Победил " + this.active_pvp.client_username + " " + "(" + this.active_pvp.client_club + ")"
              }
            }
            if (obj.winner_is == "draw") {
              if (this.my_role == "client") {
                msg = "Ничья!"
              } else {
                msg = "Ничья!"
              }
            }
            this.$swal({
              "icon": "warning",
              "text": msg,
            }).then(function () {
              context.$router.replace("/inside/raznaraz")
            })
          } else {
            let data = {
              "active_pvp": obj
            }
            this.ResultToUserState(data)
          }
        } else if (obj.type == "deleted" && this.my_role == "client") {
          this.connection.close()
          clearInterval(this.wait_interval)
          this.update({
            key: "active_pvp",
            value: {}
          })
          this.$router.push("/inside/raznaraz")
        } else if (obj.type == "deleted") {
          this.CloseConection()
        } else if (obj.type == "fint_bought") {
          this.fint_notify = obj.username + " купил финт"
          let active_pvp_copied = JSON.parse(JSON.stringify(this.active_pvp))
          active_pvp_copied.pvp_condition.fints_market = obj.active_pvp.pvp_condition.fints_market
          active_pvp_copied.pvp_condition.master_condition.fints = obj.active_pvp.pvp_condition.master_condition.fints
          active_pvp_copied.pvp_condition.client_condition.fints = obj.active_pvp.pvp_condition.client_condition.fints
          active_pvp_copied.pvp_condition.client_condition.angry = obj.active_pvp.pvp_condition.client_condition.angry
          active_pvp_copied.pvp_condition.master_condition.angry = obj.active_pvp.pvp_condition.master_condition.angry
          context.my_fints = active_pvp_copied.pvp_condition[context.my_role + '_condition'].fints
          let data = {
            "active_pvp": active_pvp_copied
          }
          this.ResultToUserState(data)
          this.FintNotify()
        } else if (obj.type == "new_step") {
          this.ResetData()
          let data = {
            "active_pvp": obj.active_obj
          }
          this.ResultToUserState(data)
          let audio = document.getElementById("audio")
          audio.play()
          let animatable = document.getElementById("animatable")
          animatable.classList.add("active")
          let remove_active_class = function () {
            animatable.classList.remove("active")
          }
          this.energy = obj.active_obj.pvp_condition[this.my_role + "_condition"].energy
          this.energy_limit = obj.active_obj.pvp_condition[this.my_role + "_condition"].energy_limit
          this.reversed_history = obj.active_obj.pvp_condition.last_steps.reverse()
          this.my_fints = obj.active_obj.pvp_condition[this.my_role + '_condition'].fints
          let icons = iconsCondition(this.my_role, this.opponent_role, obj.active_obj.pvp_condition.last_steps)
          this.my_icons = icons[0]
          this.opponent_icons = icons[1]
          this.StepTimerFuncInterval()
          setTimeout(remove_active_class, 4000)
        } else if (obj.type == "step") {
          if (this.my_role == obj.whois) {
            this.i_am_stepped = true
          } else {
            this.opponent_stepped = true
          }
        }
      },
      FintNotify () {
        let context = this
        let ffn = function () {
          context.fint_notify = null
        }
        setTimeout(ffn, 5000)
      },
      WsPersonalMessage (obj) {
        console.log("obj", obj)
        if (obj.type == "step") {
          this.opponent_stepped = true
        }
      },
      notForYou () {
        let context = this
        this.$swal({
          "icon": "error",
          "text": "Поединок окончен, или ты не являешься его участником!",
        }).then(function () {
          context.$router.replace("/inside/raznaraz")
        })
      },
      async GetOneFight () {
        let context = this
        let then = function () {
          context.$router.push("/inside/raznaraz")
        }
        let result = await GetOneFight(context, this.pvp_id, then)
        let data = {
          "active_pvp": result.data
        }
        this.ResultToUserState(data)
        return result.data
      },
      ResetData () {
        this.bet = null
        this.connection = null
        this.wait_seconds = 0
        this.wait_interval = null
        this.wait_writind = null
        this.backoff_interval = null
        this.energy = 3
        this.head_hit = false
        this.sun_hit = false
        this.liver_hit = false
        this.legs_hit = false
        this.head_block = false
        this.sun_block = false
        this.liver_block = false
        this.legs_block = false
        this.i_am_stepped = false
        this.opponent_stepped = false
        this.some_moved = false
        this.reversed_history = []
      },
      hitTargeter(target) {
        if (target == "head") {
          return "в голову"
        } else if (target == "sun") {
          return "в солнышко"
        } else if (target == "liver") {
          return "по печени"
        } else if (target == "legs") {
          return "по ногам"
        }
      },
      blockTargeter(target) {
        if (target == "head") {
          return "голову"
        } else if (target == "sun") {
          return "солнышко"
        } else if (target == "liver") {
          return "печень"
        } else if (target == "legs") {
          return "ноги"
        }
      },
      StepTimerFunc () {
        if (this.active_pvp.step_date) {
          let active_pvp = JSON.parse(JSON.stringify(this.active_pvp))
          this.step_seconds = step_timer(active_pvp.step_date)
          if (this.step_seconds <= 0) {
            clearInterval(this.step_seconds_interval)
            // if (!this.i_am_stepped) {
            //   this.MakeAStep()
            // }
          }
        }
      },
      StepTimerFuncInterval () {
        if (this.step_seconds_interval) {
          clearInterval(this.step_seconds_interval)
        }
        this.step_seconds_interval = setInterval(this.StepTimerFunc, 1000)
      },
      CheckConnection () {
        if (!this.connection) {
          this.connection = new WebSocket(settings.ws_addres + "pvp-room/" + this.active_pvp.pvp_id)
        }
      },
      CheckConnectionInterval () {
        this.check_connection_interval = setInterval(this.CheckConnection, 2000)
      },
      SetFint (fint) {
        if (this.current_fint == fint) {
          this.current_fint = null
        } else {
          this.current_fint = fint
        }
      },
      async buyFint (fint) {
        let context = this
        this.$swal({
          "icon": "warning",
          "text": "Купить финт за " + fint.price + "?",
          // "buttons": ["Нет", "Да, купить"],
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Да, купить",
          cancelButtonText: "Нет",
        }).then(async function (res) {
          if (res.isConfirmed) {
            let data = {
              "fint_id": fint.id
            }
            await BuyFint(data, context.pvp_id, context)
          }
        })
      },
      async MakeAStep () {
        let context = this
        console.log(this.active_pvp.pvp_condition.step_num)
        let pvp_step = {
          "pvp_id": this.pvp_id,
          "step_num": this.active_pvp.pvp_condition.step_num,
          "fint": this.current_fint,
          "moves": []
        }
        console.log(pvp_step)
        this.i_am_stepped = true
        for (let i=0; i<this.targets_list.length; i++) {
          if (this[this.targets_list[i]]) {
            let _pp_ = this.targets_list[i].indexOf("_")
            let target = this.targets_list[i].slice(0, _pp_)
            let type = this.targets_list[i].slice(_pp_ + 1)
            pvp_step["moves"].push({
              "type": type,
              "target": target,
              "result": null
            })
          }
        }
        if (this.current_fint) {
          for (let i=0; i<this.my_fints.length; i++) {
            if (this.current_fint.id == this.my_fints[i].id) {
              this.my_fints.pop(i)
              break
            }
          }
        }
        let then = function () {
          context.$router.push("/inside/raznaraz")
        }
        console.log(pvp_step)
        await MakeAStep(pvp_step, this.pvp_id, this, then)
        this.current_fint = null
      },
      hitMove (target) {
        if (!(this.active_pvp.pvp_condition[this.my_role + "_condition"]["hit_exclude"].indexOf(target) + 1)) {
          if (this[target + "_hit"]) {
            this[target + "_hit"] = false
            this.energy += 1
          } else {
            if (this.energy) {
              this[target + "_hit"] = true
              this.energy -= 1
            }
          }
          if (
            this.head_hit ||
            this.sun_hit ||
            this.liver_hit ||
            this.legs_hit ||
            this.head_block ||
            this.sun_block ||
            this.liver_block ||
            this.legs_block
          ) {
            this.some_moved = true
          } else {
            this.some_moved = false
          }
        }
      },
      hitMoveHead () {this.hitMove("head")},
      hitMoveSun () {this.hitMove("sun")},
      hitMoveLiver () {this.hitMove("liver")},
      hitMoveLegs () {this.hitMove("legs")},

      blockMoveHead () {
        this.blockMove("head")
      },
      blockMoveSun () {
        this.blockMove("sun")
      },
      blockMoveLiver () {
        this.blockMove("liver")
      },
      blockMoveLegs () {
        this.blockMove("legs")
      },
      blockMove (target) {
        if (this[target + "_block"]) {
          this[target + "_block"] = false
          this.energy += 1
        } else {
          if (this.energy) {
            this[target + "_block"] = true
            this.energy -= 1
          }
        }
        if (
          this.head_hit ||
          this.sun_hit ||
          this.liver_hit ||
          this.legs_hit ||
          this.head_block ||
          this.sun_block ||
          this.liver_block ||
          this.legs_block
        ) {
          this.some_moved = true
        } else {
          this.some_moved = false
        }
      },
      detectActiveFint (fint) {
        if (this.current_fint) {
          return this.current_fint.id == fint.id
        }
        return false
      },
    }
  }
  
</script>
  
<style lang="sass" scoped>

@keyframes example 
  from 
    outline: 0px solid #4aa900e0
  to
    outline: 20px solid #4AA90000
#animatable.active
  animation-name: example
  animation-duration: 0.8s
  animation-delay: 0.5s
  animation-iteration-count: 3  

.history_wrap
  border: 1px solid #EFEFEF
  border-radius: 10px
  height: 570px
  h3
    padding: 0 0 10px
  .step_wrap
    padding: 10px 10px
    p
      margin-bottom: 0px
      font-size: 14px

.v-dialog
  .fints_market_head
    padding-bottom: 0px
    position: relative
    .close_icon
      position: absolute
      top: 10px
      right: 18px
      cursor: pointer
      transform: rotate(45deg)
      &:before, &:after
        display: block
        position: absolute
        content: ""
      &:before
        width: 2px
        height: 16px
        left: 7px
        top: 0
        background-color: #000
      &:after
        height: 2px
        width: 16px
        top: 7px
        left: 0
        background-color: #000

        
  .history_wrap
    border: 1px solid #EFEFEF
    border-radius: 10px
    height: 570px
    h3
      text-align: center
      padding: 0 0 10px
    .step_wrap
      padding: 0 10px
      p
        margin-bottom: 0px
        font-size: 14px

.fints_list
  .fint_selling_wrap
    margin-bottom: 35px
    .price_wrap
      font-size: 16px
      padding: 0 3px
      margin-top: -4px
      .green_button
        span
          font-size: 14px !important
      .fint_price
        font-size: 16px
        color: #FF2E00
        font-weight: 500
      img
        position: relative
        top: 3px
        left: 3px
.fints_wrap
  position: relative
  .fint_notify
    background-color: #000
    border: 3px solid #7f0000
    box-shadow: 0px 2px 7px 1px #000000a1
    color: #fff
    line-height: 20px
    transition: opacity 0.3s ease
    padding: 3px 10px
    display: inline-block
    position: absolute
    top: -5px
    width: 340px
    font-weight: 300
    text-align: center
    left: 50%
    margin-left: -170px
    z-index: 10
    border-radius: 4px
    opacity: 0
  .fint_notify.active
    opacity: 1

.fints_market_head
  color: #000
  font-size: 18px
  font-weight: 500
  text-align: center
  padding: 7px 0 5px
  position: relative
  z-index: 1
.fints_list
  width: 100%
  display: flex
  align-items: stretch
  justify-content: flex-start
  flex-wrap: wrap
  .fint_selling_wrap
    width: 135px
    min-height: 190px
    margin: 0 7px 10px
    @media (max-width: 450px)
      width: 105px
      min-height: 165px

  .fint_wrap
    width: 135px
    border-radius: 5px
    min-height: 190px
    background-color: #000
    position: relative
    border-radius: 7px
    margin-bottom: 8px
    overflow: hidden
    cursor: pointer
    @media (max-width: 450px)
      width: 105px
      min-height: 165px
    &:before
      content: ""
      position: absolute
      width: 100%
      height: 100%
      background: url('~@/assets/images/small_fire.jpg') center center
      background-size: cover
      left: 0
      top: 0
      opacity: 0.4
      z-index: 0
    .fint_head
      color: #fff
      font-size: 16px
      text-align: center
      padding: 7px 0 5px
      position: relative
      z-index: 1
    .fint_text
      font-size: 12px
      font-weight: 300
      color: #fff
      padding: 0px 7px 10px
      position: relative
      z-index: 1
      letter-spacing: 0.5px
      @media (max-width: 450px)
        font-size: 10px
  .fint_wrap.active
    outline: 2px solid #ff2e00
    box-sizing: border-box
.fints_list.my_fints_list
  justify-content: flex-start
  .fint_wrap
    margin: 0 7px 10px
.game_block
  display: flex
  min-height: 380px
  justify-content: space-between
  @media (max-width: 550px)
    width: calc(100% + 40px)
    position: relative
    left: -20px
    background-color: #fff
    box-shadow: 0px 0px 10px 0px #00000021
    border-radius: 5px
    padding: 10px 0
  @media (max-width: 450px)
    width: calc(100% + 60px)
    position: relative
    left: -30px
  .stats_text
    font-size: 16px
    font-weight: 400
  .organs
    align-items: flex-start
  .energys, .healthes, .angrys, .organs
    display: flex
    justify-content: flex-start
    margin-top: 4px
    border-bottom: 1px solid #DDDDDD
    padding-bottom: 10px
    margin-bottom: 10px
    @media (max-width: 750px)
      flex-wrap: wrap
    .organ_icon
      width: 21px
      height: 40px
      display: flex
      align-items: center
      opacity: 0.7
      img
        width: 100%
    .head_icon
      margin-right: 5px
    .sun_icon
      width: 27px
      margin-right: 5px
    .liver_icon
      width: 30px
      margin-right: 3px
    .legs_icon
      width: 34px
      margin-right: 0
      margin-top: -2px

    .energy
      width: 22px
      height: 22px
      background: url('~@/assets/icons_and_logos/energy.svg') center center no-repeat
      background-color: #4AA900
      background-size: 8px
      border-radius: 11px
      margin-right: 3px
      filter: drop-shadow(0px 0px 3px rgba(250, 255, 0, 0.80))
      @media (max-width: 400px)
        width: 18px
        height: 18px
    .energy.no_energy
      background-color: #DADADA
      background-size: 8px
      filter: initial
    .health
      width: 18px
      height: 18px
      background: url('~@/assets/icons_and_logos/hearth.svg') center center no-repeat
      background-size: 19px
      margin-right: 1px
    .health.no_health
      width: 18px
      height: 18px
      background: url('~@/assets/icons_and_logos/no_hearth.svg') center center no-repeat
      background-size: 19px
      margin-right: 1px
    .angry
      width: 22px
      height: 22px
      background: url('~@/assets/icons_and_logos/angry.svg') center center no-repeat
      background-size: 22px
      margin-right: 3px
  .organs
    border-bottom: 1px solid #FFF
  .moves_block
    width: calc(100% - (195px * 2))
    display: flex
    align-items: flex-start
    justify-content: center
    @media (max-width: 750px)
      width: calc(100% - (105px * 2))
    @media (max-width: 750px)
      width: calc(100% - (90px * 2))
    .moves_buttons_block
      width: 100%
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      .green_button
        @media (max-width: 750px)
          padding: 0 5px
      .give_up, .history_button
        color: #cf0000
        border-bottom: 1px dashed #cf0000
        cursor: pointer
        height: 16px
        display: inline-block
        line-height: 12px
        &:hover
          border-bottom: 1px dashed #fff
      .history_button
        color: #3579A1
        border-bottom: 1px dashed #3579A1
      .step_info_wrap
        width: 100%
        min-height: 50px
        border-radius: 5px
        background-color: #375567
        color: #fff
        display: flex
        align-items: center
        justify-content: space-between
        padding: 5px 20px
        font-size: 16px
        font-weight: 400
        @media (max-width: 750px)
          font-size: 14px
          font-weight: 300
          padding: 3px 5px
          line-height: 17px
        .step_separator
          width: 1px
          min-height: 40px
          background-color: #fff
          opacity: 0.2
          margin: 5px 10px
        .step_time
          margin-top: -1px
          .step_seconds
            font-size: 18px
            font-weight: 500
            @media (max-width: 750px)
              font-size: 16px
              font-weight: 400
    .moves_block_inwrap
      width: 90%
      height: 100%
      .moves_subblock_hits, .moves_subblock_blocks
        border-radius: 10px
        width: 100%
        min-height: 100px
        position: relative
        padding: 10px 5px 11px 7px
        @media (max-width: 750px)
          padding: 7px 3px 8px 5px
        @media (max-width: 550px)
          padding: 7px 2px 8px 2px
        .move_punkt
          width: 100%
          display: flex
          justify-content: flex-start
          cursor: pointer
          margin: 2px 0
          position: relative
          z-index: 1
          @media (max-width: 550px)
            margin: 4px 0
          .move_text
            color: #444444
            font-size: 15px
            font-weight: 300
            @media (max-width: 550px)
              font-size: 13px
              line-height: 15px
              position: relative
              top: 3px
          .bullet_wrap
            width: 22px
            height: 22px
            margin-right: 5px
            display: flex
            justify-content: center
            align-items: center
            position: relative
            @media (max-width: 550px)
              margin-right: 3px
            &:before, &:after
              content: ""
              position: relative
            &:before
              width: 14px
              height: 14px
              border-radius: 7px
              border: 1px solid #848484
            &:after
              width: 10px
              height: 10px
              border-radius: 5px
              background-color: #DAFFCD
              position: absolute
              top: 50%
              left: 50%
              margin-top: -5px
              margin-left: -5px
        .move_punkt.unactive
          .bullet_wrap
            &:before
              border: 1px solid #C6C6C6
            &:after
              background-color: #EBEBEB
          .move_text
            color: #AEAEAE
        .move_punkt.active
          .move_text
            color: #4AA900
          .bullet_wrap
            &:before
              width: 20px
              height: 20px
              background: url('~@/assets/icons_and_logos/energy.svg') center center no-repeat
              background-color: #4AA900
              background-size: 8px
              border-radius: 11px
              border: none
              filter: drop-shadow(0px 0px 3px rgba(250, 255, 0, 0.80))
            &:after
              display: none
        .move_punkt.exclude
          .bullet_wrap
            &:before
              border: 1px solid #d3d3d3
            &:after
              background-color: #EEE
          .move_text
            color: #c7c7c7
          


          .move_text
            width: calc(100% - 27px)
      .moves_subblock_hits
        background-image: linear-gradient(270deg, #EEE 0%, rgba(241, 241, 241, 0.00) 100%)
        margin-bottom: 20px
        img
          position: absolute
          width: 86px
          right: 15px
          top: 50%
          margin-top: -32.5px
          z-index: 0
      .moves_subblock_blocks
        background: linear-gradient(-270deg, #EEE 0%, rgba(241, 241, 241, 0.00) 100%)
        img
          position: absolute
          width: 75px
          right: 20px
          top: 50%
          margin-top: -47px
        



  .your_data, .opponent_data
    position: relative
    width: 195px
    @media (max-width: 750px)
      width: 105px
    @media (max-width: 400px)
      width: 90px
    .player_ready
      position: absolute
      width: 80px
      height: 80px
      top: -30px
      left: -10px
      z-index: 2
      transform: rotate(-52deg) scale(0.6)
      background-color: #4AA900
      border-radius: 40px
      &:before, &:after
        content: ""
        display: block
        position: absolute
      &:before
        width: 40px
        height: 7px
        background-color: #FFF
        top: 46px
        left: 25px
      &:after
        width: 7px
        height: 30px
        background-color: #FFF
        top: 20px
        left: 25px
    .player_ready.opponent
      left: auto
      right: -10px
    .username
      width: 189px
      height: 42px
      background-color: #000
      border-bottom-left-radius: 5px
      border-bottom-right-radius: 5px
      position: relative
      left: 4px
      padding: 2px 4px 4px
      color: #fff
      text-align: right
      line-height: 19px
      font-size: 16px
      font-weight: 300
      overflow: hidden
      &:after
        display: block
        width: 15px
        height: 100%
        position: absolute
        right: 0
        top: 0
        z-index: 10
        content: ""
        background-image: linear-gradient(90deg, #00000000 0%, #000 100%)
      .username_username
        width: 500px
        position: absolute
        left: 3px
        text-align: left
        height: 20px
      .username_club
        width: 500px
        text-align: left
        position: absolute
        top: 20px
        left: 3px
        height: 20px
        em
          font-size: 15px
          @media (max-width: 750px)
            font-size: 11px
      @media (max-width: 750px)
        font-size: 12px
        line-height: 14px
        width: 105px
        left: 0
      @media (max-width: 450px)
        border-top-left-radius: 5px
        border-top-right-radius: 5px
        margin-top: 10px
      @media (max-width: 400px)
        width: 90px
    .user_data
      width: 100%
      min-height: 190px
      background: url('~@/assets/images/fighter.jpg') bottom center no-repeat
      display: flex
      justify-content: center
      align-items: center
      position: relative
      @media (max-width: 450px)
        background: none
        min-height: 70px
      .club_picture
        position: absolute
        left: 5px
        bottom: 20px
        img
          max-width: 45px
          max-height: 45px  
      .avatar
        width: 66px
        height: 66px
        border-radius: 33px
        border: 3px solid #fff
        overflow: hidden
        background-color: #ccc
        display: flex
        align-items: center
        justify-content: center
        z-index: 1
        img
          width: 100%
        span
          font-size: 37px
          font-weight: 600
          color: #fff
  .opponent_data
    .username
      text-align: left
      padding-left: 5px
      @media (max-width: 750px)
        font-size: 12px
        line-height: 14px
        width: 105px
        left: 0
      @media (max-width: 400px)
        width: 90px
    .user_data
      background: none
      &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: url('~@/assets/images/fighter.jpg') bottom center no-repeat
        transform: scale(-1, 1)
        @media (max-width: 450px)
          background: none
      .club_picture
        position: absolute
        left: initial
        right: 5px
        bottom: 20px


</style>
