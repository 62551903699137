<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ПОДЕЛИСЬ И ВЫИГРАЙ")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-4.pb-0
        h3.mb-4 Приглашай друзей присоединиться к «Битве Фанатов»!
        // p.mt-3 Каждый раз, когда по твоей ссылке регистрируется новый участник, ты получаешь балл!
        //   |  Каждый месяц обладатель ссылки, по которой зарегистрировалось больше всего новых участников,
        //   |  получает подарочный сертификат Ozon номиналом <strong>10000 руб!</strong>
        .pos_r
          img.blue_prize(src="../../../assets/icons_and_logos/prize_blue.svg")
          div.mb-0.p_image_prize 
            p.mt-3 Каждый раз, когда по твоей ссылке регистрируется новый участник, ты получаешь 5 баллов!
              |  Каждый месяц обладатель ссылки, по которой зарегистрировалось больше всего новых участников,
              |  получает звание «Агитатор месяца», подарочный сертификат Ozon номиналом <strong>10000 руб.</strong>!
              |  и попадает в «Зал Славы».

      .inventory_blocks.mt-5
        .coins_block(
          @click="CopyCode('card_code_text')"
          @shown="showTippy('card_code_text')"
          ref="card_code_text"
          v-tippy="{'trigger':'click', 'duration':[150, 150]}"
          content="Скопировано"
        )
          strong Твоя реферальная ссылка (скопировать):
          p {{ "https://bitvafanatov.ru/?ref=" + ref_link }}
            img.ml-2(
              src="../../../assets/icons_and_logos/copy.svg"
            )
            input(type="text" id="card_code_text" :value="'https://bitvafanatov.ru/?ref=' + ref_link")

        .voices_block
          strong По твоей ссылке зарегистрировалось:
          p <span>{{ user_count }}</span>

      .subcontent_wrap.mt-4
        p.mb-0 <em>В конце каждого месяца, счётчик зарегистрировавшихся обнуляется и все участники снова оказываются в равных условиях.</em>
        div.mt-4.mobile_button
          v-btn.green_button(
            v-if="voices && club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="Vote"
          ) Отдать голос
          v-btn.green_button(
            v-else-if="club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="goTo('/inside/shop')"
          ) Получить ещё голос
      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { GetSharing } from "./../../../api/Inside"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'ShareWin',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      user_count: 3,
      sub_menu: [
        {
          "title": "Реферальная ссылка",
          "link": "/inside/share-win"
        },
        {
          "title": "Рейтинг участников",
          "link": "/inside/sharewin-progress"
        },
        {
          "title": "Правила",
          "link": "/inside/sharewin-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club: "club",
      club_rating: "club_rating",
      ref_link: "ref_link",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
    this.GetSharing(this)
  },
  methods: {
    CopyCode (txt) {
      let copyText = document.getElementById(txt)
      copyText.style.display = "inline"
      copyText.select() /* Select the text field */
      copyText.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand("copy")
      copyText.style.display = "none"
    },
    showTippy (ref) {
      var context = this
      setTimeout(function() { context.$refs[ref]._tippy.hide() }, 500)
    },
    async GetSharing () {
      let result = await GetSharing(this)
      if (result) {
        this.user_count = result["data"]["user_count"]
      }
    }
  }
}

</script>


<style lang="sass" scoped>
#card_code_text
  display: none
.inventory_blocks
  width: 100%
  display: flex
  justify-content: space-between
  align-items: stretch
  flex-wrap: wrap
  margin-top: 10px
  .coins_block, .voices_block
    width: calc( 60% - 10px )
    height: auto
    background-color: #375567
    color: #fff
    border-radius: 10px
    position: relative
    padding: 10px 5px 15px 12px
    @media (max-width: 550px)
      width: 100% !important
    div
      width: 100%

    strong
      font-weight: 300
      text-align: left
      line-height: 20px
      display: inline-block
      font-size: 14px
      width: 100%
      @media (max-width: 550px)
        left: 0
        font-size: 13px
        width: 100%
        text-align: left
    p
      width: 100%
      font-size: 20px
      font-weight: 400
      margin: 0
      padding: 0
      position: relative
      text-align: left
      line-height: 24px
      top: 3px
      display: flex
      align-items: center

      @media (max-width: 550px)
        font-size: 21px

  .coins_block
    img
      cursor: pointer
    @media (max-width: 550px)
      margin-bottom: 15px
  .voices_block
    width: calc( 40% - 10px )

.blue_prize
  position: absolute
  top: 0
  left: 0
  width: 60px

.p_image_prize
  padding: 0 0 0 80px
  text-align: left

</style>
    