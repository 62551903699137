<template lang="pug">

.timer_wrap
  .timer_header 
    p До конца текущей Битвы осталось:
  .timer
    #countdown.countdown
      .countdown-number.day
        span.days.countdown-time
        span.countdown-text дн.
      .countdown-number.hour
        span.hours.countdown-time
        span.countdown-text час.
      .countdown-number.min
        span.minutes.countdown-time
        span.countdown-text мин.
      .countdown-number.sec
        span.seconds.countdown-time
        span.countdown-text сек.
  
</template>


<script>

import { initializeClock } from "../../service/service"
import { commonMixin } from "../../shared/mixins"

export default {
  name: 'BitvaTimer',
  mixins: [
    commonMixin
  ],
  async mounted () {
    let deadline = await this.championshipEnds()
    // let deadline = "September 01 2023 00:00:00 GMT+0300"
    initializeClock('countdown', deadline)
  },
  methods: {

  }
}

</script>


<style lang="sass" scoped>

.timer_wrap
  margin-top: 3vh
  width: 100%
  border: 2px solid white
  border-radius: 10px
  .timer_header 
    background-color: #375567
    padding: 0 10px
    height: 47px
    line-height: 47px
    border-radius: 10px
    color: #FFF
    @media (max-width: 700px)
      padding-left: 7px
      height: 37px
      line-height: 37px
    p
      font-size: 18px
      font-weight: 500
      margin: 0
      padding: 0
      @media (max-width: 700px)
        font-size: 16px
  .timer
    padding-left: 15px
    height: 65px
    line-height: 65px
    border-bottom-right-radius: 10px
    border-bottom-left-radius: 10px
    @media (max-width: 700px)
      height: 62px
      line-height: 62px
      padding-left: 7px
    .countdown
      color: #373737
      font-size: 35px
      @media (max-width: 550px)
        font-size: 27px
      .countdown-text
        color: #373737
        @media (max-width: 550px)
          font-size: 12px
          margin-right: 5px



</style>
    