<template lang="pug">

.header_icons(:class="{'header_icons_main': is_main}")
  a.hideble_icon(href="/inside/shop")
    img(v-if="is_main" src="~@/assets/icons_and_logos/solar_cart_white.svg")
    img(v-else src="~@/assets/icons_and_logos/solar_cart.svg")
  img.hideble_icon(v-if="is_main" src="~@/assets/icons_and_logos/vert_line_white.svg")
  img.hideble_icon(v-else src="~@/assets/icons_and_logos/vert_line.svg")
  a.hideble_icon(href="/inside/personal")
    img(v-if="is_main" src="~@/assets/icons_and_logos/ion_person_white.svg")
    img(v-else src="~@/assets/icons_and_logos/ion_person.svg")
  img.hideble_icon(v-if="is_main" src="~@/assets/icons_and_logos/vert_line_white.svg")
  img.hideble_icon(v-else src="~@/assets/icons_and_logos/vert_line.svg")
  a(@click="LogOut")
    img(v-if="is_main" src="~@/assets/icons_and_logos/iconamoon_exit_white.svg")
    img(v-else src="~@/assets/icons_and_logos/iconamoon_exit.svg")

</template>

<script>

import { commonMixin } from "../../shared/mixins"

export default {
  name: 'HeaDerIcons',
  props: [
    "is_main"
  ],
  mixins: [
    commonMixin
  ],
}

</script>

<style lang="sass" scoped>

.header_icons
  width: 150px
  position: absolute
  height: 70px
  display: flex
  align-items: center
  justify-content: space-between
  top: 0
  right: 0
  padding-right: 20px
  @media (max-width: 650px)
    width: 48px !important
    justify-content: flex-end
    .hideble_icon
      display: none !important
  a
    display: flex
    align-items: center
    line-height: 70px
    height: 70px

.header_icons_main.header_icons
  padding-right: 10px
  height: 50px

</style>
    