
import { client, errorHandler } from "./api";


export async function ShortClubs(context) {
  console.log("ShortClubs")
  const response = await client.get("registration", {},
  {}).catch(error => errorHandler(error, context, null))
  if (response) {
    return response.data;
  }
}

export async function ShortestClubs(context) {
  const response = await client.get("short-clubs", {},
  {}).catch(error => errorHandler(error, context, null))
  if (response) {
    return response.data;
  }
}

export async function CheckThePlace(club_id, context) {
  const response = await client.get("check-the-place/" + club_id, {},
  {}).catch(error => errorHandler(error, context, null))
  if (response) {
    return response.data;
  }
}

export async function VoteForClubUnregister(data, context, then) {
  const response = await client.post("vote-for-club-unreg", {
    ...data
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    console.log('response', response)
    return response.data;
  }
}

export async function checkEmail(email, context, then) {
  const response = await client.post("check-email", {
    ...email
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function checkEmailSimple(email, context, then) {
  const response = await client.post("check-email-simple", {
    ...email
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function checkPhone(phone, context, then) {
  const response = await client.post("check-phone", {
    ...phone
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function CheckUsername(phone, context, then) {
  const response = await client.post("check-username", {
    ...phone
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function CheckSimpleUsername(phone, context, then) {
  const response = await client.post("check-username-simple", {
    ...phone
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function SendCode(code, context, then) {
  const response = await client.post("send-code", {
    ...code
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function CheckCode(user_id, context, then) {
  const response = await client.get("send-code-check/" + user_id,
    {}
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function ReRequestCode(user_id, context, then) {
  const response = await client.get("re-request-code/" + user_id,
    {}
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function ReRequestCodeSms(user_id, context, then) {
  const response = await client.get("re-request-code-sms/" + user_id,
    {}
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function Registration(registr_data, context, then) {
  const response = await client.post("registration", {
    ...registr_data
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}
