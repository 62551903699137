<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="МАГАЗИН")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
          
      .subcontent_wrap.mb-0.pb-3
        //- h3.notifications_h3 Уведомления:
        .voices_goods_block

          .voice_block_wrap(v-for="good in goods" v-if="good.good_type == 'votes'" @click="buyGood(good)")
            .voice_text_icon_wrap
              .voice_text_icon
                img(src="../../../assets/icons_and_logos/voice_icon.svg")
                span {{ good.quantity }} {{ word_num_cases(good.quantity, "голос", "голоса", "голосов") }}
              .voice_button Купить за {{ good.cost }} {{ word_num_cases(good.cost, "балл", "балла", "баллов") }} <img src="../../../assets/icons_and_logos/cart_white.svg">

          //- .voice_block_wrap
          //-   .voice_text_icon_wrap
          //-     .voice_text_icon
          //-       img(src="../../../assets/icons_and_logos/voice_icon.svg")
          //-       span 1 {{ word_num_cases(1, "голос", "голоса", "голосов") }}
          //-   .voice_button Купить за 10 {{ word_num_cases(10, "балл", "балла", "баллов") }} <img src="../../../assets/icons_and_logos/cart_white.svg">
          //- .voice_block_wrap
          //-   .voice_text_icon_wrap
          //-     .voice_text_icon
          //-       img(src="../../../assets/icons_and_logos/voice_icon.svg")
          //-       span 5 {{ word_num_cases(5, "голос", "голоса", "голосов") }}
          //-   .voice_button Купить за 45 {{ word_num_cases(45, "балл", "балла", "баллов") }} <img src="../../../assets/icons_and_logos/cart_white.svg">
          //- .voice_block_wrap
          //-   .voice_text_icon_wrap
          //-     .voice_text_icon
          //-       img(src="../../../assets/icons_and_logos/voice_icon.svg")
          //-       span 10 {{ word_num_cases(10, "голос", "голоса", "голосов") }}
          //-   .voice_button Купить за 80 {{ word_num_cases(80, "балл", "балла", "баллов") }} <img src="../../../assets/icons_and_logos/cart_white.svg">

      .subcontent_wrap

        p.mt-2.pb-0
          strong Баллы не подлежат обмену на рубли и выводу с сайта.

        .rub_goods_wrap

          .rub_good(v-for="good in goods" v-if="good.good_type == 'coins'")
            .notification 
              img(src="../../../assets/icons_and_logos/gold_coins.svg")
              p <strong>{{ good.quantity }}</strong> {{ word_num_cases(good.quantity, "балл", "балла", "баллов") }}
            .buy_button(@click="buyGood(good)") Купить за {{ good.cost }} руб. <img src="../../../assets/icons_and_logos/cart_white.svg">
        
        

          //- .rub_good
          //-   .notification 
          //-     img(src="../../../assets/icons_and_logos/gold_coins.svg")
          //-     p <strong>15</strong> {{ word_num_cases(15, "балл", "балла", "баллов") }}
          //-   .buy_button Купить за 190 руб. <img src="../../../assets/icons_and_logos/cart_white.svg">
          //- .rub_good
          //-   .notification 
          //-     img(src="../../../assets/icons_and_logos/gold_coins.svg")
          //-     p <strong>30</strong> {{ word_num_cases(30, "балл", "балла", "баллов") }}
          //-   .buy_button Купить за 190 руб. <img src="../../../assets/icons_and_logos/cart_white.svg">
          //- .rub_good
          //-   .notification 
          //-     img(src="../../../assets/icons_and_logos/gold_coins.svg")
          //-     p <strong>60</strong> {{ word_num_cases(60, "балл", "балла", "баллов") }}
          //-   .buy_button Купить за 190 руб. <img src="../../../assets/icons_and_logos/cart_white.svg">
          //- .rub_good
          //-   .notification 
          //-     img(src="../../../assets/icons_and_logos/gold_coins.svg")
          //-     p <strong>120</strong> {{ word_num_cases(120, "балл", "балла", "баллов") }}
          //-   .buy_button Купить за 190 руб. <img src="../../../assets/icons_and_logos/cart_white.svg">

      .subcontent_wrap.mb-0.pb-0
        // p.mb-2.mt-4 Платежи принимаются при помощи платёжной системы
        p.mb-3.mt-4
          // img(src="../../../assets/icons_and_logos/pays.png" width="300")
          img.mr-2(src="../../../assets/icons_and_logos/mir.jpg" height="30")
          img.mr-2(src="../../../assets/icons_and_logos/visa.jpg" height="30")
          img.mr-3(src="../../../assets/icons_and_logos/mastercard.jpg" height="30")
          img.sbp_logo(src="../../../assets/icons_and_logos/sbp.jpg" height="50")

      .inventory_wrap
        h3 Инвентарь:
        .inventory_blocks
          .coins_block
            strong Мои баллы:
            p {{ coins }} <span>шт.</span>
          .voices_block
            strong Мои голоса:
            p {{ voices }} <span>шт.</span>

      div.mt-4.mobile_button
        v-btn.green_button(
          v-if="voices && club.fc_id == my_club.fc_id"
          x-large
          dark
          color="#04841F"
          @click="Vote"
        ) Отдать голос
        v-btn.green_button(
          v-else-if="club.fc_id == my_club.fc_id"
          x-large
          dark
          color="#04841F"
          @click="goTo('/inside/shop')"
        ) Получить ещё голос
      BitvaTimer

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { GetAllGoods, BuyGood } from "../../../api/Inside"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'PersonalCabinet',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
      picture: "picture",
      username: "username",
    })
  },
  data () {
    return {
      goods: [],
      // goods: [
      //   {
      //     good_id: 1,
      //     good_type: "votes",
      //     quantity: 1,
      //     currency: "coins",
      //     cost: 10,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 2,
      //     good_type: "votes",
      //     quantity: 5,
      //     currency: "coins",
      //     cost: 45,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 3,
      //     good_type: "votes",
      //     quantity: 10,
      //     currency: "coins",
      //     cost: 80,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 4,
      //     good_type: "coins",
      //     quantity: 15,
      //     currency: "rub",
      //     cost: 190,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 5,
      //     good_type: "coins",
      //     quantity: 30,
      //     currency: "rub",
      //     cost: 370,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 6,
      //     good_type: "coins",
      //     quantity: 60,
      //     currency: "rub",
      //     cost: 740,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 7,
      //     good_type: "coins",
      //     quantity: 120,
      //     currency: "rub",
      //     cost: 1420,
      //     datetime: "27.01.2023 17:42"
      //   },
      // ],
      sub_menu: [
        {
          "title": "Магазин",
          "link": "/inside/shop"
        },
        {
          "title": "История покупок",
          "link": "/inside/purchases"
        }
      ]
    }
  },
  mounted () {
    this.getAllGoods()
    // this.$swal({
    //   "icon": "warning",
    //   "text": "Сайт работает в тестовом режиме. Пожалуйста, воздержитесь от покупок в «Магазине»!"
    // })
  },
  methods: {
    async getAllGoods () {
      let context = this
      let result = await GetAllGoods(context)
      if (result) {
        this.goods = result["data"]
      }
    },
    async buyGood(good) {
      let can_buy = false
      if (good.good_type == "votes") {
        if (good.cost <= this.coins) {
          can_buy = true
        }
      } else {
        can_buy = true
      }
      console.log(can_buy, good, this.coins)
      if (can_buy && good.good_type == "votes") {
        let context = this
        let result = await BuyGood(good, context)
        if (result) {
          this.$swal({
            "icon": "success",
            "text": "Спасибо за покупку!",
          }).then(function () {
            context.ResultToUserState(result.data)
          })
        }
      } else if (can_buy && good.good_type == "coins") {
        let context = this
        let result = await BuyGood(good, context)

        if (result) {
          if (result.data) {
            if (result.data.payment_link) {
              window.location.replace(result.data.payment_link)
            }
          // this.$swal({
          //   "icon": "success",
          //   "text": "Спасибо за покупку!",
          // }).then(function () {
          //   context.ResultToUserState(result.data)
          // })
          }
        }
      } else {
        this.$swal({
          "icon": "warning",
          "text": "У тебя недостаточно баллов!"
        })
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.voices_goods_block
  display: flex
  align-items: flex-start
  justify-content: space-between
  flex-wrap: wrap
  margin-top: 25px
  .voice_block_wrap
    position: relative
    width: calc( 25% - 15px )
    height: 180px
    border: 1px solid #EFEFEF
    border-radius: 10px
    cursor: pointer
    margin-bottom: 20px
    @media (max-width: 550px)
      width: calc( 50% - 10px )
      height: 160px
    .voice_text_icon_wrap
      display: flex
      align-items: center
      justify-content: center
      display: flex
      align-items: center
      justify-content: center
      width: 100%
      height: 138px
      .voice_text_icon
        text-align: center
        padding-top: 7px
        span
          display: inline-block
          width: 100%
          text-align: center
    .voice_button
      position: absolute
      bottom: 0
      left: 0
      height: 42px
      line-height: 18px
      font-size: 16px
      font-weight: 300
      width: 100%
      background: linear-gradient(#73C8B8, #107A67)
      border-bottom-left-radius: 9px
      border-bottom-right-radius: 9px
      color: #fff
      text-align: center
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      padding: 0 4px
      @media (max-width: 650px)
        font-size: 14px
      &:hover
        background: linear-gradient(#7fd1c2, #17917b)
      img
        margin-left: 7px
        margin-top: -3px

.inventory_wrap
  margin-top: 25px

.rub_goods_wrap
  margin-top: 30px
  .rub_good
    width: 100%
    border: 2px solid #EFEFEF
    border-radius: 10px
    min-height: 48px
    display: flex
    justify-content: flex-start
    align-items: stretch
    margin-bottom: 15px
    .buy_button
      width: 190px
      padding-right: 35px !important
      background: linear-gradient(#069A26, #016E18)
      position: relative
      color: #fff
      display: flex
      justify-content: flex-start
      align-items: center
      border-top-right-radius: 8px
      border-bottom-right-radius: 8px
      cursor: pointer
      @media (max-width: 650px)
        font-size: 14px !important
        line-height: 18px
      &:hover
        background: linear-gradient(#08B52D, #038E20)
      img
        position: absolute
        right: 5px
        margin-top: -2px
    .notification, .buy_button
      min-height: 48px
      font-size: 16px
      font-weight: 300
      padding: 5px 10px
    .notification
      width: calc( 100% - 190px )
      background: linear-gradient(to right, #EFEFEF, #FFF)
      display: flex
      align-items: center
      justify-content: flex-start
      padding-right: 35px !important
      position: relative
      img
        margin-right: 10px
      @media (max-width: 550px)
        width: calc( 100% - 100px )
        padding-right: 15px !important
      p
        line-height: 20px
        margin: 0
        padding: 0
        strong
          font-size: 20px
          font-weight: 600
        @media (max-width: 550px)
          font-size: 12px
          line-height: 18px

.inventory_blocks
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 10px
  .coins_block, .voices_block
    width: calc( 50% - 10px )
    height: 100px
    background-color: #375567
    color: #fff
    border-radius: 10px
    position: relative
    display: flex
    justify-content: center
    align-items: center
    @media (max-width: 550px)
      width: calc( 50% - 10px )
      height: 90px
    strong
      position: absolute
      top: 5px
      left: 12px
      font-weight: 400
      @media (max-width: 550px)
        left: 0
        font-size: 13px
        width: 100%
        text-align: center
    p
      font-size: 26px
      font-weight: 600
      margin: 0
      padding: 0
      position: relative
      top: 3px
      span
        font-weight: 300
      @media (max-width: 550px)
        font-size: 21px

.sbp_logo
  position: relative
  top: 7px

</style>
    