<template lang="pug">
// vue-custom-scrollbar#first_screen_common(class="scroll-area")
#first_screen_common.scroll-area
  .content#fight_more
    logotype
    .second_slide_cols_wrap
      .second_slide_left_col
        h2 УЧАСТВУЙ В БИТВЕ
        p Каждый месяц Битва определяет: фанаты какого
          |  клуба самые активные и общительные.<br>
          | Чтобы поддержать свой клуб, регистрируйся
          |  и отдавай голоса в его поддержку
        .timer_wrap_landing
          .timer_header 
            p До конца текущей Битвы осталось:
          .timer
            #countdown.countdown
              .countdown-number.day
                span.days.countdown-time
                span.countdown-text дн.
              .countdown-number.hour
                span.hours.countdown-time
                span.countdown-text час.
              .countdown-number.min
                span.minutes.countdown-time
                span.countdown-text мин.
              .countdown-number.sec
                span.seconds.countdown-time
                span.countdown-text сек.
          
        v-btn.register_button.mb-5(
          x-large
          dark
          color="#FF0038"
          @click="goTo('/registration')"
        ) Регистрация

        //- .prizes_wrap
          //- .prizes_left_col
            //- img(src="~@/assets/icons_and_logos/solar_gift-bold.svg")
          //- .prizes_right_col
            //- p Самых активный участник победившего клуба получит премиальный приз.
              //- |  Самый активный участник месяца получит отдельный ценный приз, даже если его клуб не выиграет.
              //- |  Все болельщики победившего клуба получают голоса на следующую Битву.

      .second_slide_right_col
        .right_col_inwrap
          h3 ТЕКУЩИЙ ФАН-РЕЙТИНГ КЛУБОВ <em v-if="month">({{ month }})</em>:
          vue-custom-scrollbar#right_col_content
            table
              tr.table_header
                th.place
                  img(src="~@/assets/icons_and_logos/solar_cup-star-bold.svg")
                th.clubname Клуб
                th.rating Фан-рейтинг *
                tr(v-for="(club, index) in clubs" :class="{'odd': index % 2}")
                  td.place {{ index + 1 }}
                  td.clubname {{ club.name }} 
                    // span(v-if="club.league == 1") РПЛ
                    // span(v-else="club.league == 1") ФНЛ
                  td.rating(v-if="club.rating") {{ ratingText(club.rating) }}
                  td.rating(v-else) —
        .note 
          p <span class="note_star">*</span>Фан-рейтинг клуба — это сумма всех голосов, делённая на число проголосовавших уникальных фанатов.
          p <span class="note_star2">**</span>Прочерк в графе «Фан-рейтинг» означает, 
            | что клуб не преодолел минимальный порог 
            | проголосовавших фанатов, см. 
            | «<a @click="rules = !rules">Правила Конкурса</a>»
      
      v-dialog(v-model="rules" width="900")
        // .dialog_inwrap
        //   .close_icon(@click="rules=!rules")
        //   BitvaRulesText
        v-card
          v-card-title.main_card_title.pt-2.pr-10 Правила конкурса «Битва фанатов» <i class="close_icon" @click="rules = !rules"></i>
          v-divider
          v-card-text.share_wrap.bp-0.main_card_txt
            v-row.pt-0.pb-2(align="start")
              v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
                BitvaRulesText.pt-4
    // .scroll_more(@click="slideTo(2)")
    //   p Листай дальше, чтобы узнать, как выигрывать ценные призы!
    
    // .down-arrow(@click="slideTo(2)")

</template>


<script>

import { championshipClubs } from "./../../api/LandingPage"
// import { BitvaRulesText } from "../InsidePages/Bitva/BitvaRulesText"
import BitvaRulesText from '@/components/InsidePages/Bitva/BitvaRulesText'
import Logotype from "./Logotype"
import { commonMixin } from "../../shared/mixins"
import { initializeClock, ratingText } from "../../service/service"
import vueCustomScrollbar from "vue-custom-scrollbar"
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  name: 'SlideTwoBitva',
  components: {
    Logotype,
    BitvaRulesText,
    vueCustomScrollbar
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      clubs: [],
      deadline: null,
      month: null,
      rules: false
    }
  },
  async created () {
    await this.championshipClubs()
  },
  async mounted () {
    // let context = this
    // let second_screen_bitva = document.getElementById("second_screen_bitva")
    // let resized_with_scroll_up = document.getElementById("resized_with_scroll_up")
    // let resized_with_scroll_down = document.getElementById("resized_with_scroll_down")
    // wheel_main_slider_calculator(context, second_screen_bitva, resized_with_scroll_up, resized_with_scroll_down, "touchmove", 0, 2)
    // wheel_main_slider_calculator(context, second_screen_bitva, resized_with_scroll_up, resized_with_scroll_down, "wheel", 0, 2)
    let deadline = await this.championshipEnds()
    this.month = this.get_current_month(deadline)
    console.log(deadline)

    initializeClock('countdown', deadline)
  },
  methods: {
    slideTo (slide_num) {
      this.$emit("slideTo", slide_num)
    },
    ratingText (rating) {
      return ratingText(rating)
    },
    async championshipClubs () {
      let context = this
      let result = await championshipClubs(context)
      if (result) {
        this.clubs = result
        console.log(result)
      }
    }
  }
}

</script>


<style lang="sass">

.v-dialog
  .close_icon
    position: absolute
    top: 6px
    right: 18px
    cursor: pointer
    transform: rotate(45deg)
    &:before, &:after
      display: block
      position: absolute
      content: ""
    &:before
      width: 2px
      height: 16px
      left: 7px
      top: 0
      background-color: #000
    &:after
      height: 2px
      width: 16px
      top: 7px
      left: 0
      background-color: #000

.bitva_rules
  h3
    display: none

#second_screen_bitva
  width: 100%
  height: 100%

.scroll-area
  .content
    position: relative
    padding-bottom: 150px
    @media (max-width: 700px)
      padding-bottom: 200px
    .logotype
      opacity: 0

.second_slide_cols_wrap
  display: flex
  flex-wrap: wrap
  width: calc(100% - 10vw)
  max-width: 1400px
  margin: 3vh auto
  padding: 0 20px
  min-height: 20px
  align-items: flex-start
  justify-content: space-between
  @media (max-width: 1100px)
    width: calc(100% - 40px)
    padding: 0

.second_slide_left_col
  width: calc(60% - 20px)
  max-width: 680px
  text-align: left
  color: #fff
  @media (max-width: 1100px)
    width: 100%
  h2
    font-size: 40px
    @media (max-width: 700px)
      font-size: 30px
  p
    font-size: 27px
    font-weight: 400
    margin: 1vh 0 0 0
    @media (max-width: 700px)
      font-size: 18px

#right_col_content
  height: 570px
  padding-right: 15px
  @media (max-width: 700px)
    height: 470px
  @media (max-height: 800px)
    height: 470px
.second_slide_right_col
  width: 35%
  border-radius: 10px
  position: relative
  background-color: rgba(255, 255, 255, 0.8)
  padding: 15px 5px 20px 20px
  text-align: left
  @media (max-width: 1100px)
    width: 100%
  .right_col_inwrap
    h3
      font-size: 20px
      line-height: 24px
      font-weight: 500
    table, th, td
      border: 1px solid white
      border-collapse: collapse
    table
      table-layout: auto
      width: 100%
      border: 0
      margin-top: 10px
      .table_header
        width: 100%
        th
          background-color: #0D1D27
          color: #fff
          font-weight: 400
          font-size: 12px
          height: 33px
          padding: 0 5px
          line-height: 33px
          border-top: 0
        th.place
          width: 34px
          text-align: center
          padding: 0
          border-left: 0
          img
            position: relative
            top: 6px
        th.rating
          width: 90px
          border-right: 0
      tr
        td
          text-align: left
          font-weight: 400
          font-size: 14px
          padding: 3px 5px
          height: 27px
          line-height: 19px
        td.place
          text-align: center
          padding: 0
          font-weight: 600
          font-size: 16px
          border-left: 0
          background-color: #fff
        td.clubname
          background-color: #fff
        td.rating
          border-right: 0
          background-color: #fff
      tr.odd
        td.place, td.rating
          background-color: #EDEDED
        td.clubname
          background-color: #EDEDED
  .note
    position: absolute
    top: 100%
    margin-top: 1vh
    color: #fff
    font-size: 14px
    line-height: 20px
    text-shadow: 2px 2px 2px #000
    font-weight: 400
    width: calc(100% - 10px)
    margin-left: -10px
    p
      a
        color: #fff !important
        text-decoration: underline
        &:hover
          text-decoration: none
    @media (max-width: 700px)
      font-size: 13px
    .note_star, .note_star2
      position: absolute
      font-size: 16px
      font-weight: 500
      left: -10px
      @media (max-width: 700px)
        font-size: 14px
    .note_star2
      left: -18px


.timer_wrap_landing
  margin-top: 3vh
  width: 100%
  max-width: 600px
  border: 2px solid white
  border-radius: 10px
  .timer_header 
    background-color: #0C1E2A
    padding-left: 15px
    height: 47px
    line-height: 47px
    border-top-right-radius: 10px
    border-top-left-radius: 10px
    @media (max-width: 700px)
      padding-left: 7px
      height: 37px
      line-height: 37px
    p
      font-size: 24px
      font-weight: 200
      margin: 0
      padding: 0
      @media (max-width: 700px)
        font-size: 16px
  .timer
    padding-left: 15px
    height: 82px
    line-height: 82px
    border-bottom-right-radius: 10px
    border-bottom-left-radius: 10px
    @media (max-width: 700px)
      height: 62px
      line-height: 62px
      padding-left: 7px
  
.register_button
  margin-top: 3vh
  @media (max-width: 700px)
    font-size: 16px

.prizes_wrap
  margin-top: 3vh
  border-radius: 10px
  background-color: rgba(255, 255, 255, 0.8)
  display: flex
  align-items: center
  justify-content: flex-start
  padding: 15px 20px 15px 15px
  @media (max-width: 1100px)
    padding: 10px 10px 10px 10px
    margin-bottom: 20px
  .prizes_left_col
    margin-right: 20px
    @media (max-width: 700px)
      margin-right: 10px
    img
      position: relative
      top: 2px
      @media (max-width: 700px)
        width: 50px
  .prizes_right_col
    p
      padding: 0
      margin: 0
      font-size: 16px
      color: #000
      line-height: 24px
      font-weight: 400
      @media (max-width: 700px)
        line-height: 18px
        font-size: 14px

.scroll_more
  p
    background-color: rgba(0, 0, 0, 0.7)
    padding: 10px 20px
    color: #bdbdbd
    @media (max-width: 700px)


.down-arrow
  border-color: #000
  &:after
    border-color: #000

</style>
