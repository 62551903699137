<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ПОЕДИНКИ «РАЗ НА РАЗ»")
    .content_wrap

      HeaderSubMenu(:pages="sub_menu")
      .subcontent_wrap.mt-7
        .pos_r
          img.blue_prize(src="../../../assets/icons_and_logos/prize_blue.svg")
          p.mb-0.p_image_prize Игрок, одержавший наибольшее число побед, в конце месяца получит подарочный сертификат Ozon номиналом 5000 руб., звание «Громила месяца», бонус в 100 баллов и попадёт в «Зал славы».

      .subcontent_wrap.mt-5
        h3.mt-0.mb-3 Побеждай болельщиков других клубов в поединках 1 на 1!
        ul
          li Каждые сутки вы можете создать 3 бесплатных поединка
          li Чтобы играть более 3 поединков, вам необходимо делать взнос в баллах
          li Победитель поединка забирает свой взнос и взнос соперника
          li За каждые 5 побед вы получаете бесплатный голос, который можете отдать в поддержку вашего клуба, повышая его фан-рейтинг в Битве

      .subcontent_wrap.mt-4
        p.mb-0 <em>В конце каждого месяца, счётчик побед обнуляется и все участники снова оказываются в равных условиях.</em>

      //- .subcontent_wrap.mt-4
      //-   v-expansion-panels(accordion)
      //-     v-expansion-panel
      //-       v-expansion-panel-header 
      //-         .howtoplay <strong>Как играть?</strong>
      //-       v-expansion-panel-content.howtoplay_text
      //-         p Игра представляет собой упрощённый пошаговый файтинг. Игроки одновременно выбирают несколько целей на теле соперника и/или несколько областей на своём теле для блока. Всего таких целей восемь: четыре для блока и четыре для удара. <span class="omnumnum"><span>1</span></span>
      //-         p Количество целей определяется доступной энергией. В начале игры у каждого игрока по десять очков здоровья и по три очка энергии. Непотраченная энергия переносится на следующий ход, но её не может быть больше четырёх. <span class="omnumnum"><span>2</span></span>
      //-         p Цель игры — довести здоровье соперника до нуля. <span class="omnumnum"><span>3</span></span>
      //-         p Игроки делают ход одновременно в тайне друг от друга. Игроки обязаны успеть совершить ход в течение полутора минут. Когда оба игрока совершили ход, игровое поле начинает мигать зелёным и раздаётся характерный звук. В начале нового хода игроки узнают какие цели были поражены, а какие заблокированы. <span class="omnumnum"><span>4</span></span>
      //-         p Если один из игроков не успел сделать ход за полторы минуты — ему засчитывается поражение. Если ход не успевают сделать оба игрока — игра оканчивается ничьей.
      //-         p Каждый раз, когда игрок теряет единицу здоровья, ему даётся очко ярости. На очки ярости можно покупать карты финтов. Купленную карту финта можно будет приложить к своему ходу, чтобы попытаться перехитрить соперника, или получить некоторое преимущество. Каждая карточка содержит подробное описание того, что вы получите, разыграв её. <span class="omnumnum"><span>5</span></span> <span class="omnumnum six"><span>6</span></span>
      //-         p Нельзя ударить в одно и то же место два хода подряд. Цели, в которые вы били в прошлый ход будут недоступны для выбора. То же самое касается соперника. Учитывайте это, принимая решение, какую часть тела заблокировать в текущем ходу.
      //-         p В игре можно в любой момент признать своё поражение и сдаться. Но не торопитесь с этим, возможно вы сможете получить преимущество, удачно разыграв финт, или хотя бы свести поединок к ничьей. Удачи!
      //-         p 
      //-           img.pvp_gameplay_img(src="~@/assets/images/pvp_gameplay.jpg")

      .bet_rating_blocks.mt-5
        .bet_block
          strong Сделать взнос и начать поединок:
          .bet_inwrap
            .bet_arrows_wrap
              span.bet {{ bet }}
              .bet_arrows
                .bet_plus(@click="raiseBet")
                  img(src="~@/assets/icons_and_logos/arr_plus.svg")
                .bet_minus(@click="lowerBet")
                  img(src="~@/assets/icons_and_logos/arr_minus.svg")
              img.bet_coins(src="~@/assets/icons_and_logos/coins.svg")
            .bet_button_wrap
              v-btn.green_button#bet_button(
                large
                block
                :disabled="!(bet || can_bet)"
                dark
                color="#04841F"
                @click="CreatePvp"
              ) Начать поединок
        .rating_block
          strong Твои победы:
          p <span>{{ my_wins }}</span>
        strong.free_games {{ 3 - today_pvps }} из 3 бесплатных игр осталось сегодня.

      .subcontent_wrap.mt-5
        h3.mt-0.mb-3 Доступные поединки
        p Вы можете откликнуться на вызов других игроков:

      .subcontent_wrap.mt-5
        table.table_dreamteam
          tr.table_header
            th.player Игрок
            th.club Клуб
            th.bet Взнос
            th.start Поддержать взнос и начать поединок
          tr(v-for="(game, index) in pvps" :class="{'odd': index % 2}")
            td.player {{ game.master_username }}
            td.club {{ game.master_club }}
            td.bet {{ game.bet }}
            td.start 
              v-btn.green_button(
                block
                :disabled="!(coins >= bet)"
                dark
                color="#04841F"
                @click="FightJoin(game)"
              ) В бой!
        div.mt-4.mobile_button
          v-btn.green_button(
            v-if="voices && club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="Vote"
          ) Отдать голос
          v-btn.green_button(
            v-else-if="club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="goTo('/inside/shop')"
          ) Получить ещё голос
      BitvaTimer.mt-5

</template>

<script>

import { commonMixin } from "../../../shared/mixins"
import { GetAllFights, CreatePvp, FightJoin } from "../../../api/Inside"
import BitvaTimer from "./../../Common/BitvaTimer"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import vueCustomScrollbar from "vue-custom-scrollbar"
import { mapActions, mapGetters } from "vuex"
import settings from "../../../settings"

export default {
  name: 'RazNaRaz',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu,
    vueCustomScrollbar
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      user_count: 3,
      bet: 0,
      page_num: 1,
      pages_count: 1,
      today_pvps: 0,
      my_wins: 0,
      // interval: null,
      connection: null,
      pvps: [
        {
          "player": "Вася",
          "club": "Локомотив",
          "bet": 5,
          "id": 12,
        },
        {
          "player": "Иван",
          "club": "Спартак",
          "bet": 2,
          "id": 13,
        },
        {
          "player": "Петя",
          "club": "Спартак",
          "bet": 4,
          "id": 14,
        },
      ],
      sub_menu: [
        {
          "title": "Игра",
          "link": "/inside/raznaraz"
        },
        {
          "title": "Рейтинг игроков",
          "link": "/inside/raznaraz-ratings"
        },
        {
          "title": "История игр",
          "link": "/inside/raznaraz-story"
        },
        {
          "title": "Правила игры",
          "link": "/inside/raznaraz-game-rules"
        },
        {
          "title": "Правила конкурса",
          "link": "/inside/raznaraz-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      user_id: "user_id",
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club: "club"
    }),
    can_bet: function () {
      return this.bet || this.today_pvps < 3
    },
  },
  async activated () {
    // this.interval = setInterval(this.UpdateFights, 5000)
    await this.GetAllFights()
    this.connection = new WebSocket(settings.ws_addres + "pvps")
    let context = this
    this.connection.onmessage = function(event) {
      var obj = JSON.parse(event.data);
      context.UpdateFights(obj)
    }
  },
  beforeDestroy () {
    this.connection.close()
  },
  methods: {
    ...mapActions("user_store", {
      update: "ACTION_UPDATE",
    }),
    async FightJoin(game) {
      console.log(game)
      let context = this
      let result = await FightJoin(game.pvp_id, context)
      if (result) {
        let new_coins = this.coins - game.bet
        this.update({
          key: "coins",
          value: new_coins
        })
        this.$router.push("/inside/raznaraz/" + game.pvp_id)
      }
    },
    async GetAllFights() {
      console.log('GetAllFights')
      let context = this
      let result = await GetAllFights(context)
      let condition = "list"
      console.log(result.data)
      if (result.data.now_opened_pvp) {
        if (result.data.now_opened_pvp.pvp_status == "waiting") {
          condition = "on_going"
        } else if (result.data.now_opened_pvp.pvp_status == "on_going") {
          condition = "on_going"
        }
      }
      if (condition == "list") {
        this.page_num = 1
        this.pages_count = 1
        this.pvps = result.data.pvps
        this.today_pvps = result.data.today_pvps
        this.my_wins = result.data.your_rating
      } else {
        let pvp_id = result.data.now_opened_pvp.pvp_id
        let data = {
          "active_pvp": result.data.now_opened_pvp
        }
        this.ResultToUserState(data)
        this.$router.replace('/inside/raznaraz/' + pvp_id)
      }
    },
    UpdateFights(obj) {
      this.pvps = obj
    },
    async CreatePvp() {
      let context = this
      let data = {
        "bet": this.bet,
      }
      let result = await CreatePvp(data, context)
      if (result) {
        // TODO Сделать переход на страницу ожидания
        let new_coins = this.coins - this.bet
        this.update({
          key: "coins",
          value: new_coins
        })
        let pvp_id = result['data']['pvp_id']
        let pvp_websocket_link = result['data']['pvp_websocket_link']
        console.log(pvp_id, pvp_websocket_link)
        this.$router.push("/inside/raznaraz/" + result['data']['pvp_id'])
      }
    },
    raiseBet () {
      if (this.bet < this.coins) {
        this.bet += 1
      } else {
        this.bet = this.coins
      }
    },
    lowerBet () {
      if (this.bet > 0) {
        this.bet -= 1
      } else {
        this.bet = 0
      }
    }
  }
}

</script>

<style lang="sass" scoped>

.howtoplay_text
  font-size: 14px
  line-height: 23px
  .omnumnum
    position: relative
    display: inline-block
    color: #fff
    span
      position: relative
      z-index: 1
      left: 8px
      font-size: 15px
      font-weight: 500
      top: 0px
    &:before
      content: ""
      width: 25px
      height: 25px
      background-color: #d300cb
      border-radius: 15px
      z-index: 0
      position: absolute
      top: -2px
      left: 0
  .omnumnum.six
    position: relative
    left: 18px
      

.pvp_gameplay_img
  width: 100%

.blue_prize
  position: absolute
  top: 0
  left: 0
  width: 60px
.p_image_prize
  padding: 0 0 0 80px

.table_dreamteam
  table-layout: auto
  width: 100%
  border: 0
  margin-top: 10px
  .table_header
    width: 100%
    border-top-right-radius: 5px
    border-top-left-radius: 5px
    th
      font-weight: 400
      font-size: 14px
      height: 43px
      padding: 0 5px
      line-height: 43px
      border-top: 0
      line-height: 18px
      span
        display: none
      @media (max-width: 600px)
        font-size: 12px
        line-height: 16px
        span
          display: inline
      @media (max-width: 400px)
        font-size: 10px
        line-height: 14px

  tr
    td
      text-align: left
      font-weight: 400
      font-size: 16px
      padding: 0 5px
      height: 37px
      line-height: 37px
      background-color: #ECECEC
      font-size: 14px
      line-height: 15px
      padding: 5px
      @media (max-width: 550px)
        font-size: 12px

  tr.odd
    td
      background-color: #FFF




.bet_rating_blocks
  width: 100%
  display: flex
  justify-content: space-between
  align-items: stretch
  flex-wrap: wrap
  margin-top: 10px
  .free_games
    font-size: 14px
    font-weight: 500
    margin-top: 2px
  .bet_block, .rating_block
    width: calc( 50% - 10px )
    height: auto
    background-color: #EFEFEF
    color: #393939
    border-radius: 10px
    position: relative
    padding: 8px 12px 15px 12px
    @media (max-width: 550px)
      width: 100% !important
    div
      width: 100%
    strong
      font-weight: 500
      text-align: left
      line-height: 20px
      display: inline-block
      font-size: 14px
      width: 100%
      margin-bottom: 5px
      @media (max-width: 550px)
        left: 0
        font-size: 13px
        width: 100%
        text-align: left
    p
      width: 100%
      font-size: 20px
      font-weight: 400
      margin: 0
      padding: 0
      position: relative
      text-align: left
      line-height: 24px
      top: 3px
      display: flex
      align-items: center
      @media (max-width: 550px)
        font-size: 21px
  .rating_block
    border: 2px dashed #375567
    background-color: #fff
    strong
      text-align: center
    p
      width: 100%
      height: 45px
      text-align: center
      display: inline-block
      line-height: 45px
      span
        text-align: center
        font-size: 33px
        color: #375567
  .bet_block
    .bet_inwrap
      width: 100%
      min-height: 54px
      display: flex
      align-items: center
      @media (max-width: 700px)
        flex-wrap: wrap
      .bet_arrows_wrap
        display: flex
        align-items: center
        width: 30%
        min-width: 93px
        height: 54px
        span.bet
          font-size: 22px
          font-weight: 400
        .bet_arrows
          width: 28px
          height: 100%
          margin-left: 5px
          // background-color: #fff
          position: relative
          .bet_plus
            width: 100%
            height: 50%
            position: absolute
            top: 0
            cursor: pointer
            img
              position: absolute
              top: 4px
              left: 0
          .bet_minus
            width: 100%
            height: 50%
            position: absolute
            bottom: 0
            cursor: pointer
            img
              position: absolute
              bottom: 4px
              left: 0
        .bet_coins
          width: 27px
          margin-left: 4px
      .bet_button_wrap
        width: 70%
        min-width: 165px
        #bet_button.green_button.v-btn
          flex: auto
          padding-left: 4px
          padding-right: 4px
          position: relative
          white-space: normal !important
        #bet_button.green_button.v-btn .v-btn__content
          display: inline-block
    @media (max-width: 550px)
      margin-bottom: 15px


.subcontent_wrap
  width: 100%
  text-align: left
  border-bottom: 1px dotted #DDDDDD
  padding-bottom: 20px
  ul
    margin-top: 10px
    padding: 0
    li
      display: block
      padding-left: 15px
      position: relative
      margin-bottom: 2px
      &:before
        content: ""
        display: block
        width: 6px
        height: 6px
        border-radius: 10px
        background-color: #2c3e50
        position: absolute
        left: 0
        top: 8px
      a
        &:hover
          text-decoration: none

</style>