
<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ПОЕДИНКИ «РАЗ НА РАЗ»")
    .content_wrap

      HeaderSubMenu(:pages="sub_menu")
      Waiting(
        v-if="active_pvp.pvp_status == 'waiting' && my_role"
        :pvp_id="parseInt(pvp_id)"
        :bet="parseInt(bet)"
        :connection="connection"
        :personal_connection="personal_connection"
        :wait_seconds="wait_seconds"
        :wait_interval="wait_interval"
        :wait_writind="wait_writind"
        :backoff_interval="backoff_interval"
        :my_role="my_role"
        :opponent_role="opponent_role"
        @fight_cancel="FightCancel"
        @i_am_ready="IAmReady"
      )
      //- Fight(
      //-   v-else-if="active_pvp.pvp_status == 'on_going' && my_role"
      //-   ref="fightComponent"
      //-   :pvp_id="parseInt(pvp_id)"
      //-   :bet="parseInt(bet)"
      //-   :connection="connection"
      //-   :fint_notify="fint_notify"
      //-   :personal_connection="personal_connection"
      //-   :my_role="my_role"
      //-   :opponent_role="opponent_role"
      //- )
      


</template>
  
<script>
  
  import { commonMixin } from "../../../shared/mixins"
  import { clientOrMaster, clientOrMasterExcept, timeRemaining } from "../../../service/service"
  import { GetOneFight, FightCancel, UpdatePvpReady } from "../../../api/Inside"
  import Waiting from "./Waiting"
  import Fight from "./Fight"
  import HeaDer from "./../../Common/HeaDer"
  import LeftMenu from "../../Common/LeftMenu"
  import HeaderSubMenu from "../../Common/HeaderSubMenu"
  import "vue-custom-scrollbar/dist/vueScrollbar.css"
  import { mapActions, mapGetters } from "vuex"
  import settings from "../../../settings";
  
  export default {
    name: 'PvP',
    components: {
      HeaDer,
      HeaderSubMenu,
      LeftMenu,
      Waiting,
      Fight
    },
    mixins: [
      commonMixin
    ],
    data () {
      return {
        pvp_id: null,
        bet: null,
        connection: null,
        fint_notify: null,
        personal_connection: null,
        wait_seconds: 0,
        wait_interval: null,
        // step_seconds_interval: null,
        step_seconds: 61,
        wait_writind: null,
        backoff_interval: null,
        my_role: null,
        opponent_role: "",
        sub_menu: [
          {
            "title": "Игра",
            "link": "/inside/raznaraz"
          },
          {
            "title": "Рейтинг игроков",
            "link": "/inside/raznaraz-ratings"
          },
          {
            "title": "История игр",
            "link": "/inside/raznaraz-story"
          },
          {
            "title": "Правила игры",
            "link": "/inside/raznaraz-game-rules"
          },
          {
            "title": "Правила конкурса",
            "link": "/inside/raznaraz-rules"
          }
        ]
      }
    },
    computed: {
      ...mapGetters("user_store", {
        user_id: "user_id",
        username: "username",
        voices: "voices",
        coins: "coins",
        my_club: "club",
        picture: "picture",
        active_pvp: "active_pvp",
      }),
    },
    async activated () {
      console.log("activated shiit")
      this.ResetData()
      this.pvp_id = this.$route.params.pvp_id
      let context = this
      let active_pvp = await this.GetOneFight()
      if (active_pvp.pvp_id) {
        this.bet = active_pvp.bet
        let backoff_usr_id = function () {
          if (context.user_id) {
            context.my_role = clientOrMaster(context.user_id, active_pvp)
            context.opponent_role = clientOrMasterExcept(context.my_role)
            if (active_pvp["pvp_status"] == "waiting") {
              if (!context.my_role) {
                context.notForYou()
              }
              let waitingTimer = function () {
                context.wait_writind = timeRemaining(active_pvp.created_at)
              }
              context.wait_interval = setInterval(waitingTimer, 1000)
              context.connection = new WebSocket(settings.ws_addres + "pvp-room/" + active_pvp.pvp_id)
              context.connection.onmessage = function(event) {
                var obj = JSON.parse(event.data);
                context.WsMessage(obj)
              }
              clearInterval(context.backoff_interval)
            } else if (active_pvp["pvp_status"] == "on_going") {
              clearInterval(context.backoff_interval)
              context.CloseConection()
              context.$router.push("/inside/raznaraz/fight/" + context.pvp_id)
              return
              // context.personal_connection = new WebSocket(settings.ws_addres + "pvp-personal/" + context.user_id)
              // context.personal_connection.onmessage = function(event) {
              //   var obj = JSON.parse(event.data)
              //   context.WsPersonalMessage(obj)
              // }
              // context.step_seconds_interval = setInterval(context.StepTimerFunc, 1000)
              // context.$refs.fightComponent.StepTimerFuncInterval()
            } else {
              clearInterval(context.backoff_interval)
            }
          }
        }
        this.backoff_interval = setInterval(backoff_usr_id, 100)
      } else {
        this.notForYou()
      }
    },
    beforeDestroy () {
      this.CloseConection()
      // clearInterval(this.step_seconds_interval)
    },
    methods: {
      ...mapActions("user_store", {
        update: "ACTION_UPDATE",
      }),
      CloseConection () {
        if (this.wait_interval) {
          clearInterval(this.wait_interval)
        }
        if (this.connection) {
          this.connection.close()
        }
      },
      WsMessage (obj) {
        if (obj.master_id) {
          let data = {
            "active_pvp": obj
          }
          this.ResultToUserState(data)
          if (this.my_role && this.active_pvp.pvp_status == "on_going") {
            this.CloseConection()
            this.$router.push("/inside/raznaraz/fight/" + this.pvp_id)
          }
        } else if (obj.type == "deleted" && this.my_role == "client") {
          this.connection.close()
          clearInterval(this.wait_interval)
          this.update({
            key: "active_pvp",
            value: {}
          })
          this.$router.push("/inside/raznaraz")
        } else if (obj.type == "deleted") {
          this.CloseConection()
        }
        // else if (obj.type == "fint_bought") {
          // this.fint_notify = obj.username + " купил финт"
          // let active_pvp_copied = JSON.parse(JSON.stringify(this.active_pvp))
          // active_pvp_copied.pvp_condition.fints_market = obj.active_pvp.pvp_condition.fints_market
          // active_pvp_copied.pvp_condition.master_condition.fints = obj.active_pvp.pvp_condition.master_condition.fints
          // active_pvp_copied.pvp_condition.client_condition.fints = obj.active_pvp.pvp_condition.client_condition.fints
          // active_pvp_copied.pvp_condition.client_condition.angry = obj.active_pvp.pvp_condition.client_condition.angry
          // let data = {
            // "active_pvp": active_pvp_copied
          // }
          // this.ResultToUserState(data)
          // this.FintNotify()
        // } 
        // else if (obj.type == "new_step") {
        //   console.log("ws new step")
        //   this.$refs.fightComponent.resetData()
        //   let data = {
        //     "active_pvp": obj.active_obj
        //   }
        //   this.ResultToUserState(data)
        //   this.$refs.fightComponent.StepTimerFuncInterval()
        // }
      },
      FintNotify () {
        let context = this
        let ffn = function () {
          context.fint_notify = null
        }
        setTimeout(ffn, 5000)
      },
      WsPersonalMessage (obj) {
        console.log(obj)
      },
      notForYou () {
        let context = this
        this.$swal({
          "icon": "error",
          "text": "Поединок окончен, или ты не являешься его участником!",
        }).then(function () {
          context.$router.replace("/inside/raznaraz")
        })
      },
      async FightCancel () {
        let content = this
        let pvp_id = this.active_pvp.pvp_id
        let result = await FightCancel(pvp_id, content)
        if (result.data.result) {
          let new_coins = this.coins + this.bet
          this.update({
            key: "coins",
            value: new_coins
          })
          this.update({
            key: "active_pvp",
            value: {}
          })
          this.CloseConection()
          this.$router.push("/inside/raznaraz")
        }
      },
      async GetOneFight () {
        let context = this
        console.log('GetOneFight')
        let then = function () {
          context.$router.push("/inside/raznaraz")
        }
        let result = await GetOneFight(context, this.pvp_id, then)
        let data = {
          "active_pvp": result.data
        }
        this.ResultToUserState(data)
        return result.data
      },
      async IAmReady () {
        let context = this
        let active_pvp_changed = this.active_pvp
        active_pvp_changed[this.my_role + "_ready"] = true
        let data = {
          "active_pvp": active_pvp_changed
        }
        this.ResultToUserState(data)
        await UpdatePvpReady(this.active_pvp.pvp_id, context)
      },
      ResetData () {
        this.pvp_id = null
        this.bet = null
        this.connection = null
        this.wait_seconds = 0
        this.wait_interval = null
        this.wait_writind = null
        this.backoff_interval = null
        this.my_role = ""
        this.opponent_role = ""
      }
    }
  }
  
</script>
  
<style lang="sass" scoped>
.timer_block
  margin-top: 40px
  width: 100%
  height: 55px
  border-radius: 10px
  background-color: #375567
  text-align: center
  line-height: 55px
  font-size: 24px
  color: #fff

.cancel_block, .approve_block
  display: flex
  justify-content: center
  margin-top: 20px

.avas_block
  display: flex
  justify-content: center
  align-items: flex-start
  .ava_block
    width: 140px
    min-height: 200px
    display: flex
    justify-content: center
    align-items: flex-start
    flex-wrap: wrap
    position: relative
    .ava 
      width: 140px
      height: 140px
      border-radius: 70px
      background-color: #ccc
      overflow: hidden
      display: flex
      justify-content: center
      align-items: center
      margin-bottom: 5px
      .question
        font-size: 47px
        font-weight: 600
        color: #fff
    .clud_username
      position: absolute
      top: 150px
      left: 0
      width: 100%
      display: flex
      align-items: flex-start
      .club
        width: 20px
        height: 100%
        margin-right: 5px
        img
          width: 100%
      .username
        width: calc(100% - 25px)
        text-align: left
        font-size: 14px
        line-height: 16px

  .versus
    min-width: 70px
    max-width: 200px
    width: 25%
    height: 140px
    display: flex
    justify-content: center
    align-items: center
    font-size: 50px
    font-weight: 500

</style>
