<template lang="pug">

.left_col_wrap
  .burger_button(@click="openMenu")
      img(v-if="!menu_is_open" src="~@/assets/icons_and_logos/majesticons_menu_black.svg")
      img(v-else src="~@/assets/icons_and_logos/majesticons_menu_close.svg")

  .left_col_podlozhka(:class="{'menu_is_open': menu_is_open}")
    LeftMenuPuncts

</template>


<script>

import { commonMixin } from "../../shared/mixins"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"
import LeftMenuPuncts from "./LeftMenuPuncts"

export default {
  name: 'LeftMenu',
  components: {
    LeftMenuPuncts
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      menu_is_open: false
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      club: "club",
      voices_given: "voice_given"
    }),
  },
  methods: {
    openMenu () {
      this.menu_is_open = !this.menu_is_open
    },
  }
}

</script>


<style lang="sass" scoped>

.small_punkt
  a
    color: #2c3e50 !important
    &:hover
      text-decoration: none

.left_col_wrap
  .burger_button
    position: absolute
    top: 0
    left: 10px
    z-index: 1001
    cursor: pointer
    display: none
    img
      position: relative
      top: 5px
    @media (max-width: 1050px)
      display: block

  .left_col_podlozhka
    width: 100%
    height: 100%
    background-color: #fff
    padding: 30px 25px 20px 20px
    box-shadow: 0px 0px 10px 0px #00000021
    text-align: left
    .punkt
      display: block
      position: relative
      margin-bottom: 12px
      a
        color: #2c3e50 !important
        font-size: 20px
        line-height: 20px
        display: inline-block
        text-decoration: none
        border-bottom: 1px dashed transparent
        &:hover
          border-bottom: 1px dashed #2c3e50
    .punkt.unactive
      a
        color: #393939 !important
        border-bottom: 1px dashed transparent
        cursor: default
        position: relative
        font-weight: 600
        padding-left: 15px
        &:after
          content: ""
          display: block
          width: 0
          height: 0
          position: absolute
          top: 6px
          left: 0
          border-top: 5px solid transparent
          border-bottom: 5px solid transparent
          border-left: 7px solid #393939

    .my_club_logo
      width: 100%
      height: 70px
      display: flex
      align-items: center
      justify-content: flex-start
      background-color: #F0F0F0
      position: relative
      margin-top: 30px
      margin-bottom: 30px
      &:after, &:before
        content: ""
        display: block
        background-color: #F0F0F0
        position: absolute
        height: 70px
        top: 0
        z-index: 1
      &:after
        left: -20px
        width: 20px
      &:before
        right: -25px
        width: 25px
      img
        position: relative
        left: -5px
        z-index: 2
      p.club_name
        font-size: 16px
        line-height: 20px
        font-weight: 300
        margin: 0
        padding: 0
    .coins_votes_wrap
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      height: 54px
      position: relative
      background-color: #F5F5F5
      border-radius: 10px
      padding-left: 10px
      margin-bottom: 15px
      .left
        width: 50%
        height: 54px
        display: flex
        justify-content: flex-start
        align-items: center
        .img
          width: 34px
          margin-right: 10px
        .title
          font-size: 16px !important
          font-weight: 300
      .right
        width: 50%
        height: 54px
        background-color: #CFCFCF
        display: flex
        justify-content: flex-start
        align-items: center
        border-top-right-radius: 10px
        border-bottom-right-radius: 10px
        padding-left: 10px
        span
          line-height: 20px !important
        .count
          font-size: 20px !important
          font-weight: 600
        .title
          font-size: 16px !important
          font-weight: 300
          margin-left: 5px
          position: relative
          top: 1px
    .green_button
      width: 100%
      margin-bottom: 15px
    .voices_given
      font-size: 14px
      font-weight: 300
      line-height: 20px
      margin-bottom: 20px
      span
        font-size: 16px
        font-weight: 600

  .logo_grayscale
    cursor: pointer


</style>