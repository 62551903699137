<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ПОЕДИНКИ «РАЗ НА РАЗ»")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7
        h3.mb-4 Правила игры и Конкурса «Поединки Раз на Раз»
        ol
          li.mb-2 Этот Конкурс проходит постоянно и его итоги подводятся каждый месяц. Конкурс основан на достижениях его участников в браузерной игре «Поединки Раз на Раз». Для успешного ведения поединков от игроков требуется внимательность и логическое мышление.
          li.mb-2 Поединки проводятся между <em><strong>аватарами</strong></em> двух игроков. Каждый аватар имеет показатели здоровья, ярости и энергии, а также четыре точки на нём, которые можно блокировать (на своём аватаре) или наносить по ним удары (на аватаре соперника).
          li.mb-2 В начале каждого поединка аватары имеют равные показатели уровней здоровья (10 единиц), ярости (0 единиц) и энергии (3 единицы).

          h4.mlminush4.mb-2.mt-4 Суть поединка:

          ol.mt-2
            li.mb-2 Поединок состоит из нескольких раундов. Раунд длится не более 90 секунд, в течение которых оба игрока, независимо друг от друга, должны совершить свои ходы – выбрать точки на силуэтах и нажать кнопку «Сделать ход».
            li.mb-2 Если один из игроков не успеет за отведённое время нажать кнопку «Сделать ход» ему автоматически засчитается поражение в этом поединке. Если кнопку «Сделать ход» не нажмут оба игрока — поединок окончится ничьей.
            li.mb-2 Каждый игрок в течение раунда выбирает несколько точек на аватаре соперника для нанесения ему ударов по этим точкам и несколько точек на своём аватаре для защиты.
            li.mb-2 Всего таких точек (суммарно для блоков и для ударов) может быть выбрано не более, чем текущее значение показателя энергии (от трёх до четырёх).
            li.mb-2 Не потраченная в текущем раунде энергия автоматически переносится на следующий раунд, при этом значение энергии не может превышать лимит в четыре единицы.
            li.mb-2 Когда оба игрока нажали кнопку «Сделать ход» — раунд заканчивается. После этого подсчитывается ущерб здоровью каждому аватару в зависимости от блоков и пропущенных ударов.
            li.mb-2 В следующем раунде игроки заново выбирают точки на аватарах и делают удары до тех пор, пока показатель уровня здоровья одного из них не упадёт до нуля.
            li.mb-2 Если один из аватаров получает сколько-то единиц урона, то одновременно с этим он получает столько же единиц ярости.
            li.mb-2 Ярость может быть использована для покупки <em><strong>финтов</strong></em>. Финты могут разово давать незначительные преимущества своему обладателю. Подробнее о действии каждого финта можно прочитать на карточке финта непосредственно в поединке.
            li.mb-2 Купленные финты могут быть использованы только в рамках текущего поединка.
          li.mb-2 Поединок можно начать двумя способами. В первом способе игрок должен выбрать соперника из готового списка, сделав взнос в баллах, равный взносу, его соперника из списка. Во втором способе игрок делает взнос в баллах, встает в очередь и ожидает, когда его выберет соперник, готовый начать поединок с таким же взносом. Взнос может быть равен одной и более баллам.
          li.mb-2 Победителем поединка становится игрок, который быстрее доводит уровень здоровья своего соперника до нуля. Победитель поединка забирает свой взнос и взнос соперника.
          li.mb-2 Каждые сутки игрок может провести 3 бесплатных поединка, т.е. без взносов баллов. Чтобы проводить более трех поединков в сутки, необходимо делать взносы.
          li.mb-2 За каждые 5 побед игрок получает бесплатный голос, который он может отдать в поддержку своего клуба, повышая одновременно фан-рейтинг клуба и свой персональный рейтинг в текущей Битве.
          li.mb-2 В конце каждого месяца подводятся итоги этого Конкурса. Победителем Конкурса становится тот, кто больше всех одержал побед в прошедшем месяце. Если у нескольких участников количество побед одинаковое, то победителем становится тот участник, у которого сумма всех взносов за текущий месяц была больше, а при равных суммах взносов – тот, у которого дата-время последней победы в поединках в прошедшем месяце была самой ранней.
          li.mb-2 Победитель Конкурса получает ценный приз – Сертификат Ozon на 5000 руб., звание «Громила месяца», бонус в 100 баллов и попадает в «Зал Славы», а подсчет новых побед в поединках в начале каждого месяца начинается заново, с нуля.
          li.mb-2 Победители Конкурсов получают призы в своих Личных Кабинетах. Файл сертификата становится доступен для скачивания не позднее, чем через 7 дней с момента окончания Конкурса.




        p
          strong
            em Призы ждут вас! Желаем удачи!

      BitvaTimer.mt-5

</template>

  
  <script>
  
  import { commonMixin } from "../../../shared/mixins"
  import HeaDer from "./../../Common/HeaDer"
  import LeftMenu from "../../Common/LeftMenu"
  import BitvaTimer from "../../Common/BitvaTimer"
  import HeaderSubMenu from "../../Common/HeaderSubMenu"
  import "vue-custom-scrollbar/dist/vueScrollbar.css"
  import { mapGetters } from "vuex"
  
  export default {
    name: 'RazNaRazRules',
    components: {
      HeaDer,
      BitvaTimer,
      HeaderSubMenu,
      LeftMenu
    },
    mixins: [
      commonMixin
    ],
    data () {
      return {
        sub_menu: [
          {
            "title": "Игра",
            "link": "/inside/raznaraz"
          },
          {
            "title": "Рейтинг игроков",
            "link": "/inside/raznaraz-ratings"
          },
          {
            "title": "История игр",
            "link": "/inside/raznaraz-story"
          },
          {
            "title": "Правила игры",
            "link": "/inside/raznaraz-game-rules"
          },
          {
            "title": "Правила конкурса",
            "link": "/inside/raznaraz-rules"
          }
        ]
      }
    },
    computed: {
      ...mapGetters("user_store", {
        voices: "voices",
        coins: "coins",
        my_club: "club",
        club_rating: "club_rating",
        voices_given: "voices_given",
        personal_club_rating: "personal_club_rating",
        personal_rating: "personal_rating",
      })
    },
    mounted () {
    },
    methods: {
    }
  }
  
  </script>
  
  
  <style lang="sass" scoped>
  
  
  </style>
      