<template lang="pug">
// vue-custom-scrollbar#first_screen_common(class="scroll-area")
#first_screen_common.scroll-area
  .content

    logotype
    .slogan
      h2 РАЗ НА РАЗ
    .subslogan
      p Поединок фанатов!
    .definitions_warp
      .definition
        p Побеждай болельщиков других клубов в простом и азартном браузерном файтинге!
      .definition
        p Одерживая победы в поединках, ты помогаешь своему клубу стать фан-чемпионом месяца!
      .definition
        p Одержи больше всего побед в поединках в этом месяце и получи крутой приз!

    PrizeRegisterButtons(@slideTo="slideTo")

  // .down-arrow(@click="slideTo(7)")

</template>


<script>

import Logotype from './Logotype'
import PrizeRegisterButtons from './PrizeRegisterButtons'
import { commonMixin } from "../../shared/mixins"
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  
  name: 'SlideSevenRazNaRaz',
  components: {
    Logotype,
    vueCustomScrollbar,
    PrizeRegisterButtons
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {

    }
  },
  created () {
    // this.championshipClubs()
  },
  mounted () {
    // let context = this
    // let seven_screen_raz_na_raz = document.getElementById("seven_screen_raz_na_raz")
    // let resized_with_scroll_up = document.getElementById("resized_with_scroll_up")
    // let resized_with_scroll_down = document.getElementById("resized_with_scroll_down")
    // wheel_main_slider_calculator(context, seven_screen_raz_na_raz, resized_with_scroll_up, resized_with_scroll_down, "touchmove", 5, 7)
    // wheel_main_slider_calculator(context, seven_screen_raz_na_raz, resized_with_scroll_up, resized_with_scroll_down, "wheel", 5, 7)
  },
  methods: {
    slideTo (slide_num) {
      this.$emit("slideTo", slide_num)
    },
  }
}
  
</script>


<style lang="sass" scoped>

.content
  position: relative
  padding-bottom: 150px
  @media (max-width: 700px)
    padding-bottom: 150px
  .logotype
    opacity: 0

#seven_screen_raz_na_raz
  width: 100%
  height: 100%

.slogan
  margin-top: 2vh
  width: 100%
  display: flex
  justify-content: center
  h2
    font-size: 32px
    color: #fff
    line-height: 41px
    @media (max-width: 700px)
      font-size: 22px
      line-height: 31px

.subslogan
  margin-top: 10px
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: center
  p
    padding: 0 30px
    font-size: 21px
    line-height: 26px
    color: #fff
    font-weight: 300
    margin-bottom: 0
    @media (max-width: 700px)
      font-size: 18px
      line-height: 24px

.definitions_warp
  margin: 4vh auto 0
  width: 100%
  max-width: 1400px
  padding: 0 20px
  display: flex
  flex-wrap: wrap
  align-items: stretch
  justify-content: space-between
  .definition
    width: calc(33.33% - 15px)
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    padding: 20px
    border-radius: 10px
    background-color: rgba(255, 255, 255, 0.9)
    p
      font-size: 21px
      line-height: 33px
      color: #353535
      font-weight: 300
      margin-bottom: 0
      @media (max-width: 700px)
        font-size: 18px
        line-height: 24px
    @media (max-width: 700px)
      width: 100%
      margin-bottom: 20px


.slogan.with_border
  margin: 6vh auto 0
  width: 100%
  max-width: 1400px
  padding: 0 20px
  display: flex
  justify-content: center
  h2
    padding: 20px 50px
    border: 1px solid #fff
    border-radius: 15px
    text-align: center
    color: #fff
    font-weight: 400
    font-size: 27px
    line-height: 33px
    @media (max-width: 700px)
      font-size: 18px
      line-height: 25px
      padding: 10px 30px
.scroll_more
  p
    background-color: rgba(255, 255, 255, 0.7)
    padding: 10px 20px
    color: #bdbdbd
    @media (max-width: 700px)
      width: calc(100% - 40px)

.down-arrow
  border-color: #fff
  &:after
    border-color: #fff

</style>
