<template lang="pug">

.sub_header
  .punct.mr-7(v-for="page in pages" :class="{ 'unactive': path == page.link }")
    a(:href="page.link") {{ page.title }}

</template>


<script>

export default {
  name: 'HeaderSubMenu',
  props: ["pages"],
  data () {
    return {
      path: null
    }
  },
  created () {
    this.path = this.$route.path
  }
}

</script>


<style lang="sass" scoped>

.sub_header
  min-height: 45px
  border-bottom: 1px dotted #DDDDDD
  padding: 10px 0
  display: flex
  justify-content: flex-start
  align-items: center
  flex-wrap: wrap
  .punct
    a
      text-decoration: none
      border-bottom: 1px dashed #393939
      display: inline-block
      line-height: 16px
      &:hover
        border-bottom: 1px dashed transparent
  .punct.unactive
    a
      border-bottom: 1px dashed transparent
      color: #393939 !important
      font-weight: 600
      cursor: default

</style>