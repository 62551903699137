import { render, staticRenderFns } from "./Fight.vue?vue&type=template&id=3176fca7&scoped=true&lang=pug&"
import script from "./Fight.vue?vue&type=script&lang=js&"
export * from "./Fight.vue?vue&type=script&lang=js&"
import style0 from "./Fight.vue?vue&type=style&index=0&id=3176fca7&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3176fca7",
  null
  
)

export default component.exports