<template lang="pug">

.inside_wrapper
  .top_dark_line.white_bg
  LeftMenu(@slideTo="false" :currentSlide="3" :isGlory="true")
  .login_register.white_bg
    a(@click="goTo('/login')") Войти
    span &nbsp;&nbsp;|&nbsp;&nbsp;
    a(@click="goTo('/registration')") Зарегистрироваться
  .inside_inwrap
    .content
      logotype#visible_logotype
      .enter_form_wrap.pt-4
        h2 РЕГИСТРАЦИЯ
        v-form(autocomplete="off" ref="form" v-model="valid" @keyup.native.enter="Submit")
          p.mt-0 Зарегистрируйся и получи бонусный голос!
          v-text-field(
            label="E-mail"
            v-model="form.email"
            solo
            :rules="[emailRules]"
            :error-messages="email_hint"
            @change="CheckEmail"
          )
          p.only_one Для подтверждения регистрации надо будет принять звонок
          v-text-field(
            label="Телефон"
            v-model="form.phone"
            placeholder="+7 ### ###-##-##"
            solo
            :rules="[phoneRules]"
            v-mask="config"
            :error-messages="phone_hint"
            @change="CheckPhone"
          )
          v-text-field(
            label="Псевдоним"
            v-model="form.username"
            solo
            :rules="[usernameRules]"
            :error-messages="username_hint"
            @change="CheckUsername"
          )
          // :rules="[required, counter20]"
          p.only_one Клуб можно выбрать только один раз!
          v-select(
            label="Твой любимый клуб"
            v-model="form.club"
            :items="clubs"
            :rules="[required]"
            return-object
            solo
          )
          v-text-field(
            label="Пароль"
            v-model="form.password"
            solo
            :rules="[required, counter30, min]"
            :type="show ? 'text' : 'password'"
          )
          v-text-field(
            label="Повтори пароль"
            v-model="form.password_repeat"
            solo
            :rules="[required, counter30, min]"
            :type="show ? 'text' : 'password'"
          )
          v-overlay.my_overlay(
            absolute
            :dark="false"
            :light="true"
            color="#ffffff"
            :value="loading"
          )
            v-progress-circular(
              indeterminate
              color="primary"
            )
          
          v-checkbox.i_agree(
            v-model="form.checkbox"
            :rules="[required]"
          )
            template(v-slot:label)
              p.checkbox_label Я прочитал и согласен с «<a @click="agreement = !agreement">Пользовательским соглашением</a>» и «<a @click="confident = !confident">Политикой конфиденциальности</a>».
          v-btn.green_button(
            x-large
            dark
            color="#04841F"
            :disabled="!valid"
            @click="Submit"
          ) Зарегистрироваться
        
      v-dialog(v-model="agreement" width="900")
        v-card
          v-card-title.main_card_title.pt-2.pr-10 ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ <i class="close_icon" @click="agreement = !agreement"></i>
          v-divider
          v-card-text.share_wrap.bp-0.main_card_txt
            v-row.pt-0.pb-2(align="start")
              v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
                AgreementText.pt-4

      v-dialog(v-model="confident" width="900")
        v-card
          v-card-title.main_card_title.pt-2.pr-10 ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ <i class="close_icon" @click="confident = !confident"></i>
          v-divider
          v-card-text.share_wrap.bp-0.main_card_txt
            v-row.pt-0.pb-2(align="start")
              v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
                ConfidentText.pt-4
</template>


<script>

import LeftMenu from "../LandingPage/LeftMenu"
import Logotype from "../LandingPage/Logotype"
import { commonMixin } from "../../shared/mixins"
import { ShortClubs, checkEmailSimple, checkPhone, CheckSimpleUsername, Registration } from "../../api/Registration"
// import { clubs } from "../../service/examples"
import vueCustomScrollbar from "vue-custom-scrollbar"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { tokens } from "vue-the-mask"
import { getAccessTokenSimple } from "../../api/api"
import AgreementText from '@/components/Common/AgreementText'
import ConfidentText from '@/components/Common/ConfidentText'

export default {
  name: 'RegistRation',
  directives: { tokens },
  components: {
    LeftMenu,
    Logotype,
    vueCustomScrollbar,
    AgreementText,
    ConfidentText,
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      form: {
        email: null,
        phone: null,
        username: null,
        club: null,
        password: null,
        ref_link: "qwerty",
        agreement: false,
        checkbox: false,
        password_repeat: null
      },
      is_auth: false,
      valid: true,
      phone_is_free: true,
      email_is_free: true,
      username_is_free: true,
      loading: false,
      clubs: [],
      show: false,
      mask: "+7 (###) ###-##-##",
      masked: false,
      agreement: false,
      confident: false,
      email_hint: [],
      phone_hint: [],
      username_hint: [],
      tokens: {
        '#': {pattern: /[0-9]/, transform: v => v.toLocaleUpperCase()},
      },
    }
  },
  computed: {
    config () {
      return {
        mask: this.mask,
        tokens: this.tokens,
        masked: this.masked
      }
    },
  },
  created () {
    if (localStorage.getItem("ref_link")) {
      this.form.ref_link = localStorage.getItem("ref_link")
    }
    this.ShortClubs()
    // this.clubs = clubs
  },
  async mounted () {
    await this.checkIsAuth()
    console.log("1".split(".")[1])
  },
  methods: {
    async checkIsAuth () {
      let access = await getAccessTokenSimple()
      if (access) {
        this.is_auth = true
        this.$router.replace("/inside/personal")
      }
    },
    async Submit () {
      this.$refs.form.validate()
      console.log(this.valid)
      console.log(this.$refs.form.validate())
      if (this.valid) {
        if (this.phone_is_free && this.email_is_free) {
          this.loading = true
          let context = this
          let then = function () {
            context.loading = false
          }
          let after_confirm = async function () {
            let result = await Registration(context.form, context, then)
            if (result) {
              if (result["user_id"]) {
                context.$router.push("/confirm-phone/" + result["user_id"])
                context.loading = false
              }
            }
          }
          // this.$swal({
          //   icon: "warning",
          //   text: "Убедись, что номер телефона введён правильно! После нажатия на кнопку «Продолжить», тебе позвонит робот и продиктует 4-х значный номер для подтверждения регистрации.",
          //   showConfirmButton: true,
          //   showDenyButton: true,
          //   confirmButtonText: "Продолжить",
          //   denyButtonText: "Перепроверить"
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     after_confirm()
          //   } else {
          //     context.loading = false
          //   }
          // })
          after_confirm()
        } else {
          if (!this.phone_is_free) {
            this.phone_hint.push("Пользователь с таким E-mail уже зарегистрирован")
          }
          if (!this.email_is_free) {
            this.email_hint.push("Пользователь с таким телефоном уже зарегистрирован")
          }
        }
      }
    },
    async CheckEmail () {
      let context = this
      if (this.form.email) {
        if (this.emailRules(this.form.email) === true) {
          let data = {
            "email": this.form.email
          }
          let result = await checkEmailSimple(data, context, null, "Некорректно введён Email")
          if (result) {
            if (result["result"] != "ok") {
              this.email_hint.push("Пользователь с таким адресом уже зарегистрирован")
            } else {
              this.email_is_free = true
              this.email_hint = []
            }
          }
        }
      }
    },
    async CheckPhone () {
      let context = this
      if (this.form.phone) {
        if (this.phoneRules(this.form.phone) === true) {
          let data = {
            "phone": this.form.phone
          }
          let result = await checkPhone(data, context, null, "Некорректно введён телефон")
          if (result) {
            if (result["result"] != "ok") {
              this.phone_hint.push("Пользователь с таким телефоном уже зарегистрирован")
            } else {
              this.phone_is_free = true
              this.phone_hint = []
            }
          }
        }
      }
    },
    async CheckUsername () {
      let context = this
      if (this.form.username) {
        let data = {
          "username": this.form.username
        }
        let result = await CheckSimpleUsername(data, context, null, "Некорректно введён Псевдоним")
        if (result) {
          if (result["result"] != "ok") {
            this.username_hint.push("Пользователь с таким псевдонимом уже зарегистрирован")
          } else {
            this.username_is_free = true
            this.username_hint = []
          }
        }
      }
    },
    async ShortClubs () {
      let context = this
      this.clubs = await ShortClubs(context)
    },
    required (v) {
      return !!v || 'Обязательное поле.'
    },
    counter50 (v) {
      if (v) {
        return v.length <= 50 || 'Максимум 50 символов'
      }
      return true
    },
    counter20 (v) {
      if (v) {
        return v.length <= 20 || 'Максимум 20 символов'
      }
      return true
    },
    min (v) {
      if (v) {
        return v.length >= 8 || 'Минимум 8 символов'
      }
      return true
    },
    phoneRules (v) {
      if (v) {
        console.log(v.length)
        if (v.length > 20) {
          this.phone_hint = []
          return 'Максимум 20 символов'
        }
        if (v.length < 18) {
          this.phone_hint = []
          return 'Минимум 18 символов'
        }
      } else {
        this.phone_hint = []
        return 'Обязательное поле.'
      }
      return true
    },
    usernameRules (v) {
      if (v) {
        // let pattern = /^(?!\s)(?!.*\s\s)(?<!\s)$/
        // let pattern = /^(?! )(?!.* $)(?!(?:.* ){2}).*$/
        let pattern = /^(?! )(?!.* $)(?!.*\s\s).*$/
        if (v.length > 30) {
          this.username_hint = []
          return 'Максимум 30 символов'
        }
        if (v.length < 3) {
          this.username_hint = []
          return 'Минимум 3 символа'
        }
        if (!pattern.test(v)) {
          this.email_hint = []
          return 'Некорректный псевдоним.'
        }
      } else {
        this.username_hint = []
        return 'Обязательное поле.'
      }
      return true
    },
    emailRules (v) {
      if (v) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (v.length > 50) {
          this.email_hint = []
          return 'Максимум 50 символов'
        }
        if (!pattern.test(v)) {
          this.email_hint = []
          return 'Некорректный E-mail.'
        }
      } else {
        this.email_hint = []
        return 'Обязательное поле.'
      }
      return true
    }
  }
}

</script>


<style lang="sass" scoped>

.i_agree
  margin: 0
  padding: 0
  .checkbox_label
    font-size: 12px
    margin: 0
    padding: 0

.only_one
  font-size: 14px
  line-height: 12px
  color: #bfbfbf
  margin: 0 0 7px
  padding: 0
  text-align: left

.top_dark_line
  @media (max-width: 1400px)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 60px
    z-index: 40
    background: linear-gradient(#000, #00000000)
  
.top_dark_line.white_bg
  @media (max-width: 1400px)
    background: linear-gradient(#fff, #ffffff00)
    
.inside_wrapper
  .login_register
    position: absolute !important
    background: none !important
    top: 20px
    right: 20px !important
    z-index: 998
    @media (max-width: 700px)
      right: 10px
      top: 10px
    span
      color: #fff !important
      font-size: 16px
    a
      color: #fff !important
      font-size: 16px
      &:hover
        text-decoration: underline
    span, a
      @media (max-width: 700px)
        font-size: 14px

.login_register.white_bg
  span, a
    color: #000 !important

.green_button
  width: 100%

</style>
    