<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ПОДЕЛИСЬ И ВЫИГРАЙ")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      .subcontent_wrap.mt-5
        // h3.mb-2 Первая двадцатка лидеров
        p Конкурс считается состоявшимся, если в течении Битвы по ссылке лидера зарегистрируется не менее 5 новых болельщиков.
        div(v-if="my_fans")
          //- p.mt-2.mb-0 Ты привёл {{ my_fans }} {{ word_num_cases(my_fans, "болельщика", "болельщиков", "болельщиков") }}; ты на {{ my_place }} месте.
          p.mb-1 По твоей ссылке {{ word_num_cases(my_fans, "зарегистрировался", "зарегистрировалось", "зарегистрировалось") }}: {{ my_fans }} человек
          p Ты занимаешь: {{ my_place }} место
    
      .subcontent_wrap
        table.mt-4
          tr.table_header
            th.place
              img(src="~@/assets/icons_and_logos/solar_cup-star-bold.svg")
            th.clubname Псевдоним
            th.rating
              .flex <span>Кол-во фанатов</span>
          tr(v-for="(person, index) in persons" :class="{'odd': index % 2}")
            td.place {{ (20 * (page_num - 1)) + index + 1 }}
            td.clubname {{ person.username }}
            // td.rating(v-if="person.votes < 5") —
            // td.rating(v-else) {{ person.votes }}
            td.rating {{ person.rating }}
  
        .notification_pagination.mt-5
          span.paginate_page(
            v-if="page_num != 1"
            @click="reducePage"
          ) Предыдущая страница
          span.between(v-if="page_num != 1")
          span.paginate_page(
            v-if="page_num != pages_count"
            @click="increasePage"
          ) Следующая страница
        //-   img.left(
        //-     :class="{'unactive': page_num == 1}"
        //-     src="../../../assets/icons_and_logos/paginate-arrow-left.svg"
        //-     @click="reducePage"
        //-   )
        //-   span Страница <strong>{{ page_num }}</strong> из <strong>{{ pages_count }}</strong>
        //-   img.right(
        //-     :class="{'unactive': page_num == pages_count}"
        //-     src="../../../assets/icons_and_logos/paginate-arrow-right.svg"
        //-     @click="increasePage"
        //-   )



      BitvaTimer.border-bottom.mt-6
</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { GetSharingRatings } from "./../../../api/Inside"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'ShareRatings',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      page_num: 1,
      user_count: null,
      my_fans: null,
      my_place: null,
      pages_count: 4,
      persons: [],
      // persons: [
        // {
          // "id": "1",
          // "username": "Иван Константинопольский забухайло",
          // "votes": "100",
          // "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
          // "club": {
            // "fc_id": 1,
            // "name": "string",
            // "description": "string",
            // "picture": null
          // }
        // },
        // {
          // "id": "2",
          // "username": "Пётр",
          // "votes": "2",
          // "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
          // "club": {
            // "fc_id": 2,
            // "name": "string",
            // "description": "string",
            // "picture": null
          // }
        // }
      // ],
      sub_menu: [
        {
          "title": "Реферальная ссылка",
          "link": "/inside/share-win"
        },
        {
          "title": "Рейтинг участников",
          "link": "/inside/sharewin-progress"
        },
        {
          "title": "Правила",
          "link": "/inside/sharewin-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
    this.GetSharingRatings(1)
  },
  methods: {
    async GetSharingRatings (page) {
      let context = this
      let result = await GetSharingRatings(context, page)
      if (result) {
        this.persons = result["data"]["ratings"]
        this.page_num = result["data"]["page_num"]
        this.pages_count = result["data"]["pages_count"]
        this.my_fans = result["data"]["my_fans"]
        this.my_place = result["data"]["my_place"]
      }
    },
    reducePage () {
      if (this.page_num != 1) {
        this.page_num -= 1
        this.GetSharingRatings(this.page_num)
      }
    },
    increasePage () {
      if (this.page_num != this.pages_count) {
        this.page_num += 1
        this.GetSharingRatings(this.page_num)
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.green_button
  margin-top: 10px

p.note
  font-size: 14px

.border-bottom
  border-bottom: 1px dotted #DDDDDD

table, th, td
  border: 1px solid white
  border-collapse: collapse
table
  table-layout: auto
  width: 100%
  border: 0
  margin-top: 10px
  .table_header
    width: 100%
    border-top-right-radius: 5px
    border-top-left-radius: 5px
    th
      background-color: #0D1D27
      color: #fff
      font-weight: 400
      font-size: 14px
      height: 43px
      padding: 0 5px
      line-height: 43px
      border-top: 0
      @media (max-width: 550px)
        font-size: 12px
    th.place
      width: 44px
      text-align: center
      padding: 0
      border-left: 0
      border-top-left-radius: 5px
      img
        position: relative
        top: 6px
    th.rating
      width: 85px
      border-right: 0
      border-top-right-radius: 5px
      .flex
        width: 100%
        height: 100%
        display: flex
        align-items: center
        span
          display: inline-block
          line-height: 18px
      @media (max-width: 550px)
        width: 62px
  tr
    td
      text-align: left
      font-weight: 400
      font-size: 16px
      padding: 0 5px
      height: 37px
      line-height: 37px
      @media (max-width: 550px)
        font-size: 14px
    td.place
      text-align: center
      padding: 0
      font-weight: 600
      font-size: 16px
      border-left: 0
      background-color: #fff
    td.clubname
      background-color: #fff
      padding-left: 5px
      line-height: 20px
      .flex
        display: flex
        width: 100%
        justify-content: space-between
        align-items: center
        padding: 5px 0 7px
        .green_button
          margin: 0
          @media (max-width: 550px)
            display: none
        .sub_flex
          display: flex
          justify-content: flex-start
          align-items: center
          line-height: 20px
          .img_wrap
            min-width: 45px
            display: flex
            justify-content: center
            align-items: center
            @media (max-width: 550px)
              min-width: 40px
            img
              max-height: 40px
              min-width: 40px
              @media (max-width: 550px)
                max-height: 35px
    td.rating
      border-right: 0
      background-color: #fff
  tr.odd
    td.place, td.rating
      background-color: #EDEDED
    td.clubname
      background-color: #EDEDED

</style>
    