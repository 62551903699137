<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ФАН-ЛИДЕР КЛУБА")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7
        p.mb-2 В этом конкурсе определяется, кто из фанатов твоего клуба отдал наибольшее количество голосов за эту «Битву».
        p Конкурс считается состоявшимся, если за твой клуб проголосует минимум <span v-if="club.league == 1">500</span><span v-else>250</span> болельщиков.
        .pos_r
          // img.blue_prize.mt-8(src="../../../assets/icons_and_logos/prize_blue.svg")
          // div.mb-0.p_image_prize 
          h3.mb-3 Если твой клуб стал победителем в «Битве»
          p.mb-2 Участник, отдавший наибольшее количество голосов за твой клуб, получит звание «Фан-лидер клуба победителя», 
            | Сертификат Ozon на 14 000 руб., 100 баллов и попадёт в «<a href="/glory-hall">Зал Славы</a>»!
          p.mb-2 Участники, занявшие места со второго по десятое, получают по 100 баллов.
          p Каждый участник, голосовавший за свой клуб-победитель, получает по 25 баллов.

        .pos_r.mt-5
          // img.blue_prize.mt-8(src="../../../assets/icons_and_logos/prize_blue.svg")
          // div.mb-0.p_image_prize 
          h3.mb-3 Если твой клуб не стал победителем в «Битве»
          p.mb-2 Участник, отдавший наибольшее количество голосов за твой клуб, получит звание «Фан-лидер клуба» и 100 баллов.
          p Участники, занявшие места со второго по десятое, получат по 50 баллов.

      BitvaTimer.border-bottom.mt-6

      .subcontent_wrap
        div(v-if="voices_given")
          h3 Твой рейтинг:
          p.mt-2.mb-0 Ты занимаешь {{ personal_club_rating }} место ({{ voices_given }} {{ word_num_cases(voices_given, "голос", "голоса", "голосов") }}).

        div(v-else)
          h3.mt-5 Ты ещё не отдал ни одного голоса в этой Битве

        v-btn.green_button(
          v-if="voices"
          x-large
          dark
          color="#04841F"
          @click="Vote"
        ) Отдать голос
        v-btn.green_button(
          v-else
          x-large
          dark
          color="#04841F"
          @click="goTo('/inside/shop')"
        ) Получить ещё голос

      .subcontent_wrap
        h3 Турнирная таблица фан-лидеров клуба «{{ club.name }}» в текущей Битве
        table.mt-4
          tr.table_header
            th.place
              img(src="~@/assets/icons_and_logos/solar_cup-star-bold.svg")
            th.clubname Псевдоним
            //- th.rating Голоса
          tr(v-for="(person, index) in persons" :class="{'odd': index % 2}")
            td.place {{ index + 1 }}
            td.clubname {{ person.username }}
            //- v-btn.green_button(
            //-   v-if="voices && person.id == my_club.id"
            //-   x-large
            //-   dark
            //-   color="#04841F"
            //-   @click="Vote"
            //- ) Отдать голос
            //- v-btn.green_button(
            //-   v-else-if="person.id == my_club.id"
            //-   x-large
            //-   dark
            //-   color="#04841F"
            //-   @click="goTo('/inside/shop')"
            //- ) Купить голос
            //- td.rating {{ person.votes }}
  
        .notification_pagination.mt-5
          //- img.left(
          //-   :class="{'unactive': page_num == 1}"
          //-   src="../../../assets/icons_and_logos/paginate-arrow-left.svg"
          //-   @click="reducePage"
          //- )
          //- span Страница <strong>{{ page_num }}</strong> из <strong>{{ pages_count }}</strong>
          //- img.right(
          //-   :class="{'unactive': page_num == pages_count}"
          //-   src="../../../assets/icons_and_logos/paginate-arrow-right.svg"
          //-   @click="increasePage"
          //- )
          span.paginate_page(
            v-if="page_num != 1"
            @click="reducePage"
          ) Предыдущая страница
          span.between(v-if="page_num != 1")
          span.paginate_page(
            v-if="page_num != pages_count"
            @click="increasePage"
          ) Следующая страница
    
        p.note.mt-5 Если у болельщиков совпадает количество отданных голосов, выше в рейтинге
          |  оказывается тот, кто раньше отдал свой последний голос.
        
</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { championshipClubPersons } from "../../../api/Inside"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'BitvaPersonalClub',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      page_num: 1,
      pages_count: 4,
      persons: [
        {
          "id": "1",
          "username": "Иван Константинопольский забухайло",
          "votes": "100",
          "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
          "club": {
            "fc_id": 1,
            "name": "string",
            "description": "string",
            "picture": null
          }
        },
        {
          "id": "2",
          "username": "Пётр",
          "votes": "2",
          "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
          "club": {
            "fc_id": 2,
            "name": "string",
            "description": "string",
            "picture": null
          }
        }
      ],
      sub_menu: [
        {
          "title": "Рейтинг участников",
          "link": "/inside/fight-club"
        },
        {
          "title": "Правила",
          "link": "/inside/fight-club-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      club: "club",
      club_rating: "club_rating",
      voices_given: "voice_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
    this.championshipClubPersons(1)
  },
  methods: {
    async championshipClubPersons (page) {
      let context = this
      let result = await championshipClubPersons(context, page)
      console.log(result)
      if (result) {
        this.persons = result["data"]["champ_club_members"]
        this.page_num = result["data"]["page_num"]
        this.pages_count = result["data"]["pages_count"]
      }
    },
    reducePage () {
      if (this.page_num != 1) {
        this.page_num -= 1
        this.championshipClubPersons(this.page_num)
      }
    },
    increasePage () {
      if (this.page_num != this.pages_count) {
        this.page_num += 1
        this.championshipClubPersons(this.page_num)
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.pos_r
  position: relative

.blue_prize
  position: absolute
  top: 0
  left: 0
  width: 60px

.p_image_prize
  padding: 0 0 0 80px

.green_button
  margin-top: 10px

p.note
  font-size: 14px

.border-bottom
  border-bottom: 1px dotted #DDDDDD

table, th, td
  border: 1px solid white
  border-collapse: collapse
table
  table-layout: auto
  width: 100%
  border: 0
  margin-top: 10px
  .table_header
    width: 100%
    border-top-right-radius: 5px
    border-top-left-radius: 5px
    th
      background-color: #0D1D27
      color: #fff
      font-weight: 400
      font-size: 14px
      height: 43px
      padding: 0 5px
      line-height: 43px
      border-top: 0
      @media (max-width: 550px)
        font-size: 12px
    th.place
      width: 44px
      text-align: center
      padding: 0
      border-left: 0
      border-top-left-radius: 5px
      img
        position: relative
        top: 6px
    th.rating, th.clubname
      width: 85px
      border-right: 0
      border-top-right-radius: 5px
      @media (max-width: 550px)
        width: 62px
  tr
    td
      text-align: left
      font-weight: 400
      font-size: 16px
      padding: 0 5px
      height: 37px
      line-height: 37px
      @media (max-width: 550px)
        font-size: 14px
    td.place
      text-align: center
      padding: 0
      font-weight: 600
      font-size: 16px
      border-left: 0
      background-color: #fff
    td.clubname
      background-color: #fff
      padding-left: 5px
      line-height: 20px
      .flex
        display: flex
        width: 100%
        justify-content: space-between
        align-items: center
        padding: 5px 0 7px
        .green_button
          margin: 0
          @media (max-width: 550px)
            display: none
        .sub_flex
          display: flex
          justify-content: flex-start
          align-items: center
          line-height: 20px
          .img_wrap
            min-width: 45px
            display: flex
            justify-content: center
            align-items: center
            @media (max-width: 550px)
              min-width: 40px
            img
              max-height: 40px
              min-width: 40px
              @media (max-width: 550px)
                max-height: 35px
    td.rating
      border-right: 0
      background-color: #fff
  tr.odd
    td.place, td.rating
      background-color: #EDEDED
    td.clubname
      background-color: #EDEDED

</style>
    