<template lang="pug">
div
  .punkt
    a(href="/") Главная

  .punkt(:class="{ 'unactive': links_check(path, fight_links) }")
    a(href="/inside/fight") Битва <span class="small_punct_text">(Фан-рейтинги клубов)</span>

  .punkt(:class="{ 'unactive': links_check(path, fight_common_links) }")
    a(href="/inside/fight-common") Герой Битвы
  
  .punkt(:class="{ 'unactive': links_check(path, fight_club_links) }")
    a(href="/inside/fight-club") Фан-Лидер клуба

  .punkt(:class="{ 'unactive': links_check(path, dreamteam_links) }")
    a(href="/inside/dreamteam") Собери «Команду мечты»

  .punkt(:class="{ 'unactive': links_check(path, share_links) }")
    a(href="/inside/share-win") Поделись и выиграй

  .punkt(:class="{ 'unactive': links_check(path, stamps_links) }")
    a(href="/inside/stamps") Коллекция марок

  .punkt(:class="{ 'unactive': links_check(path, raznaraz_links) }")
    a(href="/inside/raznaraz") Поединки «Раз на раз»

  .punkt(:class="{ 'unactive': links_check(path, ball_links) }")
    a(href="/inside/ball") Игра «Добыча баллов»

  .punkt(:class="{ 'unactive': links_check(path, match3_links) }")
    a(href="/inside/match3") Игра «Добыча голосов»

  //- .punkt(:class="{ 'unactive': path == '/inside/ratings' }")
    //- a(href="/inside/ratings") Рейтинги
  .punkt(:class="{ 'unactive': links_check(path, prizes_links) }")
    a(href="/inside/prizes") Призы

  .punkt
    a(href="/glory-hall") Зал Славы

  .punkt(:class="{ 'unactive': links_check(path, shop_links) }")
    a(href="/inside/shop") Магазин

  .punkt(:class="{ 'unactive': links_check(path, personal_links) }")
    a(href="/inside/personal") Личный кабинет
  // .punkt
  //   a(href="/") FAQ

  .my_club_logo(:class="{ 'is_main': is_main }")
    // img(src="../../assets/icons_and_logos/Krylia_Sovetov_logo.svg")
    p.club_name Ты болеешь за «{{ club_name }}»

  .coins_votes_wrap
    .left
      .img
        img(src="../../assets/icons_and_logos/game-icons_two-coins.svg")
      .title Баллы:
    .right
      span.count {{ coins }}
      span.title шт.
  
  .coins_votes_wrap
    .left
      .img
        img(src="../../assets/icons_and_logos/votes_icon.svg")
      .title Голоса:
    .right
      span.count {{ voices }}
      span.title шт.

  v-btn.green_button(
    v-if="voices"
    x-large
    dark
    color="#04841F"
    @click="Vote"
  ) Отдать голос
  v-btn.green_button(
    v-else
    x-large
    dark
    color="#04841F"
    @click="goTo('/inside/shop')"
  ) Получить ещё голос

  .voices_given(v-if="voices_given >= 0") Ты отдал <span>{{ voices_given }}</span> {{ word_num_cases(voices_given, "голос", "голоса", "голосов") }} за эту Битву

  .small_punkt
    a(href="/contacts") Контакты
  .small_punkt
    a(@click="onas = !onas") О компании
  .small_punkt
    a(@click="agreement = !agreement") Оферта
  // .small_punkt
  //   a(@click="pay = !pay") Оплата и возврат
  .small_punkt
    a(@click="confident = !confident") Политика конфиденциальности
  
  yandex-share.mt-3(
    :services="['vkontakte','odnoklassniki','telegram','whatsapp', 'moimir']" 
    counter
    data-shape="round"
  )
  .small_punkt.mt-4
    span 2024 © БИТВА ФАНАТОВ®

  // img.logo_grayscale(src="../../assets/icons_and_logos/logo_grayscale.svg" @click="goTo('/')")
  img(src="~@/assets/icons_and_logos/logo_grayscale.svg" @click="goTo('/')" class="logo_grayscale")

  v-dialog(v-model="agreement" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 ОФЕРТА <i class="close_icon" @click="agreement = !agreement"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            AgreementText.pt-4

  v-dialog(v-model="confident" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ <i class="close_icon" @click="confident = !confident"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            ConfidentText.pt-4

  v-dialog(v-model="onas" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 О ПРОЕКТЕ <i class="close_icon" @click="onas = !onas"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            OnasText.pt-4

  v-dialog(v-model="pay" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 ОПЛАТА <i class="close_icon" @click="pay = !pay"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
            PaymentText.pt-4

</template>

<script>

import { commonMixin } from "../../shared/mixins"
import YandexShare from "@cookieseater/vue-yandex-share"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"
import AgreementText from '@/components/Common/AgreementText'
import ConfidentText from '@/components/Common/ConfidentText'
import PaymentText from '@/components/Common/PaymentText'
import OnasText from '@/components/Common/OnasText'
import ContactsText from '@/components/InsidePages/ContactsText'

export default {
  name: 'LeftMenuPuncts',
  components: {
    YandexShare,
    AgreementText,
    ConfidentText,
    OnasText,
    PaymentText,
    ContactsText
  },
  mixins: [
    commonMixin
  ],
  props: [
    "is_main"
  ],
  data () {
    return {
      confident: false,
      agreement: false,
      onas: false,
      pay: false,
      path: null,
      fight_links: ['/inside/fight', '/inside/fight-rules', '/inside/fight-history'],
      fight_club_links: ['/inside/fight-club', '/inside/fight-club-rules'],
      fight_common_links: [ '/inside/fight-common', '/inside/fight-common-rules'],
      dreamteam_links: ['/inside/dreamteam', '/inside/dreamteam-progress', '/inside/dreamteam-rules', '/inside/dreamteam-info'],
      raznaraz_links: ['/inside/raznaraz', '/inside/raznaraz-ratings', '/inside/raznaraz-story', '/inside/raznaraz-rules', '/inside/raznaraz-game-rules'],
      share_links: ['/inside/share-win', '/inside/sharewin-progress', '/inside/sharewin-rules'],
      stamps_links: ['/inside/stamps', '/inside/stamps-winners', '/inside/stamps-rules'],
      ball_links: ['/inside/ball', '/inside/ball-progress', '/inside/ball-rules'],
      match3_links: ['/inside/match3', '/inside/match3-results', '/inside/match3-rules'],
      prizes_links: ['/inside/prizes'],
      shop_links: ['/inside/shop', '/inside/purchases'],
      personal_links: ['/inside/personal', '/inside/my-wins', '/inside/my-data']
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      club: "club",
      user_id: "user_id",
      voices_given: "voice_given"
    }),
    club_name: function () {
      if (this.club) {
        return this.club.name + " (" + this.club.city + ")"
      }
      return null
    },
    club_id: function () {
      if (this.club) {
        return this.club.fc_id
      }
      return null
    }
  },
  created () {
    this.path = this.$route.path
    if (!this.user_id) {
      this.load()
    }
  },
  methods: {
    links_check (path, links) {
      if (links.includes(path)) {
        return true
      }
      return false
    },
    async load() {
      // let context = this
      let then = function () {
        // context.$router.push("/login")
        // context.LogOut()
      }
      let result = await this.loadUserData(then)
      if (result) {
        this.checkRedirectIsNeed()
      }
    },
  }
}
</script>

<style lang="sass" scoped>



.small_punkt
  a
    color: #2c3e50 !important
    &:hover
      text-decoration: none

.burger_button
  position: absolute
  top: 0
  left: 10px
  z-index: 1001
  cursor: pointer
  display: none
  img
    position: relative
    top: 5px
  @media (max-width: 1050px)
    display: block

// .left_col_podlozhka
//   width: 100%
//   height: 100%
//   background-color: #fff
//   padding: 30px 25px 20px 20px
//   box-shadow: 0px 0px 10px 0px #00000021
//   text-align: left
.punkt
  display: block
  position: relative
  margin-bottom: 12px
  .small_punct_text
    font-size: 16px
    font-weight: 300
  a
    color: #2c3e50 !important
    font-size: 20px
    line-height: 20px
    display: inline-block
    text-decoration: none
    border-bottom: 1px dashed transparent
    &:hover
      border-bottom: 1px dashed #2c3e50
.punkt.unactive
  .small_punct_text
    font-size: 16px
    font-weight: 400
  a
    color: #393939 !important
    border-bottom: 1px dashed transparent
    cursor: default
    position: relative
    font-weight: 600
    padding-left: 15px
    &:after
      content: ""
      display: block
      width: 0
      height: 0
      position: absolute
      top: 6px
      left: 0
      border-top: 5px solid transparent
      border-bottom: 5px solid transparent
      border-left: 7px solid #393939

.my_club_logo
  width: 100%
  height: 70px
  display: flex
  align-items: center
  justify-content: flex-start
  background-color: #F0F0F0
  position: relative
  margin-top: 30px
  margin-bottom: 30px
  &:after, &:before
    content: ""
    display: block
    background-color: #F0F0F0
    position: absolute
    height: 70px
    top: 0
    z-index: 1
  &:after
    left: -20px
    width: 20px
  &:before
    right: -25px
    width: 25px
  img
    position: relative
    left: -5px
    z-index: 2
  p.club_name
    font-size: 16px
    line-height: 20px
    font-weight: 300
    margin: 0
    padding: 0
.my_club_logo.is_main
  padding: 0 10px !important
.coins_votes_wrap
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  height: 54px
  position: relative
  background-color: #F5F5F5
  border-radius: 10px
  padding-left: 10px
  margin-bottom: 15px
  .left
    width: 50%
    height: 54px
    display: flex
    justify-content: flex-start
    align-items: center
    .img
      width: 34px
      margin-right: 10px
    .title
      font-size: 16px !important
      font-weight: 300
  .right
    width: 50%
    height: 54px
    background-color: #CFCFCF
    display: flex
    justify-content: flex-start
    align-items: center
    border-top-right-radius: 10px
    border-bottom-right-radius: 10px
    padding-left: 10px
    span
      line-height: 20px !important
    .count
      font-size: 20px !important
      font-weight: 600
    .title
      font-size: 16px !important
      font-weight: 300
      margin-left: 5px
      position: relative
      top: 1px
.green_button
  width: 100%
  margin-bottom: 15px
.voices_given
  font-size: 14px
  font-weight: 300
  line-height: 20px
  margin-bottom: 20px
  span
    font-size: 16px
    font-weight: 600

.logo_grayscale
  cursor: pointer


</style>