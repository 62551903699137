<template lang="pug">

.inside_wrapper
  .top_dark_line.white_bg
  LeftMenu(@slideTo="false" :currentSlide="3" :isGlory="true")
  .login_register.white_bg
    a(@click="goTo('/login')") Войти
    span &nbsp;&nbsp;|&nbsp;&nbsp;
    a(@click="goTo('/registration')") Регистрация
  vue-custom-scrollbar.inside_inwrap
    .content
      logotype#visible_logotype
      .enter_form_wrap
        h2 НОВЫЙ ПАРОЛЬ
        v-form(autocomplete="off" ref="form" v-model="valid")
          v-text-field(
            label="Придумай новый пароль"
            v-model="form.password"
            solo
            :rules="[required, counter30, min]"
            :type="show ? 'text' : 'password'"
          )
          v-text-field(
            label="Повтори пароль"
            v-model="form.password_repeat"
            solo
            :rules="[required, counter30, min]"
            :type="show ? 'text' : 'password'"
          )
          v-overlay.my_overlay(
            absolute
            :dark="false"
            :light="true"
            color="#ffffff"
            :value="loading"
          )
            v-progress-circular(
              indeterminate
              color="primary"
            )
          v-btn.green_button(
            x-large
            dark
            color="#04841F"
            @click="Submit"
          ) Сохранить новый пароль
</template>


<script>

import Vue from "vue"
import LeftMenu from "../LandingPage/LeftMenu"
import Logotype from "../LandingPage/Logotype"
import { commonMixin } from "../../shared/mixins"
import { Check, NewPassword } from "./../../api/SignIn"
import vueCustomScrollbar from "vue-custom-scrollbar"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { tokens } from "vue-the-mask"
import { getAccessTokenSimple } from "../../api/api"

export default {
  name: 'SignIn',
  directives: { tokens },
  components: {
    LeftMenu,
    Logotype,
    vueCustomScrollbar,
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      form: {
        password: null,
        password_repeat: null,
      },
      is_auth: false,
      code: null,
      valid: true,
      loading: false,
      show: false,
      masked: false,
      tokens: {
        '#': {pattern: /[0-9]/, transform: v => v.toLocaleUpperCase()},
      },
    }
  },
  computed: {
    config () {
      return {
        tokens: this.tokens,
      }
    },
  },
  created () {
    this.code = this.$route.params.temp_id
    this.CheckIt()
  },
  async mounted () {
    await this.checkIsAuth()
  },
  methods: {
    async checkIsAuth () {
      let access = await getAccessTokenSimple()
      if (access) {
        this.is_auth = true
        this.$router.replace("/inside/personal")
      }
    },
    async CheckIt () {
      let result = await Check({ "tempId": this.code }, this, null)
      if (result) {
        if (!result["result"] || result["result"] == "not ok") {
          Vue.$swal({
            "icon": "error",
            "text": 'Эта ссылка не активна',
          }).then(() => {
            this.$router.push("/login")
          })
        }
      }
    },
    async Submit () {
      this.$refs.form.validate()
      console.log(this.valid)
      console.log(this.$refs.form.validate())
      if (this.valid) {
        this.loading = true
        let context = this
        let then = function () {
          context.loading = false
        }
        let result = await NewPassword(this.code, this.form, context, then)
        console.log(result)
        if (result) {
          if (result["result"]) {
            this.$swal({
              "icon": "success",
              "text": 'Новый пароль успешно сохранён. Теперь можно залогиниться',
            }).then(() => {
              this.$router.push("/login")
            })
          }
        }
      }
    },
  }
}

</script>


<style lang="sass" scoped>

.only_one
  font-size: 14px
  line-height: 12px
  color: #bfbfbf
  margin: 0 0 7px
  padding: 0
  text-align: left

.top_dark_line
  @media (max-width: 1400px)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 60px
    z-index: 40
    background: linear-gradient(#000, #00000000)
  
.top_dark_line.white_bg
  @media (max-width: 1400px)
    background: linear-gradient(#fff, #ffffff00)
    
.inside_wrapper
  .login_register
    position: absolute !important
    background: none !important
    top: 20px
    right: 20px !important
    z-index: 998
    @media (max-width: 700px)
      right: 10px
      top: 10px
    span
      color: #fff !important
      font-size: 16px
    a
      color: #fff !important
      font-size: 16px
      &:hover
        text-decoration: underline
    span, a
      @media (max-width: 700px)
        font-size: 14px

.login_register.white_bg
  span, a
    color: #000 !important

.green_button
  width: 100%


</style>
    