import Vue from "vue";
import { Logout, UserData } from "../api/api"
import { Vote } from "../api/Inside"
import { mapMutations, mapGetters } from "vuex";
import { championshipEnds } from "./../api/LandingPage"
import settings from "../settings"

export let commonMixin = {
  computed: {
    ...mapGetters({
      campionship_ends: "campionship_ends"
    }),
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voice_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    }),
    club_id: function () {
      if (this.my_club) {
        return this.my_club["fc_id"]
      }
      return ""
    },
  },
  methods: {
    ...mapMutations("user_store", {
      update: "update",
    }),
    ...mapMutations({
      updateCommon: "update",
    }),
    checkRedirectIsNeed () {
      if (this.$route.name == 'inside') {
        this.$router.replace("/inside/personal")
      }
    },
    goTo (link) {
      console.log(link)
      this.$router.push(link).catch(()=>{});
    },
    async LogOut () {
      await Logout(this)
      Vue.$cookies.remove("refresh")
      Vue.$cookies.remove("access")
      for (let key in this.$store.state) {
        this.update({ key: key, value: null })
      }
    },
    async loadUserData (then) {
      let data = await UserData(this, then)
      if (data) {
        this.ResultToUserState(data.data)
        return true
      }
    },
    async Vote () {
      let context = this
      let vote_in = async function () {
        if (context.club_id) {
          let result = await Vote({"club_id": context.club_id}, context)
          if (result) {
            context.ResultToUserState(result.data)
            let text = ""
            if (result["data"]["voices_given"] == 2) {
              text = "Ты стал участником конкурса «Команда мечты»"
            }
            if (result["data"]["stamp"] && !result["data"]["card"]) {
              text = text + "\n" + "Ты получил новую марку"
              if (result["data"]["stamps_winner"]) {
                context.$swal({
                  // icon: settings.addres + 'media/' + result["data"]["stamp"]["picture"],
                  imageUrl: settings.addres + 'media/' + result["data"]["stamp"]["picture"],
                  imageWidth: 200,
                  title: "Твой голос засчитан!",
                  text: text,
                }).then(function () {
                  context.$swal({
                    title: "Поздравляем!",
                    text: "Ты одержал победу в конкурсе «Коллекция марок». Твой приз будет доступен в личном кабинете не позднее, чем через семь дней с момента окончания конкурса.",
                  })
                })
              } else {
                context.$swal({
                  // icon: settings.addres + 'media/' + result["data"]["stamp"]["picture"],
                  imageUrl: settings.addres + 'media/' + result["data"]["stamp"]["picture"],
                  imageWidth: 200,
                  title: "Твой голос засчитан!",
                  text: text,
                })
              }
            } else if (result["data"]["stamp"] && result["data"]["card"]) {
              text = text + "\n" + "Ты получил новую марку"
              let text1 = "Ты получил карточку футболиста для конкурса «Команда Мечты»"
              context.$swal({
                // icon: settings.addres + 'media/' + result["data"]["stamp"]["picture"],
                imageUrl: settings.addres + 'media/' + result["data"]["stamp"]["picture"],
                imageWidth: 200,
                title: "Твой голос засчитан!",
                text: text,
              }).then(function () {
                context.$swal({
                  icon: settings.addres + 'media/' + result["data"]["card"]["picture"],
                  title: "Команда мечты",
                  text: text1,
                })
              })
            } else {
              context.$swal({
                icon: "success",
                title: "Твой голос засчитан!",
                text: text,
              })
            }
          }
        }
      }
      if (localStorage.getItem("first_vote")) {
        await vote_in()
      } else {
        let html_txt = `У тебя сейчас ${context.voices} ${context.word_num_cases(context.voices, "голос", "голоса", "голосов")}. Чтобы получть ещё голоса, играй в игру «<a href='/inside/match3'>Добыча голосов</a>», или меняй голоса на баллы в «<a href='/inside/shop'>Магазине</a>»`
        context.$swal({
          icon: "warning",
          title: "Ты можешь отдать неограниченное число голосов!",
          html: html_txt,
        }).then(async function () {
          localStorage.setItem("first_vote", true)
          await vote_in()
        })
      }
    },
    ResultToUserState (data) {
      for (let key in data) {
        if (key in this.$store.state.user_store) {
          this.update({ key: key, value: data[key] })
        }
      }
    },
    async championshipEnds () {
      let context = this
      if (!context.campionship_ends) {
        let data = await championshipEnds(context)
        if (data) {
          this.updateCommon({key: "campionship_ends", value: data["datetime"]})
          return data["datetime"]
        }
      } else {
        return context.campionship_ends
      }
    },
    get_current_month (deadline) {
      let fist_space = deadline.indexOf(" ")
      let month = deadline.substr(0, fist_space)
      if (month == "January") return "Декабрь"
      if (month == "February") return "Январь"
      if (month == "March") return "Февраль"
      if (month == "April") return "Март"
      if (month == "May") return "Апрель"
      if (month == "June") return "Май"
      if (month == "July") return "Июнь"
      if (month == "August") return "Июль"
      if (month == "September") return "Август"
      if (month == "October") return "Сентябрь"
      if (month == "November") return "Октябрь"
      if (month == "December") return "Ноябрь"
    },
    required (v) {
      return !!v || 'Обязательное поле.'
    },
    required6 (v) {
      if (v) {
        return v.length == 6 || 'Код должен содержать 6 символов'
      }
      return true
    },
    counter50 (v) {
      if (v) {
        return v.length <= 50 || 'Максимум 50 символов'
      }
      return true
    },
    counter30 (v) {
      if (v) {
        return v.length <= 30 || 'Максимум 30 символов'
      }
      return true
    },
    counter20 (v) {
      if (v) {
        return v.length <= 20 || 'Максимум 20 символов'
      }
      return true
    },
    min (v) {
      if (v) {
        return v.length >= 8 || 'Минимум 8 символов'
      }
      return true
    },
    emailRules (v) {
      if (v) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (v.length > 50) {
          this.email_hint = []
          return 'Максимум 50 символов'
        }
        if (!pattern.test(v)) {
          this.email_hint = []
          return 'Некорректный E-mail.'
        }
      } else {
        this.email_hint = []
        return 'Обязательное поле.'
      }
      return true
    },
    word_num_cases (quantity, one_wrd, two_four, many) {
      if (quantity || quantity === 0) {
        let string = quantity.toString()
        let last_symb = string[string.length - 1]
        if (quantity > 9 && quantity < 20) {
          return many
        }
        if (last_symb == "1") {
          return one_wrd
        } else if (last_symb == "2" || last_symb == "3" || last_symb == "4") {
          return two_four
        }
        return many
      }
    }
  }
};