<template lang="pug">

vue-custom-scrollbar#glory_hall_wrapper(class="scroll-area")
  // .top_dark_line.white_bg
  LeftMenu(@slideTo="false" :currentSlide="3" :isGlory="true")


  .login_register_icons(v-if="is_auth" :class="{'white_bg': (current_slide == 3 || current_slide == 4 || current_slide == 7)}")
    // a(@click="goTo('/inside/personal')") Личный Кабинет
    HeaDerIcons()
  .login_register.white_bg(v-else :class="{'white_bg': (current_slide == 3 || current_slide == 4 || current_slide == 7)}")
    a(@click="goTo('/login')") Войти
    span &nbsp;&nbsp;|&nbsp;&nbsp;
    a(@click="goTo('/registration')") Регистрация

  //- .login_register
  //-   a(@click="goTo('/login')") Войти
  //-   span &nbsp;&nbsp;|&nbsp;&nbsp;
  //-   a(@click="goTo('/registration')") Регистрация

  #glory_hall_inwrap
    .content
      logotype
      .slogan
        h2 ЗАЛ СЛАВЫ
      .content_field
        v-row(justify="center")
          v-expansion-panels(accordion v-model="panel" v-if="winners.length")
            //- v-expansion-panel(
            //-   v-for="(item, i) in 5"
            //-   :key="i"
            //- )
            v-expansion-panel(
              v-for="win_pack in winners"
            )
              //- v-expansion-panel-header <h3>{{ win_pack.month }}</h3>
              //- v-expansion-panel-content
              //-   .main_winners
              //-     .winner_wrap
              //-       img(:src="win_pack.club.picture")
              //-       strong {{ win_pack.club.name }}
              //-     .winner_wrap
              //-       img(:src="win_pack.winner_champ_club.picture")
              //-       strong {{ win_pack.winner_champ_club.username }}
              //-       span.name Самый активный болельщик победившего клуба
              //-   .other_winners
              //-     .winner_wrap
              //-       img(:src="win_pack.winner_champ_votes.picture")
              //-       strong {{ win_pack.winner_champ_votes.username }}
              //-       span.name Самый активный болельщик всего чемпионата
              //-     .winner_wrap
              //-       img(:src="win_pack.winner_pvp.picture")
              //-       strong {{ win_pack.winner_pvp.username }}
              //-       span.name Самый успешный боец поединков «Раз на Раз»
              //-     .winner_wrap
              //-       img(:src="win_pack.winner_agitator.picture")
              //-       strong {{ win_pack.winner_agitator.username }}
              //-       span.name Самый активный агитатор
              //-     .winner_wrap
              //-       img(:src="win_pack.winner_ball.picture")
              //-       strong {{ win_pack.winner_ball.username }}
              //-       span.name Самый активный игрок в мини-игру «Мячик»
              //-   .hr
              //-   .dreamteam_winners
              //-     h3 КОМАНДА МЕЧТЫ
              //-     .winner_wrap(v-for="winner in win_pack.winners_dream")
              //-       img(:src="winner.picture")
              //-       strong {{ winner.username }}

              v-expansion-panel-header <h3>{{ win_pack.month }}</h3>
              v-expansion-panel-content
                .main_winners
                  .winner_wrap
                    // img(src="~@/assets/icons_and_logos/fc_torpedo_logo.svg")
                    .round_club
                      p
                        strong ФК {{ win_pack.club.name }}
                        em ({{ win_pack.club.city }})
                        span(v-if="win_pack.club.league == 1") РПЛ
                        span(v-else-if="win_pack.club.league == 2") ФНЛ-1
                        span(v-else) 
                    strong Клуб-победитель «Битвы» месяца
                    // span.name Победитель «Битвы фантов» месяца
                  .winner_wrap
                    .picture_wrap
                      img(v-if="win_pack.winner_champ_club.picture" :src="win_pack.winner_champ_club.picture")
                    strong {{ win_pack.winner_champ_club.username }}
                    span.name Самый активный болельщик клуба-победителя в «Битве фанатов» (Фан-лидер клуба победителя)
                .other_winners
                  .winner_wrap(v-if="win_pack.winner_champ_votes")
                    .picture_wrap
                      img(v-if="win_pack.winner_champ_votes.picture" :src="win_pack.winner_champ_votes.picture")
                    strong {{ win_pack.winner_champ_votes.username }}
                    span.name Самый активный болельщик в «Битве фанатов» среди всех клубов (Герой Битвы)
                  .winner_wrap(v-if="win_pack.winner_pvp")
                    .picture_wrap
                      img(v-if="win_pack.winner_pvp.picture" :src="win_pack.winner_pvp.picture")
                    strong {{ win_pack.winner_pvp.username }}
                    span.name Самый успешный боец поединков «Раз на Раз» (Громила месяца)
                  .winner_wrap(v-if="win_pack.winner_agitator")
                    .picture_wrap
                      img(v-if="win_pack.winner_agitator.picture" :src="win_pack.winner_agitator.picture")
                    strong {{ win_pack.winner_agitator.username }}
                    span.name Самый активный агитатор
                  .winner_wrap(v-if="win_pack.winner_ball")
                    .picture_wrap
                      img(v-if="win_pack.winner_ball.picture" :src="win_pack.winner_ball.picture")
                    strong {{ win_pack.winner_ball.username }}
                    span.name Самый активный игрок в мини-игру «Мячик»
                .hr
                .dreamteam_winners(v-if="win_pack.winners_dream_team.length")
                  h3 ПОБЕДИТЕЛИ КОНКУРСА «КОМАНДА МЕЧТЫ»
                  .winner_wrap(v-for="(item, i) in win_pack.winners_dream_team")
                    .picture_wrap
                      img(v-if="item.picture" :src="item.picture")
                    strong {{ item.username }}
                .hr(v-if="win_pack.winners_stamps.length")
                .dreamteam_winners(v-if="win_pack.winners_stamps.length")
                  h3 ПОБЕДИТЕЛИ КОНКУРСА «КОЛЛЕКЦИЯ МАРОК»
                  .winner_wrap(v-for="(item, i) in win_pack.winners_stamps")
                    .picture_wrap
                      img(v-if="item.picture" :src="item.picture")
                    strong {{ item.username }}

          .content_wrap(v-else)
            .subcontent_wrap.mt-5.pb-3
              h3.mt-0.mb-0 Никто ещё не прославился. Ты можешь стать первым!

            
</template>


<script>

import { WinnersMonth } from "./../../api/GloryHall"
import LeftMenu from "../LandingPage/LeftMenu"
import Logotype from "../LandingPage/Logotype"
import { commonMixin } from "../../shared/mixins"
import vueCustomScrollbar from "vue-custom-scrollbar"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import "@mdi/font/css/materialdesignicons.css"
import "@fortawesome/fontawesome-free/css/all.css"
import HeaDerIcons from "../Common/HeaDerIcons"
import { getAccessTokenSimple } from "../../api/api"

export default {
  name: 'GloryHall',
  components: {
    LeftMenu,
    Logotype,
    vueCustomScrollbar,
    HeaDerIcons,
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      panel: 0,
      winners: [],
      is_auth: false,
      current_slide: 3
    }
  },
  async created () {
    await this.WinnersMonth()
    await this.checkIsAuth()
  },
  methods: {
    async WinnersMonth () {
      let context = this
      this.winners = await WinnersMonth(context)
      console.log(this.winners)
    },
    async checkIsAuth () {
      let access = await getAccessTokenSimple()
      if (access) {
        this.is_auth = true
      }
    },
  }
}

</script>


<style lang="sass" scoped>

.login_register_icons
  position: fixed !important
  z-index: 101
  top: 0 !important
  right: 0px
  z-index: 101

.top_dark_line
  @media (max-width: 1400px)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 60px
    z-index: 40
    background: linear-gradient(#000, #00000000)
  
.top_dark_line.white_bg
  @media (max-width: 1400px)
    background: linear-gradient(#fff, #ffffff00)
    

#glory_hall_wrapper
  width: 100%
  height: 100%
  background: url('~@/assets/images/glory_hall_bg.jpg') top center repeat
  background-size: cover
  box-sizing: border-box
  .login_register
    position: absolute
    top: 0px
    right: 20px
    z-index: 998
    @media (max-width: 700px)
      right: 10px
      top: 10px
    span
      color: #000 !important
      font-size: 16px
    a
      color: #000 !important
      font-size: 16px
      &:hover
        text-decoration: underline
    span, a
      @media (max-width: 700px)
        font-size: 14px

.login_register.white_bg
  span, a
    color: #000 !important

.slogan
  margin-top: 2vh
  width: 100%
  display: flex
  justify-content: center
  h2
    font-size: 32px
    color: #241E36
    line-height: 41px
    @media (max-width: 700px)
      font-size: 22px
      line-height: 31px

.content_field
  width: calc(100% - 40px)
  margin: 2vh auto 0
  max-width: 1000px
  min-height: 100px
  // background-color: rgba(255, 255, 255, 0.9)
  border-radius: 10px
  padding: 20px

.v-expansion-panels
  border-radius: 10px !important

.v-expansion-panel-header
  h3
    font-family: Sports World, sans-serif
    font-weight: 400
    font-size: 22px
    text-align: center

.dreamteam_winners, .other_winners, .main_winners
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  justify-content: space-evenly
  padding: 35px 10px 0
.main_winners
  border: 1px solid #241E36
  border-radius: 5px
.dreamteam_winners
  justify-content: space-around
  h3
    width: 100%
    margin-bottom: 30px
    font-weight: 400

.winner_wrap
  width: 135px
  margin-bottom: 30px
  .round_club
    display: flex
    width: 135px
    height: 135px
    border-radius: 70px
    background-color: #ffc198
    align-items: center
    justify-content: center
    line-height: 20px
    color: #000
    padding: 0 4px 0
    p
      margin: 0
      padding: 0
      span, em, strong
        display: block
        margin: 0
        padding: 0
      strong
        font-size: 15px
        font-weight: 500
      em
        font-size: 13px
        font-weight: 400
      span
        font-size: 13px
        font-weight: 400
  .picture_wrap
    display: flex
    align-items: center
    justify-content: center
    width: 135px
    height: 135px
    border-radius: 70px
    overflow: hidden
    background-color: #ffdeb4
    img
      display: block
      width: 100%
  strong
    font-size: 16px
    font-weight: 400
    line-height: 20px
    display: block
    text-align: center
    margin-top: 7px
  .name
    font-size: 10px
    font-weight: 400
    line-height: 13px
    margin-top: 2px
    text-align: center
    display: block

</style>
    