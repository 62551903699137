<template lang="pug">
//vue-custom-scrollbar#first_screen_common(class="scroll-area")
#first_screen_common.scroll-area
  .content
    logotype
    .slogan.mt-12(@click="goTo('/registration')")
      // h2 ГОЛОСУЙ ЗА СВОЙ ФУТБОЛЬНЫЙ КЛУБ<br>ЧТОБЫ СДЕЛАТЬ ЕГО ФАН-ЧЕМПИОНОМ МЕСЯЦА!
      // h2 СДЕЛАЙ СВОЙ ФУТБОЛЬНЫЙ КЛУБ<br>ЧЕМПИОНОМ БИТВЫ ФАНАТОВ!
      img(src='~@/assets/icons_and_logos/slogan2.svg')
    // .subslogan(@click="goTo('/registration')")
    //   p Голосуй за свой любимый клуб!<br><span>Получай призы!</span>

    .fiches_list.mt-16

      //- .ficha
      //-   .ficha_icon.bitva
      //-     img(src='~@/assets/icons_and_logos/winstar.svg')
      //-   .ficha_text
      //-     // p Каждый месяц мы определяем,<br>болельщики какого клуба самые<br>ярые и преданные!
      //-     p Впервые в России<br>определяются фан-рейтинги<br>футбольных клубов
      //-   .ficha_button
      //-     v-btn(
      //-       x-large
      //-       dark
      //-       color="#FF0038"
      //-       @click="slideTo(1)"
      //-     ) Подробнее о Битве

      .ficha
        .ficha_icon.glory
          img(src='~@/assets/icons_and_logos/wincup.svg')
        // .ficha_text#fake_ficha_text
          // p Первый в России Онлайн-Чемпионат среди футбольных болельщиков
          // p Активные болельщики получают<br>денежные призы и попадают<br>в Зал Славы!
          //   p Зал Славы и призы активным болельщикам!
        .ficha_button
          v-btn(
            x-large
            dark
            color="#FF0038"
            href="#prizes_slide"
          ) Сражайся и выигрывай призы!

      .ficha(@click="goTo('/registration')")
        .ficha_icon.join
          img(src='~@/assets/icons_and_logos/victory.svg')
        // .ficha_text
        //   // p Для того, чтобы поддержать свой<br>клуб, просто регистрируйся<br>и голосуй!
        //   p Получи бонусный голос
        .ficha_button
          v-btn(
            x-large
            dark
            color="#FF0038"
            @click="goTo('/registration')"
          ) Регистрация — первый шаг к победе!

      // .ficha
      //   .ficha_icon.prizes
      //     img(src='~@/assets/icons_and_logos/present.svg')
      //   .ficha_text
      //     p Самые крутые призы получают<br>болельщики, отдавшие больше<br>всего голосов!
      //   .ficha_button
      //     v-btn(
      //       x-large
      //       dark
      //       color="#FF0038"
      //       @click="slideTo(7)"
      //     ) Подробнее о призах
    // .subslogan 
    //   p Впервые в России будут определяться фан-рейтинги футбольных клубов

    .slogan.with_border(@click="goTo('/registration')")
      h2 Первый в России онлайн-чемпионат среди футбольных болельщиков!

    // .scroll_more(@click="slideTo(1)")
    //   p Листай дальше, чтобы подробнее узнать о битве
    
    // .down-arrow(@click="slideTo(1)")


</template>


<script>

import Logotype from './Logotype'
import { commonMixin } from "./../../shared/mixins"
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  name: 'SlideOneCommon',
  components: {
    Logotype,
    vueCustomScrollbar
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {

    }
  },
  mounted () {
    // let context = this
    // let first_screen_common = document.getElementById("first_screen_common")
    // let resized_with_scroll_up = document.getElementById("resized_with_scroll_up")
    // let resized_with_scroll_down = document.getElementById("resized_with_scroll_down")
    // wheel_main_slider_calculator(context, first_screen_common, resized_with_scroll_up, resized_with_scroll_down, "touchmove", null, 1)
    // wheel_main_slider_calculator(context, first_screen_common, resized_with_scroll_up, resized_with_scroll_down, "wheel", null, 1)
  },
  methods: {
    slideTo (slide_num) {
      this.$emit("slideTo", slide_num)
    },
  }
}

</script>


<style lang="sass" scoped>

#first_screen_common
  width: 100%
  height: 100%

.content
  position: relative
  padding-bottom: 150px
  @media (max-width: 700px)
    padding-bottom: 100px
  .logotype
    opacity: 0
    @media (max-width: 800px)
      padding-top: 50px
.slogan.mt-12
  margin-top: 2vh
  width: 100%
  display: flex
  justify-content: center
  @media (max-width: 650px)
    margin-top: 0px !important
  img
    width: 50%
    min-width: 300px
    max-width: 800px
  h2
    text-align: center
    color: #fff
    text-shadow: 2px 2px 4px #000
    font-weight: 400
    font-size: 35px
    line-height: 40px
    @media (max-width: 700px)
      font-size: 30px
      line-height: 36px


.slogan.with_border
  margin-top: 4vh
  width: 100%
  display: flex
  justify-content: center
  h2
    padding: 20px 50px
    border: 1px solid #4f4f4f
    border-radius: 15px
    text-align: center
    color: #fff
    text-shadow: 2px 2px 4px #000
    font-weight: 400
    font-size: 27px
    line-height: 33px
    @media (max-width: 700px)
      font-size: 18px
      line-height: 25px
      padding: 10px 30px
      border: 0

.subslogan
  margin-top: 2vh
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: center
  p
    padding: 10px 30px 12px
    background-color: rgba(0, 0, 0, 0.5)
    font-size: 21px
    line-height: 26px
    color: #fff
    font-weight: 300
    margin-bottom: 0
    @media (max-width: 700px)
      font-size: 18px
      line-height: 24px
    span
      display: inline-block
      margin-top: 3px
      font-size: 19px
      color: #cbcbcb

.fiches_list
  margin: 3vh auto 0
  width: calc(100% - 10vw)
  max-width: 1400px
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  justify-content: space-between
  @media (max-width: 650px)
    margin-top: 40px !important
    flex-wrap: wrap-reverse
  .ficha
    margin: 0
    width: calc(47% - 20px)
    @media (max-width: 1400px)
      width: calc(50% - 20px)
    @media (max-width: 650px)
      width: 100%
      margin-bottom: 20px
    .ficha_icon
      display: flex
      align-items: flex-start
      justify-content: center
      height: 110px
      @media (max-width: 700px)
        height: 80px
    .bitva
      height: 94px
      img
        @media (max-width: 700px)
          height: 70px
    .glory
      img
        height: 94px
        @media (max-width: 700px)
          height: 62px
    .join
      img
        height: 96px
        @media (max-width: 700px)
          height: 63px
    .prizes
      img
        @media (max-width: 700px)
          height: 53px
    #fake_ficha_text
      @media (max-width: 650px)
        display: block
    .ficha_text
      margin-top:10px
      background-color: rgba(0, 0, 0, 0.5)
      border-radius: 7px
      padding: 15px 0 1px
      margin-bottom: 10px 
      display: none
      p
        color: #fff
        font-weight: 300
        font-size: 18px
        line-height: 28px
        @media (max-width: 700px)
          font-size: 16px
          line-height: 24px
    .ficha_button
      color: #fff !important
      a
        color: #fff !important
        span
          color: #fff !important
      .v-btn
        width: 100%
        background: linear-gradient(#ff0038, #aa0025)
        @media (max-width: 700px)
          font-size: 13px



</style>
