import { client, errorHandler } from "./api";


export async function WinnersMonth(context) {
  const response = await client.get("glory-hall",
    {}
  ).catch(error => errorHandler(error, context, null))
  if (response) {
    return response.data;
  }
}
