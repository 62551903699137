<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ИГРА «ДОБЫЧА ГОЛОСОВ»")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
              
      .subcontent_wrap.mt-5.pb-3
        h3.mt-0.mb-0 Проходи уровни, чтобы получать голоса!

      .inventory_blocks.mt-5
        .coins_block
          .image_subblock
            img.vote_white(src="../../../assets/icons_and_logos/vote_white.svg")
          .txt_subblock
            strong Очков до голоса:
            p {{ blues }}

        .voices_block
          .image_subblock
            img.armstrong_white(src="../../../assets/icons_and_logos/armstrong_white.svg")
          .strelochka#strelochka(
            v-if="datetime_podhods_1"
            @click="strelochkaActive"
          )
            .strelochka_in
          .txt_subblock(v-if="podhods")
            strong Осталось подходов:
            p <span>{{ podhods }}</span>
              span.plus_one(v-if="datetime_podhods_0") &nbsp;&nbsp;&nbsp;(+1 через {{ datetime_podhods_0 }})
            p.add_timer.ml-5(v-if="datetime_podhods_1")
              span.plus_one (+1 через {{ datetime_podhods_1 }})
            p.add_timer.ml-5(v-if="datetime_podhods_2")
              span.plus_one (+1 через {{ datetime_podhods_2 }})
            p.add_timer.ml-5(v-if="datetime_podhods_3")
              span.plus_one (+1 через {{ datetime_podhods_3 }})
            p.add_timer.ml-5(v-if="datetime_podhods_4")
              span.plus_one (+1 через {{ datetime_podhods_4 }})
          .txt_subblock(v-else)
            strong До подхода осталось:
            p <span>{{ datetime_podhods_0 }}</span>
            p.add_timer.ml-1(v-if="datetime_podhods_1")
              span.plus_one (+1 через {{ datetime_podhods_1 }})
            p.add_timer.ml-1(v-if="datetime_podhods_2")
              span.plus_one (+1 через {{ datetime_podhods_2 }})
            p.add_timer.ml-1(v-if="datetime_podhods_3")
              span.plus_one (+1 через {{ datetime_podhods_3 }})
            p.add_timer.ml-1(v-if="datetime_podhods_4")
              span.plus_one (+1 через {{ datetime_podhods_4 }})
          //- .txt_subblock

      .subcontent_wrap.mt-5
        #start_game_button
          v-btn.green_button(@click="startNewGame" dark large) <strong>Играть&nbsp;</strong> (Уровень {{ level }})
        //- #end_game_button(v-if="orientation") Закончить игру
        //- i#close_icon(v-else v-tippy content="Закончить игру")
        .bonuses_block
          .bonus_before#bonus_0()
            span {{ spinners }}
          .bonus_before#bonus_1()
            span {{ rocket_hor }}
          .bonus_before#bonus_2()
            span {{ rocket_ver }}
          .bonus_before#bonus_3()
            span {{ bombs }}
          .bonus_before#bonus_4()
            span {{ cups }}

      //- .subcontent_wrap.mt-4
      //-   v-expansion-panels(accordion)
      //-     v-expansion-panel
      //-       v-expansion-panel-header 
      //-         .howtoplay <strong>Как играть?</strong>
      //-       v-expansion-panel-content.howtoplay_text
      //-         p «Добыча голосов» — это классическая игра в жанре «три в ряд».
      //-         p Чтобы войти в игру и начать прохождение уровня, необходимо потратить один подход. Подходов всего пять. Каждый из подходов восстанавливается в течение часа.
      //-         p Каждый уровень содержит «цель» <span class="omnumnum mr-5"><span>1</span></span>и ограничение по количеству доступных ходов <span class="omnumnum mr-4"><span>2</span></span> (<em>Рисунок №1</em>). Для прохождения уровня необходимо за указанное количество ходов «уничтожить» целевое количество квадратиков определённого цвета.
      //-         p Для того, чтобы добывать голоса, необходимо «уничтожать» синие квадратики <span class="omnumnum mr-4"><span>3</span></span>. Каждый уровень ты будешь уничтожать, помимо целевых, некоторое количество синих квадратиков. После прохождения уровня количество уничтоженных синих квадратиков сохранится и прибавится к ранее сохраненным в предыдущих уровнях. Если уровень не пройден, то уничтоженные в нём синие квадратики не засчитываются. При накоплении 1000 синих квадратиков тебе автоматически выдается новый «голос» и отсчёт синих квадратиков начинается с нуля. Ты будешь получать «голос» за каждую тысячу синих квадратиков.
      //-         p Чтобы «уничтожать» квадратики, необходимо совершать ходы, ведущие к образованию определённых фигур. Чтобы совершить ход, необходимо выдлелить один из квадратиков, кликнув на него. Далее необходимо кликнуть на любой из соседних квадратиков (по вертикали или по горизонтали), чтобы поменять их местами. Если таким образом на поле образовалась одна из нижеперечисленных фигур одного цвета (<em>Рисунок №2</em>), все квадратики, составляющие эту фигуру, уничтожаются, а сверху на их место «падают» новые. Если в результате хода фигуры не получилось, то ход не засчитывается и выбранные квадратики возвращаются на свои места.
      //-         //- p Фигура может состоять из квадратиков только одного (любого, но одинакового) цвета.
      //-         p За «уничтожение» фигур, состоящих из четырёх и более квадратиков, игроку даются «бустеры» <span class="omnumnum mr-4"><span>4</span></span>. За «уничтожение» линии из четырёх квадратиков даётся «Ракета» (вертикальная или горизонтальная). За «уничтожение» четырёх квадратиков в форме квадрата два на два даётся «Спиннер». За уничтожение линии в пять квадратиков — «Кубок». За уничтожение фигур в форме буквы «Т» и «Г» даётся «Бомба» (<em>Рисунок №2</em>).
      //-         p «Бустер» можно активировать двойным кликом, или поменяв его местами с соседним квадратиком.
      //-         p Активация «Спиннера» «уничтожает» четыре ближайших квадратика вокруг него (по вертикали и по горизонтали). Далее «спиннер» летит к случайному целевому квадратику на поле.
      //-         p Активация «Ракеты» «уничтожает» целую линию квадратиков в зависимости от её направления (вертикальная или горизонтальная).
      //-         p Активация «Бомбы» «уничтожает» целую область (квадрат со стороной в 5 квадратиков) вокруг себя. 25 квадратиков, включая саму бомбу.
      //-         p Активация «Кубка» двойным кликом «уничтожает» все целевые квадратики (одного цвета) на поле. Или, если ты активируешь «Кубок», меняя его с другим квадратиком — «уничтожаются» все элементы цвета этого квадратика.
      //-         p Если один «Бустер» попадает под воздействие другого «Бустера», он также активируется.
      //-         p Бустеры также можно менять местами друг с другом. Подробно ознакомиться с тем, что происходит в этих случаях, предлагаем тебе самостоятельно. Важно лишь сказать, что это, как правило, очень выгодно как для прохождения уровня, так и для накопления синих квадратиков.
      //-         p «Бустеры» и «Подходы» можно покупать за «Баллы» на странице игры. «Баллы» можно купить в <a href="/inside/shop">Магазине</a>.
      //-         p
      //-           em Рисунок №1
      //-           img.pvp_gameplay_img(src="~@/assets/images/match3_gameplay.jpg")
      //-         p 
      //-           em Рисунок №2
      //-           img.pvp_gameplay_img(src="~@/assets/images/figures.jpg")
              





              //- p Игра представляет собой упрощённый пошаговый файтинг. Игроки одновременно выбирают несколько целей на теле соперника и/или несколько областей на своём теле для блока. Всего таких целей восемь: четыре для блока и четыре для удара. <span class="omnumnum"><span>1</span></span>
              //- p Количество целей определяется доступной энергией. В начале игры у каждого игрока по десять очков здоровья и по три очка энергии. Непотраченная энергия переносится на следующий ход, но её не может быть больше четырёх. <span class="omnumnum"><span>2</span></span>
              //- p Цель игры — довести здоровье соперника до нуля. <span class="omnumnum"><span>3</span></span>
              //- p Игроки делают ход одновременно в тайне друг от друга. Игроки обязаны успеть совершить ход в течение полутора минут. Когда оба игрока совершили ход, игровое поле начинает мигать зелёным и раздаётся характерный звук. В начале нового хода игроки узнают какие цели были поражены, а какие заблокированы. <span class="omnumnum"><span>4</span></span>
              //- p Если один из игроков не успел сделать ход за полторы минуты — ему засчитывается поражение. Если ход не успевают сделать оба игрока — игра оканчивается ничьей.
              //- p Каждый раз, когда игрок теряет единицу здоровья, ему даётся очко ярости. На очки ярости можно покупать карты финтов. Купленную карту финта можно будет приложить к своему ходу, чтобы попытаться перехитрить соперника, или получить некоторое преимущество. Каждая карточка содержит подробное описание того, что вы получите, разыграв её. <span class="omnumnum"><span>5</span></span> <span class="omnumnum six"><span>6</span></span>
              //- p Нельзя ударить в одно и то же место два хода подряд. Цели, в которые вы били в прошлый ход будут недоступны для выбора. То же самое касается соперника. Учитывайте это, принимая решение, какую часть тела заблокировать в текущем ходу.
              //- p В игре можно в любой момент признать своё поражение и сдаться. Но не торопитесь с этим, возможно вы сможете получить преимущество, удачно разыграв финт, или хотя бы свести поединок к ничьей. Удачи!
              //- p 
              //-   img.pvp_gameplay_img(src="~@/assets/images/pvp_gameplay.jpg")

      .subcontent_wrap
        .rub_goods_wrap
          .rub_good(v-for="good in goods")
            .notification 
              img(v-if="good.good_type == 'spinners'" src="../../../assets/images/spinner.png")
              img(v-else-if="good.good_type == 'rocket_hor'" src="../../../assets/images/raketahor.png")
              img(v-else-if="good.good_type == 'rocket_ver'" src="../../../assets/images/raketaver.png")
              img(v-else-if="good.good_type == 'bombs'" src="../../../assets/images/bomb.png")
              img(v-else-if="good.good_type == 'cups'" src="../../../assets/images/cup2.png")
              img(v-else-if="good.good_type == 'podhods'" src="../../../assets/icons_and_logos/armstrong_blue.svg")
              p(v-if="good.good_type == 'spinners'") Бустер «Спиннер»
              p(v-if="good.good_type == 'rocket_hor'") Бустер «Ракета Горизонтальная»
              p(v-if="good.good_type == 'rocket_ver'") Бустер «Ракета Вертикальная»
              p(v-if="good.good_type == 'bombs'") Бустер «Бомба»
              p(v-if="good.good_type == 'cups'") Бустер «Кубок»
              p(v-if="good.good_type == 'podhods'") {{ good.quantity }} подходов
            .buy_button(@click="buyGood(good)") Купить за {{ good.cost }} {{ word_num_cases(good.cost, "балл", "балла", "баллов") }}. <img src="../../../assets/icons_and_logos/cart_white.svg">
        p.mt-7.ta_center
          em
            span(@click="goTo('/inside/shop')" class="refresher") Купить баллы можно в «Магазине»

        div.mt-4.mobile_button
          v-btn.green_button(
            v-if="voices && club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="Vote"
          ) Отдать голос
          v-btn.green_button(
            v-else-if="club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="goTo('/inside/shop')"
          ) Получить ещё голос

      BitvaTimer

  #with_wrap
    //- #end_game_button(v-if="orientation") Закончить игру
    i#close_icon(@click="stopGame" v-tippy content="Закончить игру")
    #container_match
  #zaglushka_hidden
  v-overlay.my_overlay(
    absolute
    :dark="false"
    :light="true"
    color="#ffffff"
    :value="loading"
  )
    v-progress-circular(
      indeterminate
      color="primary"
    )
</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { podhodTimeRemaining } from "../../../service/service"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import {
  GetMatch,
  initMatchGame,
  EndMatchGame,
  SpendBooster,
  BuyMatch3Good
} from "../../../api/Inside"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters, mapActions } from "vuex"
import { tippy } from "vue-tippy"
import superInitGame from "./match3.js"

export default {
  name: 'Match3Game',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu,
    tippy
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      container: document.getElementById("container"),
      countRows: 7,
      countCols: 7,
      boxesplaces: [[0,0], [0,6], [6,0], [6,6], [3,1], [3,5]],
      shopcount: [3, 0, 0, 1, 2],
      purpose: [200, 150],
      moves: 300,
      purposecolor: [0, 2],
      loading: false,
      orientation: null,
      game_paused: null,
      spinners: 0,
      rocket_hor: 0,
      rocket_ver: 0,
      bombs: 0,
      cups: 0,
      level: 1,
      podhods: 5,
      blues: 500,
      datetime_podhods_0: null,
      datetime_podhods_interval_0: null,
      datetime_podhods_1: null,
      datetime_podhods_interval_1: null,
      datetime_podhods_2: null,
      datetime_podhods_interval_2: null,
      datetime_podhods_3: null,
      datetime_podhods_interval_3: null,
      datetime_podhods_4: null,
      datetime_podhods_interval_4: null,
      match3_id: null,
      goods: [
        {
          good_id: 7,
          good_type: "",
          good_name: "Бустер «Спиннер»",
          quantity: 1,
          currency: "coin",
          cost: 2,
          datetime: "27.01.2023 17:42"
        },
      ],
      sub_menu: [
        {
          "title": "Игра",
          "link": "/inside/match3"
        },
        {
          "title": "Рейтинг игроков",
          "link": "/inside/match3-results"
        },
        {
          "title": "Правила игры",
          "link": "/inside/match3-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  created () {
    this.CheckOrientation()
    window.onkeydown = function(e) {
      return e.keyCode !== 32 && e.key !== " "
    }
  },
  mounted () {
    let context = this
    window.addEventListener('focus', () => {
      context.game_paused = false
    })
    window.addEventListener('blur', () => {
      context.game_paused = true
    })
    this.GetMatch()
    // window.scrollTo(0, document.scrollHeight)
    // let app = document.getElementById("app")
    // console.log("scrollTo", document.body.scrollHeight)
    // app.scrollTo(0, 200)
    // window.focus()
    // setTimeout(() => window.scrollTo(0, 100000), 200)
    // document.body.style.height = window.innerHeight + "px"
    // document.body.style.overflow = "hidden"
    // console.log(require("./images/green.png"))
  },
  activated () {
    this.CheckOrientation()
    // this.GameBall()
  },
  methods: {
    ...mapActions("user_store", {
      update: "ACTION_UPDATE",
    }),
    strelochkaActive () {
      let strelochka = document.getElementById("strelochka")
      let add_timers = document.getElementsByClassName("add_timer")
      if (strelochka.classList.contains("active")) {
        strelochka.classList.remove("active")
        for (let i=0; i<add_timers.length; i++) {
          add_timers[i].classList.remove("active")
        }
      } else {
        strelochka.classList.add("active")
        for (let i=0; i<add_timers.length; i++) {
          add_timers[i].classList.add("active")
        }
      }
    },
    Refresh () {
      this.$router.go(0)
    },
    CheckOrientation () {
      let context = this
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        const mql = window.matchMedia("(orientation: landscape)");
        if (mql.matches) {
          this.orientation = "landscape"
        } else {
          this.orientation = "portrait"
        }
        // Прослушивание события изменения ориентации
        mql.addListener(function(m) {
          if (m.matches) {
            context.orientation = "landscape"
            console.log("меня повернули", context.orientation)
          } else {
            context.orientation = "portrait"
            console.log("меня повернули", context.orientation)
          }
        });
      }
    },
    timeRemainingInterval (datetime, i) {
      let context = this
      let strelochka = document.getElementById("strelochka")
      let add_timers = document.getElementsByClassName("add_timer")
      let myTimeRemain = function () {
        console.log("datetime", datetime)
        if (Date.parse(new Date()) >= Date.parse(datetime)) {
          context["datetime_podhods_" + i] = null
          if (strelochka) {
            strelochka.classList.remove("active")
          }
          // if (context.podhods < 5) {
          //   context.podhods += 1
          // }
          for (let i=0; i<5; i++) {
            if (context["datetime_podhods_interval_" + i]) clearInterval(context["datetime_podhods_interval_" + i])
            if (context["datetime_podhods_" + i]) context["datetime_podhods_" + i] = null
          }
          for (let i=0; i<add_timers.length; i++) {
            add_timers[i].classList.remove("active")
          }
          context.GetMatch()
        // } else if (!datetime) {
        //   clearInterval(context.datetime_podhods_interval)
        //   context.datetime_podhods = null
        } else {
          context["datetime_podhods_" + i] = podhodTimeRemaining(datetime)
        }
      }
      this["datetime_podhods_interval_" + i] = setInterval(myTimeRemain, 1000)
    },
    async GetMatch () {
      let context = this
      let balldata = await GetMatch(context)
      // console.log(balldata)
      if (balldata) {
        await this.RebuildPage(balldata)
      }
    },
    async RebuildPage (balldata) {
      this.blues = 500 - balldata["data"]["blues"]
      this.podhods = balldata["data"]["podhods"]
      this.level = balldata["data"]["level"]
      this.spinners = balldata["data"]["spinner"]
      this.rocket_hor = balldata["data"]["rocket_hor"]
      this.rocket_ver = balldata["data"]["rocket_ver"]
      this.bombs = balldata["data"]["bomb"]
      this.cups = balldata["data"]["cup"]
      this.shopcount = [this.spinners, this.rocket_hor, this.rocket_ver, this.bombs, this.cups]
      this.earned_coins = balldata["data"]["earned_coins"]
      this.goods = balldata["data"]["goods"]
      for (let i=0; i<balldata["data"]["datetime"].length; i++) {
        this.timeRemainingInterval(balldata["data"]["datetime"][i], i)
      }
    },
    async EndGame (is_win, blues) {
      let data = {
        "is_win": is_win,
        "blues": blues,
        "match3_id": this.match3_id,
      }
      let result = await EndMatchGame(this, data)
      this.blues = result.data.blues
      this.level = result.data.level
      return result.data
    },
    async SpendBooster (booster) {
      let data = {
        "booster": booster,
      }
      await SpendBooster(this, data)
    },
    async startNewGame () {
      let context = this
      let new_ball_game = await initMatchGame(context)
      let settings = new_ball_game["data"]
      this.countRows = settings["countRows"]
      this.countCols = settings["countCols"]
      this.boxesplaces = settings["boxesplaces"]
      // this.shopcount = settings["shopcount"]
      this.purpose = settings["purpose"]
      this.moves = settings["moves"]
      this.purposecolor = settings["purposecolor"]
      this.match3_id = settings["match3_id"]
      let end_game_button = document.getElementById('end_game_button')
      let close_icon = document.getElementById('close_icon')
      if (end_game_button) {
        end_game_button.classList.add("active")
      }
      if (close_icon) {
        close_icon.classList.add("active")
      }
      let with_wrap = document.getElementById('with_wrap')
      with_wrap.classList.add("active")
      let container = document.getElementById("container_match")
      setTimeout(console.log(), 1)
      let imagesCoin = [require("./images/red.png"), require("./images/green.png"), require("./images/yellow.png"), require("./images/blue.png")]
      let images_classes = ["red", "green", "yellow", "blue"]
      let bonuses = [require("./images/spinner.png"), require("./images/raketahor.png"), require("./images/raketaver.png"), require("./images/bomb.png"), require("./images/cup2.png")]
      if (localStorage.getItem("match3_first_played")) {
        superInitGame(
          context,
          container,
          this.countRows,
          this.countCols,
          this.boxesplaces,
          this.shopcount,
          this.purpose,
          this.purposecolor,
          imagesCoin,
          bonuses,
          require("./images/box.png"),
          require("./images/smoke.png"),
          images_classes,
          this.moves
        )
      } else {
        this.$swal({
          "icon": "warning",
          "text": "Чтобы менять местами элементы, сперва кликни на один, затем на соседний",
          showConfirmButton: true,
          confirmButtonText: "Понятно, начать",
        }).then(function () {
          superInitGame(
            context,
            container,
            context.countRows,
            context.countCols,
            context.boxesplaces,
            context.shopcount,
            context.purpose,
            context.purposecolor,
            imagesCoin,
            bonuses,
            require("./images/box.png"),
            require("./images/smoke.png"),
            images_classes,
            context.moves
          )
          localStorage.setItem("match3_first_played", true)
        })
      }
      // this.check_overflow()
      // window.addEventListener("resize", this.check_overflow)
    },
    stopGame () {
      this.loading = true
      document.body.style.overflow = "auto"
      this.Refresh()
    },
    // check_overflow () {
    //   let with_wrap = document.getElementById('with_wrap')
    //   let container = document.getElementById("container_match")
    //   // console.log(container.offsetHeight + 180, window.innerHeight)
    //   // if (container.offsetHeight + 180 > window.innerHeight) {
    //   //   with_wrap.style.overflow = "scroll"
    //   // } else {
    //   //   with_wrap.style.overflow = "hidden"
    //   // }
    // },
    async buyGood (good) {
      let can_buy = false
      if (good.cost <= this.coins) {
        can_buy = true
      }
      // console.log(can_buy, good, this.coins)
      if (can_buy) {
        let context = this
        let result = await BuyMatch3Good(good, context)
        if (result) {
          this.$swal({
            "icon": "success",
            "text": "Спасибо за покупку!",
          }).then(function () {
            context.update({
              "key": "coins",
              "value": context.coins - good.cost
            })
            context.RebuildPage(result)
          })
        }
      } else {
        this.$swal({
          "icon": "warning",
          "text": "У тебя недостаточно баллов!"
        })
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.strelochka
  position: absolute
  top: 50%
  margin-top: -18px
  right: 15px
  width: 30px !important
  height: 30px
  transform: rotate(-135deg)
  cursor: pointer
  transition: all 0.2s ease
  z-index: 10
  .strelochka_in
    margin-top: 8px
    margin-left: 8px
    width: 15px !important
    height: 15px
    border-left: 3px solid #fff
    border-top: 3px solid #fff
.strelochka.active
  transform: rotate(45deg)
  margin-top: -15px
.add_timer
  height: 0px
  overflow: hidden
  transition: all 0.2s ease
.add_timer.active
  height: auto
  overflow: visible

.howtoplay_text
  font-size: 14px
  line-height: 23px
  .omnumnum
    position: relative
    display: inline-block
    color: #fff
    span
      position: relative
      z-index: 1
      left: 8px
      font-size: 15px
      font-weight: 500
      top: 0px
    &:before
      content: ""
      width: 25px
      height: 25px
      background-color: #d300cb
      border-radius: 15px
      z-index: 0
      position: absolute
      top: -2px
      left: 0
  .omnumnum.six
    position: relative
    left: 18px

.pvp_gameplay_img
  width: 100%

@media (max-height: 500px)
  .address-bar
    display: none

.my_overlay
  z-index: 1005 !important

#game
  display: none
#game.active
  display: block

#with_wrap
  width: 100%
  height: 100px
  position: relative
  display: none
#with_wrap.active
  width: 100%
  height: 100%
  position: fixed
  top: 0
  left: 0
  z-index: 1002
  margin-top: 0 !important
  background-color: #fff
  background-image: url('~@/assets/images/green_field.jpg')
  background-repeat: repeat 
  padding: 90px 20px
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  overflow: hidden !important
  @media (max-height: 700px)
    padding: 50px 20px 90px
  &:before
    content: ""
    display: block
    top: 0
    left: 0
    width: 100%
    height: 100%
    position: absolute
    opacity: 0.55
    background: linear-gradient(360deg, transparent, black)
  p
    width: 100%
    text-align: center

// #end_game_button
//   display: none
#end_game_button
  display: inline-block
  text-decoration: underline
  position: absolute
  top: 10px
  left: 20px
  color: #fff
  cursor: pointer

#start_game_button
  width: 100%
  height: 100px
  z-index: 10
  background-color: #fff
  display: flex
  align-items: center
  justify-content: center

#start_game_button.active
  display: none

#close_icon
  display: none
#close_icon.active
  display: block
  position: absolute
  top: 6px
  right: 10px
  cursor: pointer
  z-index: 10
  transform: rotate(45deg) scale(2)
  width: 30px
  height: 30px
  &:before, &:after
    display: block
    position: absolute
    content: ""
  &:before
    width: 2px
    height: 16px
    left: 14px
    top: 7px
    background-color: #fff
  &:after
    height: 2px
    width: 16px
    top: 14px
    left: 7px
    background-color: #fff




.subcontent_wrap
  width: 100%
  text-align: left
  border-bottom: 1px dotted #DDDDDD
  padding-bottom: 20px
  ul
    margin-top: 10px
    padding: 0
    li
      display: block
      padding-left: 15px
      position: relative
      margin-bottom: 2px
      &:before
        content: ""
        display: block
        width: 6px
        height: 6px
        border-radius: 10px
        background-color: #2c3e50
        position: absolute
        left: 0
        top: 8px
      a
        &:hover
          text-decoration: none

.inventory_blocks
  width: 100%
  display: flex
  justify-content: space-between
  align-items: stretch
  flex-wrap: wrap
  margin-top: 10px
  .coins_block, .voices_block
    display: flex
    width: calc( 50% - 10px )
    height: auto
    background-color: #375567
    color: #fff
    border-radius: 10px
    position: relative
    padding: 10px 5px 15px 12px
    .image_subblock
      width: 55px
      display: flex
      align-items: center
      justify-content: flex-start
      .armstrong_white
        width: 34px
        position: relative
        left: 4px
      .vote_white
        width: 40px
    .txt_subblock
      width: calc(100% - 55px)
      .plus_one
        font-size: 13px
        font-weight: 300
    @media (max-width: 550px)
      width: 100% !important
    div
      width: 100%

    strong
      font-weight: 300
      text-align: left
      line-height: 20px
      display: inline-block
      font-size: 14px
      width: 100%
      @media (max-width: 550px)
        left: 0
        font-size: 13px
        width: 100%
        text-align: left
    p
      width: 100%
      font-size: 20px
      font-weight: 400
      margin: 0
      padding: 0
      position: relative
      text-align: left
      line-height: 24px
      top: 3px
      display: flex
      align-items: center

      @media (max-width: 550px)
        font-size: 21px

  .coins_block
    @media (max-width: 550px)
      margin-bottom: 15px

p
  em
    line-height: 16px
    span.refresher
      display: inline-block
      border-bottom: 1px dashed #393939
      cursor: pointer
      &:hover
        border-bottom: 1px dashed transparent

.content_wrap
  position: relative

.rub_goods_wrap
  margin-top: 30px
  .rub_good
    width: 100%
    border: 2px solid #EFEFEF
    border-radius: 10px
    min-height: 48px
    display: flex
    justify-content: flex-start
    align-items: stretch
    margin-bottom: 15px
    .buy_button
      width: 205px
      padding-right: 35px !important
      background: linear-gradient(#069A26, #016E18)
      position: relative
      color: #fff
      display: flex
      justify-content: flex-start
      align-items: center
      border-top-right-radius: 8px
      border-bottom-right-radius: 8px
      cursor: pointer
      @media (max-width: 650px)
        font-size: 14px !important
        line-height: 18px
      &:hover
        background: linear-gradient(#08B52D, #038E20)
      img
        position: absolute
        right: 5px
        margin-top: -2px
    .notification, .buy_button
      min-height: 48px
      font-size: 16px
      font-weight: 300
      padding: 5px 10px
    .notification
      width: calc( 100% - 205px )
      background: linear-gradient(to right, #EFEFEF, #FFF)
      display: flex
      align-items: center
      justify-content: flex-start
      padding-right: 35px !important
      position: relative
      img
        margin-right: 10px
        width: 27px
      @media (max-width: 550px)
        width: calc( 100% - 100px )
        padding-right: 15px !important
      p
        line-height: 20px
        margin: 0
        padding: 0
        strong
          font-size: 20px
          font-weight: 600
        @media (max-width: 550px)
          font-size: 12px
          line-height: 18px

.bonuses_block
  max-width: 250px
  margin: 0 auto 30px
  display: flex
  justify-content: space-between
  padding: 0 5px 20px
  .bonus_before
    width: 20%
    padding: 0 2px
    max-width: 40px
    min-height: 40px
    background-size: contain
    background-position: center center
    display: flex
    align-items: flex-end
    justify-content: center
    flex-wrap: nowrap
    @media (max-width: 500px)
      min-height: 50px
    span
      display: flex
      align-items: center
      justify-content: center
      width: 36px
      height: 36px
      border: 3px solid #353336
      top:40px
      border-radius: 25px
      color: #fff
      background-color: #474747
      position: relative
      font-family: Aldrich, sans-serif

  
</style>

<style lang="sass">
#with_wrap

#container_match
  width: 40%
  // max-width: 800px
  margin: 0 auto
  flex-wrap: wrap
  position: relative
  box-shadow: 0px 10px 50px 10px
  @media (max-width: 650px)
    width: 100%
  &:before, &:after
    content: ""
    position: absolute
    display: block
    top: 0
    width: 10px
    height: 100%
    background-color: #353336
  &:before
    left: -10px
    border-top-left-radius: 10px
    border-bottom-left-radius: 10px
  &:after
    right: -10px
    border-top-right-radius: 10px
    border-bottom-right-radius: 10px

.scores_block, .purpose_block
  box-sizing: border-box
  color: #fff
  width: 50%
  display: flex
  padding: 5px
  font-weight: 300
  line-height: 30px
  font-size: 16px
  position: relative
  @media (max-width: 400px)
    font-size: 14px
  &:before
    position: absolute
    width: 100%
    height: 45px
    top: -45px
    left: 0
    display: block
    font-family: Rubik, sans-serif
    color: #fff
    text-align: left
    font-size: 40px
    @media (max-width: 400px)
      font-size: 30px
    @media (max-height: 700px)
      top: -37px
      font-size: 25px
  strong
    font-family: Aldrich, sans-serif
    font-size: 16px
    font-weight: 400
    @media (max-width: 400px)
      font-size: 14px

.scores_block
  text-align: right
  justify-content: flex-end
  &:before
    content: 'ХОДЫ:'
    text-align: right
.purpose_block
  &:before
    content: 'ЦЕЛЬ:'

.bonuses_block
  box-sizing: border-box
  color: #fff
  width: 100%
  display: flex
  padding: 15px 5px
  justify-content: center
  align-items: center
  @media (max-height: 700px)
    padding: 10px 5px
  #bonus_0
    background-image: url('~@/assets/images/spinner.png')
  #bonus_1
    background-image: url('~@/assets/images/raketahor.png')
  #bonus_2
    background-image: url('~@/assets/images/raketaver.png')
  #bonus_3
    background-image: url('~@/assets/images/bomb.png')
  #bonus_4
    background-image: url('~@/assets/images/cup2.png')

#zaglushka_hidden
  display: none
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(255, 255, 255, 0.1)
  z-index: 1050

.bonus
  width: 20%
  min-height: 90px
  background-size: contain
  background-position: center center
  display: flex
  align-items: flex-end
  justify-content: center
  flex-wrap: nowrap
  @media (max-height: 700px)
    min-height: 60px
  span
    display: flex
    align-items: center
    justify-content: center
    width: 50px
    height: 50px
    border: 4px solid #353336
    border-radius: 25px
    color: #fff
    background-color: #474747
    position: relative
    top: 38px
    font-family: Aldrich, sans-serif

.selected_bonus
  border: 2px solid #fff

.blues_block
  position: absolute
  bottom: -75px
  width: calc(100% + 20px)
  height: 40px
  background: #000
  // border-top-left-radius: 15px
  // border-top-right-radius: 15px
  border-radius: 15px
  border: 4px solid #353336
  background-color: #474747
  color: #fff
  font-family: Aldrich, sans-serif
  font-size: 18px
  font-weight: 400
  // max-width: 820px
  margin: 0 auto
  display: flex
  align-items: center
  justify-content: center
  @media (max-width: 400px) 
    font-size: 16px
  span
    font-family: Roboto, sans-serif
    font-weight: 300
    font-size: 18px
    margin-right: 6px
    @media (max-width: 400px)
      font-size: 16px
  .small_vote_icon
    display: inline-block
    width: 23px
    height: 18px
    position: relative
    top: -1px
    background-image: url('~@/assets/icons_and_logos/vote_white.svg')
    background-size: contain
    background-position: center center

</style>
    