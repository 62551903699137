<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ИГРА «ДОБЫЧА БАЛЛОВ»")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7
        h3.mb-4 Правила игры «Добыча баллов»
        ol
          li.mb-2 Этот Конкурс проходит постоянно и его итоги подводятся каждый месяц.
          li.mb-2 Цель игры – как можно дольше вести мячик, обходя препятствия.
          li.mb-2 За каждые 1000 набранных очков игрок получает один балл.
          li.mb-2 За каждый новый рекорд по количеству набранных очков (свыше 1000 очков) игрок получает один балл.
          li.mb-2 Последний Ваш рекорд можно посмотреть в разделе “Игра “Добыча баллов””.
          li.mb-2 В конце каждого месяца подводятся итоги этого Конкурса. Победителем Конкурса считается участник, установивший наибольший рекорд. Победитель попадает в «Зал Славы» и получает звание «Король чеканки».
          li.mb-2 В конце каждого месяца рекорды у всех участников обнуляются и новый Конкурс все начинают в равных условиях.
        p
        strong
          em Призы ждут вас! Желаем удачи!

      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'BallGameRules',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      sub_menu: [
        {
          "title": "Игра",
          "link": "/inside/ball"
        },
        {
          "title": "Рейтинг игроков",
          "link": "/inside/ball-progress"
        },
        {
          "title": "Правила",
          "link": "/inside/ball-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
  },
  methods: {
  }
}

</script>


<style lang="sass" scoped>


</style>
    