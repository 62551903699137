<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="Контакты")
    .content_wrap
      
      // HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap
        // BitvaPersonalClubRulesText
        .form_wrap
          .left_col
            p Ты можешь отправить нам сообщение с замечаниями о работе сайта, с предложениями по конкурсам или с любыми вопросами.
            ContactsText
          .right_col
            v-form(autocomplete="off" ref="form_contacts" v-model="valid_form" @keyup.native.enter="SendMessage")
              v-text-field(
                label="Тема сообщения"
                v-model="theme"
                solo
                :rules="[required]"
              )
              v-textarea(
                label="Текст сообщения"
                v-model="message"
                :rules="[required]"
                solo
              )
              v-overlay.my_overlay(
                absolute
                :dark="false"
                :light="true"
                color="#ffffff"
                :value="message_loading"
              )
                v-progress-circular(
                  indeterminate
                  color="primary"
                )

              v-btn.green_button(
                x-large
                dark
                color="#04841F"
                :disabled="!valid_form"
                @click="SendMessage"
              ) Отправить сообщение

      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "../../shared/mixins"
import { SendContactsMessage } from "../../api/Inside"
import HeaDer from "./../Common/HeaDer"
import LeftMenu from "../Common/LeftMenu"
import BitvaTimer from "../Common/BitvaTimer"
import ContactsText from "./ContactsText"
// import BitvaPersonalClubRulesText from "./BitvaPersonalClubRulesText"
// import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'ContactsPage',
  components: {
    HeaDer,
    BitvaTimer,
    ContactsText,
    // HeaderSubMenu,
    // BitvaPersonalClubRulesText,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      message_loading: false,
      theme: null,
      message: null,
      valid_form: false,
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
  },
  methods: {
    async SendMessage () {
      this.message_loading = true
      let context = this
      // let formData = new FormData()
      let data = {
        "theme": this.theme,
        "message": this.message
      }
      let result = await SendContactsMessage(data, context)
      if (result) {
        this.$swal({
          "icon": "success",
          "text": "Сообщение успешно отправлено",
        })
        context.theme = null
        context.message = null
      }
      this.message_loading = false
    }
  }
}

</script>


<style lang="sass" scoped>
.subcontent_wrap
  border-bottom: 0s
  padding-top: 30px
  .form_wrap
    display: flex
    align-items: flex-start
    justify-content: space-between
    flex-wrap: wrap
    .left_col
      width: calc(45% - 15px)
    .right_col
      width: calc(55% - 15px)

</style>
    