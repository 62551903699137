<template lang="pug">
v-app
  v-main
    router-view
</template>

<script>

import "./assets/sass/style.sass";

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="sass">

</style>