<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ПОЕДИНКИ «РАЗ НА РАЗ»")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7
        h3.mb-4 Правила игры «Поединки Раз на Раз»
        p Игра представляет собой упрощённый пошаговый файтинг. Игроки одновременно выбирают несколько целей на теле соперника и/или несколько областей на своём теле для блока. Всего таких целей восемь: четыре для блока и четыре для удара. <span class="omnumnum"><span>1</span></span>
        p Количество целей определяется доступной энергией. В начале игры у каждого игрока по десять очков здоровья и по три очка энергии. Непотраченная энергия переносится на следующий ход, но её не может быть больше четырёх. <span class="omnumnum"><span>2</span></span>
        p Цель игры — довести здоровье соперника до нуля. <span class="omnumnum"><span>3</span></span>
        p Игроки делают ход одновременно в тайне друг от друга. Игроки обязаны успеть совершить ход в течение полутора минут. Когда оба игрока совершили ход, игровое поле начинает мигать зелёным и раздаётся характерный звук. В начале нового хода игроки узнают какие цели были поражены, а какие заблокированы. <span class="omnumnum"><span>4</span></span>
        p Если один из игроков не успел сделать ход за полторы минуты — ему засчитывается поражение. Если ход не успевают сделать оба игрока — игра оканчивается ничьей.
        p Каждый раз, когда игрок теряет единицу здоровья, ему даётся очко ярости. На очки ярости можно покупать карты финтов. Купленную карту финта можно будет приложить к своему ходу, чтобы попытаться перехитрить соперника, или получить некоторое преимущество. Каждая карточка содержит подробное описание того, что вы получите, разыграв её. <span class="omnumnum"><span>5</span></span> <span class="omnumnum six"><span>6</span></span>
        p Нельзя ударить в одно и то же место два хода подряд. Цели, в которые вы били в прошлый ход будут недоступны для выбора. То же самое касается соперника. Учитывайте это, принимая решение, какую часть тела заблокировать в текущем ходу.
        p В игре можно в любой момент признать своё поражение и сдаться. Но не торопитесь с этим, возможно вы сможете получить преимущество, удачно разыграв финт, или хотя бы свести поединок к ничьей. Удачи!
        p 
          img.pvp_gameplay_img(src="~@/assets/images/pvp_gameplay.jpg")

      BitvaTimer.mt-5

</template>

  
  <script>
  
  import { commonMixin } from "../../../shared/mixins"
  import HeaDer from "./../../Common/HeaDer"
  import LeftMenu from "../../Common/LeftMenu"
  import BitvaTimer from "../../Common/BitvaTimer"
  import HeaderSubMenu from "../../Common/HeaderSubMenu"
  import "vue-custom-scrollbar/dist/vueScrollbar.css"
  import { mapGetters } from "vuex"
  
  export default {
    name: 'RazNaRazRules',
    components: {
      HeaDer,
      BitvaTimer,
      HeaderSubMenu,
      LeftMenu
    },
    mixins: [
      commonMixin
    ],
    data () {
      return {
        sub_menu: [
          {
            "title": "Игра",
            "link": "/inside/raznaraz"
          },
          {
            "title": "Рейтинг игроков",
            "link": "/inside/raznaraz-ratings"
          },
          {
            "title": "История игр",
            "link": "/inside/raznaraz-story"
          },
          {
            "title": "Правила игры",
            "link": "/inside/raznaraz-game-rules"
          },
          {
            "title": "Правила конкурса",
            "link": "/inside/raznaraz-rules"
          }
        ]
      }
    },
    computed: {
      ...mapGetters("user_store", {
        voices: "voices",
        coins: "coins",
        my_club: "club",
        club_rating: "club_rating",
        voices_given: "voices_given",
        personal_club_rating: "personal_club_rating",
        personal_rating: "personal_rating",
      })
    },
    mounted () {
    },
    methods: {
    }
  }
  
  </script>
  
  
  <style lang="sass" scoped>
  
  
  </style>
      