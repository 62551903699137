<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="МАГАЗИН")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
          
      .subcontent_wrap

        h3(v-if="goods.length") История покупок:
        h3(v-else) Ты пока ещё ничего не приобрёл

        .rub_goods_wrap(v-if="goods.length")

          .rub_good(v-for="good in goods")
            .notification(v-if="good.good_type == 'coins'")
              span Ты приобрёл <strong>{{ good.quantity }}</strong>
                |  {{ word_num_cases(good.quantity, "балл", "балла", "баллов") }}
                |  за <strong>{{ good.cost }}</strong> руб.
            .notification(v-else-if="good.good_type == 'votes'")
              span Ты приобрёл <strong>{{ good.quantity }}</strong>
                |  {{ word_num_cases(good.quantity, "голос", "голоса", "голосов") }}
                |  за <strong>{{ good.cost }}</strong> {{ word_num_cases(good.cost, "балл", "балла", "баллов") }}.
            .notification(v-else-if="good.good_type == 'podhods'")
              span Ты приобрёл <strong>{{ good.quantity }}</strong>
                |  {{ word_num_cases(good.quantity, "подход", "подхода", "подходов") }}
                |  за <strong>{{ good.cost }}</strong> {{ word_num_cases(good.cost, "балл", "балла", "баллов") }}.
            .notification(v-else-if="good.good_type == 'spinners'")
              span Ты приобрёл <strong>{{ good.quantity }}</strong>
                |  {{ word_num_cases(good.quantity, "спиннер", "спиннера", "спиннеров") }}
                |  за <strong>{{ good.cost }}</strong> {{ word_num_cases(good.cost, "балл", "балла", "баллов") }}.
            .notification(v-else-if="good.good_type == 'rocket_hor'")
              span Ты приобрёл <strong>{{ good.quantity }}</strong>
                |  {{ word_num_cases(good.quantity, "ракету (горизотальную)", "ракеты (горизотальных)", "ракет (горизотальных)") }}
                |  за <strong>{{ good.cost }}</strong> {{ word_num_cases(good.cost, "балл", "балла", "баллов") }}.
            .notification(v-else-if="good.good_type == 'rocket_ver'")
              span Ты приобрёл <strong>{{ good.quantity }}</strong>
                |  {{ word_num_cases(good.quantity, "ракету (вертикальную)", "ракеты (вертикальных)", "ракет (вертикальных)") }}
                |  за <strong>{{ good.cost }}</strong> {{ word_num_cases(good.cost, "балл", "балла", "баллов") }}.
            .notification(v-else-if="good.good_type == 'bombs'")
              span Ты приобрёл <strong>{{ good.quantity }}</strong>
                |  {{ word_num_cases(good.quantity, "бомбу", "бомбы", "бомб") }}
                |  за <strong>{{ good.cost }}</strong> {{ word_num_cases(good.cost, "балл", "балла", "баллов") }}.
            .notification(v-else-if="good.good_type == 'cups'")
              span Ты приобрёл <strong>{{ good.quantity }}</strong>
                |  {{ word_num_cases(good.quantity, "кубок", "кубка", "кубков") }}
                |  за <strong>{{ good.cost }}</strong> {{ word_num_cases(good.cost, "балл", "балла", "баллов") }}.
            .datetime {{ good.datetime }}

          .notification_pagination 
            //- img.left(
            //-   :class="{'unactive': page_num == 1}"
            //-   src="../../../assets/icons_and_logos/paginate-arrow-left.svg"
            //-   @click="reducePage"
            //- )
            //- span Страница <strong>{{ page_num }}</strong> из <strong>{{ pages_count }}</strong>
            //- img.right(
            //-   :class="{'unactive': page_num == pages_count}"
            //-   src="../../../assets/icons_and_logos/paginate-arrow-right.svg"
            //-   @click="increasePage"
            //- )
            span.paginate_page(
              v-if="page_num != 1"
              @click="reducePage"
            ) Предыдущая страница
            span.between(v-if="page_num != 1")
            span.paginate_page(
              v-if="page_num != pages_count"
              @click="increasePage"
            ) Следующая страница

          //- .rub_good
          //-   .notification 
          //-     img(src="../../../assets/icons_and_logos/gold_coins.svg")
          //-     p <strong>15</strong> {{ word_num_cases(15, "балл", "балла", "баллов") }}
          //-   .buy_button Купить за 190 руб. <img src="../../../assets/icons_and_logos/cart_white.svg">
          //- .rub_good
          //-   .notification 
          //-     img(src="../../../assets/icons_and_logos/gold_coins.svg")
          //-     p <strong>30</strong> {{ word_num_cases(30, "балл", "балла", "баллов") }}
          //-   .buy_button Купить за 190 руб. <img src="../../../assets/icons_and_logos/cart_white.svg">
          //- .rub_good
          //-   .notification 
          //-     img(src="../../../assets/icons_and_logos/gold_coins.svg")
          //-     p <strong>60</strong> {{ word_num_cases(60, "балл", "балла", "баллов") }}
          //-   .buy_button Купить за 190 руб. <img src="../../../assets/icons_and_logos/cart_white.svg">
          //- .rub_good
          //-   .notification 
          //-     img(src="../../../assets/icons_and_logos/gold_coins.svg")
          //-     p <strong>120</strong> {{ word_num_cases(120, "балл", "балла", "баллов") }}
          //-   .buy_button Купить за 190 руб. <img src="../../../assets/icons_and_logos/cart_white.svg">

      BitvaTimer

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { getAllPurchases } from "../../../api/Inside"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  name: 'PurchasesRub',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      page_num: 1,
      pages_count: 3,
      goods: [],
      // goods: [
      //   {
      //     good_id: 1,
      //     good_type: "votes",
      //     quantity: 1,
      //     currency: "coins",
      //     cost: 10,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 2,
      //     good_type: "votes",
      //     quantity: 5,
      //     currency: "coins",
      //     cost: 45,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 3,
      //     good_type: "votes",
      //     quantity: 10,
      //     currency: "coins",
      //     cost: 80,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 4,
      //     good_type: "coins",
      //     quantity: 15,
      //     currency: "rub",
      //     cost: 190,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 5,
      //     good_type: "coins",
      //     quantity: 30,
      //     currency: "rub",
      //     cost: 370,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 6,
      //     good_type: "coins",
      //     quantity: 60,
      //     currency: "rub",
      //     cost: 740,
      //     datetime: "27.01.2023 17:42"
      //   },
      //   {
      //     good_id: 7,
      //     good_type: "coins",
      //     quantity: 120,
      //     currency: "rub",
      //     cost: 1420,
      //     datetime: "27.01.2023 17:42"
      //   },
      // ],
      sub_menu: [
        {
          "title": "Магазин",
          "link": "/inside/shop"
        },
        {
          "title": "История покупок",
          "link": "/inside/purchases"
        }
      ]
    }
  },
  mounted () {
    this.getAllPurchases(1)
  },
  methods: {
    async getAllPurchases (page) {
      let context = this
      let result = await getAllPurchases(context, page)
      if (result) {
        console.log(result)
        this.page_num = result["data"]["page_num"]
        this.pages_count = result["data"]["pages_count"]
        this.goods = result["data"]["purchases"]
      }
    },
    reducePage () {
      if (this.page_num != 1) {
        this.page_num -= 1
        this.getAllPurchases(this.page_num)
      }
    },
    increasePage () {
      if (this.page_num != this.pages_count) {
        this.page_num += 1
        this.getAllPurchases(this.page_num)
      }
    },
  }
}

</script>


<style lang="sass" scoped>

.rub_goods_wrap
  margin-top: 20px
  .rub_good
    width: 100%
    border: 2px solid #EFEFEF
    border-radius: 10px
    min-height: 48px
    display: flex
    justify-content: flex-start
    align-items: stretch
    margin-bottom: 15px
    .datetime
      width: 190px
      text-align: center
      background-color: #EFEFEF
      border-top-right-radius: 8px
      border-bottom-right-radius: 8px
      display: flex
      align-items: center
      justify-content: center
      @media (max-width: 550px)
        width: 100px
        font-size: 12px
    .notification, .datetime
      min-height: 44px
      font-size: 14px
      font-weight: 300
      padding: 5px 10px
    .notification
      width: calc( 100% - 190px )
      background: linear-gradient(to right, #EFEFEF, #FFF)
      display: flex
      align-items: center
      justify-content: flex-start
      padding-right: 35px !important
      position: relative
      img
        margin-right: 10px
      @media (max-width: 550px)
        width: calc( 100% - 100px )
        padding-right: 15px !important
      p
        line-height: 20px
        margin: 0
        padding: 0
        strong
          font-size: 20px
          font-weight: 600
        @media (max-width: 550px)
          font-size: 12px
          line-height: 18px

.notification_pagination
  height: 25px
  display: flex
  align-items: center
  justify-content: flex-start
  font-size: 15px
  margin-top: 20px
  font-weight: 300
  img
    cursor: pointer
  img.unactive
    cursor: default
    filter: grayscale(1) opacity(0.7)
  img.left
    margin-right: 10px
  img.right
    margin-left: 10px

</style>
    