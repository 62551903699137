import { client, errorHandler, getAuthHeaders } from "./api";


export async function Vote(data, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("vote", {...data}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function Notifications(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("notifications?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function AdminData(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("admin-data?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetAllFights(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("pvp", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetOneFight(context, pvp_id, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("pvp/" + pvp_id, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GiveUp(context, pvp_id, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("pvp/giveup/" + pvp_id, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function UpdatePvpReady(pvp_id, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.patch("/pvp/ready/" + pvp_id,
    {},
    {
      headers: {...authHeaders,}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function CreatePvp(data, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("pvp",
    {...data,},
    {
      headers: {...authHeaders,}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function BuyFint(data, pvp_id, context, then) {
  const authHeaders = await getAuthHeaders();
  console.log(data)
  const response = await client.patch("pvp-fint/" + pvp_id,
    {...data,},
    {
      headers: {...authHeaders,}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function MakeAStep(data, pvp_id, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.patch("pvp/" + pvp_id,
    {...data},
    {
      headers: {...authHeaders,}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function FightJoin(pvp_id, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("pvp/" + pvp_id,
    {},
    {
      headers: {...authHeaders,}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function FightCancel(pvp_id, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.delete("pvp/" + pvp_id, {
    headers: {...authHeaders,}
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function PvpResults(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("pvp-ratings?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function PvpHistory(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("pvp-history?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function MyWins(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("my-prizes", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function ChangeAvatar(data, context, header, then) {
  const authHeaders = await getAuthHeaders();
  console.log({headers: {
    ...authHeaders,
    ...header
  }})
  const response = await client.patch("change-avatar",
    {...data,},
    {
      headers: {
        ...authHeaders,
        // ...header
        'Content-Type': 'multipart/form-data'
      }
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function ChangeEmail(data, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.patch("change-email",
    {...data,},
    {
      headers: {...authHeaders}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function CheckEmailCode(data, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("change-email-confirm",
    {...data,},
    {
      headers: {...authHeaders}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function SendContactsMessage(data, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("send-contacts-message",
    {...data,},
    {
      headers: {...authHeaders}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function ChangeUsername(data, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.patch("change-username",
    {...data,},
    {
      headers: {...authHeaders,}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function ChangePassword(data, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.patch("change-password",
    {...data,},
    {
      headers: {...authHeaders,}
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function deleteAccaunt(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.delete("delete-account",
    // {...data,},
    {
      headers: { ...authHeaders }
    }
  ).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetAllGoods(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("shop", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function getAllPurchases(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("purchases?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function championshipClubPersons(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("championship-club-leaders?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function championshipPersons(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("championship-leaders?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function BuyGood(good, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("shop", {...good}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function BuyMatch3Good(good, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("match3-shop", {...good}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetDreamteam(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("dreamteam", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetDreamteamBase(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("dreamteam-base", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetDreamteamRatings(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("dreamteam-ratings?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function SetDreamteam(card, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("dreamteam", {...card}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetSharing(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("link-sharing", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetSharingRatings(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("link-sharing/ratings?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetStampsWinners(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("stamps-winners?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetStamps(context, page, then) {
  const authHeaders = await getAuthHeaders();
  //- const response = await client.get("stamps?page=" + page, {
  const response = await client.get("stamps", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function SellRepeats(card, context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("stamps", {...card}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function StartGame(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("ball", {}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function initMatchGame(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("match3", {}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function EndMatchGame(context, data, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.patch("match3", {...data}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function SpendBooster(context, data, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.patch("booster", {...data}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function EndGame(context, data, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.patch("ball", {...data}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetBall(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("ball", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetMatch(context, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("match3", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function GetBallResults(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("ball-ratings?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function Match3Results(context, page, then) {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("match3-ratings?page=" + page, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function championshipClubsHistory(context, month, then) {
  const authHeaders = await getAuthHeaders();
  let addr = ""
  if (month) {
    addr = "champ-history?month_year=" + month
  } else {
    addr = "champ-history"
  }
  const response = await client.get(addr, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}