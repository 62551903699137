<template lang="pug">

.dreamteam_card_wrap
  img.card_base(src="../../../assets/icons_and_logos/dreamteam_card.png")
  img.card_rare(v-if="rare == '4'" src="../../../assets/icons_and_logos/gold_star.svg")
  img.card_rare(v-else-if="rare == '3'" src="../../../assets/icons_and_logos/purple_star.svg")
  img.card_rare(v-else-if="rare == '2'" src="../../../assets/icons_and_logos/green_star.svg")
  img.card_rare(v-else-if="rare == '1'" src="../../../assets/icons_and_logos/gray_star.svg")
  .name_wrap
    span.name {{ name }}
  span.role {{ role }}

</template>


<script>

export default {
  name: 'DreamTeamCard',

  props: [
    'rare',
    'name',
    'role'
  ],
  methods: {

  }
}

</script>


<style lang="sass" scoped>

.dreamteam_card_wrap
  width: 186px
  height: 276px
  position: relative
  margin: 0 auto
  padding: 0
  @media (max-width: 700px)
    margin: 14px auto
  .card_base
    display: inline-block
    width: 100%
    position: relative
    z-index: 1
  .card_rare
    display: inline-block
    width: 130px
    position: absolute
    z-index: 2
    top: 25px
    left: 50%
    margin-left: -65px
  .name_wrap
    width: 100%
    height: 50px
    display: flex
    align-items: center
    padding: 0 16px
    position: absolute
    z-index: 2
    left: 0
    top: 169px
    span.name
      display: inline-block
      width: 100%
      text-align: center
      line-height: 20px
      color: #FFF7E6
      font-size: 17px
      font-weight: 400
  span.role
    width: 100%
    padding: 0 20px
    position: absolute
    text-align: center
    z-index: 2
    left: 0
    top: 219px
    color: #FFF7E6
    font-size: 13px
    font-weight: 300

</style>
    