import { render, staticRenderFns } from "./DreamteamRules.vue?vue&type=template&id=59fe851a&scoped=true&lang=pug&"
import script from "./DreamteamRules.vue?vue&type=script&lang=js&"
export * from "./DreamteamRules.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59fe851a",
  null
  
)

export default component.exports