<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="БИТВА")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7
        BitvaRulesText

      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import BitvaRulesText from "./BitvaRulesText"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'FightRules',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    BitvaRulesText,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      sub_menu: [
        {
          "title": "Рейтинг клубов",
          "link": "/inside/fight"
        },
        {
          "title": "История Фан-рейтингов",
          "link": "/inside/fight-history"
        },
        {
          "title": "Правила",
          "link": "/inside/fight-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
  },
  methods: {
  }
}

</script>


<style lang="sass" scoped>


</style>
    