<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="КОЛЛЕКЦИЯ МАРОК")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7
        h3.mb-4 Правила конкурса «Коллекция марок»
        ol
          li.mb-2 Конкурс проводится каждые 3 месяца и длится в течение 3-х месяцев. Принять участие в Конкурсе можно в любое время до его окончания. Победители получат ценные призы.
          li.mb-2 Суть Конкурса заключается в том, чтобы собрать виртуальную коллекцию из 33 реальных изображений почтовых марок о футболе, выпущенных разными странами мира. Например, коллекция может быть посвящена чемпионату мира по футболу за какой-либо год.
          li.mb-2 Коллекцию марок собираете только Вы, без взаимодействия с другими участниками этого Конкурса.
          li.mb-2 В начале Конкурса Вам дается таблица с 33 клетками, которые Вы должны заполнить марками. Для получения марок Вам необходимо голосовать за свой клуб, за каждый голос Вы получите одну марку.
          li.mb-2 Голосовать и получать марки можно неограниченное количество раз. После голосования голоса возврату не подлежат.
          li.mb-2 Каждая клетка в таблице соответствует одной уникальной марке, однако, после голосования Вы можете получить марки, которые уже есть в таблице, поэтому какие-то клетки могут содержать несколько дубликатов одной марки.
          li.mb-2 У Вас есть возможность одним нажатием кнопки удалить из таблицы все дубликаты, при этом Вы получите по одному баллу за каждый удаленный дубликат. Нельзя удалить марку, если в клетке она одна.
          li.mb-2 Таблица считается собранной полностью, если все клетки заполнены, даже если в некоторых клетках могут быть дубликаты марок.
          li.mb-2 Если до даты окончания Конкурса Вы соберете полную коллекцию из 33 марок, то в этот момент Вы станете победителем, а по окончанию конкурса получите ценный приз – Сертификат Ozon на 10 000 руб. и попадете в «Зал Славы», при этом повторно принять участие в текущем Конкурсе нельзя, нужно дождаться следующего.
          li.mb-2 Если после победы у Вас в таблице остались дубликаты марок, Вы все еще можете их удалить и получить по одному баллу за каждый дубликат.
          li.mb-2 Другие участники, независимо от Вас, также могут стать победителями и получить призы – Сертификаты Ozon на 10 000 руб., получить звание «Коллекционер марок» и попасть в «Зал Славы», если они соберут полную коллекцию марок до истечения срока Конкурса.
          li.mb-2 Победители получают призы в своих Личных Кабинетах. Файл сертификата становится доступен для скачивания не позднее, чем через 7 дней с момента окончания Конкурса.
        p
          strong
            em Призы ждут вас! Желаем удачи!

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'StampsRules',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      sub_menu: [
        {
          "title": "Коллекция марок",
          "link": "/inside/stamps"
        },
        {
          "title": "Победители",
          "link": "/inside/stamps-winners"
        },
        {
          "title": "Правила",
          "link": "/inside/stamps-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
  },
  methods: {
  }
}

</script>


<style lang="sass" scoped>


</style>
    