import Vue from "vue"
import VueRouter from "vue-router"
import Store from "../store/index"

import LandingPage from "../components/LandingPage/LandingPage"
import LandingPageNew from "../components/LandingPage/LandingPageNew"
import TestPage from "../components/TestPage"
import RegistRation from "../components/Registration"
import SignIn from "../components/SignIn"
import ForgotPassword from "../components/SignIn/ForgotPassword"
import NewPassword from "../components/SignIn/NewPassword"
import ConfirmPhone from "../components/Registration/ConfirmPhone"

import GloryHall from "../components/GloryHall"
import ContactsPage from "../components/InsidePages/ContactsPage"
import TermsPage from "../components/TermsPage"
import AdminPage from "../components/AdminPage"
// import PrivacyPage from "../components/PrivacyPage"

import InsidePages from "../components/InsidePages"
import PersonalCabinet from "../components/InsidePages/PersonalCabinet"
import MyWins from "../components/InsidePages/PersonalCabinet/MyWins"
import MyData from "../components/InsidePages/PersonalCabinet/MyData"
import EmailConfirm from "../components/InsidePages/PersonalCabinet/EmailConfirm"
import BitvaCommands from "../components/InsidePages/Bitva"
import BitvaCommandsHistory from "../components/InsidePages/Bitva/BitvaCommandsHistory"
import FightRules from "../components/InsidePages/Bitva/FightRules"
import BitvaPersonalCommon from "../components/InsidePages/BitvaCommon/BitvaPersonalCommon"
import BitvaPersonalCommonRules from "../components/InsidePages/BitvaCommon/BitvaPersonalCommonRules"
import BitvaPersonalClub from "../components/InsidePages/BitvaClub/BitvaPersonalClub"
import BitvaPersonalClubRules from "../components/InsidePages/BitvaClub/BitvaPersonalClubRules"
import Shop from "../components/InsidePages/Shop"
import PurchasesRub from "../components/InsidePages/Shop/PurchasesRub"
import DreamTeam from "../components/InsidePages/DreamTeam"
import DreamTeamDescription from "../components/InsidePages/DreamTeam/DreamTeamDescription"
import ProgressMembers from "../components/InsidePages/DreamTeam/ProgressMembers"
import DreamteamRules from "../components/InsidePages/DreamTeam/DreamteamRules"
import ShareWin from "../components/InsidePages/ShareWin"
import ShareRatings from "../components/InsidePages/ShareWin/ShareRatings"
import ShareRules from "../components/InsidePages/ShareWin/ShareRules"
import StampsMain from "../components/InsidePages/Stamps"
import StampsWinners from "../components/InsidePages/Stamps/StampsWinners"
import StampsRules from "../components/InsidePages/Stamps/StampsRules"
import BallGame from "../components/InsidePages/Ball/BallGame"
import BallGameResults from "../components/InsidePages/Ball/BallGameResults"
import BallGameRules from "../components/InsidePages/Ball/BallGameRules"
import PrizesToWin from "../components/InsidePages/PrizesToWin"
import RazNaRaz from "../components/InsidePages/RazNaRaz"
import RazNaRazRules from "../components/InsidePages/RazNaRaz/RazNaRazRules"
import RazNaRazGamesRules from "../components/InsidePages/RazNaRaz/RazNaRazGamesRules"
import Pvp from "../components/InsidePages/RazNaRaz/Pvp"
import Fight from "../components/InsidePages/RazNaRaz/Fight"
import PvpRatings from "../components/InsidePages/RazNaRaz/PvpRatings"
import PvpStory from "../components/InsidePages/RazNaRaz/PvpStory"

import Match3Game from "../components/InsidePages/Match3/Match3Game"
import Match3Rules from "../components/InsidePages/Match3/Match3Rules"
import Match3Results from "../components/InsidePages/Match3/Match3Results"


Vue.use(VueRouter)

const routes = [
  {
    path: "/contacts",
    name: "ContactsPage",
    component: ContactsPage,
    meta: {
      needAuth: true,
    }
  },
  // {
  //   path: "/privacy",
  //   name: "PrivacyPage",
  //   component: PrivacyPage,
  //   meta: {
  //     // needAuth: false,
  //   }
  // },
  // {
  //   path: "/terms",
  //   name: "TermsPage",
  //   component: TermsPage,
  //   meta: {
  //     // needAuth: false,
  //   }
  // },

  {
    path: "/glory-hall",
    name: "GloryHall",
    component: GloryHall,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/registration",
    name: "RegistRation",
    component: RegistRation,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/login",
    name: "SignIn",
    component: SignIn,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/confident",
    name: "SignIn",
    component: TermsPage,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/hdfn63dj4e",
    name: "AdminPage",
    component: AdminPage,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/confirm-email/:code",
    name: "EmailConfirm",
    component: EmailConfirm,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/confirm-phone/:user_id",
    name: "ConfirmPhone",
    component: ConfirmPhone,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/new-password/:temp_id",
    name: "NewPassword",
    component: NewPassword,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/inside",
    name: "inside",
    component: InsidePages,
    meta: {
      needAuth: true,
      // needAuth: false,
    },
    children: [
      {
        path: "personal",
        name: "PersonalCabinet",
        component: PersonalCabinet,
        meta: {
          needAuth: true,
          // // needAuth: false,
        }
      },
      {
        path: "my-wins",
        name: "MyWins",
        component: MyWins,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "my-data",
        name: "MyData",
        component: MyData,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "shop",
        name: "Shop",
        component: Shop,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "purchases",
        name: "PurchasesRub",
        component: PurchasesRub,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "fight",
        name: "BitvaCommands",
        component: BitvaCommands,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "fight-history",
        name: "BitvaCommandsHistory",
        component: BitvaCommandsHistory,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "fight-common",
        name: "BitvaPersonalCommon",
        component: BitvaPersonalCommon,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "fight-common-rules",
        name: "BitvaPersonalCommonRules",
        component: BitvaPersonalCommonRules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "fight-club",
        name: "BitvaPersonalClub",
        component: BitvaPersonalClub,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "fight-club-rules",
        name: "BitvaPersonalClubRules",
        component: BitvaPersonalClubRules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "fight-rules",
        name: "FightRules",
        component: FightRules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "dreamteam",
        name: "DreamTeam",
        component: DreamTeam,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "dreamteam-progress",
        name: "ProgressMembers",
        component: ProgressMembers,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "dreamteam-rules",
        name: "DreamteamRules",
        component: DreamteamRules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "dreamteam-info",
        name: "DreamTeamDescription",
        component: DreamTeamDescription,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "share-win",
        name: "ShareWin",
        component: ShareWin,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "sharewin-progress",
        name: "ShareRatings",
        component: ShareRatings,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "sharewin-rules",
        name: "ShareRules",
        component: ShareRules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "stamps",
        name: "StampsMain",
        component: StampsMain,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "stamps-winners",
        name: "StampsWinners",
        component: StampsWinners,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "stamps-rules",
        name: "StampsRules",
        component: StampsRules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "ball",
        name: "BallGame",
        component: BallGame,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "ball-progress",
        name: "BallGameResults",
        component: BallGameResults,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "ball-rules",
        name: "BallGameRules",
        component: BallGameRules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "prizes",
        name: "PrizesToWin",
        component: PrizesToWin,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "raznaraz",
        name: "RazNaRaz",
        component: RazNaRaz,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "raznaraz/:pvp_id",
        name: "Pvp",
        component: Pvp,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "raznaraz/fight/:pvp_id",
        name: "Fight",
        component: Fight,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "raznaraz-ratings",
        name: "PvpRatings",
        component: PvpRatings,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "raznaraz-rules",
        name: "RazNaRazRules",
        component: RazNaRazRules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "raznaraz-game-rules",
        name: "RazNaRazGamesRules",
        component: RazNaRazGamesRules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "raznaraz-story",
        name: "PvpStory",
        component: PvpStory,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "match3",
        name: "Match3Game",
        component: Match3Game,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "match3-rules",
        name: "Match3Rules",
        component: Match3Rules,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
      {
        path: "match3-results",
        name: "Match3Results",
        component: Match3Results,
        meta: {
          needAuth: true,
          // needAuth: false,
        }
      },
    ]
  },
  {
    path: "/test-page",
    name: "TestPage",
    component: TestPage,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/1",
    name: "LandingPageNew",
    component: LandingPageNew,
    meta: {
      needAuth: false,
    }
  },
  {
    path: "/:initial_slide?",
    name: "LandingPage",
    component: LandingPage,
    meta: {
      needAuth: false,
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(
  function(to, from, next) {
    let vvuuee = Vue
    Store.commit("update", {
      key: "previous_page",
      value: from
    });
    Store.commit("incRouterPageCounter");
    console.log("to.meta.needAuth", to.meta.needAuth, to)
    if (to.meta.needAuth) {
      const refresh = vvuuee.$cookies.get("refresh");
      let warningText= "Необходимо залогиниться";
      if (!refresh) {
        if (from.name === "SignIn") {
          vvuuee.swal({ "icon": "warning", "text":  warningText});
          return false;
        }
        next({ name: "SignIn" });
        vvuuee.swal({ "icon": "warning", "text": warningText});
      } else {
        next();
      }
    }
    next();
  }
);

export default router;
