<template lang="pug">

div#landing_slider_wrapper
  #images_preloader
    img(src="~@/assets/images/first_slide_fans.jpg")
    img(src="~@/assets/images/soccert_stadium_2.jpg")
    img(src="~@/assets/images/third_screen_bg_blur.jpg")
    img(src="~@/assets/images/team_bg.jpg")
    img(src="~@/assets/images/fifth_screen_bg.jpg")
    img(src="~@/assets/images/six_screen_bg2.jpg")
    img(src="~@/assets/images/seven_screen_bg.jpg")
    img(src="~@/assets/images/habar.jpg")
  #resized_with_scroll_up
  #resized_with_scroll_down
  // .top_dark_line(:class="{'white_bg': (current_slide == 3 || current_slide == 4 || current_slide == 7)}")
  .top_plashka
    .top_plashka_in
    a.logo_v_plashke#logo_v_plashke(href="/")
      img(src='~@/assets/icons_and_logos/mini_logo.svg')
  .content.logotype_wrap
    logotype
  LeftMenu(@slideTo="slideTo" :currentSlide="current_slide" :isGlory="false")
  
  .login_register_icons(v-if="is_auth" :class="{'white_bg': (current_slide == 3 || current_slide == 4 || current_slide == 7)}")
    // a(@click="goTo('/inside/personal')") Личный Кабинет
    HeaDerIcons(:is_main="true")
  .login_register(v-else :class="{'white_bg': (current_slide == 3 || current_slide == 4 || current_slide == 7)}")
    a(@click="goTo('/login')") Войти
    span &nbsp;&nbsp;|&nbsp;&nbsp;
    a(@click="goTo('/registration')") Регистрация
  
  #somesheet_down.slide_1
  #somesheet
    SlideOneCommon.fake_slide#slide1(@slideTo="slideTo")
    SlideTwoBitva.fake_slide#slide2(@slideTo="slideTo")
    SlideThreeKonkurses.fake_slide#slide3(@slideTo="slideTo")
    SlideFourDreamteam.fake_slide#slide4(@slideTo="slideTo")
    SlideFiveStamps.fake_slide#slide5(@slideTo="slideTo")
    SlideSixAgit.fake_slide#slide6(@slideTo="slideTo")
    SlideSevenRazNaRaz.fake_slide#slide7(@slideTo="slideTo")
    SlideEightPrizes.fake_slide#slide8(@slideTo="slideTo")

</template>


<script>
import Logotype from './Logotype'
import { 
  Hooper,
  Slide,
  Pagination as HooperPagination
} from "hooper"
import "hooper/dist/hooper.css"
import { mapGetters } from "vuex"
import { commonMixin } from "../../shared/mixins"
import LeftMenu from "./LeftMenu"
import SlideOneCommon from "./SlideOneCommon"
import SlideTwoBitva from "./SlideTwoBitva"
import SlideThreeKonkurses from "./SlideThreeKonkurses"
import SlideFourDreamteam from "./SlideFourDreamteam"
import SlideFiveStamps from "./SlideFiveStamps"
import SlideSixAgit from "./SlideSixAgit"
import SlideSevenRazNaRaz from "./SlideSevenRazNaRaz"
import SlideEightPrizes from "./SlideEightPrizes"
import HeaDerIcons from "../Common/HeaDerIcons"
import { getAccessTokenSimple } from "../../api/api"

let scroll_listener = function() {
  let somesheet_down = document.getElementById("somesheet_down")
  // let height = document.documentElement.clientHeight
  let heights = re_resizer()
  if (heights) {
    let logo_v_plashke = document.getElementById("logo_v_plashke")
    if (scrollY < 154) {
      logo_v_plashke.classList.remove("visible")
    } else {
      logo_v_plashke.classList.add("visible")
    }
    if (scrollY < heights[0] / 2) {
      somesheet_down.classList.remove("slide_1", "slide_2", "slide_3", "slide_4", "slide_5", "slide_6", "slide_7", "slide_8", "slide_9")
      somesheet_down.classList.add("slide_1")
    }
    if (scrollY > heights[0] / 2 && 
        scrollY < heights[0] + (heights[1] - heights[0]) / 2) {
      somesheet_down.classList.remove("slide_1", "slide_2", "slide_3", "slide_4", "slide_5", "slide_6", "slide_7", "slide_8", "slide_9")
      somesheet_down.classList.add("slide_2")
    }
    if (scrollY > heights[0] + (heights[1] - heights[0]) / 2 &&
        scrollY < heights[1] + (heights[2] - heights[1]) / 2) {
      somesheet_down.classList.remove("slide_1", "slide_2", "slide_3", "slide_4", "slide_5", "slide_6", "slide_7", "slide_8", "slide_9")
      somesheet_down.classList.add("slide_3")
    }
    if (scrollY > heights[1] + (heights[2] - heights[1]) / 2 &&
        scrollY < heights[2] + (heights[3] - heights[2]) / 2) {
      somesheet_down.classList.remove("slide_1", "slide_2", "slide_3", "slide_4", "slide_5", "slide_6", "slide_7", "slide_8", "slide_9")
      somesheet_down.classList.add("slide_4")
    }
    if (scrollY > heights[2] + (heights[3] - heights[2]) / 2 &&
        scrollY < heights[3] + (heights[4] - heights[3]) / 2) {
      somesheet_down.classList.remove("slide_1", "slide_2", "slide_3", "slide_4", "slide_5", "slide_6", "slide_7", "slide_8", "slide_9")
      somesheet_down.classList.add("slide_5")
    }
    if (scrollY > heights[3] + (heights[4] - heights[3]) / 2 &&
        scrollY < heights[4] + (heights[5] - heights[4]) / 2) {
      somesheet_down.classList.remove("slide_1", "slide_2", "slide_3", "slide_4", "slide_5", "slide_6", "slide_7", "slide_8", "slide_9")
      somesheet_down.classList.add("slide_6")
    }
    if (scrollY > heights[4] + (heights[5] - heights[4]) / 2 &&
        scrollY < heights[5] + (heights[6] - heights[5]) / 2) {
      somesheet_down.classList.remove("slide_1", "slide_2", "slide_3", "slide_4", "slide_5", "slide_6", "slide_7", "slide_8", "slide_9")
      somesheet_down.classList.add("slide_7")
    }
    if (scrollY > heights[5] + (heights[6] - heights[5]) / 2 &&
        scrollY < heights[6] + (heights[7] - heights[6]) / 2) {
      somesheet_down.classList.remove("slide_1", "slide_2", "slide_3", "slide_4", "slide_5", "slide_6", "slide_7", "slide_8", "slide_9")
      somesheet_down.classList.add("slide_8")
    }
  }
}

let re_resizer = function () {
  let somesheet_down = document.getElementById("somesheet_down")
  let width = document.documentElement.clientWidth
  let height = document.documentElement.clientHeight
  let heights = []
  var elements = document.getElementsByClassName("fake_slide")
  if (somesheet_down) {
    somesheet_down.style.width=(width+"px")
    somesheet_down.style.height=(height+"px")
    for (var i = 0; i < elements.length; i++) {
      // elements[i].style.width=(width+"px")
      let hh = elements[i].offsetHeight
      if (hh < height) {
        hh = height
        elements[i].style.height=(height+"px")
      }
      if (i) {
        heights.push(heights[i - 1] + hh)
      } else {
        heights.push(hh)
      }
    }
    console.log(heights)
    return heights
  }
}

let re_resizer_listener = function () {
  re_resizer()
}

export default {
  name: "LandingPage",
  components: {
    Logotype,
    Hooper,
    Slide,
    LeftMenu,
    HeaDerIcons,
    SlideOneCommon,
    SlideTwoBitva,
    SlideThreeKonkurses,
    SlideFourDreamteam,
    SlideFiveStamps,
    SlideSixAgit,
    SlideSevenRazNaRaz,
    SlideEightPrizes,
    HooperPagination
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      initial_slide: 0,
      current_slide: 0,
      can_slide: true,
      is_auth: false
    }
  },
  computed: {
    ...mapGetters("user_store", {
      user_id: "user_id",
      username: "username",
    })
  },
  created () {
    // if (
    //   this.$route.params.initial_slide !== null &&
    //   this.$route.params.initial_slide !== undefined &&
    //   this.$route.params.initial_slide !== ""
    // ) {
    //   this.initial_slide = parseInt(this.$route.params.initial_slide, 10)
    //   this.current_slide = this.initial_slide
    // }
    // if (window.screen.availWidth < 1000) {
    //   this.can_slide = false
    // } else if (window.screen.availWidth >= 1000) {
    //   this.can_slide = true
    // }
    if (this.$route.query.ref) {
      localStorage.setItem("ref_link", this.$route.query.ref)
    }
  },
  async mounted () {
    window.addEventListener('scroll', scroll_listener)
    re_resizer()
    window.addEventListener("resize", re_resizer_listener)
    console.log("asdfasdf", this.user_id)
    await this.checkIsAuth()
  },
  // beforeRouteLeave () {
    // window.removeEventListener('scroll', scroll_listener)
    // window.removeEventListener('resize', re_resizer_listener)
  // },
  methods: {
    slideTo (slide_num) {
      this.$refs.hooper.slideTo(slide_num)
    },
    async checkIsAuth () {
      let access = await getAccessTokenSimple()
      if (access) {
        this.is_auth = true
      }
    },
    updateCarousel (payload) {
      this.current_slide = payload.currentSlide
    }
  }
}

</script>


<style lang="sass">

.top_plashka
  width: 100%
  height: 40px
  position: fixed
  top: 0
  left: 0
  z-index: 90
  &:before, &:after
    display: block
    content: ""
    top: 0
    width: 50%
    height: 100%
    position: absolute
    background-color: #100f14
    box-shadow: 0px 0px 5px 0px #100f14
  &:before
    left: -5px
    transform: rotate(-2deg)
  &:after
    right: -5px
    transform: rotate(2deg)
  .top_plashka_in
    position: absolute
    top: 0
    left: 0px
    width: 100%
    height: 30px
    background-color: #100f14
    box-shadow: 0px 0px 5px 0px #100f14
    @media (max-width: 800px)
      height: 50px
  .logo_v_plashke
    width: 262px
    position: absolute
    top: -2px
    left: 50%
    margin-left: -131px
    z-index: 100
    opacity: 0
    transition: opacity 0.2s ease
    @media (max-width: 800px)
      display: none !important
    img
      width: 100%
  .logo_v_plashke.visible
    opacity: 1

  

#images_preloader
  position: absolute
  top: 0
  left: 0
  width: 1px
  height: 1px
  overflow: hidden
  opacity: 0

.logotype_wrap
  position: absolute
  width: 100%
  top: 0
  left: 0
  z-index: 100
  @media (max-width: 800px)
    z-index: 90
    top: 50px
  .logotype
    a
      opacity: 1 !important

.left_menu_wrap, .login_register
  position: fixed !important
  z-index: 101

.left_menu_wrap
  z-index: 101
  height: 100%
  
#somesheet_down
  position: fixed
  z-index: 0
  transition: background-image 0.5s ease

#somesheet
  position: relative
  z-index: 1

.fake_slide
  width: 100%
  background: none !important

.slide_1
  background-color: #000  
  background-position: bottom center
  background-repeat: no-repeat
  background-image: url('~@/assets/images/first_slide_fans.jpg')
  background-size: cover

.slide_2
  background-color: #000
  background-position: center center
  background-repeat: no-repeat
  background-image: url('~@/assets/images/soccert_stadium_2.jpg')
  background-size: cover

.slide_3
  background-color: #000
  background-position: center center
  background-repeat: no-repeat
  background-image: url('~@/assets/images/third_screen_bg_blur.jpg')
  background-size: cover

.slide_4
  background-color: #000  
  background-position: center center
  background-repeat: no-repeat
  background-image: url('~@/assets/images/team_bg.jpg')
  background-size: cover

.slide_5
  background-color: #000  
  background-position: center center
  background-repeat: no-repeat
  background-image: url('~@/assets/images/fifth_screen_bg.jpg')
  background-size: cover

.slide_6
  background-color: #000  
  background-position: center center
  background-repeat: no-repeat
  background-image: url('~@/assets/images/six_screen_bg2.jpg')
  background-size: cover

.slide_7
  background-color: #000  
  background-position: center center
  background-repeat: no-repeat
  background-image: url('~@/assets/images/seven_screen_bg.jpg')
  background-size: cover

.slide_8
  background-color: #000  
  background-position: center right
  background-repeat: no-repeat
  background-image: url('~@/assets/images/habar.jpg')
  background-size: cover

.top_dark_line
  position: relative
  z-index: 98
  @media (max-width: 1400px)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 60px
    z-index: 98
    background: linear-gradient(#000, #00000000)
  
.top_dark_line.white_bg
  @media (max-width: 1400px)
    background: linear-gradient(#fff, #ffffff00)

#landing_slider_wrapper
  width: 100%
  height: 100%
  background-color: #000

ul.hooper-track
  margin: 0
  padding: 0

#resized_with_scroll_up, #resized_with_scroll_down
  position: fixed
  left: 50%
  margin-left: -300px
  height: 600px
  width: 600px
  border-radius: 1000px
  border: 10px solid #FE8B01
  background-color: #F2EEDE
  box-sizing: border-box
  z-index: 10
  transition: transform 0.2s ease, opacity 0.2s ease
  transform: scaleY(0)
  opacity: 0

#resized_with_scroll_up
  top: -300px

#resized_with_scroll_down
  bottom: -300px

// .login_register
//   //background-color: #000
//   top: 0 !important
//   right: 0
//   z-index: 1001
//   padding: 10px 30px
//   border-bottom-left-radius: 15px

.login_register_icons
  position: fixed !important
  z-index: 101
  top: 0 !important
  right: 0px
  z-index: 101
  
.login_register
  position: absolute
  top: 13px !important
  right: 15px
  z-index: 101
  @media (max-width: 700px)
    right: 10px
    top: 10px
  @media (max-width: 500px)
    top: 15px !important
  span
    color: #fff !important
    font-size: 16px
  a
    color: #fff !important
    font-size: 16px
    &:hover
      text-decoration: underline
  span, a
    @media (max-width: 700px)
      font-size: 14px

.login_register.white_bg
  span, a
    color: #000 !important

.hooper-pagination.is-vertical .hooper-indicator
  width: 10px

.hooper-indicator
  height: 10px
  background-color: #ffffff6e
  &:hover
    background-color: #fff

.hooper-indicator.is-active
  background-color: #fff

</style>
    