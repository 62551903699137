<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ИГРА «ДОБЫЧА БАЛЛОВ»")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      //- .subcontent_wrap.mt-5
        //- h3.mt-0 Чтобы начать игру, нажми кнопку ниже
  
      .subcontent_wrap.mt-5#with_wrap
        div
          p.mb-4(v-if="!orientation") Жми пробел, чтобы подпрыгивать!
          canvas#game
        
        #start_game_button
          v-btn.green_button(dark large) Нажми, чтобы начать игру
        #end_game_button(v-if="orientation") Закончить игру
        i#close_icon(v-else v-tippy content="Закончить игру")
      //- p.mt-7
        //- em Если игра не запускается — <span class="refresher" @click="Refresh">обнови страницу</span>
      .subcontent_wrap.mt-6
        h3.mt-0 Чекань мячик и зарабатывай баллы!
        ul
          li За каждую 1000 очков ты получаешь балл!
          li Каждый раз, когда ты бьёшь собственный рекорд (свыше 1000 очков), ты получаешь балл!
      
      .inventory_blocks.mt-5
        .coins_block
          strong Твой рекорд:
          p {{ record }}

        .voices_block
          strong Заработано баллов:
          p <span>{{ earned_coins }}</span>

      .subcontent_wrap.mt-5
        p.mb-0 В конце каждого месяца рекорды обнуляются. Игрок, установивший наибольший рекорд, получает звание «Король чеканки» и попадает в Зал Славы! 
        div.mt-4.mobile_button
          v-btn.green_button(
            v-if="voices && club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="Vote"
          ) Отдать голос
          v-btn.green_button(
            v-else-if="club.fc_id == my_club.fc_id"
            x-large
            dark
            color="#04841F"
            @click="goTo('/inside/shop')"
          ) Получить ещё голос
      BitvaTimer

      v-overlay.my_overlay(
        absolute
        :dark="false"
        :light="true"
        color="#ffffff"
        :value="loading"
      )
        v-progress-circular(
          indeterminate
          color="primary"
        )

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import { EndGame, GetBall, StartGame } from "../../../api/Inside"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters, mapActions } from "vuex"
import Player from "./Player.js"
import Ground from "./Ground.js"
import CactiController from "./CactiController.js"
import Score from "./Score.js"
import { tippy } from "vue-tippy"

export default {
  name: 'StampsRules',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu,
    tippy
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      record: 1100,
      earned_coins: 20,
      gameball_id: null,
      loading: false,
      orientation: null,
      game_paused: null,
      sub_menu: [
        {
          "title": "Игра",
          "link": "/inside/ball"
        },
        {
          "title": "Рейтинг игроков",
          "link": "/inside/ball-progress"
        },
        {
          "title": "Правила",
          "link": "/inside/ball-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  created () {
    this.CheckOrientation()
    window.onkeydown = function(e) {
      return e.keyCode !== 32 && e.key !== " "
    }
  },
  mounted () {
    let context = this
    window.addEventListener('focus', () => {
      context.game_paused = false
    })
    window.addEventListener('blur', () => {
      context.game_paused = true
    })
    this.GetBall()
  },
  activated () {
    this.CheckOrientation()
    this.GameBall()
  },
  methods: {
    ...mapActions("user_store", {
      update: "ACTION_UPDATE",
    }),
    Refresh () {
      this.$router.go(0)
    },
    CheckOrientation () {
      let context = this
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        const mql = window.matchMedia("(orientation: landscape)");
        if (mql.matches) {
          this.orientation = "landscape"
        } else {
          this.orientation = "portrait"
        }
        // Прослушивание события изменения ориентации
        mql.addListener(function(m) {
          if (m.matches) {
            context.orientation = "landscape"
            console.log("меня повернули", context.orientation)
          } else {
            context.orientation = "portrait"
            console.log("меня повернули", context.orientation)
          }
        });
      }
    },
    async GetBall () {
      let context = this
      let balldata = await GetBall(context)
      if (balldata) {
        this.record = balldata["data"]["record"]
        this.earned_coins = balldata["data"]["earned_coins"]
      }
    },
    async startNewGame () {
      let context = this
      // if (this.orientation) {
      //   let check_orientation = function () {
      //     if (context.orientation == "portrait") {
      //       context.$swal({
      //         "text": "Перед началом игры поверни телефон горизонтально",
      //         showConfirmButton: true,
      //         showCancelButton: true,
      //         confirmButtonText: "Повернул, начать",
      //         cancelButtonText: "Нет",
      //       }).then(async function (res) {
      //         if (res.isConfirmed) {
      //           if (context.orientation == "landscape") {
      //             let balldata = await StartGame(context)
      //             if (balldata) {
      //               context.gameball_id = balldata["data"]["gameball_id"]
      //             }
      //           } else {
      //             check_orientation()
      //           }
      //         }
      //       })
      //     }
      //   }
      //   check_orientation()
      // } else {
      //   let balldata = await StartGame(context)
      //   if (balldata) {
      //     this.gameball_id = balldata["data"]["gameball_id"]
      //   }
      // }
      let balldata = await StartGame(context)
      if (balldata) {
        this.gameball_id = balldata["data"]["gameball_id"]
      }
    },
    async EndGame (gameball_id, score) {
      let context = this
      let stopLoading = function () {
        context.loading = false
      }
      this.loading = true
      let data = {
        "gameball_id": gameball_id,
        "score": score,
      }
      let balldata = await EndGame(context, data)
      if (balldata) {
        console.log(balldata)
        let new_coins = this.coins + balldata["data"]["earned_coins"]
        console.log(new_coins)
        this.earned_coins = this.earned_coins + balldata["data"]["earned_coins"]
        // this.$store.commit("update", {
          // key: "coins",
          // value: new_coins,
        // })
        // this.update({
          // key: "coins",
          // value: new_coins,
        // })
        if (balldata["data"]["score"] > this.record) {
          this.record = balldata["data"]["score"]
        }
        // this.$store.dispatch('ACTION_UPDATE', {
          // key: "coins",
          // value: new_coins,
        // });
        this.update({
          key: "coins",
          value: new_coins
        })
        context.$swal({
          "icon": "success",
          "text": "Прогресс игры сохранён"
        }).then(function () {
          context.Refresh()
          // canResume()
          setTimeout(stopLoading, 200)
        })
      }
    },
    GameBall () {
      let context = this
      const canvas = document.getElementById("game");
      const ctx = canvas.getContext("2d");

      const GAME_SPEED_START = 0.94; // 1.0
      const GAME_SPEED_INCREMENT = 0.000012; // 0.00002
      // const GAME_SPEED_START = 0.000094; // 1.0
      // const GAME_SPEED_INCREMENT = 0.000000012; // 0.00002

      const GAME_WIDTH = 1000;
      const GAME_HEIGHT = 300;
      const PLAYER_WIDTH = 88 / 1.5; //58
      const PLAYER_HEIGHT = 94 / 1.5; //62
      const MAX_JUMP_HEIGHT = GAME_HEIGHT;
      const MIN_JUMP_HEIGHT = 150;
      const GROUND_WIDTH = 2400;
      const GROUND_HEIGHT = 24;
      const GROUND_AND_CACTUS_SPEED = 0.72; // 0.75

      const CACTI_CONFIG = [
        { width: 48 / 1.5, height: 150 / 1.5, image: require("./images/cactus_1.png") },
        { width: 98 / 1.5, height: 150 / 1.5, image: require("./images/cactus_2.png") },
        { width: 68 / 1.5, height: 105 / 1.5, image: require("./images/cactus_3.png") },
      ];

      //Game Objects
      let player = null;
      let ground = null;
      let cactiController = null;
      let score = null;

      let animation = null;

      let scaleRatio = null;
      let previousTime = null;
      let gameSpeed = GAME_SPEED_START;
      let gameOver = false;
      let hasAddedEventListenersForRestart = false;
      let waitingToStart = true;

      function createSprites() {
        const playerWidthInGame = PLAYER_WIDTH * scaleRatio;
        const playerHeightInGame = PLAYER_HEIGHT * scaleRatio;
        const minJumpHeightInGame = MIN_JUMP_HEIGHT * scaleRatio;
        const maxJumpHeightInGame = MAX_JUMP_HEIGHT * scaleRatio;

        const groundWidthInGame = GROUND_WIDTH * scaleRatio;
        const groundHeightInGame = GROUND_HEIGHT * scaleRatio;

        player = new Player(
          ctx,
          playerWidthInGame,
          playerHeightInGame,
          minJumpHeightInGame,
          maxJumpHeightInGame,
          scaleRatio
        );

        ground = new Ground(
          ctx,
          groundWidthInGame,
          groundHeightInGame,
          GROUND_AND_CACTUS_SPEED,
          scaleRatio
        );

        const cactiImages = CACTI_CONFIG.map((cactus) => {
          const image = new Image();
          image.src = cactus.image;
          return {
            image: image,
            width: cactus.width * scaleRatio,
            height: cactus.height * scaleRatio,
          };
        });

        cactiController = new CactiController(
          ctx,
          cactiImages,
          scaleRatio,
          GROUND_AND_CACTUS_SPEED
        );

        score = new Score(ctx, scaleRatio);
      }

      function setScreen() {
        scaleRatio = getScaleRatio();
        // canvas.width = GAME_WIDTH * scaleRatio;
        // canvas.height = GAME_HEIGHT * scaleRatio;
        // let elem = document.getElementById("with_wrap")
        // canvas.width = GAME_WIDTH
        // let width = elem.clientWidth / 100 * 98
        let width = document.documentElement.clientWidth / 100 * 96
        if (width > GAME_WIDTH) {
          width = GAME_WIDTH
        }
        let height = Math.min(
          window.innerHeight,
          document.documentElement.clientHeight - 20
        )
        if (height > GAME_HEIGHT) {
          height = GAME_HEIGHT
        }
        canvas.width = width
        canvas.height = height
        createSprites();
      }

      setScreen();
      //Use setTimeout on Safari mobile rotation otherwise works fine on desktop
      window.addEventListener("resize", () => setTimeout(setScreen, 500));

      if (screen.orientation) {
        screen.orientation.addEventListener("change", setScreen);
      }

      function getScaleRatio() {
        const screenHeight = Math.min(
          window.innerHeight,
          document.documentElement.clientHeight
        );
        // let elem = document.getElementById("with_wrap")
        const screenWidth = Math.min(
          window.innerWidth,
          // elem.innerWidth,
          document.documentElement.clientWidth
        );
        console.log(screenHeight, screenWidth)
        //window is wider than the game width
        // if (screenWidth / screenHeight < GAME_WIDTH / GAME_HEIGHT) {
        //   return screenWidth / GAME_WIDTH;
        // } else {
        //   return screenHeight / GAME_HEIGHT;
        // }
        return 1
      }

      function setupGameReset() {
        if (!hasAddedEventListenersForRestart) {
          hasAddedEventListenersForRestart = true;
          setTimeout(() => {
            window.addEventListener("keyup", reset, { once: true });
            window.addEventListener("touchstart", resetMob, { once: true });
          }, 10);
        }
      }

      function reset(e) {
        if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
          hasAddedEventListenersForRestart = false;
          gameOver = false;
          gameSpeed = 0
          waitingToStart = false;
          ground.reset();
          cactiController.reset();
          score.reset();
          gameSpeed = GAME_SPEED_START;
        }
      }
      function resetMob() {
        hasAddedEventListenersForRestart = false;
        gameOver = false;
        gameSpeed = 0
        waitingToStart = false;
        ground.reset();
        cactiController.reset();
        score.reset();
        gameSpeed = GAME_SPEED_START;
      }

      // function startGame(e) {
      //   if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
      //     context.startNewGame()
      //     hasAddedEventListenersForRestart = false;
      //     gameOver = false;
      //     gameSpeed = 0
      //     waitingToStart = false;
      //     ground.reset();
      //     cactiController.reset();
      //     score.reset();
      //     gameSpeed = GAME_SPEED_START;
      //     animation = requestAnimationFrame(gameLoop);
      //   }
      // }

      function startGameMob() {
        console.log("Game started", context.orientation)
        let startingTheGame = function () {
          resetMob()
          context.startNewGame()
          hasAddedEventListenersForRestart = false;
          gameOver = false;
          gameSpeed = 0
          waitingToStart = false;
          ground.reset();
          cactiController.reset();
          score.reset();
          gameSpeed = GAME_SPEED_START;
          animation = requestAnimationFrame(gameLoop);
        }
        let check_orientation = function () {
          document.documentElement.requestFullscreen()
          if (context.orientation == "portrait") {
            context.$swal({
              "text": "Перед началом игры поверни телефон горизонтально",
              showConfirmButton: true,
              showCancelButton: true,
              confirmButtonText: "Повернул, начать",
              cancelButtonText: "Нет",
            }).then(async function (res) {
              if (res.isConfirmed) {
                if (context.orientation == "landscape") {
                  startingTheGame()
                } else {
                  check_orientation()
                  // window.addEventListener("touchstart", startGameMob, { once: true });
                }
              } else {
                // window.addEventListener("touchstart", startGameMob, { once: true });
                context.Refresh()
              }
            })
          } else {
            startingTheGame()
          }
        }
        check_orientation()
      }

      function showGameOver() {
        cancelAnimationFrame(animation)
        const fontSize = 30 * scaleRatio;
        ctx.font = `${fontSize}px Roboto`;
        ctx.fillStyle = "grey";
        const x = canvas.width / 2.8;
        const y = canvas.height / 2;
        ctx.fillText("Игра окончена", x, y);
        let earned_score = Math.floor(score.score)
        // let canResume = function () {
        //   window.addEventListener("keyup", startGame, { once: true });
        //   window.addEventListener("touchstart", startGame, { once: true });
        // }
        context.EndGame(context.gameball_id, earned_score)
      }

      function showStartGameText() {
        const fontSize = 20 * scaleRatio;
        ctx.font = `${fontSize}px Roboto`;
        ctx.fillStyle = "grey";
        const x = canvas.width / 3.3;
        const y = canvas.height / 2;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
          ctx.fillText("Нажми сюда, чтобы начать", x, y);
        } else {
          ctx.fillText("Нажми пробел, чтобы начать", x, y);
        }
      }

      function updateGameSpeed(frameTimeDelta) {
        gameSpeed += frameTimeDelta * GAME_SPEED_INCREMENT;
      }

      function clearScreen() {
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      function gameLoop(currentTime) {
        if (context.game_paused) {
          animation = requestAnimationFrame(gameLoop);
        } else {
          console.log("gameLoop")
          if (previousTime === null) {
            previousTime = currentTime;
            animation = requestAnimationFrame(gameLoop);
            return;
          }
          const frameTimeDelta = currentTime - previousTime;
          previousTime = currentTime;
          clearScreen();
          if (!gameOver && !waitingToStart) {
            //Update game objects
            ground.update(gameSpeed, frameTimeDelta);
            cactiController.update(gameSpeed, frameTimeDelta);
            player.update(gameSpeed, frameTimeDelta);
            score.update(frameTimeDelta);
            updateGameSpeed(frameTimeDelta);
          }
          if (!gameOver && cactiController.collideWith(player)) {
            gameOver = true;
            setupGameReset();
            score.setHighScore();
          }
          //Draw game objects
          ground.draw();
          cactiController.draw();
          player.draw();
          score.draw();
          if (gameOver) {
            showGameOver();
            return
          }
          if (waitingToStart) {
            showStartGameText();
          }
          animation = requestAnimationFrame(gameLoop);
        }
      }

      // window.addEventListener("keyup", startGame, { once: true });
      // window.addEventListener("touchstart", startGameMob, { once: true });
      let start_game_button = document.getElementById('start_game_button')
      let with_wrap = document.getElementById('with_wrap')
      let game = document.getElementById('game')
      let end_game_button = document.getElementById('end_game_button')
      let close_icon = document.getElementById('close_icon')
      console.log("orientation", context.orientation)
      if (close_icon) {
        console.log("close_icon")
        close_icon.onclick = function (event) {
          showGameOver()
          event.preventDefault()
        }
      }
      if (end_game_button) {
        console.log("end_game_button")
        end_game_button.addEventListener("touchstart", showGameOver, { once: true });
        // end_game_button.onclick = function (event) {
        //   showGameOver()
        //   event.preventDefault()
        // }
      }
      start_game_button.onclick = function (event) {
        start_game_button.classList.add("active")
        with_wrap.classList.add("active")
        if (end_game_button) {
          end_game_button.classList.add("active")
        }
        if (close_icon) close_icon.classList.add("active");
        game.classList.add("active")
        startGameMob()
        event.preventDefault()
      }
      let cancelGame = function () {
        cancelAnimationFrame(animation);
      }
      setTimeout(cancelGame, 50)
    }
  }
}

</script>


<style lang="sass" scoped>

@media (max-height: 500px)
  .address-bar
    display: none

#game
  display: none
#game.active
  display: block

#with_wrap
  width: 100%
  height: 100px
  position: relative
#with_wrap.active
  width: 100%
  height: 100%
  position: fixed
  top: 0
  left: 0
  z-index: 1002
  margin-top: 0 !important
  background-color: #fff
  padding-bottom: 0 !important
  display: flex
  align-items: center
  justify-content: center
  flex-wrap: wrap
  p
    width: 100%
    text-align: center

// #end_game_button
//   display: none
#end_game_button
  display: inline-block
  text-decoration: underline
  position: absolute
  top: 10px
  left: 20px
  cursor: pointer

#start_game_button
  width: 100%
  height: 100px
  position: absolute
  top: 0
  left: 0
  z-index: 10
  background-color: #fff
  display: flex
  align-items: center
  justify-content: center

#start_game_button.active
  display: none

#close_icon
  display: none
#close_icon.active
  display: block
  position: absolute
  top: 10px
  right: 33px
  cursor: pointer
  z-index: 10
  transform: rotate(45deg) scale(2)
  &:before, &:after
    display: block
    position: absolute
    content: ""
  &:before
    width: 2px
    height: 16px
    left: 7px
    top: 0
    background-color: #000
  &:after
    height: 2px
    width: 16px
    top: 7px
    left: 0
    background-color: #000




.subcontent_wrap
  width: 100%
  text-align: left
  border-bottom: 1px dotted #DDDDDD
  padding-bottom: 20px
  ul
    margin-top: 10px
    padding: 0
    li
      display: block
      padding-left: 15px
      position: relative
      margin-bottom: 2px
      &:before
        content: ""
        display: block
        width: 6px
        height: 6px
        border-radius: 10px
        background-color: #2c3e50
        position: absolute
        left: 0
        top: 8px
      a
        &:hover
          text-decoration: none

.inventory_blocks
  width: 100%
  display: flex
  justify-content: space-between
  align-items: stretch
  flex-wrap: wrap
  margin-top: 10px
  .coins_block, .voices_block
    width: calc( 50% - 10px )
    height: auto
    background-color: #375567
    color: #fff
    border-radius: 10px
    position: relative
    padding: 10px 5px 15px 12px
    @media (max-width: 550px)
      width: 100% !important
    div
      width: 100%

    strong
      font-weight: 300
      text-align: left
      line-height: 20px
      display: inline-block
      font-size: 14px
      width: 100%
      @media (max-width: 550px)
        left: 0
        font-size: 13px
        width: 100%
        text-align: left
    p
      width: 100%
      font-size: 20px
      font-weight: 400
      margin: 0
      padding: 0
      position: relative
      text-align: left
      line-height: 24px
      top: 3px
      display: flex
      align-items: center

      @media (max-width: 550px)
        font-size: 21px

  .coins_block
    @media (max-width: 550px)
      margin-bottom: 15px

p
  em
    line-height: 16px
    span.refresher
      display: inline-block
      border-bottom: 1px dashed #393939
      cursor: pointer
      &:hover
        border-bottom: 1px dashed transparent

.content_wrap
  position: relative

</style>
    