/* eslint-disable */

import $ from "./jquery.js"
// import Swal from 'sweetalert2'

let gem_size = function (countRows, countCols, container) {
	let width = window.innerWidth
	let height = window.innerHeight
	// let with_wrap = document.getElementById('with_wrap')
	document.body.style.height = height + "px"
	document.body.style.overflow = "hidden"
	let gem_size = 50
	let padding_top = 90
	let bonus = 120
	if (height < 700) {
		padding_top = 50
		bonus = 80
	}
	if (width < height) {
		gem_size = (width - 60) / countCols
		let all_height = gem_size * countRows + padding_top + bonus + 75 + 20 + 40
		console.log("height: ", height, ";   ", "all_height: ", all_height)
		if (all_height > height) {
			gem_size = (height - (padding_top + bonus + 75 + 20 + 40)) / countRows
			console.log(gem_size)
		}
		container.style.width = gem_size * countCols + 10 + "px"
		return gem_size
	} else  {
		gem_size = (height - (padding_top + bonus + 75 + 20 + 40)) / countRows
		let all_width = gem_size * countCols + 30
		if (all_width > width) {
			gem_size = (width - 60) / countCols
		}
		container.style.width = gem_size * countCols + 10 + "px"
		return gem_size
	}
	// if (width > height - 200) {
	// 	if (height < 700 ) {
	// 		// with_wrap.style.paddingTop = "45px"
	// 		gem_size = (height - 270) / countRows
	// 	} else {
	// 		gem_size = (height - 360) / countRows
	// 	}
	// 	container.style.width = gem_size * countCols + 10 + "px"
	// 	return gem_size
	// } else {
	// 	// with_wrap.style.paddingTop = "90px"
	// 	let gem_size = (width - 60) / countCols
	// 	container.style.width = gem_size * countCols + 10 + "px"
	// 	return gem_size
	// }
}

let superInitGame = function (context, container, countRows, countCols, boxesplaces, shopcount, purpose, purposecolor, imagesCoin, bonuses, box, smoke, images_classes, moves) {
	let mouse_down_on_elem = false
	let win = false
	let blues = 0
	let config = {
		containerColorBG: "#353336",
		contentColorBG: "#b8b8b8",
		countRows: countRows,
		countCols: countCols,
		offsetBorder: 5,
		borderRadius: 10,
		gemSize: gem_size(countRows, countCols, container),
		imagesCoin: imagesCoin, //"./images/purple.png", "./images/orange.png"
		box: box,
		smoke: smoke,
		// boxesplaces: [[0,0], [0,6], [6,0], [6,6], [3,1], [3,5]],
		boxesplaces: boxesplaces,
		bonuses: bonuses,
		gemClass:"gem",
		bonusClass: "bonus",
		spinnerClass: "spinner",
		gemIdPrefix: "gem",
		bonusIdPrefix: "bonus",
		spinerIdPrefix: "spiner",
		gameStates: ["pick", "switch", "revert", "remove"],
		shopcount: shopcount,
		gameState: "",
		spinerCount: 0,
		spinners: [],
		spinnerscoords: [],
		movingItems: 0,
		moves: moves,
		purpose: purpose,
		purposecolor: purposecolor,
		postBonuses: [],
		spinnerForSlowRemove: false,
		choicebonus: -1,
		pause: false,
		start: true,
		images_classes: images_classes,
	}
	let with_wrap = document.getElementById("with_wrap");
	let zaglushka_hidden = document.getElementById("zaglushka_hidden");
	let player = {
		selectedRow: -1,
		selectedCol: -1,
		posX: "",
		posY: ""
	}
	let components = {
		container: container,
		content: document.createElement( "div" ),
		wrapper: document.createElement( "div" ),
		cursor: document.createElement( "div" ),
		score: document.createElement( "div" ),
		purpose: document.createElement( "div" ),
		blockBonuses: document.createElement( "div" ),
		blockBlues: document.createElement( "div" ),
		gems: new Array(),
	}
	// start Game
	initGame();
	// Инициализация всех составляющих игры
	function initGame () {
		document.body.style.margin = "0px";
		createPage();
		createContentPage();
		createWrapper();
		createCursor();
		createGrid();
		createScore();
		CreatePurpose();
		CreateBlockBonuses();
		CreateBlockBlues();
		// Переключаем статус игры на "выбор"
		config.gameState = config.gameStates[ 0 ];
	}
	// Создание обертки для страницы
	function createPage() {
		components.container.style.backgroundColor = config.containerColorBG;
		//components.container.style.height = "100vh";
		//components.container.style.overflow = "hidden";
		components.container.style.display = "flex";
		components.container.style.alignItems = "center";
		components.container.style.justifyContent = "center";
		//document.body.append( components.container );
	}
	// Создание обертки с контентом
	function createContentPage () {
		components.content.style.padding = config.offsetBorder + "px";
		components.content.style.width = 	(config.gemSize * config.countCols) +
											(config.offsetBorder * 2) + "px";
		components.content.style.height = 	(config.gemSize * config.countRows) +
											(config.offsetBorder * 2) + "px";
		components.content.style.backgroundColor = config.contentColorBG;
		components.content.style.boxShadow = config.offsetBorder + "px";
		components.content.style.borderRadius = config.borderRadius + "px";
		components.content.style.boxSizing = "border-box";
		components.container.append( components.content );
	}
	// Создание обертки для монет и очков
	function createWrapper () {
		components.wrapper.style.position = "relative";
		components.wrapper.style.height = "100%";
		window.addEventListener("resize", function() { DoResize() });
		// components.wrapper.addEventListener("click", function(event) { handleTouchMove(event, event.target) });
		components.container.addEventListener("click", function(event) { handleTouchMove(event, event.target) });
		components.content.append( components.wrapper );
	}
	// Создание курсора для выделения монет
	function createCursor () {
		components.cursor.id = "marker";
		components.cursor.style.width = config.gemSize + "px";
		components.cursor.style.height = config.gemSize + "px";
		components.cursor.style.border = "5px solid white";
		components.cursor.style.borderRadius = "31px";
		components.cursor.style.position = "absolute";
		components.cursor.style.display = "none";
		// components.cursor.style.marginLeft = "3px";
		components.wrapper.append( components.cursor );
	}
	// Показать курсор
	function cursorShow () {
		components.cursor.style.display = "block";
	}
	// Скрыть курсор
	function cursorHide () {
		components.cursor.style.display = "none";
	}
	// Создание блока для количество ходов
	function createScore () {
		components.score.classList.add("scores_block")
		updateScore();
		components.container.prepend( components.score );
	}
	// создание блока с целями
	function CreatePurpose () {
		components.purpose.classList.add("purpose_block")
		updatePurpose();
	}
	// бонусы из магазина
	function CreateBlockBonuses() {
		components.blockBonuses.classList.add("bonuses_block")
		components.container.append( components.blockBonuses );
		for (var i = 0; i<config.bonuses.length; i++) {
			CreateBonusforShop(i * config.gemSize * 1.2, i, config.bonuses[i], config.shopcount[i])
		}
		// CreateBonusforShop(null, 4, config.bonuses[4], config.shopcount[4])
		// CreateBonusforShop(null, 1, config.bonuses[1], config.shopcount[1])
		// CreateBonusforShop(null, 2, config.bonuses[2], config.shopcount[2])
		// CreateBonusforShop(null, 3, config.bonuses[3], config.shopcount[3])
		// CreateBonusforShop(null, 0, config.bonuses[0], config.shopcount[0])
	}
	function CreateBlockBlues() {
		components.blockBlues.classList.add("blues_block")
		// with_wrap.append( components.blockBlues );
		components.container.append( components.blockBlues );
		components.blockBlues.innerHTML = "<span class='small_vote_icon'></span> <span>Синий:</span> " + blues
	}
	// создание бонусов для магазина
	function CreateBonusforShop(t, row, img, count) {
		let bonus = document.createElement("div");
		bonus.classList.add( config.bonusClass );
		bonus.id = config.bonusIdPrefix + '_' + row;
		// console.log(img)
		bonus.style.backgroundImage = "url("+ img +")";
		let bonus_text = document.createElement( "span" );
		bonus_text.innerText = count;
		bonus.append(bonus_text);
		components.blockBonuses.append( bonus );
	}
	// Обновить ходы на странице
	function updateScore () {
		components.score.innerHTML = '<strong>' + config.moves + '</strong>';
		// components.container.prepend( components.score );
	}
	// Обновить цели на странице
	function updatePurpose () {
		let colors = {"0": "Красный:", "1": "Зелёный:", "2": "Жёлтый:", "3": "Синий:", "4": "Оранжевый:", "5": "Фиолетовый:"}
		components.purpose.innerHTML = ''
		for (var i=0; i < config.purpose.length; i++) {
			if (i) { components.purpose.innerHTML += ';&nbsp;&nbsp;&nbsp;' }
			components.purpose.innerHTML += colors[config.purposecolor[i]] + '&nbsp;<strong>' + config.purpose[i] + '</strong>';
		}
		components.container.prepend( components.purpose );
	}
	// Уменьшение ходов
	function scoreInc () {
		if (config.moves > 0) { config.moves -= 1;}
		updateScore();
		// if (config.moves == 0) {
		// 	alert('Последний ход');
		// }
	}
	// Уменьшение цели
	function PurposeInc (color) {
		// let colors = {"0": "red", "1": "green", "2": "blue", "3": "yellow", "4": "orange", "5": "purple"}
		var win = true;
		// console.log(color)
		for (var i = 0; i<config.purposecolor.length; i++) {
			// let color_url = colors[config.purposecolor[i]]
			// let color_url = config.imagesCoin[config.purposecolor[i]]
			// if (color == 'url("' + color_url + '")') {
			// console.log(config.images_classes)
			// console.log(config.purposecolor)
			// console.log(config.purposecolor[i], color, config.images_classes.indexOf(color))
			if (config.purposecolor[i] == config.images_classes.indexOf(color)) {
				if (config.purpose[i] > 0) {config.purpose[i] -= 1}
			}
		}
		for (var i = 0; i<config.purposecolor.length; i++) {
			if (config.purpose[i] > 0) {win = false;}
		}
		if (color == "blue") {
			blues += 1
			components.blockBlues.innerHTML = "<span class='small_vote_icon'></span> <span>Синий:</span> " + blues
		}
		updatePurpose();
		// if (win) {alert('Вы победили');}
		if (win) {return true}
	}
	// Создание объекта
	function createGem ( t, l, row, col, img, index ) {
		let coin = document.createElement("div");
		coin.classList.add( config.gemClass );
		coin.id = config.gemIdPrefix + '_' + row + '_' + col;
		coin.style.boxSizing = "border-box";
		coin.style.cursor = "pointer";
		coin.style.position = "absolute";
		coin.style.top = t + "px";
		coin.style.left = l + "px";
		coin.style.width = config.gemSize + "px";
		coin.style.height = config.gemSize + "px";
		coin.style.border = "1p solid transparent";
		if (index || index === 0) {
			coin.style.backgroundImage = "url("+ config.imagesCoin[index] +")";
			coin.setAttribute("data-color", config.images_classes[index]);
		} else {
			coin.style.backgroundImage = "url("+ img +")";
		}
		coin.style.backgroundSize = "contain";
		coin.style.backgroundPosition = "center";
		if (config.start) {
			coin.style.opacity = "0";
			$(coin).animate( {
					opacity: "1",
				},
				{
					duration: 1000,
				}
			);
		}
		components.wrapper.append( coin );
		// coin.addEventListener('touchstart', handleTouchStart, false);
		// coin.addEventListener('touchmove', handleTouchSwipe, false);
		// coin.addEventListener('mousedown', handleTouchStartMdown, false);
	}
	function DoResize() {
		// config.gemSize = (container.offsetWidth - 10) / config.countRows;
		config.gemSize = gem_size(countRows, countCols, container)
		components.content.style.width = 	(config.gemSize * config.countCols) +
											(config.offsetBorder * 2) + "px";
		components.content.style.height = 	(config.gemSize * config.countRows) +
											(config.offsetBorder * 2) + "px";
		components.cursor.style.width = config.gemSize + "px";
		components.cursor.style.height = config.gemSize + "px";
		player.selectedRow = -1;
		player.selectedCol = -1;
		cursorHide()
		for(let i = 0; i < config.countRows; i++ ) {
			for(let j = 0; j < config.countCols; j++ ) {
				let image_elem = document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j );
				var image = image_elem.style.backgroundImage;
				try {
					components.wrapper.removeChild(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ))
					if (image_elem.hasAttribute("data-color")) {
						createGem(i * config.gemSize, j * config.gemSize, i, j, image.slice(5, -2), config.images_classes.indexOf(image_elem.getAttribute("data-color")));
					} else {
						createGem(i * config.gemSize, j * config.gemSize, i, j, image.slice(5, -2));
					}
				} catch (e) { continue }
			}
		}
	}
	// создание летающего спинера
	function createspiner ( t, l, row, col) {
		config.spinerCount++;
		let spiner = document.createElement("div");
		spiner.classList.add( config.spinnerClass );
		spiner.id = config.spinerIdPrefix + '_' + row + '_' + col + '_' + config.spinerCount;
		spiner.style.boxSizing = "border-box";
		spiner.style.cursor = "pointer";
		spiner.style.position = "absolute";
		spiner.style.top = t + "px";
		spiner.style.left = l + "px";
		spiner.style.width = config.gemSize + "px";
		spiner.style.height = config.gemSize + "px";
		spiner.style.border = "1px solid transparent";
		let spinner_url = config.bonuses[0]
		spiner.style.backgroundImage = "url("+ spinner_url +")";
		spiner.style.backgroundSize = "100%";
		components.wrapper.append( spiner );
	}
	// Проверка входит ли элемент в массив
	function includ (element) {
		for (var i = 0; i < config.boxesplaces.length; i++)
		{
			if (element[0] == config.boxesplaces[i][0] && element[1] == config.boxesplaces[i][1]) {return true}
		}
		return false
	}
	// Создание и наполнение сетки для монет
	function createGrid() {
		config.start = true;
		// Создание пустой сетки
		var firstCreate = true;
		if (components.gems.length != 0) { firstCreate = false; }

		for(let i = 0; i < config.countRows; i++) {
			if (firstCreate) {components.gems[i] = new Array();}
			for(let j = 0; j < config.countCols; j++) {
				components.gems[i][j] = null;
			}
		}
		// Заполняем сетку
		for(let i = 0; i < config.countRows; i++ ) {
			for(let j = 0; j < config.countCols; j++ ) {
				if (includ([i, j])) {
					// коробки (неподвижный объект)
					components.gems[i][j] = -2;
					if (firstCreate) {createGem( i * config.gemSize, j * config.gemSize, i, j, config.box );}
					else { components.wrapper.removeChild(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ));
								createGem( i * config.gemSize, j * config.gemSize, i, j, config.box );}
				} else {
					do{
						components.gems[i][j] = Math.floor(Math.random() * config.imagesCoin.length);
					} while( isStreak(i, j) );
					if (firstCreate) {createGem( i * config.gemSize, j * config.gemSize, i, j, config.imagesCoin[ components.gems[i][j] ], components.gems[i][j] );}
					else {
						components.wrapper.removeChild(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ));
						createGem( i * config.gemSize, j * config.gemSize, i, j, config.imagesCoin[ components.gems[i][j] ], components.gems[i][j] ); }
				}
			}
		}
		if (!MovePossibility()) {createGrid()}
		config.start = false;
	}
	//проверка возможности хода
	function MovePossibility() {
		var pos = false;
		for(let i = 0; i < config.countRows; i++ ) {
			for(let j = 0; j < config.countCols; j++ ) {
				if (components.gems[i][j] == -2) {continue}
				if (components.gems[i][j] < -2) {pos = true}
				if (i > 0) {
					[components.gems[i][j], components.gems[i - 1][j]] = [components.gems[i - 1][j], components.gems[i][j]]
					if ((isStreak(i, j) || components.gems[i][j] < -2) && (components.gems[i - 1][j] != -2)) { pos = true;}
					[components.gems[i][j], components.gems[i - 1][j]] = [components.gems[i - 1][j], components.gems[i][j]]
				}
				if (i < config.countRows - 1) {
					[components.gems[i][j], components.gems[i + 1][j]] = [components.gems[i + 1][j], components.gems[i][j]]
					if ((isStreak(i, j) || components.gems[i][j] < -2)  && (components.gems[i + 1][j] != -2)) { pos = true;}
					[components.gems[i][j], components.gems[i + 1][j]] = [components.gems[i + 1][j], components.gems[i][j]]
				}
				if (j > 0) {
					[components.gems[i][j], components.gems[i][j - 1]] = [components.gems[i][j - 1], components.gems[i][j]]
					if ((isStreak(i, j) || components.gems[i][j] < -2)  && (components.gems[i][j - 1] != -2)) { pos = true;}
					[components.gems[i][j], components.gems[i][j - 1]] = [components.gems[i][j - 1], components.gems[i][j]]
				}
				if (j < config.countCols - 1) {
					[components.gems[i][j], components.gems[i][j + 1]] = [components.gems[i][j + 1], components.gems[i][j]]
					if ((isStreak(i, j) || components.gems[i][j] < -2)  && (components.gems[i][j + 1] != -2)) { pos = true;}
					[components.gems[i][j], components.gems[i][j + 1]] = [components.gems[i][j + 1], components.gems[i][j]]
				}
			}
		}
		return pos
	}
	// Проверка на группу сбора
	function isStreak( row, col ) {
		return isVerticalStreak( row, col ) > 1 || isHorizontalStreak( row, col ) > 1 || isSquareStreak( row, col );
	}
	// Проверка на группу сбора по колонкам
	function isVerticalStreak( row, col ) {
		let gemValue = components.gems[row][col];
		if (gemValue <= -2) {return 0;}
		let streak = 0;
		let tmp = row;
		while (tmp > 0 && components.gems[tmp - 1][col] == gemValue) {
			streak++;
			tmp--;
		}
		tmp = row;
		while(tmp < config.countRows - 1 && components.gems[tmp + 1][col] == gemValue){
			streak++;
			tmp++;
		}
		return streak;
	}
	// Проверка на группу сбора по строкам
	function isHorizontalStreak( row, col ) {
		let gemValue = components.gems[row][col];
		if (gemValue <= -2) {return 0;}
		let streak = 0;
		let tmp = col;
		while(tmp > 0 && components.gems[row][tmp - 1] == gemValue){
			streak++;
			tmp--;
		}
		tmp = col;
		while(tmp < config.countCols - 1 && components.gems[row][tmp + 1] == gemValue){
			streak++;
			tmp++;
		}
		return streak;
	}
	// Проверка на квадратную группу сбора
	function isSquareStreak( row, col ) {
		let gemValue = components.gems[row][col];
		if (gemValue <= -2) {return 0;}
		let streak = 0;
		if (row > 0 && col > 0) {
			if (components.gems[row][col - 1] == gemValue && components.gems[row - 1][col] == gemValue && components.gems[row - 1][col - 1] == gemValue)
			{ streak++;}
		}
		if (row < config.countRows - 1 && col > 0) {
			if (components.gems[row][col - 1] == gemValue && components.gems[row + 1][col] == gemValue && components.gems[row + 1][col - 1] == gemValue)
			{ streak++;}
		}
		if (row > 0 && col < config.countCols - 1) {
			if (components.gems[row][col + 1] == gemValue && components.gems[row - 1][col] == gemValue && components.gems[row - 1][col + 1] == gemValue)
			{ streak++;}
		}
		if (row < config.countRows - 1 && col < config.countCols - 1) {
			if (components.gems[row][col + 1] == gemValue && components.gems[row + 1][col] == gemValue && components.gems[row + 1][col + 1] == gemValue)
			{ streak++;}
		}
		return streak > 0;
	}
	// цвет для кубка и подобного
	function createColor() {
		var color = 0;
		for(let i = 0; i < config.countRows; i++ ) {
			for(let j = 0; j < config.countCols; j++ ) {
				if (config.purposecolor.indexOf(components.gems[i][j]) != -1) {return components.gems[i][j]}
				if (components.gems[i][j] > -1) {color = components.gems[i][j]}
			}
		}
		return color
	}

	// Обработчик клика
	function handleTouchMove ( event, target ) {
		// Если это элемент с классом config.bonusClass
		// и
		// Если подходящее состояние игры
		if (config.pause || config.gameState == config.gameStates[ 3 ] || config.gameState == config.gameStates[ 1 ]) {return}

		// Кликаем по бонусу внизу
		if ( target.classList.contains( config.bonusClass )) {
			player.selectedRow = -1;
			player.selectedCol = -1;
			let row = parseInt( target.getAttribute( "id" ).split( "_" )[ 1 ] );
			if (config.choicebonus == -1) {
				if (config.shopcount[row] == 0) {
					context.$swal.fire("Данного бустера нет в наличии")
					return
				}
				// cursorShow();
				// components.cursor.style.top =  parseInt( config.countCols * config.gemSize * 1.1 + target.style.bottom ) + "px";
				// components.cursor.style.left = parseInt( target.style.left ) + "px";
				let bonuses = document.getElementsByClassName("bonus")
				for (let i=0; i<bonuses.length; i++) {
					bonuses[i].classList.remove("selected_bonus")
				}
				target.classList.add("selected_bonus")
				config.choicebonus = -row - 3;
			} else {
				// cursorHide();
				// target.classList.remove("selected_bonus")
				let bonuses = document.getElementsByClassName("bonus")
				for (let i=0; i<bonuses.length; i++) {
					bonuses[i].classList.remove("selected_bonus")
				}
				config.choicebonus = -1;
			}
		}

		// Если это элемент с классом config.gameClass
		// и
		// Если подходящее состояние игры
		if ( target.classList.contains( config.gemClass ) && config.gameState == config.gameStates[ 0 ]) {
		// if ( target.classList.contains( config.gemClass ) && config.gameStates[ 0 ]) {
			// определить строку и столбец
			let row = parseInt( target.getAttribute( "id" ).split( "_" )[ 1 ] );
			let col = parseInt( target.getAttribute( "id" ).split( "_" )[ 2 ] );
			//делаем бонус
			// Выделяем гем курсором
			if (components.gems[row][col] == -2) {return}

			// Ставим бонус на поле
			if (config.choicebonus <= -2) {
				let bonusesname = {0: "spinner_dict_id", 1: "rocket_hor_dict_id", 2: "rocket_ver_dict_id", 3: "bomb_dict_id", 4: "cup_dict_id"}
				// context.$swal("Ты поставил " + bonusesname[-config.choicebonus - 3])
				// alert("Ты поставил " + bonusesname[-config.choicebonus - 3])
				context.SpendBooster(bonusesname[-config.choicebonus - 3])
				let bonuses = document.getElementsByClassName("bonus")
				for (let i=0; i<bonuses.length; i++) {
					bonuses[i].classList.remove("selected_bonus")
				}
				cursorHide();
				components.gems[row][col] = config.choicebonus
				let pictures = {"-3": config.bonuses[0], "-4": config.bonuses[1], "-5": config.bonuses[2], "-6": config.bonuses[3], "-7": config.bonuses[4]};
				document.querySelector( "#" + config.gemIdPrefix + "_" + row + "_" + col ).style.backgroundImage = "url("+ pictures[config.choicebonus] +")";
				if (	config.shopcount[-components.gems[row][col] - 3] > 0) {
					config.shopcount[-components.gems[row][col] - 3] -= 1
					let bonuses = document.getElementsByClassName("bonus")
					console.log("bonuses.length", bonuses.length)
					for (let i=0; i<bonuses.length; i++) {
						bonuses[i].innerHTML = ""
						// bonuses[i].innerText = config.shopcount[i]
						let bonus_text = document.createElement( "span" );
						console.log("config.shopcount[i]", config.shopcount[i])
						bonus_text.innerText = config.shopcount[i];
						
						bonuses[i].append(bonus_text);
					}
				}
				config.choicebonus = -1
				return
			}
			cursorShow();
			components.cursor.style.top = parseInt( target.style.top ) + "px";
			components.cursor.style.left = parseInt( target.style.left ) + "px";
			// Если это первый выбор, то сохраняем выбор
			if( player.selectedRow == -1 ) {
				player.selectedRow = row;
				player.selectedCol = col;
			} else {
				// двойной клик по бонусу
				if (player.selectedRow == row && player.selectedCol == col && components.gems[row][col] <= -2) {
					cursorHide();
					DoBonus(row, col, createColor())
					// console.log(config.spinners, config.spinnerscoords)
					// console.log(1)
					function rotate_timeout () {
						rotate_spinner(config.spinners, 500, config.spinnerscoords, config.gemSize);
						config.spinners = [];
						config.spinnerscoords = [];
					}
					setTimeout(rotate_timeout);
					for (let i = 0; i < config.countRows; i++ ) {
						for (let j = 0; j < config.countCols; j++ ) {
							if (components.gems[ i ][ j ] == -1) {
								document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).classList.add( "remove" );
							}
						}
					}
					config.gameState = config.gameStates[ 3 ];
					gemFade(true);
					scoreInc();
				} else {
					// Если гем находится радом с первым выбором
					// то меняем их местами
					if ( ( Math.abs( player.selectedRow - row ) == 1 && player.selectedCol == col ) ||
						( Math.abs( player.selectedCol - col ) == 1 && player.selectedRow == row ) ){
							cursorHide();
							// После выбора меняем состояние игры
							config.gameState = config.gameStates[1];
							// сохранить позицию второго выбранного гема
							player.posX = col;
							player.posY = row;
							// поменять их местами
							gemSwitch();
					} else {
						// Если второй выбор произошел не рядом,
						// то делаем его первым выбором.
						player.selectedRow = row;
						player.selectedCol = col;
					}
				}
			}
		}
	}

	// Меняем гемы местами
	function gemSwitch () {
		let yOffset = player.selectedRow - player.posY;
		let xOffset = player.selectedCol - player.posX;
		// Метка для гемов, которые нужно двигать
		document.querySelector("#" + config.gemIdPrefix + "_" + player.selectedRow + "_" + player.selectedCol).classList.add("switch");
		document.querySelector("#" + config.gemIdPrefix + "_" + player.selectedRow + "_" + player.selectedCol).setAttribute("dir", "-1");
		if (!(components.gems[player.posY][player.posX] <= -2 && components.gems[player.selectedRow][player.selectedCol] <= -2)) {
			document.querySelector("#" + config.gemIdPrefix + "_" + player.posY + "_" + player.posX).classList.add("switch");
			document.querySelector("#" + config.gemIdPrefix + "_" + player.posY + "_" + player.posX).setAttribute("dir", "1");
		}
		// меняем местами гемы
		$( ".switch" ).each( function() {
			config.movingItems++;
			$(this).animate( {
					left: "+=" + xOffset * config.gemSize * $(this).attr("dir"),
					top: "+=" + yOffset * config.gemSize * $(this).attr("dir")
				},
				{
					duration: 60,
					complete: function() {
						//Проверяем доступность данного хода
						checkMoving();
					}
				}
			);
			$(this).removeClass("switch");
		});
		// поменять идентификаторы гемов
		document.querySelector("#" + config.gemIdPrefix + "_" + player.selectedRow + "_" + player.selectedCol).setAttribute("id", "temp");
		document.querySelector("#" + config.gemIdPrefix + "_" + player.posY + "_" + player.posX).setAttribute("id", config.gemIdPrefix + "_" + player.selectedRow + "_" + player.selectedCol);
		document.querySelector("#temp").setAttribute("id",  config.gemIdPrefix + "_" + player.posY + "_" + player.posX);
		// поменять гемы в сетке
		[components.gems[player.selectedRow][player.selectedCol], components.gems[player.posY][player.posX]] = [components.gems[player.posY][player.posX], components.gems[player.selectedRow][player.selectedCol]];
		// действие бонусов
		var b = false;
		if (components.gems[player.selectedRow][player.selectedCol] <= -3 && components.gems[player.posY][player.posX] <= -3) {
			DoSuperBonus(player.selectedRow, player.selectedCol, player.posY, player.posX, true)
			b = true
		} else if (components.gems[player.selectedRow][player.selectedCol] <= -3) {
			DoBonus(player.selectedRow, player.selectedCol, components.gems[player.posY][player.posX], true);
			b = true
		} else if (components.gems[player.posY][player.posX] <= -3) {
			DoBonus(player.posY, player.posX, components.gems[player.selectedRow][player.selectedCol], true);
			b = true
		}
		// удаление объектов после бонусов
		if (b) {
			// console.log(config.spinners, config.spinnerscoords)
			// console.log(1)
			function rotate_timeout () {
				rotate_spinner(config.spinners, 500, config.spinnerscoords, config.gemSize);
				config.spinners = [];
				config.spinnerscoords = [];
			}
			setTimeout(rotate_timeout);
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {
						document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).classList.add( "remove" );
					}
				}
			}
			config.gameState = config.gameStates[ 3 ];
			gemFade();
		}
	}

	// Проверка перемещенных гемов
	function checkMoving () {
		let can_stop = false
		config.movingItems--;
		// Действуем тольпо после всех перемещений
		if( config.movingItems == 0 ) {
			// Действия в зависимости от состояния игры
			switch( config.gameState ) {
				// После передвижения гемов проверяем на появление групп сбора
				case config.gameStates[1]:
				case config.gameStates[2]:
					// проверяем, появились ли группы сбора
					if( !isStreak( player.selectedRow, player.selectedCol ) && !isStreak( player.posY, player.posX ) ) {
						// Если групп сбора нет, нужно отменить совершенное движение
						// а если действие уже отменяется, то вернуться к исходному состоянию ожидания выбора
						if( config.gameState != config.gameStates[2] ){
							config.gameState = config.gameStates[2];
							gemSwitch();
						} else {
							config.gameState = config.gameStates[0];
							player.selectedRow = -1;
							player.selectedCol = -1;
						}
						can_stop = true
					} else {
						// Если группы сбора есть, нужно их удалить
						config.gameState = config.gameStates[3]

						// Отметим все удаляемые гемы
						if( isStreak( player.selectedRow, player.selectedCol ) ) {
							removeGems( player.selectedRow, player.selectedCol );
						}

						if(isStreak(player.posY, player.posX)) {
							removeGems( player.posY, player.posX );
						}
						// Убираем с поля
						gemFade(true);
						scoreInc();
					}
					break;
				// После удаления нужно заполнить пустоту
				case config.gameStates[3]:
					checkFalling();
					break;
				case config.gameStates[4]:
					placeNewGems();
					break;
			}
		}
		return can_stop
	}

	// Отмечаем элементы для удаления и убираем их из сетки
	function removeGems( row, col ) {
		let gemValue = components.gems[ row ][ col ];
		if (gemValue == -2 && document.querySelector( "#" + config.gemIdPrefix + "_" + row + "_" + col ).id) {return;}
		let tmp = row;

		document.querySelector( "#" + config.gemIdPrefix + "_" + row + "_" + col ).classList.add( "remove" );
		let countRemoveVerGem = 0;
		let countRemoveHorGem = 0;
		let countRemoveSquareGem = 0;

		if ( isSquareStreak( row, col ) && isVerticalStreak( row, col ) <= 2 &&  isHorizontalStreak( row, col ) <= 2 && !(isVerticalStreak( row, col ) == 2 &&  isHorizontalStreak( row, col ) == 2)) {
			countRemoveSquareGem++;
			let removingGems = [];
			if (row > 0 && col > 0) {
				if (components.gems[row][col - 1] == gemValue && components.gems[row - 1][col] == gemValue && components.gems[row - 1][col - 1] == gemValue)
				{ removingGems = [[row, col - 1], [row - 1, col], [row - 1, col - 1]]}
			}
			if (row < config.countRows - 1 && col > 0) {
				if (components.gems[row][col - 1] == gemValue && components.gems[row + 1][col] == gemValue && components.gems[row + 1][col - 1] == gemValue)
				{ removingGems = [[row, col - 1], [row + 1, col], [row + 1, col - 1]]}
			}
			if (row > 0 && col < config.countCols - 1) {
				if (components.gems[row][col + 1] == gemValue && components.gems[row - 1][col] == gemValue && components.gems[row - 1][col + 1] == gemValue)
				{ removingGems = [[row, col + 1], [row - 1, col], [row - 1, col + 1]]}
			}
			if (row < config.countRows - 1 && col < config.countCols - 1) {
				if (components.gems[row][col + 1] == gemValue && components.gems[row + 1][col] == gemValue && components.gems[row + 1][col + 1] == gemValue)
				{ removingGems = [[row, col + 1], [row + 1, col], [row + 1, col + 1]]}
			}
			for (var i = 0; i < 3; i++) {
				document.querySelector( "#" + config.gemIdPrefix + "_" + removingGems[ i ][ 0 ] + "_" + ( removingGems[ i ][ 1 ] ) ).classList.add( "remove" );
				components.gems[ removingGems[ i ][ 0 ] ][ removingGems[ i ][ 1 ] ] = -1;
			}
		}

		if ( isVerticalStreak( row, col ) > 1) {
			while ( tmp > 0 && components.gems[ tmp - 1 ][ col ] == gemValue ) {
				document.querySelector( "#" + config.gemIdPrefix + "_" + ( tmp - 1 ) + "_" + col ).classList.add( "remove" );
				components.gems[ tmp - 1 ][ col ] = -1;
				tmp--;
				countRemoveVerGem++;
			}

			tmp = row;

			while ( tmp < config.countRows - 1 && components.gems[ tmp + 1 ][ col ] == gemValue ) {
				document.querySelector( "#" + config.gemIdPrefix + "_" + ( tmp + 1 ) + "_" + col ).classList.add( "remove" );
				components.gems[ tmp + 1 ][ col ] = -1;
				tmp++;
				countRemoveVerGem++;
			}
		}

		if ( isHorizontalStreak( row, col ) > 1 ) {
			let tmp = col;

			while ( tmp > 0 && components.gems[ row ][ tmp - 1 ] == gemValue ) {
				document.querySelector( "#" + config.gemIdPrefix + "_" + row + "_" + ( tmp - 1 ) ).classList.add( "remove" );
				components.gems[ row ][ tmp - 1 ] = -1;
				tmp--;
				countRemoveHorGem++;
			}

			tmp = col;

			while( tmp < config.countCols - 1 && components.gems[ row ][ tmp + 1 ] == gemValue ) {
				document.querySelector( "#" + config.gemIdPrefix + "_" + row + "_" + ( tmp + 1 ) ).classList.add( "remove" );
				components.gems[ row ][ tmp + 1 ] = -1;
				tmp++;
				countRemoveHorGem++;
			}
		}


		// Случаи для создания бонусов
		if (Math.max(countRemoveVerGem, countRemoveHorGem) == 4) { components.gems[ row ][ col ] = -7; }
		else if (countRemoveVerGem >= 2 && countRemoveHorGem >= 2) { components.gems[ row ][ col ] = -6; }
		else if (countRemoveVerGem == 3) { components.gems[ row ][ col ] = -4; }
		else if (countRemoveHorGem == 3) { components.gems[ row ][ col ] = -5; }
		else if (countRemoveSquareGem > 0) { components.gems[ row ][ col ] = -3; }
		else { components.gems[ row ][ col ] = -1;}
		NewBonus(row, col);
	}

	// Удаляем гемы
	function gemFade(is_score_inc) {
		let remove_len = $( ".remove" ).length;
		$( ".remove" ).each(function() {
			config.movingItems++;
			//задержка при полете спинера
			let spinner_url = config.bonuses[0]
			if (config.spinnerForSlowRemove && this.style.backgroundImage != 'url(' + spinner_url + ')') {$(this).animate({left: "+=" + 0.00 + 'px'}, {duration: 500})}
			$(this).animate( { width: 0.5 * config.gemSize +'px', height: 0.5 * config.gemSize + 'px', left: "+=" + 0.25 * config.gemSize + 'px', top: "+=" + 0.25 * config.gemSize + 'px'}, {duration: 120});
			$(this).animate( {
					opacity: 0
				},
				{
					duration: 120,
					complete: function() {
						$(this).remove();
						if (!win) {
							if (this.hasAttribute("data-color")) {
								win = PurposeInc(this.getAttribute("data-color"));
							}
						}
						let is_stop = checkMoving();
						// console.log("is_stop", is_stop)
						if (!--remove_len && win) {
							if (is_stop) {
								// alert("Это победа!")
								context.EndGame(true, blues)
								context.$swal("Победа!").then(() => context.stopGame())
							}
						}
						// console.log(remove_len, is_score_inc)
						// if (!remove_len && is_score_inc) {
						// 	console.log("scoreInc")
						// 	scoreInc();
						// }
						// checkMoving();
					}
				}
			);
		});
	}

	// Заполняем пустоту
	function checkFalling() {
		config.pause = true;
		let fellDown = 0;
		let distances = new Array();
		var onedo = false;
		for( let j = 0; j < config.countCols; j++ ) {
			for( let i = config.countRows - 1; i > -1; i-- ) {
				for( let h = i + 1; h < config.countRows; h++ ) {
					if(components.gems[h][j] >= 0 || components.gems[h][j] <= -3) {break}
					if(components.gems[h][j] == -1 && (components.gems[i][j] >= 0 || components.gems[i][j] <= -3)) {
						document.querySelector( "#" + config.gemIdPrefix + "_" + (i) + "_" + j ).classList.add( "fall");
						document.querySelector( "#" + config.gemIdPrefix + "_" + (i) + "_" + j ).setAttribute( "id", config.gemIdPrefix + "_" + h + "_" + j );
						distances.push(h - i);
						components.gems[ h ][ j ] = components.gems[ i ][ j ];
						components.gems[ i ][ j ] = -1;
						fellDown++;
						onedo = true
						break;
					}
				}
				if (onedo) {break}
			}
			if (onedo) {break}
		}

		$( ".fall" ).each( function(i) {
			config.movingItems++;
			$( this ).animate( {
					top: "+=" + (config.gemSize * distances[0])
				},
				{
					duration: 10,
					complete: function() {
						$( this ).removeClass( "fall" );
						checkMoving();
					}
				}
			);
		});



		// Если все элементы передвинули,
		// то сменить состояние игры
		if( fellDown == 0 ){
			config.gameState = config.gameStates[4];
			config.movingItems = 1;
			checkMoving();
		}
	}

	function rotate_spinner (elems, duration, coords, width) {
		let style = document.createElement('style');
		let max_width = width * 2
		let duration_seconds = duration / 1000
		style.textContent = `
			.rotate_animation {
				animation: 2s ease-in-out rotate;
			}
			@keyframes rotate {
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(9000deg);
				}
			}
			.transition_spinner {
				transition: all ${duration_seconds}s ease-in-out;
			}
			.spinner_scale {
				width: ${max_width}px !important;
				height: ${max_width}px !important;
			}`;
		for (let i=0; i<elems.length; i++) {
			style.textContent += `
				.spinner_replace_${i} {
					top: ${coords[i][1]}px !important;
					left: ${coords[i][0]}px !important;
				}`
		}
		document.head.appendChild(style);
		for (let i=0; i<elems.length; i++) {
			elems[i].classList.add("rotate_animation")
			elems[i].classList.add("transition_spinner")
			elems[i].classList.add("spinner_replace_" + i)
			elems[i].classList.add("spinner_scale")
			let after_spin = function () {
				elems[i].classList.remove("rotate_animation")
				elems[i].classList.remove("transition_spinner")
				elems[i].classList.remove("spinner_replace_" + i)
				components.wrapper.removeChild(elems[i]);
				config.spinnerForSlowRemove = false;
			}
			let middle_spin = function () {
				elems[i].classList.remove("spinner_scale")
			}
			setTimeout(after_spin, duration)
			setTimeout(middle_spin, duration / 2)
		}
		function remove_style () {
			document.head.removeChild(style)
		}
		setTimeout(remove_style, duration)
	}


	// Создание бонусов
	function NewBonus(row, col) {
		if (components.gems[ row ] [ col ] <= -3) {
			let pictures = {"-3": config.bonuses[0], "-4": config.bonuses[1], "-5": config.bonuses[2], "-6": config.bonuses[3], "-7": config.bonuses[4]};
			createGem( row * config.gemSize, col * config.gemSize, row, col, pictures[ components.gems[ row ] [ col ] ] );
		}
	}


	// Распределение бонусов
	function DoBonus(row, col, nearValue, is_score_inc) {
		let smoke_url = config.smoke
		if (components.gems[ row ] [ col ] == -7) {
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {continue;}
					if (components.gems[ i ][ j] == nearValue) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
				}
			}
		}
		if (components.gems[ row ] [ col ] == -5) {
			document.querySelector( "#" + config.gemIdPrefix + "_" + row + "_" + col ).style.backgroundImage = "url("+ smoke_url +")";
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {continue;}
					if (j == col && i != row && components.gems[ i ][ j ] != -2) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
				}
			}
		}
		if (components.gems[ row ] [ col ] == -4) {
			document.querySelector( "#" + config.gemIdPrefix + "_" + row + "_" + col ).style.backgroundImage = "url("+ smoke_url +")";
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {continue;}
					if (i == row && j != col && components.gems[ i ][ j ] != -2) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
				}
			}
		}
		if (components.gems[ row ] [ col ] == -6) {
			document.querySelector( "#" + config.gemIdPrefix + "_" + row + "_" + col ).style.backgroundImage = "url("+ smoke_url +")";
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {continue;}
					if ((row == i && col == j)) {continue;}
					if (Math.abs(row - i) <= 2 && Math.abs(col - j) <= 2 && components.gems[ i ][ j ] != -2) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
				}
			}
		}
		if (components.gems[ row ] [ col ] == -3) {
			config.spinnerForSlowRemove = true;
			var one = true
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {continue;}
					if ((row == i && col == j)) {continue;}
					if (Math.abs(row - i) + Math.abs(col - j) == 1 && components.gems[ i ][ j ] != -2) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
				}
			}
			var color = createColor()
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {continue;}
					if ((row == i && col == j)) {continue;}
					if (components.gems[ i ][ j ] == color && one) {
						createspiner(row * config.gemSize, col * config.gemSize, row, col);
						config.spinners.push(document.querySelector("#" + config.spinerIdPrefix + "_" + row + "_" + col + "_" + config.spinerCount));
						config.spinnerscoords.push([j *  config.gemSize, 	i * config.gemSize]);
						components.gems[ i ][ j ] = -1;
						one = false;
					}
				}
			}
		}
		components.gems[ row ] [ col ] = -1;
		if (is_score_inc) {
			scoreInc()
		}
	}

	// Распределение супер бонусов
	function DoSuperBonus(row1, col1, row2, col2, is_score_inc) {

		let smoke_url = config.smoke

		let pictures = {"-3": config.bonuses[0], "-4": config.bonuses[1], "-5": config.bonuses[2], "-6": config.bonuses[3], "-7": config.bonuses[4]};
		let value1 = components.gems[ row1 ] [ col1 ]
		let value2 = components.gems[ row2 ] [ col2 ]
		if (value1 == -7 && value2 == -7) {
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					components.gems[ i ][ j ] = -1;
				}
			}
		}
		if ((value1 == -5 && value2 == -5) || (value1 == -4 && value2 == -4) || (value1 == -5 && value2 == -4) || (value1 == -4 && value2 == -5)) {
			document.querySelector( "#" + config.gemIdPrefix + "_" + row2 + "_" + col2 ).style.backgroundImage = "url("+ smoke_url +")";
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (i == row2 || j == col2) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
				}
			}
		}
		if (value1 == -6 && value2 == -6) {
			document.querySelector( "#" + config.gemIdPrefix + "_" + row2 + "_" + col2 ).style.backgroundImage = "url("+ smoke_url +")";
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (Math.abs(row2 - i) <= 4 && Math.abs(col2 - j) <= 4) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
				}
			}
		}
		if (value1 == -3 && value2 == -3) {
			config.spinnerForSlowRemove = true;
			var color = createColor();
			let coords = [];
			var count = 0;
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (Math.abs(row2 - i) + Math.abs(col2 - j) == 1) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
					else if (components.gems[ i ][ j ] == color && count < 4) {
						createspiner(row2 * config.gemSize, col2 * config.gemSize, row2, col2);
						config.spinners.push(document.querySelector("#" + config.spinerIdPrefix + "_" + row2 + "_" + col2 + "_" + config.spinerCount));
						config.spinnerscoords.push([j *  config.gemSize, 	i * config.gemSize]);
						components.gems[ i ][ j ] = -1;
						count++;
					}
				}
			}
		}

		if (value1 == -3 && value2 == -6 || value1 == -6 && value2 == -3) {
			config.spinnerForSlowRemove = true;
			var one = true;
			var color = createColor();
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {continue;}
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (Math.abs(row2 - i) + Math.abs(col2 - j) == 1) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
					else if (components.gems[ i ][ j ] == color && one){
						createspiner(row2 * config.gemSize, col2 * config.gemSize, row2, col2);
						config.spinners.push(document.querySelector("#" + config.spinerIdPrefix + "_" + row2 + "_" + col2 + "_" + config.spinerCount));
						config.spinnerscoords.push([j *  config.gemSize, 	i * config.gemSize]);
						document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).style.backgroundImage = "url("+ pictures[-6] +")";
						components.gems[ i ][ j ] = -6;
						config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ))
						one = false;
					}
				}
			}
		}

		if (value1 == -3 && value2 == -4 || value1 == -4 && value2 == -3) {
			config.spinnerForSlowRemove = true;
			var color = createColor();
			var one = true;
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (Math.abs(row2 - i) + Math.abs(col2 - j) == 1) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
					else if (components.gems[ i ][ j ] == color && one) {
						createspiner(row2 * config.gemSize, col2 * config.gemSize, row2, col2);
						config.spinners.push(document.querySelector("#" + config.spinerIdPrefix + "_" + row2 + "_" + col2 + "_" + config.spinerCount));
						config.spinnerscoords.push([j *  config.gemSize, 	i * config.gemSize]);
						document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).style.backgroundImage = "url("+ pictures[-4] +")";
						components.gems[ i ][ j ] = -4;
						config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ))
						one = false;
					}
				}
			}
		}

		if (value1 == -3 && value2 == -5 || value1 == -5 && value2 == -3) {
			var color = createColor();
			config.spinnerForSlowRemove = true;
			var one = true;
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (Math.abs(row2 - i) + Math.abs(col2 - j) == 1) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
					else if (components.gems[ i ][ j ] == color && one) {
						createspiner(row2 * config.gemSize, col2 * config.gemSize, row2, col2);
						config.spinners.push(document.querySelector("#" + config.spinerIdPrefix + "_" + row2 + "_" + col2 + "_" + config.spinerCount));
						config.spinnerscoords.push([j *  config.gemSize, 	i * config.gemSize]);
						document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).style.backgroundImage = "url("+ pictures[-5] +")";
						components.gems[ i ][ j ] = -5;
						config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ))
						one = false;
					}
				}
			}
		}

		if (value1 == -6 && value2 == -4 || value1 == -4 && value2 == -6 || value1 == -6 && value2 == -5 || value1 == -5 && value2 == -6) {
			document.querySelector( "#" + config.gemIdPrefix + "_" + row2 + "_" + col2 ).style.backgroundImage = "url("+ smoke_url +")";
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (Math.abs(row2 - i) <= 1 || Math.abs(col2 - j) <= 1) {
						if (components.gems[ i ][ j ] < -2) {config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j )); continue;}
						components.gems[ i ][ j ] = -1;
					}
				}
			}
		}

		if (value1 == -7 && value2 == -4 || value1 == -4 && value2 == -7 || value1 == -7 && value2 == -5 || value1 == -5 && value2 == -7) {
			var color = createColor();
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (components.gems[ i ][ j ] == color) {
						if ((i + j) % 2 == 0) {
							document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).style.backgroundImage = "url("+ pictures[-4] +")";
							components.gems[ i ][ j ] = -4;
						} else {
							document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).style.backgroundImage = "url("+ pictures[-5] +")";
							components.gems[ i ][ j ] = -5;
						}
						config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ))
					}
				}
			}
		}

		if (value1 == -6 && value2 == -7 || value1 == -7 && value2 == -6){
			var color = createColor();
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (components.gems[ i ][ j ] == color) {
						document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).style.backgroundImage = "url("+ pictures[-6] +")";
						components.gems[ i ][ j ] = -6;
						config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ))
					}
				}
			}
		}

		if (value1 == -3 && value2 == -7 || value1 == -7 && value2 == -3) {
			var color = createColor();
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {continue;}
					if ((row1 == i && col1 == j) || (row2 == i && col2 == j) || (components.gems[ i ][ j ] == -2)) {continue;}
					if (components.gems[ i ][ j ] == color) {
						createspiner(row2 * config.gemSize, col2 * config.gemSize, row2, col2);
						config.spinners.push(document.querySelector("#" + config.spinerIdPrefix + "_" + row2 + "_" + col2 + "_" + config.spinerCount));
						config.spinnerscoords.push([j *  config.gemSize, 	i * config.gemSize]);
						document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).style.backgroundImage = "url("+ pictures[-3] +")";
						components.gems[ i ][ j ] = -3;
						config.postBonuses.push(document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ))
					}
				}
			}
		}

		components.gems[ row1 ] [ col1 ] = -1;
		components.gems[ row2 ] [ col2 ] = -1;
		if (is_score_inc) {
			scoreInc()
		}
	}


	function DoPostBonus() {
		var b = false;
		for (var i = 0; i < config.postBonuses.length; i++) {
			var row = config.postBonuses[i].id.split('_')[1]
			var col = config.postBonuses[i].id.split('_')[2]
			if (components.gems[ row ][ col ] <= -3) {DoBonus(row, col, createColor())}
			b = true;
		}
		config.postBonuses = [];
		function rotate_timeout () {
			rotate_spinner(config.spinners, 500, config.spinnerscoords, config.gemSize)
			config.spinners = []
			config.spinnerscoords = []
		}
		setTimeout(rotate_timeout);
		if (b) {
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if (components.gems[ i ][ j ] == -1) {
						document.querySelector( "#" + config.gemIdPrefix + "_" + i + "_" + j ).classList.add( "remove" );
					}
				}
			}
			config.gameState = config.gameStates[ 3 ];
			gemFade();
		}
	}


	// Создание новых гемов
	async function placeNewGems() {
		let gemsPlaced = 0;

		// Поиск мест, в которых необходимо создать гем
		for( let i = 0; i < config.countCols; i++ ) {
			var j = 0
			// console.log(components.gems);
			while (components.gems[ j ][ i ] == -2) {j++;}
			if ( components.gems[ j ][ i ] == -1 ) {
				components.gems[ j ][ i ] = Math.floor( Math.random() * config.imagesCoin.length );
				createGem( j * config.gemSize, i * config.gemSize, j, i, config.imagesCoin[ components.gems[ j ][ i ] ], components.gems[ j ][ i ] );
				gemsPlaced++;
			}
		}

		// Если мы создали гемы, то проверяем необходимость их сдвинуть вниз.
		if ( gemsPlaced ) {
			config.gameState = config.gameStates[ 3 ];
			checkFalling();
		} else {
			// Проверка на группы сбора
			let combo = 0
			for (let i = 0; i < config.countRows; i++ ) {
				for (let j = 0; j < config.countCols; j++ ) {
					if ( isStreak(i, j) ) {
						combo++;
						removeGems( i, j );
					}
				}
			}
			// удаляем найденные группы сбора
			if( combo > 0 ) {
				config.gameState = config.gameStates[ 3 ];
				gemFade();
			} else {
				config.pause = false;
				//работают постбонусы
				if (config.postBonuses.length > 0)
				{
					DoPostBonus()
					return
				}
				setTimeout(function() {config.pause = false;}, 1000);
				setTimeout(function() {if (!MovePossibility()) {createGrid()}}, 1000);
				// Запускаем основное состояние игры
				config.gameState = config.gameStates[ 0 ];
				player.selectedRow= -1;
				
				if (win) {
					// alert("Это победа, когда всё кончилось!")
					zaglushka_hidden.style.display = "block"
					let data = await context.EndGame(true, blues)
					console.log(data, Boolean(data))
					let aaaa = function () {
						context.$swal("Победа!").then(function(){
							if (data["is_earned"]) {
								context.$swal("Ты заработал голос!").then(() => context.stopGame())
							} else {
								context.stopGame()
							}
						})
					}
					if (data) {
						setTimeout(aaaa, 2000)
					}
					console.log(data)
				}
				if (config.moves === 0 && !win) {
					zaglushka_hidden.style.display = "block"
					context.EndGame(false, 0)
					let aaaa = function () {
						context.$swal("Повезёт в следующий раз!").then(() => context.stopGame())
					}
					setTimeout(aaaa, 2000)
					// alert("Сорян, но ты продул!")
				}
			}
		}
	}

	// Вешаем на прикосновение функцию handleTouchStart
	// document.addEventListener('touchstart', handleTouchStart, false);  
	// А на движение пальцем по экрану - handleTouchMove      
	// document.addEventListener('touchmove', handleTouchMove, false);

	var xDown = null;
	var yDown = null;

	function handleTouchStartMdown(evt) {
		// console.log(player)
		if (config.pause || config.gameState == config.gameStates[ 3 ] || config.gameState == config.gameStates[ 1 ]) {return}
		xDown = evt.pageX;
		yDown = evt.pageY;
		console.log(xDown, yDown)
		let row = parseInt( evt.target.getAttribute( "id" ).split( "_" )[ 1 ] );
		let col =  parseInt( evt.target.getAttribute( "id" ).split( "_" )[ 2 ] );
		if ((row === 0 || row > 0) && (col > 0 || col === 0)) {
			if (includ([row, col])) {return}
			mouse_down_on_elem = true
			player.selectedRow = row;
			player.selectedCol = col;
		}
	};

	function handleTouchStart(evt) {
		// console.log(player)
		if (config.pause || config.gameState == config.gameStates[ 3 ] || config.gameState == config.gameStates[ 1 ]) {return}
		xDown = evt.touches[0].clientX;
		yDown = evt.touches[0].clientY;
		// console.log(xDown, yDown)
		let row = parseInt( evt.target.getAttribute( "id" ).split( "_" )[ 1 ] );
		let col =  parseInt( evt.target.getAttribute( "id" ).split( "_" )[ 2 ] );
		if ((row === 0 || row > 0) && (col > 0 || col === 0)) {
			if (includ([row, col])) {return}
			player.selectedRow = row;
			player.selectedCol = col;
		}
	};

	function dropSelctedElem () {
		// console.log("ояебу!")
		player.selectedRow = -1;
		player.selectedCol = -1;
		player.posX = "";
		player.posY = "";
	}

	function handleTouchSwipe(evt, mousemove=null) {
		if (config.pause || config.gameState == config.gameStates[ 3 ] || config.gameState == config.gameStates[ 1 ]) {return}
		// if (includ(evt.target)) {return}
		if (includ([player.selectedRow, player.selectedCol])) {return}
		if ( ! xDown || ! yDown ) {return}

		document.body.style.overflow = "hidden"
		if (mousemove) {
			var xUp = evt.pageX
			var yUp = evt.pageY
			var xDiff = xDown - xUp;
			var yDiff = yDown - yUp;
			// console.log("xDown: " + xDown + ";   yDown: " + yDown)
			// console.log("X: " + xUp + ";   Y: " + yUp)
			// console.log("xDiff: " + Math.abs(xDiff) + ";   yDiff: " + Math.abs(yDiff))
			if (Math.abs(xDiff) < 2 && Math.abs(yDiff) < 2) {
				mouse_down_on_elem = false
				return
			}
		} else {
			var xUp = evt.touches[0].clientX;                                    
			var yUp = evt.touches[0].clientY;
			// console.log("xDown: " + xDown + ";   yDown: " + yDown)
			// console.log("X: " + xUp + ";   Y: " + yUp)
		}
		var xDiff = xDown - xUp;
		var yDiff = yDown - yUp;
		let col = null
		let row = null
		// немного поясню здесь. Тут берутся модули движения по оси абсцисс и ординат (почему модули? потому что если движение сделано влево или вниз, то его показатель будет отрицательным) и сравнивается, чего было больше: движения по абсциссам или ординатам. Нужно это для того, чтобы, если пользователь провел вправо, но немного наискосок вниз, сработал именно коллбэк для движения вправо, а ни как-то иначе.
		if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) { /*most significant*/
			if ( xDiff > 0 ) {
				/* left swipe */ 
				// console.log("left swipe")
				if (player.selectedCol == 0) {
					dropSelctedElem();
				} else {
					col = player.selectedCol - 1
					row = player.selectedRow
					preGemSwitch(col, row);
				}
			} else {
				/* right swipe */
				// console.log("right swipe")
				if (player.selectedCol == countCols - 1) {
					dropSelctedElem();
				} else {
					col = player.selectedCol + 1
					row = player.selectedRow
					preGemSwitch(col, row);
				}
			}                       
		} else { // Это вам, в общем-то, не надо, вы ведь только влево-вправо собираетесь двигать
			if ( yDiff > 0 ) {
				/* up swipe */
				// console.log("up swipe")
				if (player.selectedRow == 0) {
					dropSelctedElem();
				} else {
					col = player.selectedCol
					row = player.selectedRow - 1
					preGemSwitch(col, row);
				}
			} else {
				/* down swipe */
				// console.log("down swipe")
				evt.preventDefault(); 
				evt.stopPropagation();
				if (player.selectedRow == countRows - 1) {
					dropSelctedElem();
				} else {
					col = player.selectedCol
					row = player.selectedRow + 1
					preGemSwitch(col, row);
				}
			}                                                                 
		}
		/* reset values */
		xDown = null;
		yDown = null;
		// setTimeout(function(){document.body.style.overflow = "auto"}, 200)
		// dropSelctedElem()                                          
	};

	function preGemSwitch (col, row) {
		if ( ( Math.abs( player.selectedRow - row ) == 1 && player.selectedCol == col ) ||
			( Math.abs( player.selectedCol - col ) == 1 && player.selectedRow == row ) ) {
			cursorHide();
			// После выбора меняем состояние игры
			// сохранить позицию второго выбранного гема
			// console.log("preGemSwitch", col, row)
			if ((row === 0 || row > 0) && (col > 0 || col === 0)) {
				if (includ([row, col])) {
					dropSelctedElem()
					return
				}
				config.gameState = config.gameStates[1];
				player.posX = col;
				player.posY = row;
				// console.log("player", player)
				// поменять их местами
				gemSwitch();
			}
		}
	}
}

export default superInitGame