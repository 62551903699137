<template lang="pug">

div
  p <strong>E-mail:</strong> <a href="mailto:fanbatushka@yandex.ru">fanbatushka@yandex.ru</a>
  p <strong>Адрес:</strong> 119270, Россия, Москва, Хамовнический вал, 16, 9
  p <strong>Телефон:</strong> <a href="tel:+79265906204">+7 926 590-62-04</a>
  p <strong>ИП:</strong> «Козлов Сергей Фёдорович»
  p <strong>ИНН:</strong> 770401814314
  p <strong>ОГРНИП:</strong> 324774600077612
  p <strong>РС:</strong> 40802810400005937742
  p <strong>Время приёма звонков и ответа на сообщения:</strong> Пн.–Пт. 11:00–18:00

</template>


<script>

export default {
  name: 'ContactsText',
  data () {
    return {}
  },
}

</script>

<style lang="sass" scoped></style>
    