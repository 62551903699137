<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer.mt-2(header="ИНФО")
    .content_wrap

      // HeaderSubMenu(:pages="sub_menu")

      .subcontent_wrap.mt-4
        h3.mt-0.mb-3 Всего {{ users_count }} юзеров
        .overflow_wrap
          table
            tr
              th Имя
              th Дата регистрации
              th E-mail
              th Телефон
              th Монетки
              th Голоса
              th ФК
              th Активен
              th Match-3
            tr(v-for="user in users")
              td {{ user.username }}
              td {{ user.registr_date }}
              td {{ user.email }}
              td {{ user.phone }}
              td {{ user.coins }}
              td {{ user.voices }}
              td {{ user.fc }}
              td
                span(v-if="user.is_active") Да
                span(v-else) Нет
              td(v-if="user.level") {{ user.level - 1 }}
              td(v-else) —
          .notification_pagination
            span.paginate_page(
              v-if="page_num != 1"
              @click="reducePage"
            ) Предыдущая страница
            span.between(v-if="page_num != 1")
            span.paginate_page(
              v-if="page_num != pages_count && pages_count"
              @click="increasePage"
            ) Следующая страница

      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "@/shared/mixins"
import HeaDer from "@/components/Common/HeaDer"
import LeftMenu from "@/components/Common/LeftMenu"
import BitvaTimer from "@/components/Common/BitvaTimer"
import HeaderSubMenu from "@/components/Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"
import { AdminData } from "@/api/Inside"

export default {
  name: 'AdminPage',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      users: [],
      page_num: 1,
      pages_count: 1,
      users_count: 1,
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
    this.AdminData(1)
  },
  methods: {
    async AdminData (page) {
      let context = this
      let data = await AdminData(context, page)
      this.page_num = data["data"]["page"]
      this.pages_count = data["data"]["pages_count"]
      this.users = []
      console.log(data["data"])
      console.log(data["data"]["pages_count"])
      
      if (data.data.users) {
        this.users_count = data.data.users_count
        for (let i=0; i<data.data.users.length; i++) {
          this.users.push(
            data.data.users[i]
          )
        }
        // this.users = notifications["data"]["notifications"]
      }
    },
    reducePage () {
      if (this.page_num != 1) {
        this.page_num -= 1
        this.AdminData(this.page_num)
      }
    },
    increasePage () {
      if (this.page_num != this.pages_count) {
        this.page_num += 1
        this.AdminData(this.page_num)
      }
    },
  }
}

</script>


<style lang="sass" scoped>
  .subcontent_wrap
    padding-top: 20px
    .overflow_wrap
      table
        th, td
          border: 1px solid #000
          padding: 5px
          font-size: 12px
          border-collapse: collapse
  
</style>
    