import { render, staticRenderFns } from "./StampsWinners.vue?vue&type=template&id=5379b080&scoped=true&lang=pug&"
import script from "./StampsWinners.vue?vue&type=script&lang=js&"
export * from "./StampsWinners.vue?vue&type=script&lang=js&"
import style0 from "./StampsWinners.vue?vue&type=style&index=0&id=5379b080&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5379b080",
  null
  
)

export default component.exports