<template lang="pug">

.inside_wrapper
  .top_dark_line.white_bg
  LeftMenu(@slideTo="false" :currentSlide="3" :isGlory="true")
  .login_register.white_bg
    a(@click="goTo('/login')") Войти
    span &nbsp;&nbsp;|&nbsp;&nbsp;
    a(@click="goTo('/registration')") Регистрация
  vue-custom-scrollbar.inside_inwrap
    .content
      logotype#visible_logotype
      .enter_form_wrap
        h2.confirm_phone ПОДТВЕРЖДЕНИЕ ТЕЛЕФОНА
        v-form.confirm_form(autocomplete="off")
          p 
            em Сейчас тебе придёт SMS c четырёхзначным кодом
          v-otp-input(
            v-model="code"
            :disabled="loading"
            @finish="onFinish"
            length="4"
            type="number"
          )
          v-overlay.my_overlay(
            absolute
            :dark="false"
            :light="true"
            color="#ffffff"
            :value="loading"
          )
            v-progress-circular(
              indeterminate
              color="primary"
            )
          v-btn.green_button(
            x-large
            dark
            color="#04841F"
            :disabled="!can_resend || !code"
            @click="onFinish"
          ) Подтвердить телефон
        div(v-if="can_resend")
          //- p.request_again(@click="ReRequestCode") <span>Позвонить ещё раз</span>
          p.request_again.mt-2(@click="ReRequestCodeSms") <span>Отправить ещё раз</span>
        p.wait_for_it(v-else) Повторная отправка возможна через {{ seconds_remain }} сек.
</template>


<script>

import LeftMenu from '../LandingPage/LeftMenu';
import Logotype from '../LandingPage/Logotype'
import { commonMixin } from "../../shared/mixins"
import { CheckCode, ReRequestCode, ReRequestCodeSms, SendCode } from "./../../api/Registration"
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { getAccessTokenSimple } from "../../api/api"

const TIME = 60

export default {
  name: 'ConfirmPhone',
  components: {
    LeftMenu,
    Logotype,
    vueCustomScrollbar,
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      is_auth: false,
      loading: false,
      code: null,
      user_id: null,
      seconds_remain: TIME,
      can_resend: false,
      interval: null
    }
  },
  created () {
    this.user_id = this.$route.params.user_id
    this.CheckCode(this.user_id)
  },
  mounted () {
    this.RequestSendingAgain()
  },
  methods: {
    async checkIsAuth () {
      let access = await getAccessTokenSimple()
      if (access) {
        this.is_auth = true
        this.$router.replace("/inside/personal")
      }
    },
    RequestSendingAgain () {
      let can_resend = () => {
        this.can_resend = true
        clearInterval(this.interval)
        localStorage.removeItem(this.user_id)
        this.seconds_remain = TIME
      }
      let check_remain = this.CheckInterval()
      if (check_remain) {
        this.seconds_remain = check_remain
      }
      this.can_resend = false
      localStorage.setItem(this.user_id, this.seconds_remain )
      setTimeout(can_resend, this.seconds_remain  * 1000)
      this.interval = setInterval(this.IntervalStep, 1000)
    },
    async ReRequestCode () {
      if (this.can_resend) {
        await ReRequestCode(this.user_id, this)
        this.RequestSendingAgain()
      }
    },
    async ReRequestCodeSms () {
      if (this.can_resend) {
        await ReRequestCodeSms(this.user_id, this)
        this.RequestSendingAgain()
      }
    },
    async CheckCode (user_id) {
      let context = this
      let then = function () {
        context.$router.push("/")
      }
      await CheckCode(user_id, context, then)
    },
    CheckInterval () {
      return localStorage.getItem(this.user_id)
    },
    IntervalStep () {
      this.seconds_remain -= 1
      localStorage.setItem(this.user_id, this.seconds_remain)
    },
    async onFinish () {
      if (this.code) {
        this.loading = true
        // this.can_resend = false
        let context = this
        let then = function () {
          context.loading = false
        }
        let data = {
          user_id: this.user_id,
          code: this.code,
        }
        let result = await SendCode(data, context, then)
        if (result) {
          console.log(result)
          if (result["result"] == "ok") {
            this.$swal({
              "icon": "success",
              "text": "Номер успешно подтверждён",
            }).then(function () {
              then()
              context.$router.push("/login")
            })
          }
        }
      }
    },
  }
}

</script>


<style lang="sass" scoped>
.confirm_form
  width: 250px
  margin: 0 auto

.request_again, .wait_for_it
  margin: 20px 0 0
  padding: 0

.wait_for_it
  font-size: 14px

.request_again
  span
    display: inline-block
    border-bottom: 1px dashed #3579A1
    line-height: 17px
    color: #3579A1
    cursor: pointer
    &:hover
      text-decoration: none
      border-bottom: 1px solid transparent

.enter_form_wrap
  h2.confirm_phone
    top: -70px !important
  .v-btn.green_button
    margin-top: 10px

.top_dark_line
  @media (max-width: 1400px)
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 60px
    z-index: 40
    background: linear-gradient(#000, #00000000)
  
.top_dark_line.white_bg
  @media (max-width: 1400px)
    background: linear-gradient(#fff, #ffffff00)
    
.inside_wrapper
  .login_register
    position: absolute !important
    background: none !important
    top: 20px
    right: 20px !important
    z-index: 998
    @media (max-width: 700px)
      right: 10px
      top: 10px
    span
      color: #fff !important
      font-size: 16px
    a
      color: #fff !important
      font-size: 16px
      &:hover
        text-decoration: underline
    span, a
      @media (max-width: 700px)
        font-size: 14px

.login_register.white_bg
  span, a
    color: #000 !important

</style>
    