<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="БИТВА")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      .pos_r
        v-overlay.my_overlay(
          absolute
          :dark="false"
          :light="true"
          color="#ffffff"
          :value="loading"
        )
          v-progress-circular(
            indeterminate
            color="primary"
          )
        .subcontent_wrap.mt-7

          .monthes_buttons
            //- .month_button(v-for="x in 11" v-if) 
            //-   span(v-if="x < 10") {{ "0" + x + ".2023" }}
            //-   span(v-else) {{ x + ".2023" }}
            .month_button(
              v-for="month, index in month_years"
              :class="{'active': (index == 0 && !month_year) || (month_year == month)}"
              @click="changeMonth(month)"
            ) 
              span {{ month }}

        BitvaTimer.mt-5

        .subcontent_wrap
          h3 {{ month_txt }}
          table(v-if="clubs.length")
            tr.table_header
              th.place
                img(src="~@/assets/icons_and_logos/solar_cup-star-bold.svg")
              th.clubname Клуб
              th.rating Фан-Рейтинг *
            tr(v-for="(club, index) in clubs" :class="{'odd': index % 2, 'my_club': club.fc_id == my_club.fc_id}")
              td.place {{ index + 1 }}
              td.clubname
                .flex
                  span {{ club.name }}
              td.rating(v-if="club.rating") {{ ratingText(club.rating) }}
              td.rating(v-else) —
          p.note.mt-5 <span class="note_star">*</span> Фан-рейтинг клуба — это сумма отданных за него голосов, делённая на число проголосовавших за него уникальных фанатов. Фан-рейтинг клуба РПЛ показывается в таблице если за него проголосовали 500 и более уникальных болельщиков. Для клубов ФНЛ это количество начинается с 250.
          v-dialog(v-model="rules" width="900")
            v-card
              v-card-title.main_card_title.pt-2.pr-10 Правила конкурса «Битва фанатов» <i class="close_icon" @click="rules = !rules"></i>
              v-divider
              v-card-text.share_wrap.bp-0.main_card_txt
                v-row.pt-0.pb-2(align="start")
                  v-col.pb-0.dialog_inwrap.bitva_rules(cols="12" md="12")
                    BitvaRulesText.pt-4

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { monthText, ratingText } from "../../../service/service"
import { championshipClubsHistory } from "./../../../api/Inside"
import BitvaRulesText from '@/components/InsidePages/Bitva/BitvaRulesText'
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'BitvaCommands',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    BitvaRulesText,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      rules: false,
      month_year: false,
      loading: false,
      month_txt: "",
      clubs: [
        {
          "id": "1",
          "name": "Спартак",
          "rating": "3.2",
          "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
        },
        {
          "id": "2",
          "name": "Спартак",
          "rating": "3.2",
          "picture": "~@/assets/icons_and_logos/solar_cup-star-bold.svg",
        }
      ],
      month_years: [],
      sub_menu: [
        {
          "title": "Фан-рейтинг клубов",
          "link": "/inside/fight"
        },
        {
          "title": "История Фан-рейтингов",
          "link": "/inside/fight-history"
        },
        // {
          // "title": "Рейтинг участников (ваш клуб)",
          // "link": "/inside/fight-club"
        // },
        // {
          // "title": "Рейтинг участников (общий)",
          // "link": "/inside/fight-common"
        // },
        {
          "title": "Правила",
          "link": "/inside/fight-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
    if (this.$route.query.month_year) {
      this.championshipClubs(this.$route.query.month_year)
    } else {
      this.championshipClubs()
    }
  },
  methods: {
    ratingText (rating) {
      return ratingText(rating)
    },
    async championshipClubs (month) {
      this.loading = true
      let context = this
      let result = null
      if (month) {
        result = await championshipClubsHistory(context, month)
      } else {
        result = await championshipClubsHistory(context)
      }
      console.log(result)
      if (result) {
        this.clubs = result["data"]["fc_ratings"]
        this.month_years = result["data"]["month_years"]
        if (this.clubs.length || this.month_years.length) {
          if (month) {
            this.month_year = month
          } else {
            this.month_year = this.month_years[0]
          }
          this.month_txt = monthText(this.month_year)
        } else {
          this.month_txt = "Истории битв пока нет."
          this.loading = false
        }
        this.loading = false
      }
    },
    async changeMonth (month) {
      this.month_year = month
      await this.championshipClubs(month)
    }
  }
}

</script>


<style lang="sass" scoped>

.monthes_buttons
  display: flex
  flex-wrap: wrap
  align-items: flex-start
  justify-content: flex-start
  .month_button
    width: 100px
    height: 30px
    line-height: 30px
    border-radius: 5px
    text-align: center
    background-color: #d0e6f3
    margin-right: 10px
    margin-bottom: 10px
    cursor: pointer
    &:hover
      background-color: #b0cfe1
  .month_button.active
    background-color: #487997
    color: #fff

p.note
  font-size: 14px

table, th, td
  border: 1px solid white
  border-collapse: collapse
table
  table-layout: auto
  width: 100%
  border: 0
  margin-top: 10px
  .table_header
    width: 100%
    border-top-right-radius: 5px
    border-top-left-radius: 5px
    th
      background-color: #0D1D27
      color: #fff
      font-weight: 400
      font-size: 14px
      height: 43px
      padding: 0 5px
      line-height: 43px
      border-top: 0
      @media (max-width: 550px)
        font-size: 12px
    th.place
      width: 44px
      text-align: center
      padding: 0
      border-left: 0
      border-top-left-radius: 5px
      img
        position: relative
        top: 6px
    th.rating
      width: 85px
      border-right: 0
      line-height: 20px
      text-align: right
      border-top-right-radius: 5px
      @media (max-width: 550px)
        width: 70px
  tr
    td
      text-align: left
      font-weight: 400
      font-size: 16px
      padding: 0 5px
      height: 37px
      line-height: 37px
      @media (max-width: 550px)
        font-size: 14px
    td.place
      text-align: center
      padding: 0
      font-weight: 600
      font-size: 16px
      border-left: 0
      background-color: #fff
    td.clubname
      background-color: #fff
      .flex
        display: flex
        width: 100%
        justify-content: space-between
        align-items: center
        .green_button
          margin: 5px 0 7px
          @media (max-width: 550px)
            display: none
          
    td.rating
      border-right: 0
      background-color: #fff
      text-align: right
  tr.odd
    td.place, td.rating
      background-color: #EDEDED
    td.clubname
      background-color: #EDEDED
  tr.my_club
    td.place, td.rating, td.clubname
      background-color: #EAF2DF


</style>
    