<template lang="pug">

div.logotype
  a(href="/0")
    img(src='~@/assets/icons_and_logos/main_logo.svg')

</template>


<script>

export default {
  name: 'App'
}

</script>


<style lang="sass">

.logotype
  width: 100%
  display: flex
  justify-content: center
  a
    display: block
    width: auto
    height: 16vh
    min-height: 220px
    max-height: 260px
    opacity: 0
    img
      height: 100%
      @media (max-width: 700px)
        height: auto
        width: 180px
        margin-top: 30px


</style>