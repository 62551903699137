<template lang="pug">

.buttons_wrap
  v-btn.register_button(
    x-large
    dark
    color="#FF0038"
    href="#prizes_slide"
  ) Подробнее о призах
  v-btn.register_button(
    x-large
    dark
    color="#FF0038"
    @click="goTo('/registration')"
  ) Регистрация
  
</template>


<script>

import { commonMixin } from "../../shared/mixins"

export default {
  
  name: 'PrizeRegisterButtons',
  mixins: [
    commonMixin
  ],
  data () {
    return {

    }
  },
  methods: {
    slideTo (slide_num) {
      this.$emit("slideTo", slide_num)
    },
  }
}
  
</script>


<style lang="sass" scoped>

.buttons_wrap
  margin: 4vh auto 0
  display: flex
  flex-wrap: wrap
  justify-content: center
  width: 100%
  max-width: 1020px
  .v-btn
    width: calc(50% - 30px)
    margin: 0 10px
    color: #fff !important
    @media (max-width: 700px)
      width: calc(100% - 40px)
      font-size: 16px
      margin: 20px 10px 0
    a
      color: #fff !important
      span
        color: #fff !important

</style>
