<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ИГРА «ДОБЫЧА ГОЛОСОВ»")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7.howtoplay_text
        h3.mb-4 Правила игры «Добыча голосов»
        p «Добыча голосов» — это классическая игра в жанре «три в ряд».
        p Чтобы войти в игру и начать прохождение уровня, необходимо потратить один подход. Подходов всего пять. Каждый из подходов восстанавливается в течение часа.
        p Каждый уровень содержит «цель» <span class="omnumnum mr-5"><span>1</span></span>и ограничение по количеству доступных ходов <span class="omnumnum mr-4"><span>2</span></span> (<em>Рисунок №1</em>). Для прохождения уровня необходимо за указанное количество ходов «уничтожить» целевое количество квадратиков определённого цвета.
        p Для того, чтобы добывать голоса, необходимо «уничтожать» синие квадратики <span class="omnumnum mr-4"><span>3</span></span>. Каждый уровень ты будешь уничтожать, помимо целевых, некоторое количество синих квадратиков. После прохождения уровня количество уничтоженных синих квадратиков сохранится и прибавится к ранее сохраненным в предыдущих уровнях. Если уровень не пройден, то уничтоженные в нём синие квадратики не засчитываются. При накоплении 500 синих квадратиков тебе автоматически выдается новый «голос» и отсчёт синих квадратиков начинается с нуля. Ты будешь получать «голос» за каждые пятьсот синих квадратиков.
        p Чтобы «уничтожать» квадратики, необходимо совершать ходы, ведущие к образованию определённых фигур. Чтобы совершить ход, необходимо выдлелить один из квадратиков, кликнув на него. Далее необходимо кликнуть на любой из соседних квадратиков (по вертикали или по горизонтали), чтобы поменять их местами. Если таким образом на поле образовалась одна из нижеперечисленных фигур одного цвета (<em>Рисунок №2</em>), все квадратики, составляющие эту фигуру, уничтожаются, а сверху на их место «падают» новые. Если в результате хода фигуры не получилось, то ход не засчитывается и выбранные квадратики возвращаются на свои места.
        //- p Фигура может состоять из квадратиков только одного (любого, но одинакового) цвета.
        p За «уничтожение» фигур, состоящих из четырёх и более квадратиков, игроку даются «бустеры» <span class="omnumnum mr-4"><span>4</span></span>. За «уничтожение» линии из четырёх квадратиков даётся «Ракета» (вертикальная или горизонтальная). За «уничтожение» четырёх квадратиков в форме квадрата два на два даётся «Спиннер». За уничтожение линии в пять квадратиков — «Кубок». За уничтожение фигур в форме буквы «Т» и «Г» даётся «Бомба» (<em>Рисунок №2</em>).
        p «Бустер» можно активировать двойным кликом, или поменяв его местами с соседним квадратиком.
        p Активация «Спиннера» «уничтожает» четыре ближайших квадратика вокруг него (по вертикали и по горизонтали). Далее «спиннер» летит к случайному целевому квадратику на поле.
        p Активация «Ракеты» «уничтожает» целую линию квадратиков в зависимости от её направления (вертикальная или горизонтальная).
        p Активация «Бомбы» «уничтожает» целую область (квадрат со стороной в 5 квадратиков) вокруг себя. 25 квадратиков, включая саму бомбу.
        p Активация «Кубка» двойным кликом «уничтожает» все целевые квадратики (одного цвета) на поле. Или, если ты активируешь «Кубок», меняя его с другим квадратиком — «уничтожаются» все элементы цвета этого квадратика.
        p Если один «Бустер» попадает под воздействие другого «Бустера», он также активируется.
        p Бустеры также можно менять местами друг с другом. Подробно ознакомиться с тем, что происходит в этих случаях, предлагаем тебе самостоятельно. Важно лишь сказать, что это, как правило, очень выгодно как для прохождения уровня, так и для накопления синих квадратиков.
        p «Бустеры» и «Подходы» можно покупать за «Баллы» на странице игры. «Баллы» можно купить в <a href="/inside/shop">Магазине</a>.
        p
          em Рисунок №1
          img.pvp_gameplay_img(src="~@/assets/images/match3_gameplay.jpg")
        p 
          em Рисунок №2
          img.pvp_gameplay_img(src="~@/assets/images/figures.jpg")

      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'BallGameRules',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      sub_menu: [
        {
          "title": "Игра",
          "link": "/inside/match3"
        },
        {
          "title": "Рейтинг игроков",
          "link": "/inside/match3-results"
        },
        {
          "title": "Правила игры",
          "link": "/inside/match3-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
  },
  methods: {
  }
}

</script>


<style lang="sass" scoped>

.howtoplay_text
  font-size: 14px
  line-height: 23px
  .omnumnum
    position: relative
    display: inline-block
    color: #fff
    span
      position: relative
      z-index: 1
      left: 8px
      font-size: 15px
      font-weight: 500
      top: 0px
    &:before
      content: ""
      width: 25px
      height: 25px
      background-color: #d300cb
      border-radius: 15px
      z-index: 0
      position: absolute
      top: -2px
      left: 0
  .omnumnum.six
    position: relative
    left: 18px

.pvp_gameplay_img
  width: 100%
</style>
    