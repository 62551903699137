import { client, errorHandler } from "./api"


export async function SignIn(login_data, context, then) {
  const response = await client.post("login", {
    ...login_data
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function Forgot(forgot_data, context, then) {
  const response = await client.post("forgot", {
    ...forgot_data
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function Check(data, context, then) {
  const response = await client.post("check-change-password-key", {
    ...data
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}

export async function NewPassword(tempId, data, context, then) {
  const response = await client.post("change-password/" + tempId, {
    ...data
  }, {}).catch(error => errorHandler(error, context, then))
  if (response) {
    return response.data;
  }
}
