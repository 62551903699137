<template lang="pug">

.header_wrap
  h2 {{ header }}
  HeaDerIcons

</template>

<script>

import { commonMixin } from "../../shared/mixins"
import HeaDerIcons from "./HeaDerIcons"

export default {
  name: 'HeaDer',
  props: ['header',],
  components: {
    HeaDerIcons
  },
  mixins: [
    commonMixin
  ],
}

</script>

<style lang="sass" scoped>

.header_wrap
  width: calc(100% - 40px)
  max-width: 800px
  margin: 0 auto
  height: 70px
  line-height: 70px
  display: flex
  align-items: center
  h2
    width: 100%
    padding: 0 150px
    font-size: 30px
    line-height: 30px
    @media (max-width: 650px)
      padding: 0 50px !important
      font-size: 27px !important
    @media (max-width: 550px)
      font-size: 21px !important

  .header_icons
    width: 150px
    position: absolute
    height: 70px
    display: flex
    align-items: center
    justify-content: space-between
    top: 0
    right: 0
    padding-right: 20px
    @media (max-width: 650px)
      width: 48px !important
      justify-content: flex-end
      .hideble_icon
        display: none !important
    a
      display: flex
      align-items: center
      line-height: 70px
      height: 70px

</style>
    