<template lang="pug">
div
  p У какого футбольного клуба России самые активные и коммуникабельные фанаты? Этот проект — попытка дать ответ на этот вопрос и не только.
  p Наша цель — создать информационно-познавательный ресурс о футболе, его истории и событиях. Основное назначение сайта состоит в популяризации российского футбола и обеспечении коммуникации между болельщиками, в том числе в соревновательных и развлекательных формах: конкурсах, голосованиях, играх и др.
  p Средства от проекта планируется инвестировать в расширение функционала ресурса, создание онлайн-энциклопедии российского футбола, а также других интересных и полезных IT-продуктов для футбольных клубов и их болельщиков.
  p Мы сделали только первый шаг, главное впереди!
</template>

<script>
export default {
  name: 'OnasText',
}
</script>

<style lang="sass" scoped>
.mlminush4
  margin-left: -24px !important

h5
  font-size: 0.9rem

</style>
