<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ПОДЕЛИСЬ И ВЫИГРАЙ")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-7
        h3.mb-4 Правила конкурса «Поделись и выиграй»
        ol
          li.mb-2 Этот Конкурс проходит постоянно и его итоги подводятся каждый месяц.
          li.mb-2 Цель Конкурса заключается в том, чтобы выяснить кто из болельщиков среди всех клубов больше всех пригласил на наш сайт новых пользователей в течение месяца. Новым пользователем считается тот, кто зарегистрировался на нашем сайте.
          li.mb-2 Для участия в этом Конкурсе Вам необходимо приглашать на наш сайт новых пользователей с помощью реферальной ссылки.
          li.mb-2 Реферальная ссылка — это ссылка на наш сайт, которая содержит Ваш уникальный идентификатор. По этому идентификатору мы определяем, что именно Вы пригласили нового пользователя в момент его регистрации.
          li.mb-2 Распространяйте эту ссылку среди как можно большего числа Ваших друзей и знакомых, а они, кликая по этой ссылке, перейдут на страницу регистрации нашего сайта. За каждого нового пользователя Вам будет начисляться по 5 баллов.
          li.mb-2 Вашу персональную реферальную ссылку Вы можете скопировать в разделе «Поделись и выиграй», там же Вы можете посмотреть количество приглашенных Вами и зарегистрированных новых пользователей в текущем месяце.
          li.mb-2 В конце каждого месяца подводятся итоги этого Конкурса. Победителем этого Конкурса считается участник, по реферальной ссылке которого в текущем месяце зарегистрировалось максимальное количество новых пользователей, но не менее 5 (пяти) человек.
          li.mb-2 Если у нескольких участников эти количества совпадают, то  победителем становится владелец ссылки, по которой в текущем месяце дата и время последней регистрации были самыми ранними.
          li.mb-2 Если по итогам Конкурса ни по одной реферальной ссылке не зарегистрировались хотя бы 5 человек, то такой Конкурс заканчивается без определения победителей.
          li.mb-2 Победитель получает ценный приз – Сертификат Ozon на 10 000 руб., звание «Агитатор месяца» и попадает в «Зал Славы», а подсчет новых пользователей в начале каждого месяца начинается заново, с нуля.
          li.mb-2 Победители получают призы в своих Личных Кабинетах. Файл сертификата становится доступен для скачивания не позднее, чем через 7 дней с момента окончания Конкурса.

        p
        strong
          em Призы ждут вас! Желаем удачи!

      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'ShareRules',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      sub_menu: [
        {
          "title": "Реферальная ссылка",
          "link": "/inside/share-win"
        },
        {
          "title": "Рейтинг участников",
          "link": "/inside/sharewin-progress"
        },
        {
          "title": "Правила",
          "link": "/inside/sharewin-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
  },
  methods: {
  }
}

</script>


<style lang="sass" scoped>


</style>
    