<template lang="pug">
div
  h3.mb-4 Правила конкурса «Герой Битвы»
  ol
    li.mb-2 Конкурс «Герой Битвы» проходит постоянно и его итоги подводятся каждый месяц.
    li.mb-2 Цель Конкурса заключается в том, чтобы выяснить кто из болельщиков всех клубов отдал больше всего голосов за свой клуб.
    li.mb-2 Для участия в этом Конкурсе Вам достаточно голосовать за свой клуб, это можно делать в любое время и неограниченное количество раз.
    li.mb-2 Голоса приобретаются только за «баллы» (все о «баллах» см. в «Пользовательском соглашении»). Голоса, израсходованные при голосовании, возврату не подлежат.
    li.mb-2 Количество отданных Вами в текущей «Битве» голосов – свой рейтинг Вы всегда можете видеть на странице Конкурса и в Личном Кабинете.
    li.mb-2 В конце каждого месяца подводятся итоги этого Конкурса. Победителем становится тот, кто отдал больше всех голосов за свой клуб среди участников из всех клубов. Если у нескольких участников количество голосов одинаковое, то победителем становится тот участник, у которого дата и время его последнего голосования были самыми ранними в прошедшем месяце.
    li.mb-2 Конкурс считается состоявшимся, если за ваш клуб проголосует определённое минимальное число уникальных болельщиков. Для клубов РПЛ это число составляет 500 болельщиков, а для клубов ФНЛ — 250.
    li.mb-2 Победитель получает звание «Герой Битвы», ценный приз – Сертификат Ozon на 14 000 руб., бонус в 150 баллов и попадает в «Зал Славы».
    li.mb-2 Участники из всех клубов, занявшие места со второго по десятое, получают по 150 баллов.
    li.mb-2 В начале каждого месяца подсчет голосов начинается заново, т.е. рейтинги всех участников обнуляются на начало очередной «Битвы».
    li.mb-2 Победители получают призы в своих Личных Кабинетах. Файл сертификата становится доступен для скачивания не позднее, чем через 7 дней с момента окончания Конкурса.
  
  p
    strong
      em Призы ждут вас! Желаем удачи!

</template>

<script>
export default {
  name: 'BitvaPersonalCommonRulesText',
}
</script>









