import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
// import VueSwal from "vue-swal"
import VueSweetalert2 from "vue-sweetalert2"
import VueTheMask from "vue-the-mask"
import store from "./store"
import VueCookies from "vue-cookies"
import VueTippy from "vue-tippy";
// import "sweetalert2/dist/sweetalert2.min.css"
import "@sweetalert2/theme-bulma/bulma.css"
import Fullscreen from './plugins/fullscreen'

Vue.config.productionTip = false

Vue.use(VueTheMask, {
  tokens: {
    "#": { pattern: /[e-fE-F0-9]/, transform: v => v.toLocaleUpperCase() },
    "&": { pattern: /\d/ },
    "X": { pattern: /[0-9a-zA-Z]/ },
    "S": { pattern: /[a-zA-Z]/ },
    "A": { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
    "a": { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
    "!": { escape: true }
  }
})

// Vue.use(VueSwal)
Vue.use(Fullscreen)
Vue.use(VueSweetalert2)
Vue.use(VueTippy)
Vue.use(VueCookies, { expires: "365d"})

new Vue({
  vuetify,
  router,
  store: store,
  render: h => h(App)
}).$mount("#app")
