<template lang="pug">
div
  h3.mb-4 Правила конкурса «Фан-лидер клуба»
  ol
    li.mb-2 Конкурс «Фан-лидер клуба» проходит постоянно и его итоги подводятся каждый месяц.
    li.mb-2 Цель Конкурса заключается в том, чтобы выяснить кто из болельщиков вашего клуба отдал больше всего голосов за свой клуб.
    li.mb-2 Для участия в этом Конкурсе Вам достаточно голосовать за свой клуб, это можно делать в любое время и неограниченное количество раз.

    li.mb-2 Голоса приобретаются только за «баллы» (все о «баллах» см. в «Пользовательском соглашении»). Голоса, израсходованные при голосовании, возврату не подлежат.

    li.mb-2 Количество отданных Вами в текущей «Битве» голосов — свой рейтинг, Вы всегда можете видеть на странице Конкурса и в Личном Кабинете.

    li.mb-2 В конце каждого месяца подводятся итоги этого Конкурса. Победителем становится тот, кто отдал больше всех голосов за свой клуб. Если у нескольких участников количество голосов одинаковое, то победителем становится тот участник, у которого дата и время его последнего голосования были самыми ранними в прошедшем месяце.

    li.mb-2 Конкурс считается состоявшимся, если за ваш клуб проголосует определённое минимальное число уникальных болельщиков. Для клубов РПЛ это число составляет 500 болельщиков, а для клубов ФНЛ — 250.

    li.mb-2 В Этом Конкурсе победители определяются в двух номинациях.
      ol.mt-2
        li.mb-2 Если клуб стал победителем «Битвы», то участник, отдавший наибольшее количество голосов за этот клуб, получает звание <em><strong>«Фан-лидер клуба-победителя»</strong></em>, приз – Сертификат Ozon на 14 000 руб., бонус в 100 баллов и попадает в «Зал Славы». Участники, занявшие места со второго по десятое, получают по 100 баллов. Все остальные участники, проголосовавшие за свой клуб-победитель, получают по 25 баллов.
        li.mb-2 Если клуб НЕ стал победителем «Битвы», то участник, отдавший наибольшее количество голосов за этот клуб, получает звание <em><strong>«Фан-лидер клуба»</strong></em> и бонус в 100 баллов. Участники, занявшие места со второго по десятое, получат по 50 баллов.
    
    li.mb-2 Таким образом, в каждом клубе ежемесячно определяется свой «фан-лидер клуба», а если клуб стал еще и фан-чемпионом месяца (победителем «Битвы»), то фан-лидер этого клуба получает более почетное звание — «фан-лидер клуба-победителя».
    li.mb-2 Победители получают призы в своих Личных Кабинетах. Файл сертификата становится доступен для скачивания не позднее, чем через 7 дней с момента окончания Конкурса.
    
  p.mt-3
    strong
      em Призы ждут вас! Желаем удачи!

</template>

<script>
export default {
  name: 'BitvaPersonalClubRulesText',
}
</script>
