<template lang="pug">
// vue-custom-scrollbar#first_screen_common(class="scroll-area")
#first_screen_common.scroll-area
  .content

    logotype
    .slogan.mb-10
      //- h2 ГОЛОСУЯ ЗА СВОЙ КЛУБ, ТЫ СТАНОВИШЬСЯ<br>УЧАСТНИКОМ ДОПОЛНИТЕЛЬНЫХ КОНКУРСОВ
      h2 Побеждай в шести разных конкурсах!
    .definitions_warp
      
      .konkurs_round.first_konkurs
        span ФАН-ЛИДЕР<br>КЛУБА
        .konkurs_info
          p Побеждает участник, отдавший наибольшее количество голосов за свой клуб!
      
      .konkurs_round.second_konkurs
        span ГЕРОЙ<br>БИТВЫ
        .konkurs_info
          p Побеждает участник, отдавший наибольшее количество голосов среди всех клубов!
      
      .konkurs_round.third_konkurs
        span КОМАНДА<br>МЕЧТЫ
        .konkurs_info
          p Побеждают участники, которые смогли первыми скооперироваться и собраться в команду!
      
      .konkurs_round.fourth_konkurs
        span КОЛЛЕКЦИЯ<br>МАРОК
        .konkurs_info
          p Побеждают все участники, успевшие собрать всю коллекцию марок в отведённый срок!
      
      .konkurs_round.fifth_konkurs
        span ПОДЕЛИСЬ<br>И ВЫИГРАЙ
        .konkurs_info
          p Побеждает участник, пригласивший больше всего новых болельщиков!
      
      .konkurs_round.six_konkurs
        span ПОЕДИНКИ<br>«РАЗ НА РАЗ»
        .konkurs_info
          p Побеждает участник, одержавший больше всего побед в поединках!
    //- .subslogan
      //- p Поддерживай свой клуб, участвуя в четырёх разных конкурсах
    //- .subslogan
      //- p В каждом таком конкурсе регулярно вручаются крутые призы!
    //- .subslogan
      //- p Для новичков участие во всех конкурсах абсолютно бесплатно! Просто зарегистрируйся и проголосуй за свой клуб!
    
    PrizeRegisterButtons(@slideTo="slideTo")

    // .scroll_more(@click="slideTo(3)")
    //   p Листай дальше, чтобы узнать, в каких конкурсах можно выигрывать ценные призы!
    
    // .down-arrow(@click="slideTo(3)")

</template>


<script>

import Logotype from './Logotype'
import PrizeRegisterButtons from './PrizeRegisterButtons'
import { commonMixin } from "../../shared/mixins"
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  
  name: 'SlideThreeKonkurses',
  components: {
    Logotype,
    vueCustomScrollbar,
    PrizeRegisterButtons
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {

    }
  },
  created () {
    // this.championshipClubs()
  },
  mounted () {
    // let context = this
    // let second_screen_bitva = document.getElementById("three_screen_konkurses")
    // let resized_with_scroll_up = document.getElementById("resized_with_scroll_up")
    // let resized_with_scroll_down = document.getElementById("resized_with_scroll_down")
    // wheel_main_slider_calculator(context, second_screen_bitva, resized_with_scroll_up, resized_with_scroll_down, "touchmove", 1, 3)
    // wheel_main_slider_calculator(context, second_screen_bitva, resized_with_scroll_up, resized_with_scroll_down, "wheel", 1, 3)
  },
  methods: {
    slideTo (slide_num) {
      this.$emit("slideTo", slide_num)
    },
  }
}
  
</script>


<style lang="sass" scoped>

.definitions_warp
  width: 100%
  margin: 0 auto
  display: flex
  align-items: flex-start
  justify-content: center
  flex-wrap: wrap
  max-width: 1100px
  .konkurs_round
    position: relative
    width: 270px
    height: 270px
    background-color: #666666
    border-radius: 50px
    margin: 20px
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    transition: all 0.5s ease
    padding: 10px
    border: 5px solid #ffffffb8
    span
      transition: all 0.5s ease
      position: relative
      top: 0
      font-size: 27px
      line-height: 33px
      font-weight: 500
      color: #fff
    &:hover
      height: 360px
      span
        top: -80px
      .konkurs_info
        top: 120px
        height: 250px
        opacity: 1
    .konkurs_info
      width: 100%
      position: absolute
      top: 100%
      left: 0
      display: flex
      align-items: center
      justify-content: center
      padding: 0 30px 0 30px
      text-align: center
      color: #fff
      transition: all 0.5s ease
      height: 200px
      opacity: 0
  @media (max-width: 700px)
    .konkurs_round
      // transform: scale(0.4)
      // width: 140px
      // height: 140px
      // margin: 10px
      // padding: 5px
      // border: 4px solid #ffffffb8
      // span
      //   font-size: 27px
      //   line-height: 33px

  // .first_konkurs
  //   background-color: #EE5700
  // .second_konkurs
  //   background-color: #00BF91
  // .third_konkurs
  //   background-color: #EE0056
  // .fourth_konkurs
  //   background-color: #006AD1
  // .fifth_konkurs
  //   background-color: #008A00
  // .six_konkurs
  //   background-color: #8200D2

  .first_konkurs
    background: linear-gradient(0deg, #571914, #79627f)
  .second_konkurs
    background: linear-gradient(0deg, #571914, #79627f)
  .third_konkurs
    background: linear-gradient(0deg, #571914, #79627f)
  .fourth_konkurs
    background: linear-gradient(0deg, #571914, #79627f)
  .fifth_konkurs
    background: linear-gradient(0deg, #571914, #79627f)
  .six_konkurs
    background: linear-gradient(0deg, #571914, #79627f)

.content
  position: relative
  padding-bottom: 150px
  @media (max-width: 700px)
    padding-bottom: 150px
  .logotype
    opacity: 0

#three_screen_konkurses
  width: 100%
  height: 100%

.slogan
  margin-top: 2vh
  width: 100%
  display: flex
  justify-content: center
  h2
    font-size: 33px
    color: #ffffff
    text-shadow: 0px 0px 4px #000000
    line-height: 41px
    @media (max-width: 700px)
      font-size: 22px
      line-height: 31px

.subslogan
  margin-top: 3vh
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: center
  p
    padding: 20px 30px 22px
    width: calc(100% - 40px)
    max-width: 1000px
    background-color: #525059d1
    font-size: 21px
    line-height: 26px
    color: #fff
    font-weight: 300
    margin-bottom: 0
    border-radius: 10px
    @media (max-width: 700px)
      font-size: 18px
      line-height: 24px

.scroll_more
  p
    background-color: rgba(0, 0, 0, 0.7)
    padding: 10px 20px
    color: #bdbdbd
    @media (max-width: 700px)
      width: calc(100% - 40px)

.down-arrow
  border-color: #000
  &:after
    border-color: #000

</style>
