import axios from "axios";
import Vue from "vue";
import router from "../router/index";
import settings from "../settings";

console.log(settings.address + "/api/v1/")
export const client = axios.create({
  // baseURL: 'https://fanbattle.fun:8000' + "/api/v1/",
  baseURL: settings.address + "/api/v1/",
  headers: {"Access-Control-Allow-Origin": "*"}
})

export const errorHandler = function (err, context, then, text) {
  if (!then) {
    then = function () {}
  }
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(err.response.data);
    console.log(err.response.status);
    console.log(err.response.headers);
    if (!text) {
      text = "Ошибка валидации"
    }
    if (err.response.data.detail) {
      if (typeof err.response.data.detail == "string") {
        text = err.response.data.detail
      }
    }
    if (text == "Без авторизации никак!") {
      then = function () {
        Vue.$cookies.remove("access");
        Vue.$cookies.remove("refresh");
        router.push("/login");
      }
    }
    context.$swal({
      "icon": "error",
      "text": text,
    }).then(then)
  } else if (err.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(err.request);
    context.$swal({
      "icon": "error",
      "text": "Проблемы на стороне сервера. Мы скоро всё починим!",
    }).then(then)
  } else {
    // Something happened in setting up the request that triggered an Error
    context.$swal({
      "icon": "error",
      "text": "Упс, непредвиденная поломка. Мы скоро всё починим!",
    }).then(then)
  }
  // console.log(err.config);
}

export const getAccessTokenSimple = async () => {
  let access = Vue.$cookies.get("access");
  if (access) {
    return access;
  } else {
    let refresh_old = Vue.$cookies.get("refresh");
    if (refresh_old) {
      const response = await client.get("token-refresh", {
        headers: {
          authorizations: refresh_old
        }
      });
      const { access, refresh } = response.data;
      Vue.$cookies.set("access", access, settings.auth.accessExp);
      Vue.$cookies.set("refresh", refresh, settings.auth.refreshExp);
      return access;
    }
  }
}

export const getAuthHeaders = async () => {
  const refresh = Vue.$cookies.get("refresh");
  if (!refresh) return {};
  try {
    const access = await getAccessTokenSimple();
    return {
      authorizations: access
    };
  } catch (e) {
    Vue.$cookies.remove("access");
    Vue.$cookies.remove("refresh");
    if (e && e.response && e.response.status === 401) {
      // await store.dispatch("auth/Logout");
      await router.push("/login");
      return {};
    } else {
      throw e;
    }
  }
}

export async function Logout(context) {
  const authHeaders = await getAuthHeaders();
  const response = await client.post("logout", {}, {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, null))
  if (response) {
    context.$router.push("/login")
  }
}

export async function UserData(context, then) {
  const authHeaders = await getAuthHeaders();
  console.log("authHeaders", authHeaders)
  const response = await client.get("user-data", {
    headers: { ...authHeaders }
  }).catch(error => errorHandler(error, context, then))
  if (response) {
    return response
  }
}

export async function getPrices() {
  const authHeaders = await getAuthHeaders();
  const response = await client.get("/get_prices", {}, {
    headers: { ...authHeaders }
  });
  return response.data;
}
