<template lang="pug">
// vue-custom-scrollbar#first_screen_common(class="scroll-area")
#first_screen_common.scroll-area
  .content#prizes_slide

    logotype
    .slogan
      h2 ПРИЗЫ
    .definitions_warp
      //- .definition
      //-   h3 САМЫЙ АКТИВНЫЙ БОЛЕЛЬЩИК ПОБЕДИВШЕГО КЛУБА
      //-   .def_subwrap
      //-     img(src="~@/assets/icons_and_logos/prize.jpg")
      //-     p Участник битвы, который отдаст наибольшее количество голосов за победивший клуб в текущей бытве, получит подарочный сертификат OZON на 25 000 руб!
      //- .definition
      //-   h3 САМЫЙ АКТИВНЫЙ БОЛЕЛЬЩИК МЕСЯЦА
      //-   .def_subwrap
      //-     img(src="~@/assets/icons_and_logos/prize.jpg")
      //-     p Участник битвы, который отдаст наибольшее количество голосов за месяц проведения битвы, получит подарочный сертификат OZON на 20 000 руб!
      //- .definition
      //-   h3 САМЫЙ АКТИВНЫЙ АГИТАТОР МЕСЯЦА
      //-   .def_subwrap
      //-     img(src="~@/assets/icons_and_logos/prize.jpg")
      //-     p Участник, реферальной ссылкой которого при регистрации воспользовались наибольшее число раз, получит подарочный сертификат OZON на 10 000 руб!
      //- .definition
      //-   h3 КОЛЛЕКЦИОНЕР МАРОК
      //-   .def_subwrap
      //-     img(src="~@/assets/icons_and_logos/prize.jpg")
      //-     p Каждый участник, собравший все 33 уникальные марки получит подарочный сертификат OZON на 10 000 руб!
      //- .definition
      //-   h3 ПЕРВЫЕ 22 УЧАСТНИКА, СОБРАВШИЕ «КОМАНДУ МЕЧТЫ»
      //-   .def_subwrap
      //-     img(src="~@/assets/icons_and_logos/prize.jpg")
      //-     p 22 болельщика, которые первыми скооперируются и победят в конкурсе, получат подарочные сертификаты OZON на 5 000 руб. каждый!
      //- .definition
      //-   h3 ЧЕМПИОН МЕСЯЦА В ПОЕДИНКАХ «РАЗ НА РАЗ»
      //-   .def_subwrap
      //-     img(src="~@/assets/icons_and_logos/prize.jpg")
      //-     p Участник, одержавший больше всего побед в поединках «РАЗ НА РАЗ» получит подарочный сертификат OZON на 5 000 руб!
      .definition(v-if="prizes" v-for="prize in prizes")
        h3 {{ prize.name }}
        .def_subwrap
          img(v-if="prize.id == 1 || prize.id == 4" src="~@/assets/icons_and_logos/ozon5000.png")
          img(v-else-if="prize.id == 2 || prize.id == 3" src="~@/assets/icons_and_logos/ozon10000.png")
          img(v-else-if="prize.id == 5 || prize.id == 6" src="~@/assets/icons_and_logos/ozon14000.png")
          // img(v-else-if="prize.id == 6" src="~@/assets/icons_and_logos/ozon20000.png")
          p(v-html="prize.description")

    .slogan.with_border
      p На сумму электронного сертификата можно выбирать из миллионов товаров и услуг на Ozon: технику, игрушки, игры, книги, инструменты, спортивный инвентарь и атрибутику. Даже авиа и ж/д билеты!
        span.small_text Сертификат можно потратить на одну покупку или несколько заказов. Остаток не сгорает!<br>Целый год, чтобы активировать и три года, чтобы пользоваться.
    .buttons_wrap
      v-btn.register_button(
        x-large
        dark
        color="#FF0038"
        @click="goTo('/registration')"
      ) Регистрация
    
    // .down-arrow(@click="slideTo(5)")

</template>


<script>

import { getAllPrizes } from "./../../api/LandingPage"
import Logotype from './Logotype'
import PrizeRegisterButtons from './PrizeRegisterButtons'
import { commonMixin } from "../../shared/mixins"
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  
  name: 'SlideEightPrizes',
  components: {
    Logotype,
    vueCustomScrollbar,
    PrizeRegisterButtons
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      prizes: [],
    }
  },
  async created () {
    await this.getAllPrizes()
  },
  mounted () {
    // let context = this
    // let eight_screen_prizes = document.getElementById("eight_screen_prizes")
    // let resized_with_scroll_up = document.getElementById("resized_with_scroll_up")
    // let resized_with_scroll_down = document.getElementById("resized_with_scroll_down")
    // wheel_main_slider_calculator(context, eight_screen_prizes, resized_with_scroll_up, resized_with_scroll_down, "touchmove", 6, null)
    // wheel_main_slider_calculator(context, eight_screen_prizes, resized_with_scroll_up, resized_with_scroll_down, "wheel", 6, null)
  },
  methods: {
    slideTo (slide_num) {
      this.$emit("slideTo", slide_num)
    },
    async getAllPrizes () {
      let context = this
      let result = await getAllPrizes(context)
      if (result) {
        this.prizes = result
      }
    },
  }
}
  
</script>


<style lang="sass" scoped>

.content
  position: relative
  padding-bottom: 150px
  @media (max-width: 700px)
    padding-bottom: 150px
  .logotype
    opacity: 0

#eight_screen_prizes
  width: 100%
  height: 100%


.slogan
  margin-top: 2vh
  width: 100%
  display: flex
  justify-content: center
  h2
    font-size: 32px
    color: #241E36
    line-height: 41px
    @media (max-width: 700px)
      font-size: 22px
      line-height: 31px

.definitions_warp
  margin: 2vh auto 0
  width: 100%
  max-width: 1400px
  padding: 0 20px
  display: flex
  flex-wrap: wrap
  align-items: stretch
  justify-content: space-between
  .definition
    width: calc(33.33% - 15px)
    padding: 20px
    border-radius: 10px
    background-color: rgba(255, 255, 255, 0.9)
    margin-bottom: 20px
    h3
      text-align: left
      font-size: 20px
      line-height: 24px
      min-height: 34px
      width: 100%
      margin-bottom: 10px
      display: block
    .def_subwrap
      width: 100%
      display: flex
      align-items: flex-start
      img
        margin-right: 15px
        width: 105px
        position: relative
        top: 6px
      p
        font-size: 18px
        line-height: 30px
        color: #353535
        font-weight: 300
        margin-bottom: 0
        text-align: left
        width: calc(100% - 120px)
        @media (max-width: 700px)
          font-size: 18px
          line-height: 24px
    @media (max-width: 1250px)
      h3
        text-align: left
        font-size: 16px
        line-height: 22px
      .def_subwrap
        p
          font-size: 15px
          line-height: 26px
        img
          margin-right: 10px
          width: 90px
    @media (max-width: 1000px)
      h3
        height: 40px
        font-size: 15px
      width: calc(50% - 10px)
    @media (max-width: 700px)
      h3
        height: auto
      width: 100%
      margin-bottom: 20px
      .def_subwrap
        img
          display: none
        p
          width: 100%

.slogan.with_border
  margin: 2vh auto 0
  width: 100%
  max-width: 1400px
  padding: 0 20px
  display: flex
  justify-content: center
  p
    padding: 20px 50px 10px
    border: 1px solid #4f4f4f
    background-color: rgba(255, 255, 255, 0.8)
    border-radius: 15px
    text-align: center
    color: #000
    font-weight: 400
    font-size: 22px
    line-height: 29px
    @media (max-width: 700px)
      font-size: 18px
      line-height: 25px
      padding: 10px 30px
    .small_text
      font-size: 18px
      line-height: 26px
      font-weight: 300
      display: inline-block
      padding: 10px 50px 10px
      margin-bottom: 0
      @media (max-width: 700px)
        font-size: 16px
        line-height: 22px
        padding: 10px 30px

.buttons_wrap
  margin: 2vh auto 0
  display: flex
  flex-wrap: wrap
  justify-content: center
  width: 100%
  max-width: 1020px
  .v-btn
    width: calc(50% - 30px)
    margin: 0 10px
    @media (max-width: 700px)
      width: calc(100% - 40px)
      font-size: 16px
      margin: 20px 10px 0

.scroll_more
  p
    background-color: rgba(0, 0, 0, 0.7)
    padding: 10px 20px
    color: #bdbdbd
    @media (max-width: 700px)
      width: calc(100% - 40px)

.down-arrow
  border-color: #000
  &:after
    border-color: #000

</style>
