
// export const login = async (username, password) => {
//   const response = await client.post("login",
//     { username, password });
//   Vue.$cookies.set("access", response.data["access"], settings.auth.accessExp);
//   Vue.$cookies.set("refresh", response.data["refresh"], settings.auth.refreshExp);
// };

export function getTimeRemaining(endtime) {
  let t = Date.parse(endtime) - Date.parse(new Date());
  let seconds = Math.floor((t / 1000) % 60);
  let minutes = Math.floor((t / 1000 / 60) % 60);
  let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
  let days = Math.floor(t / (1000 * 60 * 60 * 24));
  return {
    'total': t,
    'days': days,
    'hours': hours,
    'minutes': minutes,
    'seconds': seconds
  }
}

export function initializeClock(id, endtime) {
  let clock = document.getElementById(id);
  let daysSpan = clock.querySelector('.days');
  let hoursSpan = clock.querySelector('.hours');
  let minutesSpan = clock.querySelector('.minutes');
  let secondsSpan = clock.querySelector('.seconds');
  function updateClock() {
    let t = getTimeRemaining(endtime);
    daysSpan.innerHTML = ('0' + t.days).slice(-2);
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }
  updateClock();
  let timeinterval = setInterval(updateClock, 1000);
}

export function clientOrMaster(user_id, active_pvp) {
  if (active_pvp.client_id == user_id) {
    return "client"
  } else if (active_pvp.master_id == user_id) {
    return "master"
  }
}
export function clientOrMasterExcept(opponent_role) {
  if (opponent_role == "master") {
    return "client"
  } else if (opponent_role == "client") {
    return "master"
  }
}

export function timeRemaining(from_time) {
  let wait_seconds = Math.floor(
    (Date.parse(new Date()) - Date.parse(from_time)) / 1000
  );
  let seconds = zeroWriter(wait_seconds % 60)
  let minutes = zeroWriter(Math.floor(wait_seconds / 60) % 60)
  let hours = zeroWriter(Math.floor(wait_seconds / (60 * 60)))
  let wait_writind = ""
  if (hours != '00') {
    wait_writind = hours + ':' + minutes + ':' + seconds
  } else {
    wait_writind = minutes + ':' + seconds
  }
  return wait_writind
}

export function podhodTimeRemaining(to_time) {
  let wait_seconds = Math.floor(
    (Date.parse(to_time) - Date.parse(new Date())) / 1000
  );
  let seconds = zeroWriter(wait_seconds % 60)
  let minutes = zeroWriter(Math.floor(wait_seconds / 60) % 60)
  let hours = zeroWriter(Math.floor(wait_seconds / (60 * 60)))
  let wait_writind = ""
  if (hours != '00') {
    wait_writind = hours + ':' + minutes + ':' + seconds
  } else {
    wait_writind = minutes + ':' + seconds
  }
  return wait_writind
}

export function step_timer(step_time) {
  let wait_seconds = Math.floor(
    90 - ((Date.parse(new Date()) - Date.parse(step_time)) / 1000)
  );
  // let seconds = zeroWriter(wait_seconds % 60)
  // let minutes = zeroWriter(Math.floor(wait_seconds / 60) % 60)
  // let hours = zeroWriter(Math.floor(wait_seconds / (60 * 60)))
  return wait_seconds
}

export let wheel_main_slider_calculator = function (context, elem_scroll, elem_resize_up, elem_resize_down, event, slide_under, slide_behind) {
  let over_scroll = 0
  // let elem_scroll_top = elem_scroll.scrollTop
  elem_scroll.addEventListener(event, function (e) {
    let backWheelToZero = function () {
      elem_resize_up.style["transform"] = "scaleY(0)"
      elem_resize_down.style["transform"] = "scaleY(0)"
      elem_resize_up.style["opacity"] = 0
      elem_resize_down.style["opacity"] = 0
      over_scroll = 0
    }
    let slideTo = function (context, slide_num) {
      context.$emit("slideTo", slide_num)
    }
    // console.log(Math.ceil(elem_scroll.scrollHeight - elem_scroll.offsetHeight), Math.ceil(elem_scroll.scrollTop))
    if (Math.ceil(elem_scroll.scrollHeight - elem_scroll.offsetHeight) == Math.ceil(elem_scroll.scrollTop) && slide_behind && e.deltaY > 0) {
      over_scroll += 1
      let scale_num = over_scroll * 0.02
      let opacity = over_scroll * 0.12
      elem_resize_down.style["transform"] = "scaleY(" + scale_num + ")"
      elem_resize_down.style["opacity"] = opacity
      // console.log(over_scroll)
      setTimeout(backWheelToZero, 500)
      if (over_scroll > 6) {
        slideTo(context, slide_behind)
      }
    }
    // console.log(elem_scroll.scrollTop, slide_under, e.deltaY)
    if (elem_scroll.scrollTop == 0 && (slide_under || slide_under === 0) && e.deltaY < 0) {
      over_scroll -= 1
      let scale_num = over_scroll * -1 * 0.02
      let opacity = over_scroll * -1 * 0.12
      elem_resize_up.style["transform"] = "scaleY(" + scale_num + ")"
      elem_resize_up.style["opacity"] = opacity
      setTimeout(backWheelToZero, 500)
      if (over_scroll < -6) {
        slideTo(context, slide_under)
      }
    }
  })
}

export function showIt(id) {
  let elem = document.getElementById(id)
  elem.style.display = "block"
  let ff = function () {
    elem.classList.add('show')
  }
  setTimeout(ff, 10)
}
export function hideIt(id, duration) {
  let elem = document.getElementById(id)
  elem.classList.remove('show')
  let ff = function () {
    elem.style.display = "none"
  }
  setTimeout(ff, duration)
}

export function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function zeroWriter(num) {
  if (num < 10) {
    return '0' + num
  }
  return '' + num
}

export function preloadImage(url) {
    var img=new Image();
    img.src=url;
}

export function monthText(month_year) {
  let month_year_splitted = month_year.split(".")
  let year = month_year_splitted[1]
  let month = month_year_splitted[0]
  let month_txt = ""
  if (month == "01") {
    month_txt = "Январь"
  } else if (month == "02") {
    month_txt = "Февраль"
  } else if (month == "03") {
    month_txt = "Март"
  } else if (month == "04") {
    month_txt = "Апрель"
  } else if (month == "05") {
    month_txt = "Май"
  } else if (month == "06") {
    month_txt = "Июнь"
  } else if (month == "07") {
    month_txt = "Июль"
  } else if (month == "08") {
    month_txt = "Август"
  } else if (month == "09") {
    month_txt = "Сентябрь"
  } else if (month == "10") {
    month_txt = "Октябрь"
  } else if (month == "11") {
    month_txt = "Ноябрь"
  } else if (month == "12") {
    month_txt = "Декабрь"
  }
  return month_txt + " " + year
}

export function ratingText(rating) {
  let rate_txt = rating + ""
  let rate_splitted = rate_txt.split(".")
  if (!rate_splitted[1]) {
    return rate_splitted[0] + ".00"
  } else if (rate_splitted[1] == "1") {
    return rate_splitted[0] + ".01"
  } else if (rate_splitted[1] == "2") {
    return rate_splitted[0] + ".02"
  } else if (rate_splitted[1] == "3") {
    return rate_splitted[0] + ".03"
  } else if (rate_splitted[1] == "4") {
    return rate_splitted[0] + ".04"
  } else if (rate_splitted[1] == "5") {
    return rate_splitted[0] + ".05"
  } else if (rate_splitted[1] == "6") {
    return rate_splitted[0] + ".06"
  } else if (rate_splitted[1] == "7") {
    return rate_splitted[0] + ".07"
  } else if (rate_splitted[1] == "8") {
    return rate_splitted[0] + ".08"
  } else if (rate_splitted[1] == "9") {
    return rate_splitted[0] + ".09"
  }
  return rate_txt
}