<template lang="pug">

.inside_wrapper
  .right_col_wrap
    .content_wrap
      .subcontent_wrap.mt-7
        h3.mb-4 Правила конкурса «Команда мечты»
        ol
          li.mb-2 Для участия в этом Конкурсе Вам необходимо отдать за свой клуб 2 голоса. Голоса, отданные за Ваш футбольный клуб, необходимые для участия в этом Конкурсе, не подлежат возврату, независимо от его результата.
          li.mb-2 Конкурс проводится с первого по последнее число каждого месяца. Принять участие в Конкурсе можно в любое время до его окончания. Победители получат призы.
          li.mb-2 Суть Конкурса заключается в том, чтобы его участники совместными действиями быстрее всех собрали набор виртуальных карточек футболистов, представляющих состав сборной СССР/России по футболу за определенный год.
          li.mb-2 В каждом Конкурсе состав сборной СССР/России может состоять из разного количества футболистов, но не более 22 игроков. Каждая виртуальная карточка представляет одного футболиста (например, Лев Яшин, вратарь).
          li.mb-2 В начале Конкурса, в разделе “Команда мечты”, Вам дается таблица с пустыми клетками, подписанными именами футболистов. Одна клетка в этой таблице уже заполнена карточкой, которая имеет уникальный код (например, A1B2C3) и является Вашей “личной” карточкой – ее код “привязан” только к Вам. Разным участникам могут достаться их “личные“ карточки одного и того же футболиста, но код у этих карточек всегда будет разный и уникальный для каждого участника. Каждый участник в своей таблице имеет одну “личную” карточку.
        p Приглашайте больше друзей и Вы обязательно победите!<br>Призы ждут вас! Желаем удачи!
        v-btn(@click="SomeClick") Переключить в полноэкранный режим
</template>


<script>

import { commonMixin } from "../shared/mixins"

export default {
  name: 'DreamteamRules',
  components: {
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {
    const mql = window.matchMedia("(orientation: landscape)");
    if (mql.matches) {
      console.log("Горизонтальная ориентация");
    } else {
      console.log("Портретная ориентация");
    }
    // Прослушивание события изменения ориентации
    mql.addListener(function(m) {
      if (m.matches) {
        console.log("Изменено на горизонтальный режим");
      } else {
        console.log("Изменено на портретный режим");
      }
    });
  },
  methods: {
    SomeClick () {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        document.documentElement.requestFullscreen();
        // screen.lockOrientationUniversal = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation;
        // screen.orientation.lock("landscape")
      }


    }
  }
}

</script>


<style lang="sass" scoped>


</style>
    