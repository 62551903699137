<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="КОМАНДА МЕЧТЫ")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")
      
      NoCard

      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import HeaDer from "./../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import HasCard from "./HasCard"
import NoCard from "./NoCard"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"

export default {
  name: 'DreamTeamDescription',
  components: {
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu,
    HasCard,
    NoCard
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      backoff_interval: null,
      sub_menu: [
        {
          "title": "Конкурс",
          "link": "/inside/dreamteam"
        },
        {
          "title": "Описание конкурса",
          "link": "/inside/dreamteam-info"
        },
        {
          "title": "Прогресс участников",
          "link": "/inside/dreamteam-progress"
        },
        {
          "title": "Правила",
          "link": "/inside/dreamteam-rules"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voice_given: "voice_given",
    })
  },
  mounted () {
    // let context = this
    // let backoff_usr_id = function () {
    //   if (context.voice_given >= 2) {
    //     let sub_menu = []
    //     for (let i=0; i<context.sub_menu.length; i++) {
    //       console.log(context.sub_menu[i])
    //       if (i == 1) {
    //         sub_menu.push({
    //           "title": "Описание конкурса",
    //           "link": "/inside/dreamteam-info"
    //         })
    //       }
    //       sub_menu.push(context.sub_menu[i])
    //     }
    //     context.sub_menu = sub_menu
    //     clearInterval(context.backoff_interval)
    //   } else if (context.voice_given === 0 || context.voice_given > 0) {
    //     clearInterval(context.backoff_interval)
    //   }
    // }
    // this.backoff_interval = setInterval(backoff_usr_id, 500)
  }
}

</script>


<style lang="sass" scoped>

</style>
    