<template lang="pug">
// vue-custom-scrollbar#first_screen_common(class="scroll-area")
#first_screen_common.scroll-area
  .content

    logotype
    .slogan
      h2 СОБЕРИТЕ КОМАНДУ МЕЧТЫ
    .subslogan
      p Конкурс проводится каждый месяц
    .definitions_warp
      .definition
        p Отдай два голоса за свой любимый клуб и получи карточку с именем футболиста из легендарной сборной СССР или России
      .definition
        p У тебя будет только одна карточка, поэтому кооперируйся с друзьями и другими участниками конкурса в соцсетях, чтобы собрать весь состав сборной
      .definition
        p Первые участники, собравшие все карточки команды, получат столько крутых призов, сколько футболистов в этой сборной!
    
    PrizeRegisterButtons(@slideTo="slideTo")

    // .slogan.with_border
    //   h2 СОБЕРИТЕ КОМАНДУ МЕЧТЫ И ПОЛУЧИТЕ ПРИЗЫ ВСЕ ВМЕСТЕ!
    
    // .down-arrow(@click="slideTo(4)")

</template>


<script>

import Logotype from './Logotype'
import PrizeRegisterButtons from './PrizeRegisterButtons'
import { commonMixin } from "../../shared/mixins"
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  
  name: 'SlideFourDreamteam',
  components: {
    Logotype,
    vueCustomScrollbar,
    PrizeRegisterButtons
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {

    }
  },
  created () {
    // this.championshipClubs()
  },
  mounted () {
    // let context = this
    // let second_screen_bitva = document.getElementById("four_screen_dreamteam")
    // let resized_with_scroll_up = document.getElementById("resized_with_scroll_up")
    // let resized_with_scroll_down = document.getElementById("resized_with_scroll_down")
    // wheel_main_slider_calculator(context, second_screen_bitva, resized_with_scroll_up, resized_with_scroll_down, "touchmove", 2, 4)
    // wheel_main_slider_calculator(context, second_screen_bitva, resized_with_scroll_up, resized_with_scroll_down, "wheel", 2, 4)
  },
  methods: {
    slideTo (slide_num) {
      this.$emit("slideTo", slide_num)
    },
  }
}
  
</script>


<style lang="sass" scoped>

.content
  position: relative
  padding-bottom: 150px
  @media (max-width: 700px)
    padding-bottom: 150px
  .logotype
    opacity: 0

#four_screen_dreamteam
  width: 100%
  height: 100%

.slogan
  margin-top: 2vh
  width: 100%
  display: flex
  justify-content: center
  h2
    font-size: 32px
    color: #241E36
    text-shadow: 0px 0px 4px #fff
    line-height: 41px
    @media (max-width: 700px)
      font-size: 22px
      line-height: 31px

.subslogan
  margin: 10px 20px 0
  width: calc(100% - 40px)
  display: flex
  flex-wrap: wrap
  justify-content: center
  p
    padding: 5px 30px 7px
    font-size: 21px
    line-height: 26px
    color: #fff
    border-radius: 5px
    font-weight: 300
    margin-bottom: 0
    background-color: #525059d1
    @media (max-width: 700px)
      font-size: 18px
      line-height: 24px

.definitions_warp
  margin: 3vh auto 0
  width: 100%
  max-width: 1400px
  padding: 0 20px
  display: flex
  flex-wrap: wrap
  align-items: stretch
  justify-content: space-between
  .definition
    width: calc(33.33% - 15px)
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    padding: 20px
    border-radius: 10px
    background-color: #525059d1
    p
      font-size: 21px
      line-height: 33px
      color: #fff
      font-weight: 300
      margin-bottom: 0
      @media (max-width: 700px)
        font-size: 18px
        line-height: 24px
    @media (max-width: 700px)
      width: 100%
      margin-bottom: 20px


.slogan.with_border
  margin: 6vh auto 0
  width: 100%
  max-width: 1400px
  padding: 0 20px
  display: flex
  justify-content: center
  h2
    padding: 20px 50px
    border: 1px solid #4f4f4f
    border-radius: 15px
    text-align: center
    color: #000
    font-weight: 400
    font-size: 27px
    line-height: 33px
    @media (max-width: 700px)
      font-size: 18px
      line-height: 25px
      padding: 10px 30px
.scroll_more
  p
    background-color: rgba(0, 0, 0, 0.7)
    padding: 10px 20px
    color: #bdbdbd
    @media (max-width: 700px)
      width: calc(100% - 40px)

.down-arrow
  border-color: #000
  &:after
    border-color: #000

</style>
