<template lang="pug">

.inside_wrapper
  .left_col_wrap
    LeftMenu

  .right_col_wrap
    HeaDer(header="ПОЛУЧЕННЫЕ ПРИЗЫ")
    .content_wrap
      
      HeaderSubMenu(:pages="sub_menu")

      div(v-if="prizes.length")
        .avatar_data_wrap(v-for="prize in prizes")
          .picture_block
            img(:src="prize.picture")
          .data_block
            h4 {{ prize.konkurs_name }}
            p {{ prize.description }}
      
      .subcontent_wrap(v-else)
        h3 У тебя пока нет призов

      //- .avatar_data_wrap
      //-   .picture_block
      //-     img(src="../../../assets/images/ava_example.png")
      //-   .data_block
      //-     h4 Команда мечты
      //-     p Ты одержал победу в конкурсе «Команда мечты». Твой приз — подарочный сертификат Ozon номиналом 5000 руб.
      //- .avatar_data_wrap
      //-   .picture_block
      //-     img(src="../../../assets/images/ava_example.png")
      //-   .data_block
      //-     h4 Команда мечты
      //-     p Ты одержал победу в конкурсе «Команда мечты». Твой приз — подарочный сертификат Ozon номиналом 5000 руб.
      //- .avatar_data_wrap
      //-   .picture_block
      //-     img(src="../../../assets/images/ava_example.png")
      //-   .data_block
      //-     h4 Команда мечты
      //-     p Ты одержал победу в конкурсе «Команда мечты». Твой приз — подарочный сертификат Ozon номиналом 5000 руб.
      //- .avatar_data_wrap
      //-   .picture_block
      //-     img(src="../../../assets/images/ava_example.png")
      //-   .data_block
      //-     h4 Команда мечты
      //-     p Ты одержал победу в конкурсе «Команда мечты». Твой приз — подарочный сертификат Ozon номиналом 5000 руб.
      //- .avatar_data_wrap
      //-   .picture_block
      //-     img(src="../../../assets/images/ava_example.png")
      //-   .data_block
      //-     h4 Команда мечты
      //-     p Ты одержал победу в конкурсе «Команда мечты». Твой приз — подарочный сертификат Ozon номиналом 5000 руб.
      //- .avatar_data_wrap
      //-   .picture_block
      //-     img(src="../../../assets/images/ava_example.png")
      //-   .data_block
      //-     h4 Команда мечты
      //-     p Ты одержал победу в конкурсе «Команда мечты». Твой приз — подарочный сертификат Ozon номиналом 5000 руб.

      BitvaTimer

</template>


<script>

import { commonMixin } from "../../../shared/mixins"
import { MyWins } from "../../../api/Inside"
import HeaDer from "../../Common/HeaDer"
import LeftMenu from "../../Common/LeftMenu"
import BitvaTimer from "../../Common/BitvaTimer"
import HeaderSubMenu from "../../Common/HeaderSubMenu"
import vueCustomScrollbar from "vue-custom-scrollbar"
import "vue-custom-scrollbar/dist/vueScrollbar.css"

export default {
  name: 'MyWins',
  components: {
    vueCustomScrollbar,
    HeaDer,
    BitvaTimer,
    HeaderSubMenu,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      prizes: [],
      sub_menu: [
        {
          "title": "Моя страница",
          "link": "/inside/personal"
        },
        {
          "title": "Мои призы",
          "link": "/inside/my-wins"
        },
        {
          "title": "История покупок",
          "link": "/inside/purchases"
        },
        {
          "title": "Персональные данные",
          "link": "/inside/my-data"
        }
      ]
    }
  },
  mounted () {
    console.log(this.prizes.length)
    this.MyWins()
  },
  methods: {
    async MyWins () {
      let context = this
      let result = await MyWins(context)
      if (result) {
        this.prizes = result["data"]
      }
    }
  }
}

</script>


<style lang="sass" scoped>

.avatar_data_wrap
  display: flex
  justify-content: flex-start
  align-items: center
  flex-wrap: wrap
  margin-top: 20px
  border-bottom: 1px dotted #DDDDDD
  padding-bottom: 20px
  .picture_block
    margin-right: 20px
    width: 145px
    @media (max-width: 550px)
      margin-right: 10px
      width: 100px
    @media (max-width: 400px)
      width: 80px
    img
      width: 145px
      @media (max-width: 550px)
        width: 100px
      @media (max-width: 400px)
        width: 80px
    .faked_picture
      width: 145px
      height: 145px
      border-radius: 145px
      background-color: #DDD
  .data_block
    text-align: left
    width: calc( 100% - 165px )
    @media (max-width: 550px)
      width: calc( 100% - 110px )
    @media (max-width: 400px)
      width: calc( 100% - 90px )
    h4
      margin-bottom: 5px
      font-size: 18px
      font-weight: 500

</style>
    