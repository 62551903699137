<template lang="pug">
div
  h3 ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
  h4 1. ОБЩИЕ ПОЛОЖЕНИЯ
  p 1.1. Настоящая Политика конфиденциальности (далее – <strong>Политика</strong>) относится к вебсайту, расположенному по адресу <a href="https://www.bitvafanatov.ru" title="Битва Фанатов">https://www.bitvafanatov.ru</a> (далее – <strong>Сайт</strong>).
  p 1.2. Термины, перечисленные ниже, имеют для целей настоящей Политики следующие значения:
  p.ml-2 <strong>(1) "Компания"</strong> – ИП «Козлов С.Ф.» (ОГРНИП 324774600077612), действующий по законодательству Российской Федерации, который является владельцем Сайта и им управляет;
  p.ml-2 <strong>(2) "Закон"</strong> – Федеральный закон Российской Федерации "О персональных данных" со всеми изменениями и дополнениями, а также иные законодательные акты Российской Федерации;
  p.ml-2 <strong>(3) "Персональные данные"</strong> – совокупность личных данных и/или неперсонифицированной информации о Пользователе, предоставляемую им самим Компании и/или автоматически собираемую Компанией и/или третьими лицами;
  p.ml-2 <strong>(4) "Пользователь", "Вы", "Ваш", "Вас", "Вами"</strong> или какие-либо другие схожие производные (в зависимости от контекста) означает юридическое или физическое лицо, которое <strong>(а)</strong> пользуется Сайтом и/или получило к нему доступ с какого-либо устройства и <strong>(б)</strong> дало свое согласие на соблюдение правил пользования Сайтом, изложенных в тексте Пользовательского соглашения (см. п. <strong>(5)</strong> ниже), путем использования данного Сайта;
  p.ml-2 <strong>(5) "Пользовательское соглашение"</strong> – соглашение, заключаемое между Компанией и Пользователем в отношении порядка, правил и особенностей использования Сайта. Пользователь присоединяется к такому соглашению и не имеет права вносить и/или требовать внесения в него каких-либо изменений или дополнений. Пользователь может ознакомиться с условиями Пользовательского соглашения на Сайте Компании;
  p.ml-2 <strong>(6) "Обработка Персональных данных"</strong> – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с Персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, удаление Персональных данных;
  p.ml-2 <strong>(7) "Автоматизированная обработка Персональных данных"</strong> – обработка Персональных данных с помощью средств вычислительной техники;
  p.ml-2 <strong>(8) "Распространение Персональных данных"</strong> – действие, направленное на раскрытие Персональных данных определенному кругу лиц по предварительному согласию, в случаях, предусмотренных законом;
  p.ml-2 <strong>(9) "Предоставление Персональных данных"</strong> – действия, направленные на раскрытие Персональных данных определенному лицу или определенному кругу лиц;
  p.ml-2 <strong>(10) "Обезличивание Персональных данных"</strong> – действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность Персональных данных к конкретному субъекту Персональных данных;
  p.ml-2 <strong>(11) "Файлы куки"</strong> – небольшие файлы, отправляемые каким-либо сайтом, и размещаемые на компьютерах, смартфонах, планшетах, часах и других мобильных устройствах Пользователя, для улучшения работы сайтов, а также качества размещенного в них контента.
  h4 2. ОТНОШЕНИЯ, НА КОТОРЫЕ РАСПРОСТРАНЯЕТСЯ ПОЛИТИКА
  h5 Общие положения
  p 2.1. Настоящая Политика используется и применима исключительно к Персональным данным, получаемым от Пользователя в связи с использованием им Сайта. Положения настоящей Политики направлены на:
  p.ml-2 <strong>(1)</strong> определение видов и типов получаемых Персональных данных, направлений и целей использования (обработки) Персональных данных, а также источников получения таких Персональных данных;
  p.ml-2 <strong>(2)</strong> определение прав Пользователя в отношении защиты конфиденциальности, передаваемых им Персональных данных;
  p.ml-2 <strong>(3)</strong> определение лиц, ответственных за обработку и хранение Персональных данных, а также третьих лиц, которым такие данные раскрываются (полностью или частично).
  p 2.2. Правила настоящей Политики не применяются в случае обработки третьими лицами Персональных данных, которые добровольно предоставляются Пользователем.
  p 2.3. Посредством использования Сайта Пользователь соглашается с условиями настоящей Политики и дает свое согласие Компании на сбор, обработку, удержание и хранение Персональных данных в порядке и на условиях, предусмотренных настоящей Политикой.
  p 2.4. Если Пользователь не согласен с условиями настоящей Политики и/или отдельные условия Политики ему не понятны, в таком случае Пользователь обязан немедленно прекратить использование Сайта.
  h5 Права Пользователя по защите Персональных данных
  p 2.5. В связи с предоставлением Персональных данных Пользователь автоматически получает следующие права:
  p.ml-2 <strong>(1)</strong> получать данные, касающиеся их обработки (основания и цели такой обработки, применяемые способы обработки, сведения о лицах, которые имеют доступ к ним или которым они могут быть раскрыты на основании договора или Закона);
  p.ml-2 <strong>(2)</strong> получать данные о месте нахождения и идентификационных данных лиц, совершающих обработку Персональных данных;
  p.ml-2 <strong>(3)</strong> получать данные о сроках хранения Персональных данных;
  p.ml-2 <strong>(4)</strong> получать данные об осуществленной или о предполагаемой трансграничной передаче Персональных данных;
  p.ml-2 <strong>(5)</strong> обжаловать действия или бездействие Компании в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке;
  p.ml-2 <strong>(6)</strong> получать возмещение убытков и/или компенсацию морального вреда в судебном порядке в следствие допущенных Компанией и/или третьими лицами нарушений прав Пользователя на охрану и защиту его Персональных данных;
  p.ml-2 <strong>(7)</strong> реализовывать иные права в области защиты персональных данных, предусмотренные Законом или положениями настоящей Политики.
  h4 3. ПЕРЕЧЕНЬ СОБИРАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ
  h5 Неперсонифицированная информация о пользователях
  p 3.1. В связи с использованием Сайта Компания может автоматически собирать и обрабатывать следующею неперсонифицированную информацию о Пользователе:
  p.ml-2 <strong>(1)</strong> информацию о трафике, возможном количестве совершенных кликов, логи и другие данные;
  p.ml-2 <strong>(2)</strong> информацию об устройстве (идентификационный номер устройства, с которого выполняется вход, операционная система, платформа, тип браузера и другая информация о браузере, IP адрес).
  h5 Личные данные о пользователях
  p 3.2. Пользователь предоставляет Компании о себе следующие личные данные:
  p.ml-2 <strong>(1)</strong> адрес электронной почты;
  p.ml-2 <strong>(2)</strong> номер мобильного телефона;
  p.ml-2 <strong>(3)</strong> данные, которые содержатся в Личном Кабинете (ЛК) Пользователя, все внутренние переписки Пользователя (если есть), а также иная активность ЛК Пользователя;
  p.ml-2 <strong>(4)</strong> данные о совершенных Пользователям полученных/оплаченных услугах через Сайт.
  p 3.3. Пользователь является единственным ответственным лицом за полноту предоставляемых личных (персональных) данных и обязан осуществлять своевременное их изменение (обновление, проверку, корректировку) на регулярной основе.
  p 3.4. Компания исходит из того, что все предоставленные Пользователем личные (персональные) данные являются достоверными, и что Пользователь поддерживает такую информацию в актуальном состоянии.
  h5 Информация о совершаемых транзакциях
  p 3.5. Пользователь через Сайт может осуществлять оплату за товары или услуги, посредством законных платежных систем (интернет-эквайринга).
  p 3.6. При осуществлении платежей Компания НЕ ИМЕЕТ ДОСТУПА К ДАННЫМ БАНКОВСКИХ КАРТ ИЛИ ИНЫХ ПЛАТЕЖНЫХ ИНСТРУМЕНТОВ Пользователей, т.к. система интернет-эквайринга, используемая на Сайте, перед оплатой отправляет Пользователя на страницу вебсайта банка или соответствующей платежной системы, на которой Пользователь вводит данные банковских карт или иных платежных инструментов, необходимые для проведения платежа, а все операции с этими данными производит исключительно банк или соответствующая платежная система. Компания получает от банка или платежной системы только уведомление о результатах платежной операции – успешно она прошла или нет.
  p 3.7. Пользователь дает свое согласие на доступ и сбор банком или соответствующей платежной системой, через который/которую проводится оплата, к своим Персональным данным, а также соглашается с политикой конфиденциальности банка или соответствующей платежной системы.
  h5 Использование файлов куки
  p 3.8. Сайт применяет определенные Файлы куки для сохранения IP-адреса, предпочтений Пользователей или типа используемого устройства с целью:
  p.ml-2 <strong>(1)</strong> ведения статистики посещений и трафика сайта;
  p.ml-2 <strong>(2)</strong> персонализации выводимых на экран Пользователя данных;
  p.ml-2 <strong>(3)</strong> сохранения данных, необходимых для идентификации Пользователя, в том числе при доступе с разных устройств;
  p.ml-2 <strong>(4)</strong> показа рекламы в соответствии с интересами и предпочтениями Пользователя. Сайт может использовать как собственные Файлы куки, принадлежащие Компании, так и Файлы куки третьих лиц.
  p 3.9. Сайт использует следующие Файлы куки:
  p.ml-2 <strong>(1)</strong> Технические (функциональные) Файлы куки, которые нужны для контроля трафика и передачи данных, для идентификации Пользователей и предоставления доступа Пользователю к контенту и без которых использование Сайта является функционально ограниченным, а также для предотвращения предоставления рекомендаций, не соответствующих интересам Пользователя;
  p.ml-2 <strong>(2)</strong> Статистические Файлы куки, которые нужны для отслеживания частоты посещаемости Сайта, способов использования Сайта и/или его контента Пользователями, выявления типа и вида контента, который является популярными или интересным для Пользователя;
  p.ml-2 <strong>(3)</strong> Рекламные (маркетинговые) Файлы куки, которые нужны для размещения рекламных и/или маркетинговых объявлений, соответствующих предпочтениям и интересам Пользователя;
  p.ml-2 <strong>(4)</strong> Файлы куки третьих лиц, которые устанавливаются третьими лицами с разрешения Пользователя и предназначены для проведения статистических исследований, касающихся поведения Пользователя в сети Интернет и/или направления персонализированных рекламных или маркетинговых материалов Пользователю и/или предоставления товаров или услуг.
  p 3.10. Пользователь имеет право в любой момент отключить Файлы куки путем изменения определенных настроек браузера. Такое отключение может повлечь за собой ограничение или изменение доступа Пользователя к функциональным возможностям Сайта и/или его контенту.
  h4 4. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
  h5 Определение целей обработки
  p 4.1. Сбор и обработка Персональных данных осуществляется в следующих целях:
  p.ml-2 <strong>(1)</strong> для анализа поведения Пользователя, а также выявления предпочтений Пользователя к определенному виду контента;
  p.ml-2 <strong>(2)</strong> для оперативной и корректной работы Сайта, улучшения его функциональности и контента;
  p.ml-2 <strong>(3)</strong> для идентификации Пользователя;
  p.ml-2 <strong>(4)</strong> для предоставления персонализированных рекламных и маркетинговых материалов;
  p.ml-2 <strong>(5)</strong> для направления персонализированных рекламных и маркетинговых материалов на указанный электронный адрес и/или мобильный телефон Пользователя;
  p.ml-2 <strong>(6)</strong> для соблюдения требований Закона;
  p.ml-2 <strong>(7)</strong> для отслеживания заказов, совершенных Пользователем через Сайт;
  p.ml-2 <strong>(8)</strong> для технической поддержки Сайта, выявления проблем в его работе и их устранение;
  p.ml-2 <strong>(9)</strong> для поддержания связи с Пользователем (коммуникация);
  p.ml-2 <strong>(10)</strong> для выполнения иных обязательств Компании, которые возникли перед Пользователем;
  p.ml-2 <strong>(11)</strong> для проведения статистических исследований;
  p.ml-2 <strong>(12)</strong> для любых других целей, при условии получения отдельного согласия от Пользователя.
  p 4.2. Обработка Персональных данных осуществляется на основе принципов:
  p.ml-2 <strong>(1)</strong> законности целей и способов обработки;
  p.ml-2 <strong>(2)</strong> добросовестности;
  p.ml-2 <strong>(3)</strong> соответствия целей обработки Персональных данных целям, заранее определенным и заявленным при сборе таких Персональных данных;
  p.ml-2 <strong>(4)</strong> соответствия объема и характера обрабатываемых Персональных данных заявленным целям их обработки.
  h5 Условия обработки персональных данных
  p 4.3. Обработка Персональных данных проводится в случаях:
  p.ml-2 <strong>(1)</strong> получения согласия от Пользователя;
  p.ml-2 <strong>(2)</strong> достижения Компанией целей, предусмотренных Законом;
  p.ml-2 <strong>(3)</strong> предоставления Пользователем своих Персональных данных неограниченному кругу лицу;
  p.ml-2 <strong>(4)</strong> выполнения иных обязательств Компании перед Пользователем, включая, однако не ограничиваясь, предоставление определенного контента Пользователю.
  p 4.4. В случае обезличивания Персональных данных, что не позволяет прямо или опосредованно определить Пользователя, последующее использование и раскрытие таких данных третьим лицам допускается и в отношении их более не применяются правила настоящей Политики.
  p 4.5. Компания принимает все возможные меры для защиты конфиденциальности полученных Персональных данных, за исключением случаев, когда Пользователь сделал такие данные общедоступными.
  p 4.6. Обработка Персональных данных осуществляется с использованием средств автоматизации и без использования таких средств автоматизации.
  p 4.7. Компания не ведет обработку Персональных данных на бумажных носителях информации.
  h4 5. ДОСТУП ТРЕТЬИХ ЛИЦ К ПЕРСОНАЛЬНЫМ ДАННЫМ
  h5 Раскрытие персональных данных третьим лицам
  p 5.1. Компания имеет право раскрывать Персональные данные:
  p.ml-2 <strong>(1)</strong> своим аффилированным лицам, филиалам и представительствам, открытым как на территории Российской Федерации, так и на территории других государств;
  p.ml-2 <strong>(2)</strong> правопреемникам Компании, которые возникли в результате его ликвидации, реорганизации или банкротства, и которые получили исключительные права владения Сайтом;
  p.ml-2 <strong>(3)</strong> третьим лицам исключительно для целей оказания получения Пользователем определенного контента или доступа к нему;
  p.ml-2 <strong>(4)</strong> третьим лицам, когда Пользователем было дано согласие на раскрытие, передачу или обработку своих Персональных данных, а также в иных случаях, прямо предусмотренных Законом или настоящей Политикой.
  p 5.2. Компания раскрывает Персональные данные только в том случае, если <strong>(1)</strong> уверена, что третьи лица будут соблюдать условия настоящей Политики и предпринимать такие же меры по защите конфиденциальности Персональных данных, которые предпринимает сама Компания, и <strong>(2)</strong> согласие на такое раскрытие было предварительно выражено Пользователем и/или допускается на основании Закона.
  h5 Реклама от третьих лиц
  p 5.3. Сайт может содержать рекламные баннеры и/или ссылки на сайты третьих лиц. Использование Пользователем таких сайтов (путем перехода по ссылке или любым другим способом) может повлечь за собой сбор, обработку и использование Персональных данных, а также возможную автоматическую передачу Файлов куки на устройство Пользователя, с которого совершается переход на сайт третьих лиц. Компания не несет какой-либо ответственности за способы, методы и порядок обработки Персональных данных сайтами третьих лиц, вследствие чего, Компания также не является ответственным лицом в случае раскрытия Персональных данных неограниченному кругу лиц в связи с использованием Пользователем таких сайтов.
  p 5.4. Компания настоятельно рекомендует каждому Пользователю детально ознакомиться с политиками защиты персональных данных используемых сайтов.
  p 5.5. Пользователь имеет право в любой момент отключить такие рекламные баннеры на Сайте в настройках своего браузера.
  h4 6. ИЗМЕНЕНИЕ ИЛИ УДАЛЕНИИ ПЕРСОНАЛЬНЫХ ДАННЫХ
  p 6.1. Пользователь имеет право в любой момент самостоятельно изменить или удалить свои Персональные данные, за исключением случаев, когда такое изменение или удаление может привести (1) к нарушению правил настоящей Политики; или (2) к нарушению Закона; или (3) характер таких Персональных данных является доказательством в каком-либо судебном процессе, возникшем между Компанией и Пользователем.
  p 6.2. Для удаления своих Персональных данных Пользователю требуется удалить свой личный аккаунт на Сайте. Возможность удаления личного аккаунта предоставлена Пользователю в его ЛК.
  p 6.3. Компания имеет право в любой момент удалить личный аккаунт Пользователя, если он нарушил условия настоящей Политики и/или Пользовательского соглашения.
  h4 7. ПОРЯДОК И СРОКИ ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ
  p 7.1. Хранение Персональных данных осуществляется Компанией самостоятельно и в течение всего срока использования Пользователем Сайта.
  p 7.2. В случае удаления личного аккаунта Пользователя по инициативе Пользователя или Компании, Персональные данные Пользователя хранятся в базах данных Компании пять лет в соответствии с законодательством РФ.
  p 7.3. Персональные данные Пользователя, по истечении вышеуказанного срока хранения (пять лет), удаляются автоматически заданным алгоритмом, который задает Компания.
  h4 8. ДОСТУП К САЙТУ НЕСОВЕРШЕННОЛЕТНИХ
  p 8.1. Без согласия родителей или законных опекунов Компания не собирает, не использует и не раскрывает Персональные данные лиц, если они не достигли полного совершеннолетия в стране постоянного места жительства (в РФ с 18 лет).
  p 8.2. Если Компании станет известно о наличии на сайте Персональных данных несовершеннолетних лиц, размещенных без согласия родителей или законных опекунов, Компания будет принимать все меры по удалению данной информации в кратчайшие сроки.
  p 8.3. В случае, если Вы не достигли полного совершеннолетия в стране постоянного места жительства (в РФ с 18 лет), просим Вас передать настоящую Политику конфиденциальности и Пользовательское соглашение для ознакомления Вашим родителям или законным опекунам. Используя Сайт, Вы тем самым подтверждаете, что Вы (Ваш родитель или законный опекун от Вашего имени, если Вы несовершеннолетний) прочли, поняли и согласны со всеми условиями настоящей Политики конфиденциальности и Пользовательского соглашения. Если Вы (Ваш родитель или законный опекун) не согласны с каким-либо пунктом настоящей Политики конфиденциальности или Пользовательского соглашения, просим Вас не использовать данный Сайт и не проходить процедуру регистрации.
  h4 9. ПОРЯДОК ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
  p 9.1. Защита конфиденциальности Персональных данных является первостепенной и важной задачей для Компании.
  p 9.2. Компания принимает технические и организационно-правовые меры в целях обеспечения защиты Персональных данных Пользователя от неправомерного или случайного доступа к ним, распространения, уничтожения, изменения, а также от иных неправомерных действий.
  p 9.3. Для авторизованного доступа к Сайту используется логин и пароль Пользователя. Пользователь не имеет права передавать свой логин и пароль третьим лицам, а также обязан обеспечивать их сохранность и конфиденциальность. Ответственность за сохранность данной информации полностью несет Пользователь.
  h4 10. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
  h5 Изменение и дополнение Политики
  p 10.1. Настоящая Политика может быть изменена или дополнена время от времени. Компания не несет какой-либо ответственности перед Пользователем за изменение или дополнений условий настоящей Политики без разрешения и/или согласия Пользователя.
  p 10.2. Пользователь сам обязуется на регулярной основе проверять положения настоящей Политики на предмет ее возможного изменения или дополнения.
  h5 Применимое законодательство
  p 10.3. Настоящая Политика разработана в соответствие с действующим законодательством о защите персональных данных Российской Федерации, в частности, с нормами Федерального закона от 27.07.2006 № 152-ФЗ "О персональных данных" (со всеми дополнениями и изменениями), Федерального закона от 21.07.2014 № 242-ФЗ "О внесении изменений в отдельные законодательные акты Российской Федерации в части уточнения порядка обработки персональных данных в информационно-телекоммуникационных сетях" (со всеми дополнениями и изменениями).
  h5 Риск разглашения
  p 10.4. Независимо от предпринимаемых Компанией мер защиты конфиденциальности Персональных данных, Пользователь настоящим считается должным образом ознакомлен с тем, что любая передача Персональных данных в сети Интернет не может быть гарантированно безопасной, поэтому Пользователь осуществляет такую передачу на свой собственный риск.

</template>

<script>
export default {
  name: 'ConfidentText',
}
</script>

<style lang="sass" scoped>
.mlminush4
  margin-left: -24px !important

h5
  font-size: 0.9rem

</style>
