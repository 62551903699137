<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer.mt-2(header="ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ")
    .content_wrap
      
      // HeaderSubMenu(:pages="sub_menu")
      
      .subcontent_wrap.mt-4
        ConfidentText.pt-2

      BitvaTimer.mt-5
  
  </template>
  
  
  <script>
  
  import { commonMixin } from "@/shared/mixins"
  import HeaDer from "@/components/Common/HeaDer"
  import LeftMenu from "@/components/Common/LeftMenu"
  import BitvaTimer from "@/components/Common/BitvaTimer"
  import HeaderSubMenu from "@/components/Common/HeaderSubMenu"
  import "vue-custom-scrollbar/dist/vueScrollbar.css"
  import { mapGetters } from "vuex"
  import ConfidentText from '@/components/Common/ConfidentText'
  
  export default {
    name: 'FightRules',
    components: {
      HeaDer,
      BitvaTimer,
      HeaderSubMenu,
      ConfidentText,
      LeftMenu
    },
    mixins: [
      commonMixin
    ],
    data () {
      return {
        sub_menu: [
          {
            "title": "Рейтинг участников",
            "link": "/inside/fight-common"
          },
          {
            "title": "Правила",
            "link": "/inside/fight-common-rules"
          }
        ]
      }
    },
    computed: {
      ...mapGetters("user_store", {
        voices: "voices",
        coins: "coins",
        my_club: "club",
        club_rating: "club_rating",
        voices_given: "voices_given",
        personal_club_rating: "personal_club_rating",
        personal_rating: "personal_rating",
      })
    },
    mounted () {
    },
    methods: {
    }
  }
  
  </script>
  
  
  <style lang="sass" scoped>
  
  
  </style>
      