<template lang="pug">

#landing_wrapper
  Logotype.landing_logo
  .slogan(@click="goTo('/registration')")
    h2 Сделай свой клуб<br>чемпионом!
  .subslogan
    p Первый онлайн-чемпионат<br>России среди болельщиков
  
  #form_wrap
    .int_round 1
    .first_step
      p Выбери свой футбольный клуб
      v-select(
        label="Твой любимый клуб"
        v-model="club"
        :items="clubs"
        :rules="[required]"
        return-object
        solo
      )
      .dashed_line(v-if="!club")
    .second_step(v-if="club")
      .int_round 2
      p Отдавай голоса за победу фанатов клуба «{{ club.text }}»
      .my_button.red_button(@click="VoteForClub")
        span Отдать голос за «{{ club.text }}»
      p.small_p(@click="CheckThePlace") Узнать на каком месте «{{club.text}}»

    .third_step(v-if="club")
      .int_round 3
      p Участвуй в конкурсах и играх, чтобы добыть больше голосов!
      .my_button.red_button(@click="goTo('/registration')")
        span Зарегистрироваться

    .fourth_step(v-if="club")
      .int_round 4
      p Выигрывай многочисленные денежные призы!
      .my_button.red_button(@click="goTo('/registration')")
        span Зарегистрироваться
      p.small_p(@click="inform = !inform") Читать подробнее о призах и конкурсах
      .dashed_line(v-if="club")
  .black_plashka

  .login_register_icons(v-if="is_auth")
    // a(@click="goTo('/inside/personal')") Личный Кабинет
    HeaDerIconsNewLanding(:is_main="true")
  .login_register(v-else)
    a(@click="goTo('/login')") Войти
    span &nbsp;&nbsp;|&nbsp;&nbsp;
    a(@click="goTo('/registration')") Регистрация

  v-dialog(v-model="inform" width="900")
    v-card
      v-card-title.main_card_title.pt-2.pr-10 Призы и конкурсы <i class="close_icon" @click="inform = !inform"></i>
      v-divider
      v-card-text.share_wrap.bp-0.main_card_txt
        v-row.pt-0.pb-2(align="start")
          v-col.pb-0.pt-7.dialog_inwrap.bitva_rules(cols="12" md="12")
            p В основе «Битвы Фанатов» лежит голосование. Игрок выбирает свой любимый клуб и может отдать неограниченное количество голосов в его поддержку. Один голос стоит 10 Баллов. Баллы можно добывать в играх и конкурсах. Клуб можно выбрать только один раз, при регистрации. Битва стартует первого числа каждого месяца и заканчивается в конце месяца. В конце каждой Битвы клуб, набравший наибольшее количество голосов, становится победителем.

            p Помимо конкурса клубов, каждый месяц проводятся конкурсы для болельщиков.

            h4 Герой Битвы
            p Побеждает участник, отдавший наибольшее количество голосов среди всех клубов. Приз составляет 14 000 руб. в виде сертификата Ozon.

            h4 Фан-лидер клуба
            p Побеждает участник, отдавший наибольшее количество голосов за свой клуб. Если клуб при этом стал победителем Битвы, учасник получает приз: 14 000 руб. в виде сертификата Ozon.

            p Если клуб не стал победителем Битвы — первые 10 болельщиков, отдавших наибольшее количество голосов за свой клуб, получат бонусные Баллы.

            h4 Команда мечты
            p Каждую битву, когда игрок отдаёт минимум два голоса за свой клуб, он получает одну (и только одну) случайную карточку футболиста сборной СССР. Игроку необходимо скооперироваться с другими болельщиками за пределами нашего сайта, чтобы собрать всю сборную. Каждый участник собранной сборной получает приз: 5 000 руб. в виде сертификата Ozon.

            h4 Поединки «Раз на раз»
            p На нашем сайте есть игра «Раз на раз» — казуальный файтинг между двумя болельщиками. Ближайший аналог — браузерная игра «Бойцовский клуб». Участник, одержавший набибольшее количество побед в месяц, получает приз — 5 000 руб. в виде сертификата Ozon.

            h4 Поделись и выиграй
            p Каждый болельщик при регистрации на нашем сайте получает реферальную ссылку. Если по этой ссылке зарегистрируется наибольшее количество новых пользователей в месяц, владелец ссылки получает приз: 10 000 руб. в виде сертификата Ozon.

            h4 Коллекция марок
            p Единственный конкурс, который длится три месяца вместо одного. Каждый раз, когда болельщик отдаёт голос в поддержку своего клуба, он получает одну из 33 марок. Если болельщик успевает за 3 месяца собрать все марки — он получает приз: 10 000 руб. в виде сертификата Ozon.

</template>


<script>
import Logotype from './Logotype'
import { mapGetters } from "vuex"
import { commonMixin } from "../../shared/mixins"
import { ShortestClubs, CheckThePlace, VoteForClubUnregister } from "../../api/Registration"
import { getAccessTokenSimple } from "../../api/api"
import { evercookie_indexdb_storage } from "../../plugins/my_cookan"
import HeaDerIconsNewLanding from "../Common/HeaDerIconsNewLanding"
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const fpPromise = FingerprintJS.load()
let fingerprint = null
;(async () => {
  // Get the visitor identifier when you need it.
  const fp = await fpPromise
  fingerprint = await fp.get()
  console.log(fingerprint.visitorId)
})()

export default {
  name: "LandingPage",
  components: {
    Logotype,
    HeaDerIconsNewLanding
  },
  mixins: [
    commonMixin,
  ],
  data () {
    return {
      club: null,
      clubs: [],
      is_auth: false,
      voted: false,
      inform: false,
      place: null
    }
  },
  computed: {
    ...mapGetters("user_store", {
      user_id: "user_id",
      username: "username",
    })
  },
  async created () {
    this.ShortClubs()
    await this.check_is_voted()
  },
  async mounted () {
    setInterval(this.check_is_voted, 1000)
  },
  async updated () {
    await this.checkIsAuth()
  },
  methods: {
    async ShortClubs () {
      let context = this
      this.clubs = await ShortestClubs(context)
    },
    async CheckThePlace () {
      let context = this
      this.place = await CheckThePlace(this.club.fc_id, context)
      this.$swal({
        "title": "Клуб «" + context.club.text + "» на " + context.place + " месте"
      })
    },
    async VoteForClub () {
      let context = this
      let data = {
        "club_id": this.club.fc_id,
        "fingerprint": fingerprint.visitorId
      }
      if (!this.voted) {
        let vote = await VoteForClubUnregister(data, context)
        if (vote.result == 'ok') {
          this.$swal({
            "icon": "success",
            "title": "Твой голос засчитан!",
            "text": "Не забудь зарегистрироваться и проверить победителей в начале следующего месяца"
          })
        } else if (vote.result == 'not ok') {
          this.already_voted()
        }
        evercookie_indexdb_storage("voted", "true")
        this.$cookies.set("voted", "true")
        localStorage.setItem("voted", "true")
        sessionStorage.setItem("voted", "true")
        this.voted = true
      } else {
        this.already_voted()
      }
    },
    async CheckerClick () {
      let result = await evercookie_indexdb_storage("voted")
      setTimeout(function() {}, 300)
      console.log("result", result)
    },
    async checkIsAuth () {
      let access = await getAccessTokenSimple()
      if (access) {
        this.is_auth = true
      }
    },
    async check_is_voted () {
      if (this.$cookies.get("voted")) {
        localStorage.setItem("voted", "true")
        sessionStorage.setItem("voted", "true")
        evercookie_indexdb_storage("voted", "true")
        this.voted = true
      } else if (localStorage.getItem("voted")) {
        this.$cookies.set("voted", "true")
        sessionStorage.setItem("voted", "true")
        evercookie_indexdb_storage("voted", "true")
        this.voted = true
      } else if (sessionStorage.getItem("voted")) {
        this.$cookies.set("voted", "true")
        sessionStorage.setItem("voted", "true")
        evercookie_indexdb_storage("voted", "true")
        this.voted = true
      } else if (this.voted) {
        this.$cookies.set("voted", "true")
        localStorage.setItem("voted", "true")
        sessionStorage.setItem("voted", "true")
        evercookie_indexdb_storage("voted", "true")
      } else if (await evercookie_indexdb_storage("voted")) {
        this.$cookies.set("voted", "true")
        localStorage.setItem("voted", "true")
        sessionStorage.setItem("voted", "true")
        this.voted = true
      }
    },
    already_voted () {
      this.$swal({
        "icon": "warning",
        "title": "Ты уже проголосовал.",
        "text": "Чтобы помочь своим победить — зови друзей!",
      })
    }
  }
}

</script>


<style lang="sass">

.bitva_rules
  h4
    font-size: 20px
    margin-bottom: 8px

#landing_wrapper
  padding: 0 20px
  width: 100%
  height: 100%
  background-color: #000  
  background-position: top center
  background-repeat: repeat
  background-image: url('~@/assets/images/green_gras.jpg')
  background-size: 50%
  position: relative
  @media (max-width: 1200px)
    background-size: 100%
  @media (max-width: 600px)
    background-size: 150%
    padding: 0 10px

  .landing_logo
    @media (max-width: 600px)
      padding-top: 15px

  .black_plashka
    width: 100%
    height: 42px
    background-color: #000
    left: 0
    top: 0
    position: absolute
    z-index: 0
    display: none
    @media (max-width: 600px)
      display: block

  .login_register
    position: absolute !important
    top: 9px !important

  .login_register_icons
    position: absolute !important
    top: -5px !important

  .dashed_line
    position: absolute
    bottom: -17px
    right: 0
    width: 7px
    height: 7px
    background-color: #fff
    &:after
      display: block
      content: ""
      bottom: -16px
      width: 100%
      left: 0
      height: 7px
      position: absolute
      background-color: #fff

  // .ficha_button
  //   color: #fff !important
  //   a
  //     color: #fff !important
  //     span
  //       color: #fff !important
  //   .v-btn
  //     width: 100%
  //     background: linear-gradient(#ff0038, #aa0025)
  //     span
  //       letter-spacing: normal
  //       text-transform: none
  //     @media (max-width: 700px)
  //       font-size: 13px

  .logotype
    a
      opacity: 1 !important
  
  .slogan
    margin-top: 40px
    position: static
    cursor: pointer
    @media (max-width: 1400px)
      margin-top: 30px
    @media (max-width: 700px)
      margin-top: -10px
    h2
      color: #f1eddd
      text-shadow: 0px 7px 10px #000
      font-size: 50px
      line-height: 55px
      @media (max-width: 1400px)
        font-size: 45px
        line-height: 50px
      @media (max-width: 700px)
        font-size: 35px
        line-height: 42px
      @media (max-width: 500px)
        font-size: 25px
        line-height: 33px
    
  .subslogan
    position: relative
    margin-top: 30px
    padding-top: 30px
    @media (max-width: 700px)
      margin-top: 20px
      padding-top: 20px
    &:before
      display: block
      content: ""
      top: 0
      width: 16%
      left: 42%
      height: 1px
      position: absolute
      background-color: #fff
    p
      font-size: 30px
      line-height: 38px
      text-shadow: 0px 7px 10px #000
      color: #fff
      @media (max-width: 700px)
        font-size: 25px
        line-height: 30px
      @media (max-width: 500px)
        font-size: 20px
        line-height: 25px

  #form_wrap
    width: 100%
    max-width: 800px
    position: relative
    margin: 50px auto
    @media (max-width: 700px)
      margin: 40px auto
    p
      color: #fff
      font-weight: 400
      font-size: 21px
      line-height: 27px
      text-align: left
      @media (max-width: 700px)
        font-size: 18px
        line-height: 24px
      @media (max-width: 600px)
        font-size: 16px
        line-height: 22px
    .first_step, .second_step, .third_step, .fourth_step
      position: relative
      width: 100%
      padding: 50px 50px 50px 75px
      text-align: left
      @media (max-width: 700px)
        padding: 30px 20px 30px 40px
      &:after
        display: block
        content: ""
        top: 0
        width: 100%
        left: 0
        height: 7px
        position: absolute
        background-color: #fff
      .small_p
        font-weight: 300
        color: #fff
        opacity: 0.8
        font-size: 16px
        margin-top: 10px
        margin-bottom: 0
        text-decoration: underline
        // border-bottom: 1px dashed #eee
        display: inline-block
        transition: all 0.3s ease
        text-align: left
        cursor: pointer
        &:hover
          text-decoration: none
          opacity: 0.95
        @media (max-width: 700px)
          font-size: 15px
          line-height: 22px
        @media (max-width: 600px)
          font-size: 14px
          line-height: 20px

    .first_step
      padding: 50px 50px 25px 75px
      @media (max-width: 700px)
        padding: 30px 30px 10px 50px
      &:before
        display: block
        content: ""
        top: 0
        right: 0
        height: 100%
        width: 7px
        position: absolute
        background-color: #fff
      
    .second_step
      padding: 50px 50px 40px 75px
      @media (max-width: 700px)
        padding: 30px 30px 30px 50px
      &:before
        display: block
        content: ""
        top: 0
        left: 22px
        height: 100%
        width: 7px
        position: absolute
        background-color: #fff

    .third_step
      padding: 50px 50px 55px 75px
      @media (max-width: 700px)
        padding: 30px 30px 35px 50px
      &:before
        display: block
        content: ""
        top: 0
        right: 0
        height: 100%
        width: 7px
        position: absolute
        background-color: #fff
      
    .fourth_step
      padding: 50px 50px 55px 75px
      @media (max-width: 700px)
        padding: 30px 30px 30px 50px
      &:before
        display: block
        content: ""
        top: 0
        left: 22px
        height: 100%
        width: 7px
        position: absolute
        background-color: #fff
      .dashed_line
        right: initial
        left: 22px

    .int_round
      width: 50px
      height: 50px
      border: 7px solid #fff
      border-radius: 25px
      position: absolute
      top: -22px
      left: 0
      background-color: #002300
      text-align: center
      line-height: 35px
      font-size: 25px
      font-weight: 600
      color: #fff
      z-index: 10

</style>
