import { client, errorHandler } from "./api";


export async function championshipClubs(context) {
  const response = await client.get("championship-clubs",
    {}
  ).catch(error => errorHandler(error, context, null))
  if (response) {
    return response.data;
  }
}

export async function championshipEnds(context) {
  const response = await client.get("championship-ends",
    {}
  ).catch(error => errorHandler(error, context, null))
  if (response) {
    return response.data;
  }
}

export async function getAllPrizes(context) {
  const response = await client.get("prizes",
    {}
  ).catch(error => errorHandler(error, context, null))
  if (response) {
    return response.data;
  }
}