<template lang="pug">

.inside_wrapper

  LeftMenu

  .right_col_wrap
    HeaDer(header="ПРИЗЫ")
    .content_wrap
      
      .definitions_warp.mt-0
        //- .definition(v-for="prize in prizes")
        //-   h3 {{ prize.name }}
        //-   .def_subwrap
        //-     img(:src="prize.picture")
        //-     p {{ prize.description }}
        //- .definition
        //-   h3 САМЫЙ АКТИВНЫЙ БОЛЕЛЬЩИК МЕСЯЦА
        //-   .def_subwrap
        //-     img(src="~@/assets/icons_and_logos/prize.jpg")
        //-     p Участник битвы, который отдаст наибольшее количество голосов за месяц проведения битвы, получит подарочный сертификат OZON на 20 000 руб!
        //- .definition
        //-   h3 САМЫЙ АКТИВНЫЙ АГИТАТОР МЕСЯЦА
        //-   .def_subwrap
        //-     img(src="~@/assets/icons_and_logos/prize.jpg")
        //-     p Участник, реферальной ссылкой которого при регистрации воспользовались наибольшее число раз, получит подарочный сертификат OZON на 10 000 руб!
        //- .definition
        //-   h3 КОЛЛЕКЦИОНЕР МАРОК
        //-   .def_subwrap
        //-     img(src="~@/assets/icons_and_logos/prize.jpg")
        //-     p Каждый участник, собравший все 33 уникальные марки получит подарочный сертификат OZON на 10 000 руб!
        //- .definition
        //-   h3 ПЕРВЫЕ 22 УЧАСТНИКА, СОБРАВШИЕ «КОМАНДУ МЕЧТЫ»
        //-   .def_subwrap
        //-     img(src="~@/assets/icons_and_logos/prize.jpg")
        //-     p 22 болельщика, которые первыми скооперируются и победят в конкурсе, получат подарочные сертификаты OZON на 5 000 руб. каждый!
        //- .definition
        //-   h3 ЧЕМПИОН МЕСЯЦА В ПОЕДИНКАХ «РАЗ НА РАЗ»
        //-   .def_subwrap
        //-     img(src="~@/assets/icons_and_logos/prize.jpg")
        //-     p Участник, одержавший больше всего побед в поединках «РАЗ НА РАЗ» получит подарочный сертификат OZON на 5 000 руб!
        .definition(v-for="prize in prizes")
          // Команда мечты
          .pos_r(v-if="prize.id == 1" @click="goTo('/inside/dreamteam')")
            h3 
              span {{ prize.name }}
            .def_subwrap
              img(src="~@/assets/icons_and_logos/ozon5000.png")
              p(v-html="prize.description")

          // Коллекционер марок
          .pos_r(v-else-if="prize.id == 2" @click="goTo('/inside/stamps')")
            h3 
              span {{ prize.name }}
            .def_subwrap
              img(src="~@/assets/icons_and_logos/ozon10000.png")
              p(v-html="prize.description")

          // Агитатор месяца
          .pos_r(v-else-if="prize.id == 3" @click="goTo('/inside/share-win')")
            h3 
              span {{ prize.name }}
            .def_subwrap
              img(src="~@/assets/icons_and_logos/ozon10000.png")
              p(v-html="prize.description")

          // Громила месяца
          .pos_r(v-else-if="prize.id == 4" @click="goTo('/inside/raznaraz')")
            h3 
              span {{ prize.name }}
            .def_subwrap
              img(src="~@/assets/icons_and_logos/ozon5000.png")
              p(v-html="prize.description")

          // Фан-Лидер клуба
          .pos_r(v-else-if="prize.id == 5" @click="goTo('/inside/fight-club')")
            h3 
              span {{ prize.name }}
            .def_subwrap
              img(src="~@/assets/icons_and_logos/ozon14000.png")
              p(v-html="prize.description")

          // Герой битвы
          .pos_r(v-else-if="prize.id == 6" @click="goTo('/inside/fight-common')")
            h3 
              span {{ prize.name }}
            .def_subwrap
              img(src="~@/assets/icons_and_logos/ozon14000.png")
              p(v-html="prize.description")
      div.mt-4.mobile_button
        v-btn.green_button(
          v-if="voices && club.fc_id == my_club.fc_id"
          x-large
          dark
          color="#04841F"
          @click="Vote"
        ) Отдать голос
        v-btn.green_button(
          v-else-if="club.fc_id == my_club.fc_id"
          x-large
          dark
          color="#04841F"
          @click="goTo('/inside/shop')"
        ) Получить ещё голос
      BitvaTimer.mt-5

</template>


<script>

import { commonMixin } from "../../shared/mixins"
import HeaDer from "./../Common/HeaDer"
import LeftMenu from "../Common/LeftMenu"
import BitvaTimer from "../Common/BitvaTimer"
import "vue-custom-scrollbar/dist/vueScrollbar.css"
import { mapGetters } from "vuex"
import { getAllPrizes } from "./../../api/LandingPage"

export default {
  name: 'PrizesToWin',
  components: {
    HeaDer,
    BitvaTimer,
    LeftMenu
  },
  mixins: [
    commonMixin
  ],
  data () {
    return {
      prizes: [],
    }
  },
  computed: {
    ...mapGetters("user_store", {
      voices: "voices",
      coins: "coins",
      my_club: "club",
      club: "club",
      club_rating: "club_rating",
      voices_given: "voices_given",
      personal_club_rating: "personal_club_rating",
      personal_rating: "personal_rating",
    })
  },
  mounted () {
    this.getAllPrizes()
  },
  methods: {
    async getAllPrizes () {
      let context = this
      let result = await getAllPrizes(context)
      if (result) {
        this.prizes = result
        //- console.log(result)
      }
    },
  }
}

</script>


<style lang="sass" scoped>

.definitions_warp
  width: 100%
  padding: 20px  0 0
  display: flex
  flex-wrap: wrap
  align-items: stretch
  justify-content: space-between
  .definition
    width: calc(50% - 10px)
    padding: 0 10px 20px 10px
    border-radius: 10px
    background-color: rgba(255, 255, 255, 0.9)
    margin-bottom: 20px
    cursor: pointer
    transition: box-shadow, 0.5s ease
    &:hover
      box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.2)
      h3
        span
          border-bottom: 1px dashed #fff
          transition: border-bottom, 0.5s ease
    h3
      text-align: left
      font-size: 20px
      line-height: 24px
      min-height: 32px
      width: 100%
      margin-top: 7px
      margin-bottom: 10px
      span
        display: inline-block
        font-size: 20px
        font-weight: 500
        border-bottom: 1px dashed #393939
        line-height: 20px
    .def_subwrap
      width: 100%
      display: flex
      align-items: flex-start
      img
        margin-right: 15px
        width: 105px
        position: relative
        top: 6px
      p
        font-size: 16px
        line-height: 22px
        color: #353535
        font-weight: 300
        margin-bottom: 0
        text-align: left
        width: calc(100% - 120px)
        @media (max-width: 700px)
          font-size: 18px
          line-height: 24px
    @media (max-width: 1250px)
      h3
        text-align: left
        font-size: 16px
        line-height: 22px
      .def_subwrap
        p
          font-size: 15px
          line-height: 26px
        img
          margin-right: 10px
          width: 90px
    @media (max-width: 1000px)
      h3
        height: 40px
        font-size: 15px
      width: calc(50% - 10px)
    @media (max-width: 700px)
      h3
        height: auto
      width: 100%
      margin-bottom: 20px
      .def_subwrap
        img
          display: none
        p
          width: 100%

</style>
    